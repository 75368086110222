import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { Translate, withLocalize } from 'react-localize-redux'
import { browserHistory } from 'react-router'
import * as platforms from '../common/Platforms'
import * as yup from 'yup'
import Form from 'react-formal'

import {
    Button,
    FormGroup,
    ControlLabel,
    Alert
} from 'react-bootstrap'
import {
    Main,
    UserPhoto,
    Icon,
    Container,
    Data,
    List,
    Wrapper,
    SecondaryItem,
    Separator
} from './styles'
import InfoUserProfile from '../../containers/Conversation/components/InfoUserProfile'
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
class StartQuery extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: {},
            loading: false,
            formValues: {
                textToSend: this.props.location.query.text || ''
            }
        }
        this.start = this.start.bind(this)
    }

    componentWillMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    _setState(state) {
        return new Promise((resolve) => {
            if (this._isMounted) {
                this.setState(state, resolve)
            } else {
                resolve()
            }
        })
    }

    getModelSchema() {
        const { formValues, value } = this.state

        return yup.object({
            textToSend: yup.string().required(this.props.translate('query.placeholders.message')).default(formValues.textToSend)
        })
    }

    async start() {
        const self = this
        try {
            const { textToSend } = self.state.formValues
            const { userId, accountId, appCodename, platform } = self.props.location.query

            await self._setState({
                loading: true
            })

            if (!userId || !accountId || !appCodename || !platform) {
                throw new Error('errorParams')
            }

            const response = await self.props.actions.createMessageUser(appCodename, { userId, accountId, platform, url: self.props.location.search })
            const messageUser = response && response.payload && response.payload.data && response.payload.data.messageUser
            if (!messageUser) {
                throw new Error('errorMessageuser')
            }
            const checkQueryActiveByUser = await self.props.actions.verifyQuery(appCodename, messageUser._id)
            if (checkQueryActiveByUser && checkQueryActiveByUser.payload && checkQueryActiveByUser.payload.data && checkQueryActiveByUser.payload.data.queryId) {
                await self._setState({
                    activeQueryId: `${process.env.project.API_URL.toString()}/manager/customer/${appCodename}/${checkQueryActiveByUser.payload.data.queryId}`
                })
                throw new Error('activeQuery')
            }
            const query = await self.props.actions.newQuery(appCodename, {
                message: {},
                messageUser,
                account: { _id: accountId }
            })
            if (!query) {
                throw new Error('errorQuery')
            }
            const queryId = query && query.payload && query.payload.data && query.payload.data._id
            if (queryId) {
                const promise = []
                Object.keys(self.props.location.query).forEach(queryKey => {
                    if (['profile.name', 'profile.email', 'profile.id'].indexOf(queryKey) >= 0 || queryKey.startsWith('profile.custom')) {
                        let valueParse = self.props.location.query[queryKey]
                        try {
                            valueParse = JSON.parse(self.props.location.query[queryKey])
                        } catch (e) {
                            //
                        }
                        if (typeof valueParse === 'string' || typeof valueParse === 'number' || typeof valueParse === 'boolean') {
                            promise.push(self.props.actions.editContext(appCodename, messageUser._id, queryKey, self.props.location.query[queryKey], queryId))
                        }
                    }
                })
                promise.push(self.props.actions.editContext(appCodename, messageUser._id, 'context.urlQuery', self.props.location.search, queryId))
                promise.push(self.props.actions.editContext(appCodename, messageUser._id, 'customerService.active', true, queryId))
                promise.push(self.props.actions.editContext(appCodename, messageUser._id, 'customerService.id', queryId, queryId))
                await Promise.all(promise)

                await self.props.actions.webhookAction(appCodename, 'send', messageUser._id, {
                    text: textToSend,
                    queryId
                })
                await self.props.actions.takeQuery(appCodename, queryId)
                browserHistory.push({
                    pathname: `/manager/customer/${appCodename}/${queryId}`,
                    search: '?status=me'
                })
            }
        } catch (e) {
            await self._setState({
                error: {
                    error: true,
                    title: e.message || 'errorQuery'
                }
            })
        } finally {
            await self._setState({
                loading: false
            })
        }
    }

    getCustomProperties(query) {
        const toReturn = []
        if (query && Object.keys(query).length) {
            toReturn.push(
                <SecondaryItem key='separator'>
                    <Separator />
                </SecondaryItem>
            )
            for (const [key, value] of Object.entries(query)) {
                let displayValue = value || '-'
                try {
                    value = JSON.parse(value)
                } catch (e) {
                    //
                }
                if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                    toReturn.push(
                        <SecondaryItem key={key} style={{ paddingBottom: '2px', paddingTop: '2px' }}>
                            <strong>{key}:</strong>&nbsp;{displayValue || '-'}
                        </SecondaryItem>
                    )
                }
            }
        }
        return toReturn
    }

    render() {
        const self = this
        const { error, loading } = self.state
        const query = self.props.location.query
        const { userId, appCodename, platform } = query
        const platformSettings = platforms[platform] || platforms['unknown']
        const icon = platformSettings.icon
        const color = platformSettings.color
        return (
            <Container>
                <Wrapper>
                    {
                        error && error.error && <Alert bsStyle="danger">{self.props.translate(`query.create.${error.title}`)} {self.state.activeQueryId && <a href={`${self.state.activeQueryId}`}>{self.props.translate('navigation.notification.click')}</a>}</Alert>
                    }
                    <Main>
                        <UserPhoto color={color}>
                            <Icon color={color}>
                                <i className={icon}></i>
                            </Icon>
                        </UserPhoto>
                        <Data>
                            <InfoUserProfile
                                type='primary'
                                propValue={query['profile.name'] || self.props.translate('messageUser.defaultName')}
                                disabled={true}
                            />
                        </Data>
                    </Main>
                    <List>
                        <InfoUserProfile
                            style={{ margin: '10px' }}
                            propName={'Id'}
                            propValue={userId}
                            disabled={true}
                        />
                        <InfoUserProfile
                            propName={'Bot'}
                            propValue={appCodename}
                            disabled={true}
                        />
                        {self.getCustomProperties(query)}
                        <Form key='form' className="col-xs-12" schema={self.getModelSchema()} defaultValue={self.getModelSchema().default()} onChange={(formValues, updatedPath) => {
                            self.setState({
                                formValues
                            })
                        }} onSubmit={self.start}>
                            <FormGroup>
                                <ControlLabel>
                                    <Translate id="query.message.type.default" />
                                </ControlLabel>
                                <Form.Field name="textToSend" className="form-control" type="textarea" rows={3} />
                                <Form.Message for={'textToSend'} />
                            </FormGroup>
                            {platform && ['whatsapp-chatapi-instance', 'telegram'].indexOf(platform) >= 0 ?
                                <Button type="submit" disabled={loading} style={{
                                    backgroundColor: '#F4D345',
                                    color: '#000'
                                }}>
                                    <FontAwesomeIcon icon="paper-plane" /> &nbsp;
                                    <Translate id="buttons.send" />
                                </Button>
                                : <></>}
                        </Form>
                    </List>
                </Wrapper>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        isReady: state.isReady,
        profile: state.profile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(StartQuery))
