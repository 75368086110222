import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import * as project from '../../utils/project'
import List from './../common/List'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import moment from 'moment'
import 'moment-timezone'
import momentLocalizer from 'react-widgets-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate, withLocalize } from 'react-localize-redux'

import {
    Button
} from 'react-bootstrap'

class Schedule extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataColumnsNames: [
                'schedules.dataColumnsNames.working', 'schedules.dataColumnsNames.week', 'schedules.dataColumnsNames.from', 'schedules.dataColumnsNames.to'
            ],
            days: null,
            save: 'save'
        }

        this.getSchedule = this.getSchedule.bind(this)
        this.cleanSchedule = this.cleanSchedule.bind(this)
        this.updateSchedule = this.updateSchedule.bind(this)

        this.props.actions.getSchedule = this.props.actions.getSchedule.bind(this, this.props.params.appCodename, { sort: { isoWeekday: 1 } })
        this.props.actions.cleanSchedule = this.props.actions.cleanSchedule.bind(this)

        momentLocalizer(moment)
    }

    getSchedule() {
        const days = []
        for (let isoWeekday = 1; isoWeekday <= 7; isoWeekday++) {
            days.push({
                enabled: false,
                from: '09:00',
                to: '18:00',
                isoWeekday: isoWeekday
            })
        }

        return this.props.actions.getSchedule().then(result => {
            const schedule = result.payload.data.objects

            schedule.forEach(day => {
                days.splice(day.isoWeekday - 1, 1, day)
            })

            this.setState({
                days
            }, () => Promise.resolve())
        }).catch(() => {
            // do nothing
        })
    }

    cleanSchedule() {
        return this.props.actions.cleanSchedule().then(() => {
            this.setState({
                days: null
            }, () => Promise.resolve())
        })
    }

    updateSchedule() {
        let promise = Promise.resolve()

        this.setState({
            save: 'sync'
        })

        promise = promise.then(() => {
            return this.props.actions.updateScheduleDay(this.props.params.appCodename, { days: this.state.days })
        }).catch(() => {
            // do nothing
        }).then(action => {
            if (action && action.payload && action.payload.data && action.payload.data.ok) {
                this.setState({
                    save: 'check'
                })
            } else {
                this.setState({
                    save: 'times'
                })
            }

            setTimeout(() => {
                this.setState({
                    save: 'save'
                })
            }, 2000)
        })

        return promise
    }

    changeValue(value, prop, index) {
        const days = this.state.days.slice(0)
        days[index][prop] = value
        this.setState({
            days
        })
    }

    dataObjectProcess(day, index) {
        const { activeLanguage } = this.props
        const row = []
        const enabled = day.enabled
        const from = moment(day.from, 'HH:mm').toDate()
        const to = moment(day.to, 'HH:mm').toDate()

        row.push(
            <input
                type="checkbox"
                checked={enabled}
                onChange={(e) => {
                    this.changeValue(e.target.checked, 'enabled', index)
                }}
            />
        )
        row.push(
            <Translate id={`schedules.days.${day.isoWeekday}`} />
        )
        row.push(
            <DateTimePicker
                culture={activeLanguage.code}
                date={false}
                parse={['HH:mm', 'H:mm']}
                format="HH:mm"
                timeFormat="HH:mm"
                defaultValue={from}
                onChange={v => {
                    this.changeValue(moment(v).format('HH:mm'), 'from', index)
                }}
                onSelect={v => {
                    this.changeValue(moment(v).format('HH:mm'), 'from', index)
                }}
            />
        )
        row.push(
            <DateTimePicker
                culture={activeLanguage.code}
                date={false}
                parse={['HH:mm', 'H:mm']}
                format="HH:mm"
                timeFormat="HH:mm"
                defaultValue={to}
                onChange={v => {
                    this.changeValue(moment(v).format('HH:mm'), 'to', index)
                }}
                onSelect={v => {
                    this.changeValue(moment(v).format('HH:mm'), 'to', index)
                }}
            />
        )

        return row
    }

    render() {
        const itemsProcessed = []

        if (this.state.days) {
            this.state.days.forEach((day, index) => {
                itemsProcessed.push(this.dataObjectProcess(day, index))
            })
        }

        const description = (
            <div className="row">
                <div className="col-xs-12">
                    <Button title={this.props.translate('buttons.save')} bsStyle="primary" onClick={this.updateSchedule}>
                        <FontAwesomeIcon icon={this.state.save} />
                    </Button>
                    <Link to={`/manager/${this.props.params.appCodename}/schedule/unavailable`} className="btn btn-default pull-right">
                        <FontAwesomeIcon icon="calendar-alt" />&nbsp;&nbsp;<Translate id="schedules.buttons.expections" />
                    </Link>
                </div>
            </div>
        )

        return (
            <div>
                <List ref={(list) => { this.list = list }}
                    items={itemsProcessed}
                    name={this.props.translate('schedules.titles.list')}
                    dataColumnsNames={this.state.dataColumnsNames.map(dataColumnsNames => this.props.translate(dataColumnsNames))}
                    description={description}
                    getObjects={this.getSchedule}
                    clearObjects={this.cleanSchedule}
                    routing={this.props.routing}
                    displayLimit={false}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        schedule: state.schedule,
        profile: state.profile,
        routing: state.routing
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Schedule))
