import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'

class UsersContainer extends Component {
    render() {
        var self = this
        return (
            <div>
                { self.props.children }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.users,
        routing: state.routing
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(UsersContainer)
