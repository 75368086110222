import styled from 'styled-components'

export const Container = styled.div`
    padding: 1px 15px;
    border-radius: 3px;
`

export const Action = styled.div`
    display: ${props => props.enabled ? 'inline-block' : 'none'};
    float: right;
`

export const Explanation = styled.div`
    display: ${props => props.enabled ? 'block' : 'none'};
    font-size: 1rem;
    font-style: italic;
    color: gray;
    margin-bottom: 5px;
`

export const Answer = styled.p`
    display: ${props => props.enabled ? 'block' : 'none'};
    white-space: pre-wrap;
`

export const Label = styled.label`
    font-size: 1.18rem;
    cursor: ${props => props.cursor};
`

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
`

export const DropCont = styled.p`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    cursor: pointer;
`
export const DropText = styled.p`
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
`

export const FilesText = styled.p`
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
`
export const Text = styled.div `
    border: none;
    background-color: #fff;
    color: #999999;
    font-size: 15px;
    box-sizing: border-box;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto;
    margin-top: 10px;

    & > input {
        border: none;
        border-bottom: 1px dashed #D4D6D6;

        &:focus {
            outline: 0;
        }
    }
`
