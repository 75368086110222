import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import Navigation from '../Navigation'
import { Link } from 'react-router'
import { Alert, ButtonToolbar, Modal, Button } from 'react-bootstrap'
import moment from 'moment'
import { Translate, withLocalize } from 'react-localize-redux'
import '../common/Platforms/index.less'

class Stats extends Component {
    constructor(props) {
        super(props)

        const self = this
        self.state = {
            loading: false,
            modal: {
                data: {
                    title: null,
                    description: null,
                    extraBody: null,
                    button: {
                        cancel: {
                            title: null,
                            className: null
                        },
                        success: {
                            title: null,
                            className: null,
                            onClick: null,
                            onClickBind: null
                        },
                        moreButtons: []
                    }
                },
                metadata: {},
                show: false
            }
        }
        self.bullStats = props.actions.bullStats.bind(self)
        self.uptimeStats = props.actions.uptimeStats.bind(self)
        self.clearNotification = self.clearNotification.bind(self)
        self.openModal = self.openModal.bind(self)
        self.closeModal = self.closeModal.bind(self)
        self.updateAllStats = self.updateAllStats.bind(self)

        self.bullQueuesStyles = {
            active: true,
            completed: true,
            delayed: true,
            failed: true,
            paused: true,
            waiting: true,
            'waiting-children': true
        }

        self.bullQueuesColumns = ['queueName'].concat(Object.keys(self.bullQueuesStyles))
    }

    updateAllStats() {
        const self = this
        self.bullStats()
        self.uptimeStats()
    }

    componentDidMount() {
        const self = this
        self.updateAllStats()
        self.refreshDataTimer = setInterval(self.updateAllStats, 10000)
    }

    componentWillUnmount() {
        const self = this
        if (self.refreshDataTimer) {
            clearInterval(self.refreshDataTimer)
            self.refreshDataTimer = null
        }
    }

    openModal(data, metadata) {
        const self = this
        self.setState({
            modal: {
                data,
                metadata,
                show: true
            }
        }, _ => {
            if (self.modalInputElement) {
                self.modalInputElement.focus()
            }
        })
    }

    closeModal() {
        const self = this
        self.setState({ modal: { show: false } })
        self.modalInputElement = null
    }

    getModal() {
        const self = this
        return self.state.modal.show && (
            <div className="static-modal">
                <Modal show={self.state.modal.show} onHide={ self.closeModal.bind(self) }>
                    <Modal.Header closeButton>
                        <Modal.Title>{ self.state.modal.data && self.state.modal.data.title }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <strong>{ self.state.modal.data && self.state.modal.data.description }</strong>
                        { self.state.modal.data && self.state.modal.data.extraBody }
                    </Modal.Body>
                    <Modal.Footer>
                        { self.state.modal.data && self.state.modal.data.button && self.state.modal.data.button.cancel && self.state.modal.data.button.cancel.title && (
                            <Button className={ self.state.modal.data.button.cancel.className } onClick={ self.closeModal.bind(self) }>{ self.state.modal.data.button.cancel.title }</Button>
                        )}
                        { self.state.modal.data && self.state.modal.data.button && self.state.modal.data.button.success && self.state.modal.data.button.success.title && (
                            <Button className={ self.state.modal.data.button.success.className } onClick={ self.state.modal.data.button.success && self.state.modal.data.button.success.onClick && self.state.modal.data.button.success.onClickBind && self.state.modal.data.button.success.onClick.bind(self.state.modal.data.button.success.onClickBind, self.state.modal.metadata) || null }>{ self.state.modal.data.button.success.title }</Button>
                        )}
                        { self.state.modal.data && self.state.modal.data.button && self.state.modal.data.button.moreButtons && self.state.modal.data.button.moreButtons.map(function(button, index) {
                            if (button.title && button.className) {
                                const onClickCall = (button.onClick && button.onClickBind) ? button.onClick.bind(button.onClickBind, self.state.modal.metadata) : null
                                return <Button key={'moreButtons-' + index} className={button.className} onClick={ onClickCall }>{button.title}</Button>
                            }
                        }) }
                    </Modal.Footer>
                </Modal>
            </div>
        ) || null
    }

    clearNotification() {
        const self = this
        if (self.clearNotificationTimer) {
            clearTimeout(self.clearNotificationTimer)
            self.clearNotificationTimer = null
        }
        self.setState({ notification: null })
    }

    render() {
        const self = this
        const lastSeenMaxAgo = moment.utc().subtract(30, 'seconds')
        const bullStatsForQueueNames = self.props.bullStats && self.props.bullStats.stats && Object.keys(self.props.bullStats.stats)
        let showProcessValueIsBadInfo = false

        return (
            <div>
                <Navigation title="Superadmin" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header"><Translate id="queueStats.title"/></h1>
                        </div>
                    </div>
                    { self.state.notification && self.state.notification.data && self.state.notification.status && (
                        <div className="row">
                            <div className="col-lg-12">
                                <Alert bsStyle={ self.state.notification.status } onDismiss={self.clearNotification}>
                                    <strong>{ self.state.notification.data }</strong>
                                </Alert>
                            </div>
                        </div>
                    ) || null}
                    { self.props.uptimeStats && (
                        <div className="row">
                            <div className="col-lg-12">
                                <h3><Translate id="queueStats.uptime"/></h3>
                            </div>
                        </div>
                    ) || null}
                    { self.props.uptimeStats && (
                        <div className="row">
                            <div className="col-lg-12">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <td className="col-md-2"><strong><Translate id="queueStats.process"/></strong></td>
                                            <td className="col-md-2"><strong><Translate id="queueStats.branch"/></strong></td>
                                            <td className="col-md-2"><strong><Translate id="queueStats.uptime"/></strong></td>
                                            <td className="col-md-2"><strong><Translate id="queueStats.responseTime"/></strong></td>
                                            <td className="col-md-2"><strong><Translate id="queueStats.memoryUsage"/></strong></td>
                                            <td className="col-md-2"><strong><Translate id="queueStats.cpuUsage"/></strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            self.props.uptimeStats && self.props.uptimeStats.uptime && Object.keys(self.props.uptimeStats.uptime).map(process => {
                                                const uptime = self.props.uptimeStats.uptime[process]
                                                const branch = self.props.uptimeStats.branch[process]
                                                const lastSeen = self.props.uptimeStats.lastSeen[process]
                                                const responseTime = self.props.uptimeStats.responseTime[process]
                                                const memoryUsage = self.props.uptimeStats.memoryUsage[process]
                                                const cpuUsage = self.props.uptimeStats.cpuUsage[process]
                                                const lastSeenSuccceded = uptime && lastSeen && lastSeen.isAfter(lastSeenMaxAgo)
                                                const labelClass = lastSeenSuccceded ? 'label-success' : 'label-danger'
                                                showProcessValueIsBadInfo = showProcessValueIsBadInfo || !lastSeenSuccceded
                                                return (
                                                    <tr key={ process }>
                                                        <td><h4>{ process }</h4></td>
                                                        <td><h4>{ branch || (<Translate id="queueStats.unknown"/>) }</h4></td>
                                                        <td><h4><span className={ 'label ' + labelClass }>{!lastSeenSuccceded && `⚠️ `}{ uptime && `${uptime && (uptime / 60).toFixed(0)} minutes` || (<Translate id="queueStats.unknown"/>) }</span></h4></td>
                                                        <td><h4><span className={ 'label ' + labelClass }>{!lastSeenSuccceded && `⚠️ `}{ uptime && lastSeen && `${responseTime} ms` || (<Translate id="queueStats.unknown"/>) }</span></h4></td>
                                                        <td><h4><span className={ 'label ' + labelClass }>{!lastSeenSuccceded && `⚠️ `}{ uptime && lastSeen && memoryUsage && memoryUsage.rss && `${(memoryUsage.rss / 1024 / 1024).toFixed(2)} MB` || (<Translate id="queueStats.unknown"/>) }</span></h4></td>
                                                        <td><h4><span className={ 'label ' + labelClass }>{!lastSeenSuccceded && `⚠️ `}{ uptime && lastSeen && memoryUsage && memoryUsage.rss && `${(cpuUsage.user / 1000 / 60 / 60).toFixed(2)} minutes` || (<Translate id="queueStats.unknown"/>) }</span></h4></td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) || null}
                    { showProcessValueIsBadInfo && (
                        <div className="row">
                            <div className="col-lg-12">
                                <Alert bsStyle="danger">
                                    <Translate
                                        id="queueStats.reboot"
                                        options={{ renderInnerHtml: true }}
                                        data={{
                                            link: (
                                                `<a href="https://bitbucket.org/mobiletonic/chattonic-api/addon/pipelines/home" target="_blank">Bitbucket</a>`
                                            )
                                        }}
                                    />
                                </Alert>
                            </div>
                        </div>
                    ) || null}
                    { self.props.bullStats && (
                        <div className="row">
                            <div className="col-lg-12">
                                <h3><Translate id="queueStats.bull"/></h3>
                            </div>
                        </div>
                    ) || null}
                    { (
                        (!bullStatsForQueueNames || bullStatsForQueueNames && !bullStatsForQueueNames.length) && (
                            <Alert bsStyle="info">
                                <Translate id="queueStats.bullNoStats"/>
                            </Alert>
                        )
                    ) || null}
                    { bullStatsForQueueNames && bullStatsForQueueNames.length && (
                        <div className="row">
                            <div className="col-lg-12">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            {
                                                self.bullQueuesColumns.map((bullQueuesColumn, i) => {
                                                    if (!i) {
                                                        return (
                                                            <td key={`bullTitleColumn${i}`}><strong><Translate id="queueStats.queueName" /></strong></td>
                                                        )
                                                    } else {
                                                        return (
                                                            <td key={`bullTitleColumn${i}`}><strong>{ bullQueuesColumn }</strong></td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { bullStatsForQueueNames.map(queueName => {
                                            const queueStats = self.props.bullStats.stats[queueName]
                                            return (
                                                <tr key={ queueName }>
                                                    {
                                                        self.bullQueuesColumns.map((bullQueuesColumn, i) => {
                                                            if (!i) {
                                                                return (
                                                                    <td key={`bull${queueName}Column${i}`}><h4>{ queueName }</h4></td>
                                                                )
                                                            } else {
                                                                return (
                                                                    <td key={`bull${queueName}Column${i}`}><h4>{ queueStats[bullQueuesColumn] }</h4></td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) || null}
                    <div className="row">
                        <div className="col-lg-12">
                            <ButtonToolbar className="pull-right">
                                <Link className="btn btn-default" to={'/manager/app'}><Translate id="queueStats.back"/></Link>
                            </ButtonToolbar>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            { self.getModal() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        bullStats: state.bullStats,
        uptimeStats: state.uptimeStats
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Stats))
