// SENTRY
import * as Sentry from '@sentry/browser'
import { RewriteFrames } from '@sentry/integrations'
if (process.env && process.env.project && process.env.project.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.project.SENTRY_DSN,
        environment: process.env.MT_ENV,
        integrations: [
            new RewriteFrames()
        ],
        ignoreErrors: [
            'ChunkLoadError',
            'Loading chunk',
            'Out of memory',
            'ResizeObserver loop limit exceeded'
        ],
        beforeSend(event) {
            if (event && event.extra && event.extra.__serialized__ && event.extra.__serialized__.apiCall) {
                // IGNORE ALL UNHANDLED EXCEPTIONS FROM API CALL
                return null
            }
            return event
        }
    })
}

import React from 'react'
import store from './state/store'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import { IndexRoute, Router, Route, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import 'react-widgets/lib/less/react-widgets.less'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)

// BASE
import Base from './containers/Base'

// LOGIN
import Login from './containers/Login'

// DASHBOARD
import Dashboard from './containers/Dashboard'

// APPS
import AppsContainer from './containers/AppsContainer'
import Apps from './components/Apps'
import AppAdd from './components/AppAdd'
import AppEdit from './components/AppEdit'

import Stats from './components/Stats'

// MANAGER
import Manager from './containers/Manager'

// CUSTOMER
import Customer from './containers/Customer'
import Conversation from './containers/Conversation'

// USERS
import UsersContainer from './containers/UsersContainer'
import Users from './components/Users'
import UserEdit from './components/UserEdit'
import UserAdd from './components/UserAdd'

// TOPICS
import TopicsContainer from './containers/TopicsContainer'
import Topics from './components/Topics'
import TopicAddEdit from './components/TopicAddEdit'

// SCHEDULES
import ScheduleContainer from './containers/ScheduleContainer'
import Schedule from './components/Schedule'

// UNAVAILABLE SCHEDULES
import ScheduleUnavailable from './components/ScheduleUnavailable'
import ScheduleUnavailableDayAddEdit from './components/ScheduleUnavailableDayAddEdit'

// Resolutions
import ResolutionContainer from './containers/ResolutionContainer'
import Resolution from './components/Resolution'

// Origins
import OriginContainer from './containers/OriginContainer'
import Origin from './components/Origin'

// Derive
import Derive from './components/Derive'

import StartQuery from './components/StartQuery'

// Hsm
import HsmContainer from './containers/HsmContainer'
import HSMs from './components/HSMs'

const rootElement = document.getElementById('root')
const history = syncHistoryWithStore(browserHistory, store)

render(
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
                <Route path="/manager" component={ Base }>
                    <Route path="start" component={ StartQuery } />
                    <Route path="login" component={ Login }/>
                    <Route path="app" component={ AppsContainer }>
                        <IndexRoute component={ Apps }/>
                        <Route path="add" component={ AppAdd }/>
                        <Route path="edit/:appId" component={ AppEdit }/>
                    </Route>
                    <Route path="stats" component={Stats} />
                    <Route path=":appCodename" component={ Manager }>
                        <Route path="dashboard" component={ Dashboard }/>
                        <Route path="derive" component={ Derive }/>
                        <Route path="user" component={ UsersContainer }>
                            <IndexRoute component={ Users }/>
                            <Route path="edit/:userId" component={ UserEdit }/>
                            <Route path="add" component={ UserAdd }/>
                        </Route>
                        <Route path="origins" component={ OriginContainer }>
                            <IndexRoute component={ Origin }/>
                        </Route>
                        <Route path="resolutions" component={ ResolutionContainer }>
                            <IndexRoute component={ Resolution }/>
                        </Route>
                        <Route path="schedule" component={ ScheduleContainer }>
                            <IndexRoute component={ Schedule }/>
                            <Route path="unavailable" component={ ScheduleUnavailable }/>
                            <Route path="unavailable/add" component={ ScheduleUnavailableDayAddEdit }/>
                            <Route path="unavailable/edit/:scheduleUnavailableDayId" component={ ScheduleUnavailableDayAddEdit }/>
                        </Route>
                        <Route path="topic" component={ TopicsContainer }>
                            <IndexRoute component={ Topics }/>
                            <Route path="add" component={ TopicAddEdit }/>
                            <Route path="edit/:topicId" component={ TopicAddEdit }/>
                        </Route>
                        <Route path="hsm" component={ HsmContainer }>
                            <IndexRoute component={ HSMs } />
                        </Route>
                    </Route>
                    <Route path="customer/:appCodename" component={ Customer }>
                        <Route path=":queryId" component={ Conversation }/>
                    </Route>
                </Route>
            </Router>
        </LocalizeProvider>
    </Provider>,
    rootElement
)
