import React, { Component} from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import moment from 'moment'
import 'moment-timezone'
import DashboardItem from '../../components/common/DashboardItem'
import 'bootstrap-daterangepicker/daterangepicker.css'
import style from './styles.less'
import { Translate, withLocalize } from 'react-localize-redux'
import { browserHistory } from 'react-router'

const now = moment()

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dashboardKey: `dashboardCharts-${new Date().toISOString()}`,
            chartsLoading: true,
            selectedRangeStr: 'calendar.ranges.last24hours',
            selectedRange: [now.clone().subtract(24, 'hours'), now.clone()],
            getCharts: false,
            focus: true
        }

        this.dashboardValues = {
            pending: 0,
            progress: 0,
            resolved: 0,
            failed: 0,
            attention: 0,
            resolution: 0,
            attentionAndResolution: 0,
            userEfficiency: 0,
            interactionEfficiency: 0
        }

        this.onSetInterval = this.onSetInterval.bind(this)
        this.onClearInterval = this.onClearInterval.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
    }

    componentDidMount() {
        const self = this
        window.addEventListener('focus', self.onFocus)
        window.addEventListener('blur', self.onBlur)
        this.onSetInterval()
    }

    componentWillUnmount() {
        const self = this
        window.removeEventListener('focus', self.onFocus)
        window.removeEventListener('blur', self.onBlur)
        this.onClearInterval()
    }

    onSetInterval() {
        const self = this
        self.getCharts().then(() => {
            self.refreshDataTimer = setInterval(_ => {
                self.getCharts()
            }, 60000)
        }).catch(_ => {
            // console.log(error)
        })
    }

    onClearInterval() {
        const self = this
        if (self.refreshDataTimer) {
            clearInterval(self.refreshDataTimer)
            self.refreshDataTimer = null
        }
    }

    onFocus() {
        const self = this
        if (!self.state.focus) {
            self.setState({
                focus: true
            }, _ => self.onSetInterval())
        }
    }

    onBlur() {
        const self = this
        if (self.state.focus) {
            self.setState({
                focus: false
            }, _ => this.onClearInterval())
        }
    }

    changePeriod(event, picker) {
        const self = this
        const { localize } = this.props
        let label = picker.chosenLabel
        let endDate = picker.endDate.clone()

        if (picker.chosenLabel === self.props.translate('calendar.customRangeLabel')) {
            label = `${picker.startDate.clone().format('DD-MM-YYYY')} - ${picker.endDate.clone().format('DD-MM-YYYY')}`
            endDate = picker.endDate.clone().endOf('day')
        }

        if (!label.startsWith('calendar.ranges') && localize && localize.translations) {
            const keys = Object.keys(localize.translations).filter(k => k.startsWith('calendar.ranges'))
            const key = keys.find(k => localize.translations[k].indexOf(label) >= 0)
            if (key) {
                label = key
            }
        }

        this.setState({
            selectedRange: [picker.startDate.clone(), endDate],
            selectedRangeStr: label,
            dashboardKey: `dashboardCharts-${new Date().toISOString()}`,
            chartsLoading: true
        }, _ => {
            self.getCharts()
        })
    }

    getCharts() {
        const self = this
        if (self.state.focus && !self.state.getCharts) {
            self.setState({
                getCharts: true
            })

            const { selectedRange, selectedRangeStr } = this.state
            const { app } = this.props
            const query = {
                dateFormatGroup: '%Y-%m-%d %H',
                queryName: 'dashboard'
            }

            const now = moment().tz(app.timezone)
            if (selectedRangeStr === 'calendar.ranges.last24hours') {
                query.startDate = now.clone().subtract(24, 'hours')
                query.endDate = now.clone()
            } else if (selectedRangeStr === 'calendar.ranges.today') {
                query.startDate = now.clone().startOf('day')
                query.endDate = now.clone().endOf('day')
            } else if (selectedRangeStr === 'calendar.ranges.yesterday') {
                query.startDate = now.clone().subtract(1, 'days').startOf('day')
                query.endDate = now.clone().subtract(1, 'days').endOf('day')
            } else if (selectedRangeStr === 'calendar.ranges.last7Days') {
                query.startDate = now.clone().startOf('day').subtract(6, 'days')
                query.endDate = now.clone().endOf('day')
            } else if (selectedRangeStr === 'calendar.ranges.thisWeek') {
                query.startDate = now.clone().startOf('week')
                query.endDate = now.clone().endOf('week')
            } else if (selectedRangeStr === 'calendar.ranges.last30Days') {
                query.startDate = now.clone().startOf('day').subtract(29, 'days')
                query.endDate = now.clone().endOf('day')
            } else if (selectedRangeStr === 'calendar.ranges.thisMonth') {
                query.startDate = now.clone().startOf('month')
                query.endDate = now.clone().endOf('month')
            } else if (selectedRangeStr === 'calendar.ranges.lastMonth') {
                query.startDate = now.clone().subtract(1, 'month').startOf('month')
                query.endDate = now.clone().subtract(1, 'month').endOf('month')
            } else if (selectedRangeStr === 'calendar.ranges.last6Months') {
                query.startDate = now.clone().startOf('month').subtract(5, 'months')
                query.endDate = now.clone().endOf('month')
            } else if (selectedRangeStr === 'calendar.ranges.last12Months') {
                query.startDate = now.clone().startOf('month').subtract(11, 'months')
                query.endDate = now.clone().endOf('month')
            } else {
                query.startDate = now.clone().year(selectedRange[0].year()).month(selectedRange[0].month()).date(selectedRange[0].date()).startOf('day')
                query.endDate = now.clone().year(selectedRange[1].year()).month(selectedRange[1].month()).date(selectedRange[1].date()).endOf('day')
            }

            query.startDate = query.startDate.utc().toISOString()
            query.endDate = query.endDate.utc().toISOString()

            return this.props.actions.requestChartData(this.props.params.appCodename, query).then(action => {
                if (action && action.payload && action.payload.data && action.payload.data[0]) {
                    const data = action.payload.data[0]
                    const dashboardValues = Object.keys(self.dashboardValues)
                    dashboardValues.forEach(dashboardValue => {
                        self.dashboardValues[dashboardValue] = data[dashboardValue] || 0
                    })

                    self.setState({
                        dashboardKey: `dashboardCharts-${new Date().toISOString()}`,
                        chartsLoading: false,
                        getCharts: false
                    })
                }
            })
        } else {
            return Promise.resolve()
        }
    }

    goPage(pathname) {
        browserHistory.push(pathname)
    }

    render() {
        const self = this
        const { app } = self.props
        let content = null
        const now = moment()
        const timeRanges = Object.assign({}, {
            [this.props.translate('calendar.ranges.last24hours')]: [now.clone().subtract(24, 'hours'), now.clone()],
            [this.props.translate('calendar.ranges.today')]: [now.clone().startOf('day'), now.clone().endOf('day')],
            [this.props.translate('calendar.ranges.yesterday')]: [now.clone().subtract(1, 'days').startOf('day'), now.clone().subtract(1, 'days').endOf('day')],
            [this.props.translate('calendar.ranges.last7Days')]: [now.clone().startOf('day').subtract(6, 'days'), now.clone().endOf('day')],
            [this.props.translate('calendar.ranges.thisWeek')]: [now.clone().startOf('week'), now.clone().endOf('week')],
            [this.props.translate('calendar.ranges.last30Days')]: [now.clone().startOf('day').subtract(29, 'days'), now.clone().endOf('day')],
            [this.props.translate('calendar.ranges.thisMonth')]: [now.clone().startOf('month'), now.clone().endOf('month')],
            [this.props.translate('calendar.ranges.lastMonth')]: [now.clone().subtract(1, 'month').startOf('month'), now.clone().subtract(1, 'month').endOf('month')],
            [this.props.translate('calendar.ranges.last6Months')]: [now.clone().startOf('month').subtract(5, 'months'), now.clone().endOf('month')],
            [this.props.translate('calendar.ranges.last12Months')]: [now.clone().startOf('month').subtract(11, 'months'), now.clone().endOf('month')]
        })
        const key = now.clone().toISOString()
        const dashboardItems = []

        dashboardItems.push(
            <DashboardItem
                key={`pending_${key}`}
                value={self.dashboardValues.pending}
                icon="comment-alt"
                name={self.props.translate('dashboard.chart.pending')}
                color="red"
                selected={this.goPage.bind(this, `/manager/customer/${this.props.params.appCodename}?status=pending`)}
                classNames="col-lg-6 col-md-6"
            />
        )

        dashboardItems.push(
            <DashboardItem
                key={`progress_${key}`}
                value={self.dashboardValues.progress}
                icon="comment-alt"
                name={self.props.translate('dashboard.chart.progress')}
                color="yellow"
                selected={this.goPage.bind(this, `/manager/customer/${this.props.params.appCodename}?status=progress`)}
                classNames="col-lg-6 col-md-6"
            />
        )

        dashboardItems.push(
            <DashboardItem
                key={`resolved_${key}`}
                value={self.dashboardValues.resolved}
                icon="comment-alt"
                name={self.props.translate('dashboard.chart.resolved')}
                color="green"
                selected={this.goPage.bind(this, `/manager/customer/${this.props.params.appCodename}?status=resolved`)}
                classNames="col-lg-6 col-md-6"
            />
        )

        dashboardItems.push(
            <DashboardItem
                key={`failed_${key}`}
                value={self.dashboardValues.failed}
                icon="comment-alt"
                name={self.props.translate('dashboard.chart.failed')}
                color="ct-gray"
                selected={this.goPage.bind(this, `/manager/customer/${this.props.params.appCodename}?status=failed`)}
                classNames="col-lg-6 col-md-6"
            />
        )

        dashboardItems.push(
            <DashboardItem
                key={`attention_${key}`}
                value={self.dashboardValues.attention}
                icon="check"
                name={self.props.translate('dashboard.chart.attention')}
                color="ct-purple"
                classNames="col-lg-4 col-md-4"
            />
        )

        dashboardItems.push(
            <DashboardItem
                key={`resolution_${key}`}
                value={self.dashboardValues.resolution}
                icon="check"
                name={self.props.translate('dashboard.chart.resolution')}
                color="ct-purple"
                classNames="col-lg-4 col-md-4"
            />
        )

        dashboardItems.push(
            <DashboardItem
                key={`attentionAndResolution_${key}`}
                value={self.dashboardValues.attentionAndResolution}
                icon="check"
                name={self.props.translate('dashboard.chart.attentionAndResolution')}
                color="ct-purple"
                classNames="col-lg-4 col-md-4"
            />
        )

        if (dashboardItems && dashboardItems.length) {
            content = dashboardItems.map(function(dashboardItem) {
                return dashboardItem
            })
        }

        return (
            <div key={self.state.dashboardKey}>
                <div className="row">
                    <div className="col-xs-12">
                        <h1 className="page-header"><Translate id="dashboard.title"/></h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className={style.timeButton}>
                            <label><Translate id="dashboard.period"/>:</label>
                            <DateRangePicker
                                key={`calendar_${self.props.activeLanguage.code}`}
                                startDate={self.state.selectedRange[0].clone().format('MM/DD/YYYY')}
                                endDate={self.state.selectedRange[1].clone().format('MM/DD/YYYY')}
                                alwaysShowCalendars={true}
                                locale={Object.assign({}, {
                                    format: 'MM/DD/YYYY',
                                    separator: ' - ',
                                    applyLabel: this.props.translate('calendar.applyLabel'),
                                    cancelLabel: this.props.translate('calendar.cancelLabel'),
                                    fromLabel: this.props.translate('calendar.fromLabel'),
                                    toLabel: this.props.translate('calendar.toLabel'),
                                    customRangeLabel: this.props.translate('calendar.customRangeLabel'),
                                    weekLabel: this.props.translate('calendar.weekLabel'),
                                    daysOfWeek: [
                                        this.props.translate('calendar.daysOfWeek.sunday'),
                                        this.props.translate('calendar.daysOfWeek.monday'),
                                        this.props.translate('calendar.daysOfWeek.tuesday'),
                                        this.props.translate('calendar.daysOfWeek.wednesday'),
                                        this.props.translate('calendar.daysOfWeek.thursday'),
                                        this.props.translate('calendar.daysOfWeek.friday'),
                                        this.props.translate('calendar.daysOfWeek.saturday')
                                    ],
                                    monthNames: [
                                        this.props.translate('calendar.monthNames.january'),
                                        this.props.translate('calendar.monthNames.february'),
                                        this.props.translate('calendar.monthNames.march'),
                                        this.props.translate('calendar.monthNames.april'),
                                        this.props.translate('calendar.monthNames.may'),
                                        this.props.translate('calendar.monthNames.june'),
                                        this.props.translate('calendar.monthNames.july'),
                                        this.props.translate('calendar.monthNames.august'),
                                        this.props.translate('calendar.monthNames.september'),
                                        this.props.translate('calendar.monthNames.october'),
                                        this.props.translate('calendar.monthNames.november'),
                                        this.props.translate('calendar.monthNames.december')
                                    ]
                                })}
                                timePicker={false}
                                timePicker24Hour={true}
                                ranges={timeRanges}
                                onApply={self.changePeriod.bind(self)}
                            >
                                <button className="btn btn-default">
                                    {(this.state.selectedRangeStr.startsWith('calendar.ranges')) ? (
                                        <Translate id={this.state.selectedRangeStr} />
                                    ) : this.state.selectedRangeStr}
                                </button>
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        { content }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        localize: state.localize,
        routing: state.routing
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Dashboard))
