import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Translate } from 'react-localize-redux'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import * as actions from '../../../../state/actions'
import { bindActionCreators } from 'redux'
import Spinner from '../../../../components/common/Spinner'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import {
    Container,
    Images,
    StatusFooter
} from './styles'

class Menu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            urls: [
                {
                    name: 'status',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 500
                    }
                },
                {
                    url: '/dashboard',
                    name: 'menu.dashboard',
                    icon: 'tachometer-alt',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 600
                    }
                }, {
                    custom: true,
                    url: `/manager/customer/${this.props.appCodename}`,
                    name: 'menu.queries',
                    icon: 'headset',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 500
                    }
                }, {
                    url: '/derive',
                    name: 'menu.derive',
                    icon: 'share',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 1000
                    }
                }, {
                    url: '/user',
                    name: 'menu.users',
                    icon: 'user',
                    condition: () => {
                        return this.props.profile && (this.props.profile.profile >= 1000 || this.props.profile.authAbmUser && (this.props.profile.authAbmUser.generateUser || this.props.profile.authAbmUser.editUser || this.props.profile.authAbmUser.deleteUser))
                    }
                }, {
                    url: '/topic',
                    name: 'menu.knowledgeBase',
                    icon: 'tasks',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 600
                    }
                }, {
                    url: '/hsm',
                    name: 'menu.whatsAppHsm',
                    icon: ['fab', 'whatsapp'],
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 500
                    }
                }, {
                    collapse: true,
                    collapsed: true,
                    name: 'menu.config',
                    icon: 'cog',
                    urls: [
                        {
                            url: '/schedule',
                            name: 'menu.schedule',
                            icon: 'calendar-alt'
                        }, {
                            url: '/origins',
                            name: 'menu.origins',
                            icon: 'comment'
                        }, {
                            url: '/resolutions',
                            name: 'menu.resolutions',
                            icon: 'check'
                        }
                    ],
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 600
                    }
                }
            ],
            show: false,
            loading: false
        }
    }

    setShow(value) {
        this.setState({show: value})
    }

    toggleCollapse(url) {
        const { urls } = this.state
        const matchedUrl = urls.indexOf(url)

        if (matchedUrl >= 0) {
            urls[matchedUrl].collapsed = !urls[matchedUrl].collapsed
            this.setState({
                urls
            })
        }
    }

    changeStatusAlert(status, titleStatus, descriptionStatus, saveStatus) {
        if (this.props.app.derivationOfQueries && this.props.app.derivationOfQueries.enabled) {
            this.setState({
                show: true,
                titleStatus: titleStatus,
                descriptionStatus: descriptionStatus,
                saveStatus: this.changeStatus.bind(this, status)
            })
        } else {
            this.changeStatus(status)
        }
    }

    async changeStatus(status) {
        await this.setState({ loading: true })
        await this.props.actions.changeStatus(this.props.appCodename, status)
        await this.setState({ loading: false, show: false })
        this.props.toggleMenu()
    }

    render () {
        const { urls } = this.state
        const { app, appCodename, profile, show, status, statusCT } = this.props

        const options = []
        urls.forEach((url, index) => {
            if (!url.condition || (url.condition && url.condition())) {
                if (url.name === 'status') {
                    if (app && app.userStatus && profile && profile.profile < 2000) {
                        options.push(<div key={url.name} className="status">
                            <div className="title">
                                <div className={(status) || 'offline'}>
                                    <FontAwesomeIcon icon={(status === 'online') ? 'user-astronaut' : (status === 'away') ? 'user-clock' : 'user-times'} />
                                </div>
                                <a>
                                    {(profile && profile.name && profile.name.givenName) && (
                                        profile.name.givenName
                                    )}
                                    {' '}
                                    <Translate id='menu.you' />
                                    <div className="bold">
                                        <div className={(status) || 'offline'}>
                                            <Translate id={(status === 'online') ? 'menu.online' : (status === 'away') ? 'menu.away' : 'menu.offline'} />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            {(status !== 'online') && (
                                <Button
                                    onClick={this.changeStatusAlert.bind(this, 'online', <Translate id='menu.updateOnline' />, <Translate id='menu.descriptionUpdateOnline' />)}
                                >
                                    <div className="online"><FontAwesomeIcon icon='user-astronaut' /></div>
                                    <Translate id='menu.change' />
                                    <div className="boldButton">
                                        <div className="online">
                                            <Translate id='menu.online' />
                                        </div>
                                    </div>
                                </Button>
                            )}
                            {(status !== 'away') && (
                                <Button
                                    onClick={this.changeStatusAlert.bind(this, 'away', <Translate id='menu.updateAway' />, <Translate id='menu.descriptionUpdateAway' />)}
                                >
                                    <div className="away"><FontAwesomeIcon icon='user-clock' /></div>
                                    <Translate id='menu.change' />
                                    <a className="boldButton">
                                        <div className="away">
                                            <Translate id='menu.away' />
                                        </div>
                                    </a>
                                </Button>
                            )}
                            {(status !== 'offline') && (
                                <Button
                                    onClick={this.changeStatusAlert.bind(this, 'offline', <Translate id='menu.updateOffline' />, <Translate id='menu.descriptionUpdateOffline' />)}
                                >
                                    <div className="offline"><FontAwesomeIcon icon='user-times' /></div>
                                    <Translate id='menu.change' />
                                    <a className="boldButton">
                                        <div className="offline"><Translate id='menu.offline' /></div>
                                    </a>
                                </Button>
                            )}
                        </div>)
                    }
                } else if (url.collapse) {
                    options.push(
                        <li className="nohover" key={'collapse-' + index}>
                            <a onClick={this.toggleCollapse.bind(this, url)}>
                                <div>
                                    {url.icon ? (<FontAwesomeIcon icon={url.icon} />) : ''}
                                    &nbsp;&nbsp;
                                    <Translate id={url.name} />
                                    <FontAwesomeIcon icon={`caret-${!url.collapsed ? 'up' : 'down'}`} style={{ float: 'right' }} />
                                </div>
                            </a>
                            {!url.collapsed && <ul>
                                {url.urls.map(slUrl => {
                                    if (!slUrl.condition || (slUrl.condition && slUrl.condition())) {
                                        return (
                                            <li key={slUrl.url}>
                                                <Link to={`/manager/${appCodename}${slUrl.url}`}>
                                                    {slUrl.icon ? (<FontAwesomeIcon icon={slUrl.icon} />) : ''}
                                                            &nbsp;&nbsp;
                                                            <Translate id={slUrl.name} />
                                                </Link>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>}
                        </li>
                    )
                } else {
                    const link = url.custom ? url.url : `/manager/${appCodename}${url.url}`
                    options.push(
                        <li key={link}>
                            <Link to={link}>
                                {url.icon ? (<FontAwesomeIcon icon={url.icon} />) : ''}
                                        &nbsp;&nbsp;
                                        <Translate id={url.name} />
                            </Link>
                        </li>
                    )
                }
            }
        })
        if (statusCT && statusCT.icon) {
            options.push(
                <StatusFooter>
                    <a href='https://status.chat-tonic.com' target='_blank'>
                        {statusCT.description}
                        &nbsp;&nbsp;
                        <FontAwesomeIcon icon={statusCT.icon} style={{ color: statusCT.color }}/>
                    </a>
                </StatusFooter>
            )
        }

        return (
            <Container show={show}>
                {(this.state.loading) && (
                    <div className="row">
                        <div className="col-lg-12">
                            {<Spinner />}
                        </div>
                    </div>
                )}
                { options }
                <Modal show={this.state.show} onHide={this.setShow.bind(this, false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.titleStatus}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.descriptionStatus}</Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.setShow.bind(this, false)}>
                            <Translate id='menu.cancel' />
                        </Button>
                        <Button bsStyle="primary" onClick={this.state.saveStatus}>
                            <Translate id='menu.done' />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}

Menu.propTypes = {
    show: PropTypes.bool,
    appCodename: PropTypes.string,
    status: PropTypes.string,
    toggleMenu: PropTypes.func
}

function mapStateToProps(state) {
    return {
        profile: state.profile,
        app: state.app,
        statusCT: state.statusCT
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
