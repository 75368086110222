import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import Spinner from './../common/Spinner'
import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import { Alert, Button, ButtonToolbar, FormGroup, ControlLabel } from 'react-bootstrap'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate, withLocalize } from 'react-localize-redux'

class UserEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAllQueries: true,
            passwordSelected: false,
            errorEditUser: false,
            errorEditPassword: false,
            loading: true,
            editing: false,
            user: null,
            passwords: null
        }

        yup.addMethod(yup.string, 'samePassword', this.samePassword)
        yup.addMethod(yup.string, 'hasANumber', this.hasANumber)
        yup.addMethod(yup.string, 'hasAnUpperCaseLetter', this.hasAnUpperCaseLetter)
        yup.addMethod(yup.array, 'leastOne', this.leastOne)

        Form.addInputTypes(types)

        this.editUser = this.editUser.bind(this)
        this.editPassword = this.editPassword.bind(this)
        this.togglePasswordSelected = this.togglePasswordSelected.bind(this)
        this.toggleShowAllQueries = this.toggleShowAllQueries.bind(this)
        this.goBack = this.goBack.bind(this)
    }

    componentWillMount() {
        const { profile } = this.props
        let promise = this.props.actions.userCleanOne()

        promise = promise.then(() => {
            return this.props.actions.userGetOne(this.props.params.appCodename, this.props.params.userId, (profile && profile.profile >= 1000) ? {
                populates: '[{"key": "origins"}]'
            } : {})
        })

        if (profile && profile.profile >= 1000) {
            promise = promise.then(() => {
                return this.props.actions.originsGet(this.props.params.appCodename)
            })
        }

        return promise.then(() => {
            this.setState({
                showAllQueries: get(this.props.user, 'operator.showAllQueries'),
                loading: false
            })
        }).catch(() => {
            this.goBack()
        })
    }

    componentWillUnmount() {
        return this.props.actions.userCleanOne()
    }

    getUserSchema() {
        const { profile, user, origins } = this.props

        const abmUser = {
            generateUser: user.authAbmUser.generateUser,
            editUser: user.authAbmUser.editUser,
            deleteUser: user.authAbmUser.deleteUser,
            authorization: user.authAbmUser.authorization
        }

        const schema = {
            name: yup.object({
                givenName: yup.string()
                    .required(this.props.translate('validations.required', { propName: 'firstName' })),
                familyName: yup.string()
                    .required(this.props.translate('validations.required', { propName: 'lastName' }))
            }).default(user.name)
        }

        if (profile.profile >= 1000) {
            schema.profile = yup.number()
                .required(this.props.translate('validations.select', { propName: 'profile' }))
                .default(user.profile)
            schema.origins = yup.array()
                .leastOne(this.props.translate('validations.select', { propName: 'origins' }), (this.state.showAllQueries || !(origins && origins.objects && origins.objects.length)))
                .default(user.origins || [])
            schema.operator = yup.object({
                showAllQueries: yup.bool().default(user.operator && user.operator.showAllQueries)
            })
            schema.position = yup.string().default(user.position)
            schema.area = yup.string().default(user.area)
            schema.externalId = yup.string().default(user.externalId)
        }
        schema.authAbmUser = yup.object({
            generateUser: yup.boolean(),
            editUser: yup.boolean(),
            deleteUser: yup.boolean(),
            authorization: yup.boolean()
        }).default(abmUser)

        return yup.object(schema)
    }

    getPasswordSchema() {
        return yup.object({
            password: yup.string()
                .required(this.props.translate('validations.required', { propName: 'password' }))
                .min(8, this.props.translate('validations.leastCharacters', { count: 8 }))
                .hasANumber(this.props.translate('validations.leastCharacters', { count: 1 }))
                .hasAnUpperCaseLetter(this.props.translate('validations.leasUppercase', { count: 1 })),
            validation: yup.string()
                .required(this.props.translate('validations.required', { propName: 'password' }))
                .samePassword(this.props.translate('validations.doNotMatch', { propName: 'passwords' }), this.state.passwords && this.state.passwords.password || '')
        })
    }

    samePassword(msg, password) {
        return this.test('samePassword', msg || '${path} must be the same password', value => {
            return value === password
        })
    }

    hasANumber(msg) {
        return this.test('hasANumber', msg || '${path} must contain a number', value => {
            return value && value.match(/\d+/g) && value.match(/\d+/g).length
        })
    }

    hasAnUpperCaseLetter(msg) {
        return this.test('hasAnUpperCaseLetter', msg || '${path} must contain a capital letter', value => {
            return value && value !== value.toLowerCase()
        })
    }

    leastOne(msg, otherValidation) {
        return this.test('leastOne', msg || 'You must choose at least one ${path}', value => {
            return otherValidation || (value && value.length)
        })
    }

    goBack() {
        const { profile } = this.props
        if (profile && profile.profile >= 1000 || (profile.authAbmUser && profile.authAbmUser.generateUser || profile.authAbmUser.editPassword || profile.authAbmUser.deleteUser)) {
            browserHistory.push(`/manager/${this.props.params.appCodename}/user`)
        } else {
            browserHistory.push(`/manager/customer/${this.props.params.appCodename}`)
        }
    }

    editUser(formValues) {
        const { profile } = this.props
        const data = Object.assign({}, formValues)
        data.displayName = `${formValues.name.givenName} ${formValues.name.familyName}`

        if (profile.profile >= 1000) {
            if (!formValues.operator.showAllQueries && formValues.origins && formValues.origins.length) {
                data.origins = formValues.origins.map(o => {
                    if (o._id) {
                        return o._id
                    } else {
                        return o
                    }
                })
            } else {
                data.origins = []
            }
        } else {
            delete data.profile
        }

        const promise = this.props.actions.userEdit(this.props.params.appCodename, this.props.params.userId, data).then(action => {
            if (action.payload.status === 204) {
                this.setState({
                    editing: false
                }, () => this.goBack())
            } else {
                this.setState({
                    editing: false,
                    errorEditUser: true
                })
            }
        }).catch(() => {
            this.setState({
                editing: false,
                errorEditUser: true
            })
        })

        this.setState({
            editing: true,
            errorEditUser: false
        }, () => promise)
    }

    editPassword(formValues) {
        const promise = this.props.actions.userChangePassword(this.props.params.userId, { password: formValues.password }).catch(() => {
            // do nothing
        }).then((action) => {
            if (action.payload.status === 204) {
                this.setState({
                    editing: false
                }, () => this.goBack())
            } else {
                this.setState({
                    editing: false,
                    errorEditPassword: true
                })
            }
        })

        this.setState({
            editing: true,
            errorEditPassword: false
        }, () => promise)
    }

    togglePasswordSelected() {
        this.setState({
            passwordSelected: !this.state.passwordSelected
        })
    }

    toggleShowAllQueries() {
        this.setState({
            showAllQueries: !this.state.showAllQueries
        })
    }

    render() {
        const { profile, user, origins } = this.props
        const { loading, editing, passwordSelected, showAllQueries, errorEditPassword, errorEditUser } = this.state

        if (!user || loading) {
            return (
                <Spinner />
            )
        } else {
            const content = (passwordSelected) ? (
                <Form className="col-xs-12"
                    schema={this.getPasswordSchema()}
                    defaultValue={this.getPasswordSchema().default()}
                    onChange={(formValues, fieldUpdated) => {
                        this.setState({ passwords: formValues })
                    }}
                    onSubmit={this.editPassword}
                >
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <FormGroup>
                                <ControlLabel>
                                    <Translate id="users.propNames.password" />
                                </ControlLabel>
                                <Form.Field name="password" placeholder={this.props.translate('users.placeholders.password')} className="form-control" type="password" />
                                <Form.Message for="password" />
                            </FormGroup>

                        </div>
                        <div className="col-xs-12 col-md-6">
                            <FormGroup>
                                <ControlLabel>
                                    <Translate id="users.propNames.validation" />
                                </ControlLabel>
                                <Form.Field name="validation" placeholder={this.props.translate('users.placeholders.password')} className="form-control" type="password" />
                                <Form.Message for="validation" />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <FormGroup>
                                <ButtonToolbar className='pull-right'>
                                    <Form.Button type="submit" className="btn btn-primary" disabled={editing}>
                                        <Translate id="buttons.save" />
                                    </Form.Button>
                                    <Button bsStyle="danger" onClick={this.togglePasswordSelected} disabled={editing}>
                                        <Translate id="buttons.cancel" />
                                    </Button>
                                </ButtonToolbar>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
            ) : (
                <Form
                    className="col-xs-12"
                    schema={this.getUserSchema()}
                    defaultValue={this.getUserSchema().default()}
                    onChange={(formValues, fieldUpdated) => {
                        this.setState({ user: formValues })
                    }}
                    onSubmit={this.editUser}
                >
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <FormGroup>
                                <ControlLabel>
                                    <Translate id="users.propNames.firstName" />
                                </ControlLabel>
                                <Form.Field name="name.givenName" placeholder={this.props.translate('users.placeholders.firstName')} className="form-control" />
                                <Form.Message for="name.givenName" />
                            </FormGroup>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <FormGroup>
                                <ControlLabel>
                                    <Translate id="users.propNames.lastName" />
                                </ControlLabel>
                                <Form.Field name="name.familyName" placeholder={this.props.translate('users.placeholders.lastName')} className="form-control" />
                                <Form.Message for="name.familyName" />
                            </FormGroup>
                        </div>
                    </div>

                    {profile.profile >= 1000 ? (
                        <div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="users.propNames.position" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="position"
                                            placeholder={this.props.translate('users.placeholders.optional')}
                                            className="form-control"
                                            disabled={loading}
                                        />
                                        <Form.Message for="position" />
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="users.propNames.area" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="area"
                                            placeholder={this.props.translate('users.placeholders.optional')}
                                            className="form-control"
                                            disabled={loading}
                                        />
                                        <Form.Message for="area" />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="users.propNames.profile" />
                                        </ControlLabel>
                                        <Form.Field name="profile" className="form-control" type="select">
                                            <option value={undefined}>{this.props.translate('users.misc.selectRole')}</option>
                                            {/* <option value={0}>{this.props.translate('users.profiles.user')}</option> */}
                                            <option value={500}>{this.props.translate('users.profiles.operator')}</option>
                                            <option value={600}>{this.props.translate('users.profiles.coordinator')}</option>
                                            <option value={1000}>{this.props.translate('users.profiles.admin')}</option>
                                        </Form.Field>
                                        <Form.Message for="profile" />
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="users.propNames.externalId" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="externalId"
                                            placeholder={this.props.translate('users.placeholders.optional')}
                                            className="form-control"
                                            disabled={loading}
                                        />
                                        <Form.Message for="externalId" />
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="users.misc.showAllQueries" />&nbsp;<Form.Field name="operator.showAllQueries" onClick={this.toggleShowAllQueries} />
                                        </ControlLabel>
                                        <Form.Message for="operator.showAllQueries" />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    ) : ''}
                    {profile && profile.authAbmUser && (profile.authAbmUser.authorization || profile.profile >= 1000) && user.profile >= 600 ? (
                        <div className="row">
                            <div className="col-md-12">
                                <FormGroup>
                                    <ControlLabel> <Translate id="userEdit.panel.authAbmUser.titleAuth"/></ControlLabel>
                                    &nbsp;
                                    <Form.Field name="authAbmUser.authorization" type="select">
                                        <option value={false}>{this.props.translate('userEdit.panel.no')}</option>
                                        <option value={true}>{this.props.translate('userEdit.panel.yes')}</option>
                                    </Form.Field>
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup>
                                    <ControlLabel> <Translate id="userEdit.panel.authAbmUser.titleGenerate"/></ControlLabel>
                                    &nbsp;
                                    <Form.Field name="authAbmUser.generateUser" type="select" className="form-control">
                                        <option value={true}>{this.props.translate('userEdit.panel.yes')}</option>
                                        <option value={false}>{this.props.translate('userEdit.panel.no')}</option>
                                    </Form.Field>
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup>
                                    <ControlLabel> <Translate id="userEdit.panel.authAbmUser.titleEdit"/></ControlLabel>
                                    &nbsp;
                                    <Form.Field name="authAbmUser.editUser" type="select" className="form-control">
                                        <option value={true}>{this.props.translate('userEdit.panel.yes')}</option>
                                        <option value={false}>{this.props.translate('userEdit.panel.no')}</option>
                                    </Form.Field>
                                </FormGroup>
                            </div>
                            <div className="col-md-4">
                                <FormGroup>
                                    <ControlLabel> <Translate id="userEdit.panel.authAbmUser.titleDelete"/></ControlLabel>
                                    &nbsp;
                                    <Form.Field name="authAbmUser.deleteUser" type="select" className="form-control">
                                        <option value={true}>{this.props.translate('userEdit.panel.yes')}</option>
                                        <option value={false}>{this.props.translate('userEdit.panel.no')}</option>
                                    </Form.Field>
                                </FormGroup>
                            </div>
                        </div>
                    ) : ''}

                    {profile.profile >= 1000 && (!showAllQueries && origins && origins.objects && origins.objects.length) ? (
                        <div className="row">
                            <div className="col-xs-12">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.origins" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="origins"
                                        type="multiselect"
                                        textField="name"
                                        data={origins.objects}
                                        disabled={editing}
                                    />
                                    <Form.Message for="origins" />
                                </FormGroup>
                            </div>
                        </div>
                    ) : ''}
                    <div className="row">
                        <div className="col-xs-12">
                            <FormGroup>
                                <ButtonToolbar className="pull-right">
                                    {(profile.profile >= 1000 || this.props.params.userId === 'me') ? (
                                        <Button bsStyle="primary" type="button" onClick={this.togglePasswordSelected} disabled={editing}>
                                            <Translate id="buttons.changePassword" />&nbsp;<FontAwesomeIcon icon="chevron-right" />
                                        </Button>
                                    ) : ''}
                                    <Form.Button type="submit" className="btn btn-primary" disabled={editing}>
                                        <Translate id="buttons.save" />
                                    </Form.Button>
                                    <Button bsStyle="danger" type="button" onClick={this.goBack} disabled={editing}>
                                        <Translate id="buttons.cancel" />
                                    </Button>
                                </ButtonToolbar>
                            </FormGroup>
                        </div>
                    </div>
                </Form>
            )

            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="page-header">
                                <Translate id={passwordSelected ? 'users.titles.password' : 'users.titles.edit'} />
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                        {content}
                    </div>
                    <Alert bsStyle="warning" style={{ display: ((!passwordSelected && errorEditUser) || (passwordSelected && errorEditPassword)) ? 'block' : 'none' }}>
                        <Translate id={(passwordSelected && errorEditPassword) ? 'users.errors.password' : 'users.errors.edit'} />
                    </Alert>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        origins: state.origins,
        profile: state.profile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(UserEdit))
