import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import * as storage from '../../utils/storage'
import { bindActionCreators } from 'redux'
import { Translate, withLocalize } from 'react-localize-redux'

import {
    Grid,
    Row,
    Col,
    Form,
    FormGroup
} from 'react-bootstrap'

import {
    Wrapper,
    Logo,
    Input,
    Button,
    Error
} from './styles'

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: false,
            formValues: {}
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleSubmit(event) {
        event.preventDefault()
        const { formValues } = this.state
        formValues.username = formValues.username ? formValues.username.toLowerCase() : ''
        if (formValues.username && formValues.password) {
            this.props.actions.cleanAll()
            const promise = this.props.actions.userLogin(formValues).then(result => {
                if (result.error) {
                    this.setState({
                        loading: false,
                        error: result.error
                    })
                }
            }).catch(error => {
                this.setState({
                    loading: false,
                    error
                })
            })

            this.setState({
                loading: true,
                error: false
            }, () => promise)
        }
    }

    handleInputChange(event) {
        const { formValues } = this.state
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        formValues[name] = value

        this.setState({
            formValues
        })
    }

    render() {
        const { loading, formValues, error } = this.state

        return (
            <Wrapper>
                <Grid>
                    <Row>
                        <Col xs={10} xsOffset={1} sm={6} smOffset={3} md={4} mdOffset={4}>
                            <Logo src='/manager/static/images/chat-tonic-horizontal-negativo.jpg' />
                            <Form onSubmit={this.handleSubmit} disabled={loading}>
                                <FormGroup>
                                    <Input
                                        name="username"
                                        type="email"
                                        className="form-control"
                                        placeholder={this.props.translate('login.placeholders.username')}
                                        onChange={this.handleInputChange}
                                        value={formValues.username}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        placeholder={this.props.translate('login.placeholders.password')}
                                        onChange={this.handleInputChange}
                                        value={formValues.password}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button type="submit" className="btn btn-lg btn-block">
                                        <Translate id={loading ? 'login.loading' : 'login.submit'} />
                                    </Button>
                                    {error ? (
                                        <Error>
                                            <Translate id="login.error" />
                                        </Error>
                                    ) : ''}
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Grid>
            </Wrapper>
        )
    }
}

function mapStateToProps(state) {
    return {
        isReady: state.isReady
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Login))
