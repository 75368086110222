import * as actions from '../actions'

const defaultState = null

export default function app(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_SELECT:
            return action.payload

        case actions.APP_GET_ONE:
        case actions.APP_GET_ONE_CODENAME:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.APP_ADD:
            if (!action.error) {
                state = defaultState // So we force-reload when getting back to a list of apps
            }
            return state

        case actions.APP_CLEAN_ONE:
        case actions.APP_RESET:
            return defaultState

        default:
            return state
    }
}
