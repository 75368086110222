import React, { Component } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'

class DashboardItem extends Component {
    constructor(props) {
        super(props)
        this.goSelected = this.goSelected.bind(this)
    }

    formatNumber() {
        const { value, unformatted } = this.props
        if (unformatted) {
            return value
        } else {
            const format = (value > 1000) ? '0.0a' : '0a'
            return numeral(value).format(format)
        }
    }

    goSelected(e) {
        const { selected } = this.props
        if (e) {
            e.preventDefault()
        }
        if (selected) {
            selected(e)
        }
    }

    render() {
        const { selected, footer, classNames, color, icon, name } = this.props

        return (
            <div className={classNames}>
                <div className={`panel panel-${color}`}>
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-xs-3">
                                <FontAwesomeIcon icon={icon} size="5x" />
                            </div>
                            <div className="col-xs-9 text-right">
                                <div className="huge">{this.formatNumber()}</div>
                                <div>{name}</div>
                            </div>
                        </div>
                    </div>
                    {selected ? (
                        <a href="" onClick={this.goSelected}>
                            <div className="panel-footer">
                                <span className="pull-left">
                                    <Translate id="dashboard.items.details" />
                                </span>
                                <span className="pull-right">
                                    <FontAwesomeIcon icon="arrow-circle-right" />
                                </span>
                                <div className="clearfix"></div>
                            </div>
                        </a>
                    ) : (
                        <div className="panel-footer">
                            <span>{footer}</span>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

DashboardItem.defaultProps = {
    value: 0,
    footer: <span>&nbsp;</span>,
    classNames: 'col-lg-6 col-md-6'
}

DashboardItem.propTypes = {
    value: PropTypes.number,
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    format: PropTypes.string,
    selected: PropTypes.func,
    footer: PropTypes.element,
    unformatted: PropTypes.bool,
    classNames: PropTypes.string
}

export default DashboardItem
