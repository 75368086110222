import * as actions from '../actions'
import * as storage from '../../utils/storage'

const defaultState = null

export default function profile(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.LOGIN_USER:
            if (action.payload && action.payload.data && action.payload.data.profile > 0) {
                storage.set(storage.accessTokenKey, action.payload.data.token)
                state = action.payload.data
                state._id = state.id
                delete state.token
                delete state.id
            } else {
                storage.remove(storage.accessTokenKey)
                state = defaultState
            }
            return state

        case actions.USER_GET_PROFILE:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.USER_CLEAN_PROFILE:
            storage.remove(storage.accessTokenKey)
            return defaultState

        case actions.USER_GET_ONE:
            if (!action.error) {
                if (state && state._id === action.payload.data._id) {
                    state = Object.assign(state, action.payload.data)
                }
            }
            return state

        case actions.SHOW_NEW_CUSTOMER:
            if (state && action && action.payload && action.payload.data && action.payload.data.showNewCustomer !== state.showNewCustomer) {
                state.showNewCustomer = action.payload.data.showNewCustomer
            }
            return state

        case actions.CHANGE_STATUS:
            if (state && action && action.payload && action.payload.data && state && state._id.toString() === action.payload.data.userId) {
                state.status = action.payload.data.status
                state.statusUpdatedAt = action.payload.data.statusUpdatedAt
            }
            return state

        default:
            if (action.payload && action.payload.error && action.payload.error.response && action.payload.error.response.status && action.payload.error.response.status === 401) {
                state = defaultState
            }
            return state

    }
}
