import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-top: 5px solid #D4D6D6;

    & > .btn-group-vertical {
        width: 50%;

        & > button {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }

`
