import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate, getTranslate } from 'react-localize-redux'
import { connect } from 'react-redux'

import moment from 'moment'
import * as platforms from '../../../../components/common/Platforms'

import Spinner from 'react-spinkit'
import { get } from 'lodash'

import {
    Label,
    Collapse,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap'

import {
    Loading,
    Container,
    Main,
    UserPhoto,
    Icon,
    Data,
    Secondary,
    SecondaryItem,
    TimeToResponse,
    Separator,
    ExpandableList
} from './styles'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import { isEmail, isPhone } from '../../../../utils/validate'
import InfoUserProfile from '../InfoUserProfile'

class Info extends Component {
    constructor(props) {
        super(props)

        this.state = {
            now: moment.utc().toISOString(),
            open: false
        }

        this.timer = setInterval(() => this.setState({ now: moment.utc().toISOString() }), 1000)
        this.toggleCollapse = this.toggleCollapse.bind(this)

        this.onUserProfileChanged = this.onUserProfileChanged.bind(this)
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    }

    toggleCollapse() {
        this.setState({
            open: !this.state.open
        })
    }

    forceCollapse() {
        this.setState({ open: false })
    }

    getCustomProperties() {
        const toReturn = []
        const custom = get(this.props.messageUser, 'profile.custom', {})
        if (custom && Object.keys(custom).length) {
            toReturn.push(
                <SecondaryItem key='separator'>
                    <Separator/>
                </SecondaryItem>
            )
            for (const [key, value] of Object.entries(custom)) {
                const capKey = key.charAt(0).toUpperCase() + key.slice(1)
                let displayValue = value || '-'
                if (typeof value === 'object') {
                    displayValue = JSON.stringify(value)
                }
                toReturn.push(
                    <SecondaryItem key={key} style={{paddingBottom: '2px', paddingTop: '2px'}}>
                        <strong>{capKey}:</strong>&nbsp;{displayValue || '-'}
                    </SecondaryItem>
                )
            }
        }
        return toReturn
    }

    onUserProfileChanged(value) {
        const self = this
        self.setState({
            formValues: value
        })
    }

    editFullName(fullName) {
        const self = this
        const { firstName, lastName } = self.props.messageUser || {}
        if (fullName.length && fullName !== this.props.translate('messageUser.defaultName')) {
            const changeFirstName = !fullName.startsWith(firstName + ' ')
            const changeLastName = !fullName.endsWith(' ' + lastName)
            if (!changeLastName) {
                return self.props.editContext('firstName', fullName.replace(lastName, '').trim())
            } else if (!changeFirstName) {
                return self.props.editContext('lastName', fullName.replace(firstName, '').trim())
            } else {
                const lastNameIndex = fullName.lastIndexOf(' ')
                if (lastNameIndex > -1) {
                    return self.props.editContext('firstName', fullName.substring(0, lastNameIndex)).then(() => {
                        return self.props.editContext('lastName', fullName.substring(lastNameIndex).trim())
                    })
                }
                return Promise.resolve()
            }
        }
    }

    editEmail(email) {
        const self = this
        if (email.length === 0 || isEmail(email)) {
            return self.editContext('profile.email', email).then(result => {
                if (get(result, 'payload.error')) {
                    return Promise.reject('El e-mail ingresado no es válido.')
                }
                return Promise.resolve()
            })
        } else {
            return Promise.reject('El e-mail ingresado no es válido.')
        }
    }

    editPhone(phone) {
        const self = this
        if (phone.length === 0 || isPhone(phone)) {
            return self.editContext('profile.phone', phone).then(result => {
                if (get(result, 'payload.error')) {
                    return Promise.reject('El teléfono ingresado no es válido.')
                }
                return Promise.resolve()
            })
        } else {
            return Promise.reject('El teléfono ingresado no es válido.')
        }
    }

    editContext(messageUserProp, newValue) {
        const self = this
        if (newValue !== get(self.props.messageUser, messageUserProp)) {
            return self.props.editContext(messageUserProp, newValue && newValue.length > 0 ? newValue : null)
        } else {
            return Promise.resolve()
        }
    }

    handleEnter(e) {
        if (e && e.target && e.keyCode === 13) {
            e.preventDefault()
            e.target.blur()
        }
    }

    getFullName(messageUser) {
        let name = this.props.translate('messageUser.defaultName')
        if (messageUser && messageUser.firstName && messageUser.lastName) {
            name = `${messageUser.firstName} ${messageUser.lastName}`
        } else if (messageUser && messageUser.firstName) {
            name = messageUser.firstName
        } else if (messageUser && messageUser.platform && messageUser.platform.startsWith('whatsapp') && messageUser.id) {
            name = messageUser.id
        }
        return name

    }

    getToggleError(error) {
        return (
            <OverlayTrigger placement="top" overlay={
                <Tooltip>
                    {error}
                </Tooltip>
            }>
                <FontAwesomeIcon color='red' icon='times-circle' size={10} style={{marginRight: '4px', marginBottom: '2px'}}/>
            </OverlayTrigger>
        )
    }

    render() {
        const { query, messageUser, app } = this.props

        if (!(messageUser && messageUser._id)) {
            return (
                <Loading>
                    <Spinner name="circle" />
                </Loading>
            )
        } else {
            const platform = messageUser && messageUser.platform

            let profilePicture = messageUser.profilePictureURL
            if (profilePicture && !profilePicture.startsWith('http')) {
                profilePicture = `${(app && app.chattonic && app.chattonic.url) ? app.chattonic.url : ''}${profilePicture}`
            } else if (!profilePicture) {
                profilePicture = '/manager/static/images/user.png'
            }

            const platformSettings = platforms[platform] || platforms['unknown']
            const color = platformSettings.color
            const icon = platformSettings.icon

            let timeToResponse = ''
            if (query.status !== 'resolved' && ((platform && platform.startsWith('whatsapp') && (platform !== 'whatsapp-chatapi-instance')) || (platform === 'messenger'))) {
                let diffTime = -1
                if (query.lastNotifiedAt || (query.message && (query.message.createdAt || query.message.receivedAt))) {
                    const lastMessagePlus24Hours = moment.utc(query.lastNotifiedAt || query.message.createdAt || query.message.receivedAt).add(24, 'hours')
                    diffTime = lastMessagePlus24Hours.diff(moment.utc(), 'minutes')
                }

                if (query.HSMSendedAt) {
                    timeToResponse = (
                        <Label bsStyle="success">
                            <Translate id="query.recontactSend" />
                        </Label>
                    )
                } else if (diffTime > 0) {
                    timeToResponse = (
                        <Label bsStyle="warning">
                            <Translate id="query.timeToReply" data={{ hours: Math.floor(diffTime / 60), minutes: diffTime % 60 }} />
                        </Label>
                    )
                } else {
                    timeToResponse = (
                        <Label bsStyle="danger">
                            <Translate id="query.noTimeToReply" />
                        </Label>
                    )
                }
            }

            return (
                <Container>
                    <Main>
                        <UserPhoto url={profilePicture} color={color}>
                            <Icon color={color}>
                                <i className={icon}></i>
                            </Icon>
                        </UserPhoto>
                        <Data>
                            <InfoUserProfile
                                type='primary'
                                propValue={this.getFullName(messageUser)}
                                placeholder={this.props.translate('messageUser.placeholders.name')}
                                onConfirm={this.editFullName.bind(this)}
                                disabled={query.status !== 'progress'}
                            />
                        </Data>
                    </Main>
                    <ExpandableList>
                        <Secondary>
                            <SecondaryItem>
                                <strong><Translate id="messageUser.platform" />:</strong>&nbsp;{platform}
                            </SecondaryItem>
                            <InfoUserProfile
                                type='secondary'
                                propName={this.props.translate('messageUser.propNames.email')}
                                propValue={get(messageUser, 'profile.email', '')}
                                errorText={this.props.translate('messageUser.errors.email')}
                                placeholder={this.props.translate('messageUser.placeholders.email')}
                                onConfirm={this.editEmail.bind(this)}
                                disabled={query.status !== 'progress'}
                            />
                            <InfoUserProfile
                                type='secondary'
                                propName={this.props.translate('messageUser.propNames.phone')}
                                propValue={get(messageUser, 'profile.phone', '')}
                                errorText={this.props.translate('messageUser.errors.phone')}
                                placeholder={this.props.translate('messageUser.placeholders.phone')}
                                onConfirm={this.editPhone.bind(this)}
                                disabled={query.status !== 'progress'}
                            />
                            <InfoUserProfile
                                type='secondary'
                                propName={this.props.translate('messageUser.propNames.id')}
                                propValue={get(messageUser, 'profile.id', '')}
                                placeholder={this.props.translate('messageUser.placeholders.id')}
                                onConfirm={this.editContext.bind(this, 'profile.id')}
                                disabled={query.status !== 'progress'}
                            />
                        </Secondary>
                        {(get(messageUser, 'profile.custom', {}) && Object.keys(get(messageUser, 'profile.custom', {})).length > 0) ? (
                            <button onClick={this.toggleCollapse}>
                                <FontAwesomeIcon icon={this.state.open ? 'chevron-up' : 'chevron-down' } />
                            </button>
                        ) : '' }
                    </ExpandableList>
                    <Collapse in={this.state.open}>
                        <Secondary>
                            {this.getCustomProperties()}
                        </Secondary>
                    </Collapse>
                    <TimeToResponse key={this.state.now}>
                        {timeToResponse}
                    </TimeToResponse>
                </Container>
            )
        }
    }
}

Info.propTypes = {
    query: PropTypes.object,
    messageUser: PropTypes.object,
    app: PropTypes.object,
    editContext: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    translate: getTranslate(state.localize)
})

export default connect(mapStateToProps, null, null, { withRef: true })(Info)
