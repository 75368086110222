import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Translate, withLocalize } from 'react-localize-redux'
import style from './styles.less'

import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'

import {
    FormGroup,
    ButtonToolbar,
    Button,
    Collapse
} from 'react-bootstrap'

import {
    Scrollbars
} from 'react-custom-scrollbars'

import {
    FormContainer,
    QueriesContainer,
    Status,
    NotFound
} from './styles'

import Query from '../Query'

import Spinner from 'react-spinkit'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

class Queries extends Component {
    constructor(props) {
        super(props)
        Form.addInputTypes(types)
        this.state = {
            loading: false,
            showSpinner: false,
            search: props.search && props.search.length,
            filters: (props.origin && props.origin !== 'all') || (props.operatorsSelected && props.operatorsSelected.map((e) => { return e._id }).indexOf('all') === -1)
        }

        this.verifyScrollPosition = this.verifyScrollPosition.bind(this)
        this.toggleFilters = this.toggleFilters.bind(this)
        this.toggleSearch = this.toggleSearch.bind(this)
    }

    getFilterSchema() {
        const { status, operatorsSelected, origin, search } = this.props
        return yup.object({
            status: yup.string().default(status),
            origin: yup.string().default(origin),
            operatorsSelected: yup.array().default(operatorsSelected),
            search: yup.string().nullable().default(search)
        })
    }

    goTo(uri) {
        browserHistory.push({
            pathname: uri
        })
    }

    toggleFilters() {
        this.setState({
            filters: !this.state.filters,
            search: false
        })
    }

    toggleSearch() {
        this.setState({
            search: !this.state.search,
            filters: false
        })
    }

    showQueries() {
        const { appCodename, app, selected, queries, translations, counts, origins, resolutions, operators } = this.props
        const toReturn = []
        const status = []
        queries.forEach((query) => {
            if (status.indexOf(query.status) < 0) {
                if (query.status === 'pending') {
                    toReturn.push(
                        <Status key="status_pending" color="#F95E4E" fontColor="#FFF">
                            <Translate id="status.pending" />{counts && counts.pending && ` (${counts.pending})` || null}
                            &nbsp;&nbsp;
                            <FontAwesomeIcon
                                icon="exclamation-circle"
                            />
                        </Status>
                    )
                } else if (query.status === 'progress') {
                    toReturn.push(
                        <Status key="status_progress" color="#F4D345" fontColor="#000">
                            <Translate id="status.progress" />{(counts && this.props.status === 'all' && (counts.progress + counts.operand) && ` (${counts.progress + counts.operand})`) || (this.props.status === 'other' && counts.operand && ` (${counts.operand})`) || counts.progress && ` (${counts.progress})` || null}
                            &nbsp;&nbsp;
                            <FontAwesomeIcon
                                icon="question-circle"
                            />
                        </Status>
                    )
                } else if (query.status === 'resolved') {
                    toReturn.push(
                        <Status key="status_resolved" color="#27CCC3" fontColor="#FFF">
                            <Translate id="status.resolved" />{counts && counts.resolved && ` (${counts.resolved})` || null}
                            &nbsp;&nbsp;
                            <FontAwesomeIcon
                                icon="check-circle"
                            />
                        </Status>
                    )
                } else if (query.status === 'failed') {
                    toReturn.push(
                        <Status key="status_failed" color="#D4D6D6" fontColor="#000">
                            <Translate id="status.failed" />{counts && counts.failed && ` (${counts.failed})` || null}
                            &nbsp;&nbsp;
                            <FontAwesomeIcon
                                icon="times-circle"
                            />
                        </Status>
                    )
                }
                status.push(query.status)
            }
            toReturn.push(
                <Query
                    key={`query_${query._id}`}
                    appCodename={appCodename}
                    app={app}
                    query={query}
                    selected={selected === query._id}
                    status={this.props.status}
                    translations={translations}
                    clearNotifications={this.props.clearNotifications}
                    origins={origins}
                    resolutions={resolutions}
                    operators={operators}
                />
            )
        })

        return toReturn
    }

    verifyScrollPosition(e) {
        const self = this
        if (self.props.allowInfiniteLoad && !self.state.showSpinner) {
            if (self.queries) {
                const values = self.queries.getValues()
                if (values.top > 0.5) {
                    const promise = self.props.getNextQueries().then(() => {
                        self.setState({
                            showSpinner: false
                        })
                    })

                    self.setState({
                        showSpinner: true
                    }, () => promise)
                }
            }
        }

        if (self.props.reachedTop && e.currentTarget.scrollTop <= 10) {
            self.props.reachedTop(e)
        }
    }

    render () {
        const { origins, operators, handleSubmitFilter, profile, counts } = this.props
        const status = [
            {
                id: 'me',
                name: 'status.me'
            }, {
                id: 'pending',
                name: 'status.pending'
            }, {
                id: 'progress',
                name: 'status.progress'
            }, {
                id: 'resolved',
                name: 'status.resolved'
            }, {
                id: 'failed',
                name: 'status.failed'
            }, {
                id: 'other',
                name: 'status.other'
            }
        ]

        if (profile && profile.profile >= 100) {
            status.push({
                id: 'all',
                name: 'status.all',
                count: 0
            })
        }

        const item = ({ item }) => (
            <span>
                <Translate id={item.name} />{['me', 'pending', 'progress'].indexOf(item.id) >= 0 && ` (${counts[item.id] || 0})` || null}
            </span>
        )

        return (
            <div>
                <FormContainer>
                    <Form schema={this.getFilterSchema()} defaultValue={this.getFilterSchema().default()} onSubmit={handleSubmitFilter} onChange={(formValues, fieldUpdated) => {
                        if (formValues.status && formValues.status.id) {
                            formValues.status = formValues.status.id
                        }
                        if (['status', 'origin', 'operatorsSelected'].indexOf(fieldUpdated[0]) >= 0) {
                            handleSubmitFilter(formValues)
                        }
                    }}>

                        <FormGroup>
                            <Form.Field
                                name="status"
                                type="dropdownlist"
                                className={style.status}
                                data={status}
                                itemComponent={item}
                                valueComponent={item}
                                textField="id"
                                valueField="id"
                                disabled={this.state.loading}
                            />
                            <ButtonToolbar>
                                <Button onClick={this.toggleFilters} bsSize="small">
                                    <FontAwesomeIcon icon="filter" />
                                </Button>
                                <Button onClick={this.toggleSearch} bsSize="small">
                                    <FontAwesomeIcon icon="search" />
                                </Button>
                            </ButtonToolbar>
                        </FormGroup>
                        <Collapse in={this.state.filters}>
                            <FormGroup>
                                <Form.Field name="origin" type="select" className="form-control" disabled={this.state.loading}>
                                    {origins.map(o => (
                                        <option key={o.id} value={o.id}>{o.name}</option>
                                    ))}
                                </Form.Field>
                            </FormGroup>
                        </Collapse>
                        {(this.props.status === 'all' || this.props.status === 'other' || this.props.status === 'resolved') && (
                            <Collapse in={this.state.filters}>
                                <FormGroup>
                                    <Form.Field
                                        name="operatorsSelected"
                                        type="multiselect"
                                        disabled={this.state.loading}
                                        defaultValue={operators.slice(0, 1)}
                                        textField={item => item.displayName}
                                        data={operators}
                                        onChange={value => {
                                            const valueIdx = value.map((e) => { return e._id }).indexOf('all')
                                            if (valueIdx !== -1) {
                                                const idx = value.length - 1
                                                if (value[idx]._id === 'all') {
                                                    value.splice(0, idx)
                                                } else {
                                                    value.splice(valueIdx, 1)
                                                }
                                            }
                                        }}
                                    >
                                    </Form.Field>
                                </FormGroup>
                            </Collapse>
                        )}
                        <Collapse in={!!this.state.search}>
                            <FormGroup>
                                <Form.Field name="search"
                                    placeholder={this.props.translate('misc.search')}
                                    className="form-control"
                                    disabled={this.state.loading}
                                />
                            </FormGroup>
                        </Collapse>
                        <Form.Button style={{display: 'none'}} type="submit"></Form.Button>
                    </Form>
                </FormContainer>
                <QueriesContainer adjust={(this.state.filters) ? 135 : (this.state.search) ? 100 : 60}>
                    {(this.props.queries && this.props.queries.length) ? (
                        <Scrollbars
                            ref={(c) => { this.queries = c }}
                            onScroll={this.verifyScrollPosition}
                        >
                            {this.showQueries()}
                            {this.state.showSpinner ? (
                                <Spinner style={{ margin: '10px auto' }} name="circle" />
                            ) : ''}
                        </Scrollbars>
                    ) : (
                        <NotFound>
                            <Translate id={`notFound.${this.props.status}`} />
                        </NotFound>
                    )}
                </QueriesContainer>
            </div>
        )
    }
}

Queries.propTypes = {
    appCodename: PropTypes.string.isRequired,
    app: PropTypes.object.isRequired,
    status: PropTypes.string,
    origin: PropTypes.string,
    search: PropTypes.string,
    queries: PropTypes.array,
    counts: PropTypes.object,
    operatorsCount: PropTypes.object,
    origins: PropTypes.array,
    operatorsSelected: PropTypes.array,
    operators: PropTypes.array,
    handleSubmitFilter: PropTypes.func.isRequired,
    clearNotifications: PropTypes.func,
    selected: PropTypes.string,
    getNextQueries: PropTypes.func,
    allowInfiniteLoad: PropTypes.bool,
    profile: PropTypes.object,
    translations: PropTypes.object.isRequired,
    reachedTop: PropTypes.func,
    resolutions: PropTypes.array
}

Queries.defaultProps = {
    status: 'all',
    origin: 'all',
    operatorsSelected: [{
        _id: 'all',
        displayName: 'Todos los operadores'
    }],
    search: '',
    queries: [],
    origins: [
        {
            id: 'all',
            name: 'Todos los origenes'
        }
    ],
    operators: [
        {
            _id: 'all',
            displayName: 'Todos los operadores'
        }
    ]
}

export default withLocalize(Queries)
