import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import { withLocalize } from 'react-localize-redux'
import * as project from '../../utils/project'

import * as actions from '../../state/actions'

import '../../public/manager/static/icons/icomoon/style.css'

import Spinner from 'react-spinkit'

import {
    Navigation,
    Queries,
    Menu
} from './components'

import {
    Container,
    Loading,
    NotFound
} from './styles'

const minThrottle = 200
const maxThrottle = 1000
const recurringIntervalInMs = 60000

class Customer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            status: 'me',
            origin: 'all',
            operatorsSelected: [{
                _id: 'all',
                displayName: 'Todos los operadores'
            }],
            search: '',
            queriesCounts: {},
            operatorsCount: {},
            menu: false,
            skip: 0,
            limit: 20,
            allowInfiniteLoad: true,
            onfocus: true
        }

        this.refreshQueriesThrottleTimer = null

        this.handleSubmitFilter = this.handleSubmitFilter.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.getNextQueries = this.getNextQueries.bind(this)
        this.refreshQueries = this.refreshQueries.bind(this)
        this.refreshAlertQueryPending = this.refreshAlertQueryPending.bind(this)
        this.refreshAlertMessage = this.refreshAlertMessage.bind(this)
        this.setQueryToPrivate = this.setQueryToPrivate.bind(this)
        this.props.actions.userCleanProfile = this.props.actions.userCleanProfile.bind(this)
        this.clearNotifications = this.clearNotifications.bind(this)
        this.handleReachedTop = this.handleReachedTop.bind(this)

        this.audioNotification = new Audio('https://s3.amazonaws.com/web-chattonic.mobile-tonic.com/resources/pop.mp3')
    }

    componentWillMount() {
        const self = this
        self.props.actions.appGetOneCodename(self.props.params.appCodename).catch(() => {
            // do nothing
        }).then(() => {
            return self.props.actions.originsGet(self.props.params.appCodename)
        }).then(() => {
            return self.props.actions.originsAllGet(self.props.params.appCodename)
        }).catch(() => {
            // do nothing
        }).then(() => {
            if (self.props.profile && self.props.profile._id) {
                return self.props.actions.userGetOne(
                    self.props.params.appCodename,
                    self.props.profile._id,
                    (self.props.profile && self.props.profile.profile >= 1000) ? {
                        populates: '[{"key": "origins"}]'
                    } : {}
                )
            } else {
                return Promise.resolve()
            }
        }).catch(() => {
            // do nothing
        }).then(() => {
            return self.props.actions.getHSMTemplates(self.props.params.appCodename)
        }).catch(() => {
            // do nothing
        }).then(() => {
            return self.props.actions.userGetUnlimited(this.props.actions.userGet, self.props.params.appCodename, {
                profile: { $lte: self.props.profile.profile }
            })
        }).catch(() => {
            // do nothing
        }).then(() => {
            return self.getQueries(self.props.profile)
        }).catch(() => {
            // do nothing
        }).then(() => {
            if (!self.props.socket && self.props.profile && self.props.profile._id && self.props.app) {
                self.connectSocketIO(self.props.app)
            }
        }).catch(() => {
            // do nothing
        }).then(() => {
            self.startRecurringCheck()
        })
    }

    componentDidMount() {
        const self = this
        window.onfocus = () => {
            self.setState({ onfocus: true })
            return self.clearNotifications()
        }

        window.onblur = () => {
            self.setState({ onfocus: false })
        }
    }

    componentWillUnmount() {
        const self = this
        self.clearNotifications()
        self.stopRecurringCheck()
        return self.props.actions.disconnectSocket()
    }

    startRecurringCheck() {
        const self = this
        self.stopRecurringCheck()
        self.recurringCheckTimer = setInterval(self.refreshQueries, recurringIntervalInMs)
    }

    stopRecurringCheck() {
        const self = this
        if (self.recurringCheckTimer) {
            clearInterval(self.recurringCheckTimer)
            self.recurringCheckTimer = null
        }
    }

    clearNotifications() {
        clearInterval(this.state.notif)
        clearInterval(this.state.normal)
        document.title = project.title
        this.mostrarTituloNormal()
    }

    componentWillUpdate(nextProps, nextState) {
        if (!this.state.loading && nextProps.location.pathname.startsWith('/manager/customer') && nextProps.location.query && nextProps.location.query.status && nextProps.location.query.status !== nextState.status && nextProps.profile && nextProps.profile._id) {

            this.setState({
                loading: true,
                status: nextProps.location.query.status
            }, () => this.getQueries(nextProps.profile))
        }
    }

    connectSocketIO(app) {
        const self = this
        return self.props.actions.connectSocket(app, self.refreshQueries).then(() => {
            self.props.actions.addSocketMethod('/queries/receive', self.refreshQueries)
            self.props.actions.addSocketMethod('/queries/pending', self.refreshAlertQueryPending)
            self.props.actions.addSocketMethod('/queries/progress', self.refreshQueries)
            self.props.actions.addSocketMethod('/queries/resolved', self.refreshQueries)
            self.props.actions.addSocketMethod('/queries/failed', self.refreshQueries)
            self.props.actions.addSocketMethod('/queries/reassigned', self.refreshQueries)
            self.props.actions.addSocketMethod('/queries/private', self.setQueryToPrivate)
            self.props.actions.addSocketMethod(`/queries/${this.props.profile._id}`, self.refreshAlertMessage)
        })
    }

    refreshQueries() {
        const self = this
        if (!self.refreshQueriesThrottleTimer) {
            self.refreshQueriesThrottleTimer = setTimeout(async _ => {
                try {
                    await this.getQueries(this.props.profile, true)
                } catch (error) {
                }
                self.refreshQueriesThrottleTimer = null
            }, Math.floor(Math.random() * (maxThrottle - minThrottle + 1) + minThrottle))
        }
    }

    refreshAlertQueryPending(response) {
        const self = this
        if (response.data && self.isValidOrigin(response.data.origins, response.data.operator)) {
            if (this.props.app.soundNotifications) {
                this.playAudioNotification()
            }
            self.clearNotifications()
            self.setState((prevState) => {
                const notif = setInterval(self.mostrarPendingQueries.bind(this), 1000)
                const normal = setInterval(self.mostrarTituloNormal, 1500)
                return { normal, notif }
            })
            self.refreshQueries()
        }
    }

    refreshAlertMessage(response) {
        const self = this
        if (response.data) {
            const location = self.props.routing && (self.props.routing.location || self.props.routing.locationBeforeTransitions)
            if (self.state.onfocus && location && location.pathname && response.data._id && location.pathname.includes(response.data._id.toString())) {
                return Promise.resolve()
            } else {
                if (this.props.app.soundNotifications) {
                    this.playAudioNotification()
                }
                self.clearNotifications()
                const notif = setInterval(self.mostrarNotif.bind(this), 1000)
                const normal = setInterval(self.mostrarTituloNormal, 1500)
                self.setState({ normal, notif })
            }
            self.refreshQueries()
        }
    }

    setQueryToPrivate(response) {
        const self = this
        if (response.data) {
            self.props.actions.setQueryToPrivate(response.data._id)
            self.refreshQueries()
        }
    }

    mostrarTituloNormal() {
        document.title = project.title
    }

    mostrarNotif() {
        if (this.props.translate) {
            document.title = `(*) ${this.props.translate('query.newMessage')}`
        }
    }
    mostrarPendingQueries(cant) {
        if (this.props.translate) {
            document.title = `(*) ${this.props.translate('query.new')}`
        }
    }

    playAudioNotification() {
        try {
            if (document.hasFocus()) {
                this.audioNotification.pause()
            } else {
                this.audioNotification.play()
            }
        } catch (error) {
            // do nothing
        }
    }

    isValidOrigin(origins, operator) {
        const { profile } = this.props
        if (profile) {
            const allowedOrigins = (profile.origins && profile.origins.length) ? profile.origins.map(origin => (origin && origin._id) ? origin._id : origin) : []
            const allow = (origins && origins && origins.length) ? origins.some(origin => allowedOrigins.includes(origin)) : false
            return ((profile && operator && operator._id && operator._id === profile._id) || profile.profile >= 1000 || (profile.operator && profile.operator.showAllQueries) || allow)
        } else {
            return false
        }
    }

    generateFilter(status, origin, operatorsSelected, search) {
        const filter = {
            status,
            origin,
            skip: this.state.skip,
            limit: this.state.limit
        }

        if (operatorsSelected && operatorsSelected.length > 0) {
            filter.operator = operatorsSelected.map(o => { return o._id })
        }

        if (search && search.length > 0) {
            filter.search = search
        }
        return filter
    }

    getQueries(profile, showNotification) {
        const self = this
        const { status, origin, search, operatorsSelected } = self.state

        const queriesPending = self.props.queries.filter(query => query.status === 'pending').map(query => query._id)
        return self.props.actions.getQueries(self.props.params.appCodename, self.generateFilter(status, origin, operatorsSelected, search, profile)).catch(() => {
            // do nothing
        }).then(action => {
            let counts = {
                pending: 0,
                progress: 0
            }
            let operatorsCount = {}

            if (showNotification && action && action.payload && action.payload.data && action.payload.data.objects) {
                const queriesPendingNew = action.payload.data.objects.filter(query => query.status === 'pending').map(query => query._id)
                let isNewQuery = false
                for (const queryId of queriesPendingNew) {
                    if (queriesPending.indexOf(queryId) < 0) {
                        isNewQuery = true
                    }
                }
                if (isNewQuery) {
                    self.clearNotifications()
                    const notif = setInterval(self.mostrarPendingQueries.bind(this), 1000)
                    const normal = setInterval(self.mostrarTituloNormal, 1500)
                    self.setState({ normal, notif })
                }
            }

            if (action && action.payload && action.payload.data && action.payload.data.counts) {
                counts = action.payload.data.counts
            }

            if (action && action.payload && action.payload.data && action.payload.data.operatorsCount) {
                operatorsCount = action.payload.data.operatorsCount
            }
            self.setState({
                loading: false,
                queriesCounts: counts,
                operatorsCount: operatorsCount
            })
            return Promise.resolve((action && action.payload && action.payload.data && action.payload.data.objects) ? action.payload.data.objects.length : 0)
        })
    }

    getNextQueries() {
        const self = this
        const { skip, limit } = self.state
        const oldLength = self.props.queries.length
        return new Promise((resolve, reject) => {
            let promise = self.getQueries(self.props.profile)
            promise = promise.then(newLength => {
                self.setState({
                    allowInfiniteLoad: (oldLength + newLength) > oldLength
                })
                resolve()
            }).catch(error => {
                reject(error)
            })

            self.setState({ skip: skip + limit }, () => promise)
        })
    }

    handleSubmitFilter(formValues) {
        const self = this
        formValues.loading = true
        formValues.skip = 0
        formValues.allowInfiniteLoad = true
        browserHistory.replace({
            pathname: self.props.routing.locationBeforeTransitions.pathname,
            search: `?status=${formValues.status}`
        })
        self.setState(formValues, () => self.getQueries(self.props.profile))
    }

    toggleMenu() {
        this.setState({
            menu: !this.state.menu
        })
    }

    handleReachedTop(e) {
        this.setState({
            skip: 0
        })
    } 

    render() {
        const { status, origin, operatorsSelected, search, queriesCounts, operatorsCount } = this.state
        const { profile, app, localize, resolutions } = this.props
        let queries = []
        if (this.props.queries && this.props.queries.length) {
            if (operatorsSelected && operatorsSelected.length > 0) {
                queries = this.props.queries
            } else {
                const queriesByStatus = {
                    pending: [],
                    progress: [],
                    resolved: [],
                    failed: []
                }

                this.props.queries.forEach(query => {
                    queriesByStatus[query.status].push(query)
                })

                if (status === 'me') {
                    queries = queriesByStatus.progress.concat(queriesByStatus.pending)
                } else if (status === 'other') {
                    queries = queriesByStatus.progress
                } else if (status === 'all') {
                    queries = queriesByStatus.progress.concat(queriesByStatus.pending)
                    queries = queries.concat(queriesByStatus.resolved)
                    queries = queries.concat(queriesByStatus.failed)
                } else {
                    queries = queriesByStatus[status]
                }
            }
        }

        const origins = [
            {
                id: 'all',
                name: this.props.translate('origins.all')
            }
        ]

        if (this.props.origins && this.props.origins.objects && this.props.origins.objects.length) {
            this.props.origins.objects.forEach(origin => {
                if (profile && profile.origins && !(profile.operator && profile.operator.showAllQueries)) {
                    if ((profile.origins && profile.origins.indexOf(origin._id) >= 0) || profile.profile === 2000) {
                        origins.push({
                            id: origin._id,
                            name: origin.name,
                            codename: origin.codename
                        })
                    }
                } else {
                    origins.push({
                        id: origin._id,
                        name: origin.name,
                        codename: origin.codename
                    })
                }
            })
        }

        let operators = [
            {
                _id: 'all',
                displayName: this.props.translate('users.all')
            }
        ]

        if (this.props.users && this.props.users.objects && this.props.users.objects.length) {
            operators = operators.concat(this.props.users.objects)
        }

        const content = this.state.loading ? (
            <Loading>
                <Spinner name="circle" />
            </Loading>
        ) : (
            <Queries
                appCodename={this.props.params.appCodename}
                app={app || {}}
                status={status}
                origin={origin}
                search={search}
                queries={queries}
                origins={origins}
                operatorsSelected={operatorsSelected}
                operators={operators}
                counts={queriesCounts}
                operatorsCount={operatorsCount}
                handleSubmitFilter={this.handleSubmitFilter}
                selected={this.props.params.queryId}
                getNextQueries={this.getNextQueries}
                allowInfiniteLoad={this.state.allowInfiniteLoad}
                profile={this.props.profile}
                translations={(localize && localize.translations) ? localize.translations : {}}
                clearNotifications={this.clearNotifications}
                reachedTop={this.handleReachedTop}
                resolutions={resolutions && resolutions.objects}
            />
        )

        return (
            <div>
                <Navigation
                    userCleanProfile={this.props.actions.userCleanProfile}
                    appCodename={this.props.params.appCodename}
                    toggleMenu={this.toggleMenu}
                    profile={this.props.profile}
                    status={(this.props.profile && this.props.profile.status) || 'offline'}
                    open={this.state.menu}
                />
                <Menu
                    show={this.state.menu}
                    status={(this.props.profile && this.props.profile.status) || 'offline'}
                    appCodename={this.props.params.appCodename}
                    toggleMenu={this.toggleMenu}
                />
                <Container>
                    {content}
                    {this.props.children ? (
                        <div>
                            {this.props.children}
                        </div>
                    ) : (
                        <NotFound>
                            <img src="/manager/static/images/chat-tonic-isotipo-black.png" />
                        </NotFound>
                    )}
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        localize: state.localize,
        queries: state.queries,
        origins: state.origins,
        socket: state.socket,
        app: state.app,
        profile: state.profile,
        isReady: state.isReady,
        routing: state.routing,
        users: state.users,
        resolutions: state.resolutions
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Customer))
