import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    padding: 10px;
    border: 1px solid #DDD;
`

export const Top = styled.div`
    cursor: pointer;

    & label {
        font-family: sans-serif;
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 0px;
    }

    & span {
        font-size: 1.18rem;
        font-style: italic;
    }
`

export const Bottom = styled.div`
    & label {
        font-family: sans-serif;
        font-size: 1.22rem;
        font-weight: bold;
    }

    & p {
        font-size: 1.2rem;
        font-style: italic;
    }
`
export const Separator = styled.div`
    border-top: 1px solid #D4D6D6;
    width: 90%;
    margin: 10px auto;
`

export const Text = styled.p`
    white-space: pre-wrap;
`
