import styled from 'styled-components'

export const Container = styled.div`
    background-color: ${props => props.selected ? props.color : '#FFF'};
    padding: 5px;
    border-bottom: 1px solid #D4D6D6;
    min-height: 75px;
    & a {
        color: black;
        text-decoration: none;
    }
`

export const Main = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const UserPhoto = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-right: 10px;
    background-image: url(${props => props.url});
    background-color: lightgray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid ${props => props.color};
    cursor: pointer;
`

export const Icon = styled.div`
    position: relative;
    left: 35px;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    color: ${props => props.color};
    border: 2px solid ${props => props.color};
    font-size: 1.6rem;
    background-color: white;
    border-radius: 100%;
`

export const Data = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
`

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    & > span {
        &:first-child {
            font-family: sans-serif;
            font-size: 1.5rem;
            font-weight: bold;
            max-width: 60%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:last-child {
            font-style: italic;
            font-size: 1rem;
            text-transform: capitalize;
            max-width: 40%;
        }
    }
`

export const Message = styled.div`
    display: flex;
    justify-content: space-between;
    font-style: italic;
    font-size: 1.2rem;
    max-height: 35px;
    overflow: hidden;
    cursor: pointer;
`

export const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
        background-color: transparent;
        border: none;
        outline: inherit;
        padding-top: 3px;
    }
`

export const Arrow = styled.div`
    float: right;
    cursor: pointer;
    position: relative;
    bottom: 10px;
`

export const Separator = styled.div`
    border-top: 1px solid #000;
    width: 90%;
    margin: 10px auto;
`

export const Other = styled.div`
    & > a {
        text-decoration: underline;
    }
`

export const Table = styled.table`
    max-width: 100%;
    font-size: 1.2rem;

    & > tbody {
        & > tr {
            & > td:first-child {
                font-weight: bold;
                padding-right: 5px;
            }
        }
    }
`

export const TitleExtraData = styled.td`
    vertical-align: top;
`
