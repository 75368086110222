import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import * as project from '../../utils/project'
import { ButtonToolbar, Button } from 'react-bootstrap'
import List from './../common/List'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate, withLocalize } from 'react-localize-redux'

class Topics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataColumnsNames: [
                'topics.dataColumnsNames.title', 'topics.dataColumnsNames.description', 'topics.dataColumnsNames.tags', 'topics.dataColumnsNames.answer', 'topics.dataColumnsNames.actions'
            ],
            showModal: false,
            loading: false
        }

        this.getTopic = this.props.actions.getTopic.bind(this, this.props.params.appCodename)
        this.cleanTopics = this.props.actions.cleanTopics.bind(this)
    }

    deleteData(objectId) {
        const self = this
        const list = self.list.getWrappedInstance()
        return list.openModal({
            title: this.props.translate('topics.delete.title'),
            description: this.props.translate('topics.delete.description'),
            button: {
                cancel: {
                    title: this.props.translate('buttons.cancel'),
                    className: 'btn-default'
                },
                success: {
                    title: this.props.translate('buttons.delete'),
                    className: 'btn-danger',
                    onClick: self.confirmDeleteData,
                    onClickBind: self
                }
            }
        }, {
            objectId: objectId
        })
    }

    confirmDeleteData(metadata) {
        const list = this.list.getWrappedInstance()
        let promise = this.props.actions.deleteTopic(this.props.params.appCodename, metadata.objectId)

        promise = promise.catch(() => {
            // do nothing
        }).then(() => {
            return list.refreshData(true)
        }).then(() => {
            return list.closeModal()
        }).then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    dataObjectProcess(data) {
        const { profile } = this.props
        const { loading } = this.state
        const row = []

        row.push(data.title)

        row.push(data.description)

        row.push(
            <div>
                {data.tags && data.tags.map(tag => {
                    return tag + ' '
                })}
            </div>
        )

        if (data.media && data.media.length) {
            row.push(
                <div>
                    {data.answerUser}<br/><br/>
                    Media: <a href={`${process.env.project.S3_URL}${data.media[0].originalURL}`} target="_blank">{data.media[0].type}</a>
                </div>
            )
        } else {
            row.push(data.answerUser)
        }

        row.push(
            <ButtonToolbar>
                {((profile && profile.profile && profile.profile === 2000) || data.type === 'Respuesta') && <Link className="btn btn-primary" title="Editar" to={`/manager/${this.props.params.appCodename}/topic/edit/${data._id}`} disabled={loading}>
                    <FontAwesomeIcon icon="edit" />
                </Link>}
                <Button bsStyle="danger" title="Eliminar" onClick={this.deleteData.bind(this, data._id)} disabled={loading}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button>
            </ButtonToolbar>
        )
        return row
    }

    render() {
        const { loading } = this.state
        const itemsProcessed = []
        if (this.props.topics) {
            this.props.topics.forEach(topic => {
                itemsProcessed.push(this.dataObjectProcess(topic))
            })
        }

        const description = (
            <div className="row">
                <div className="col-xs-12">
                    <ButtonToolbar className="pull-left">
                        <Link className="btn btn-primary pull-right" title="Agregar" to={`/manager/${this.props.params.appCodename}/topic/add`} disabled={loading}>
                            <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;<Translate id="topics.titles.list" />
                        </Link>
                    </ButtonToolbar>
                </div>
            </div>
        )

        return (
            <div>
                <List ref={(list) => { this.list = list }}
                    items={itemsProcessed}
                    name={this.props.translate('topics.titles.list')}
                    dataColumnsNames={this.state.dataColumnsNames.map(dataColumnsNames => this.props.translate(dataColumnsNames))}
                    description={description}
                    getObjects={this.getTopic}
                    clearObjects={this.cleanTopics}
                    routing={this.props.routing}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        topics: state.topics,
        routing: state.routing,
        profile: state.profile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Topics))
