import styled from 'styled-components'

export const Container = styled.ul`
    display: ${props => props.show ? 'block' : 'none'};
    position: fixed;
    z-index: 2;
    width: 30%;
    height: 100%;
    background-color: #FFF;
    border-right: 1px solid #D4D6D6;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    & a {
        position: relative;
        display: block;
        padding: 10px 15px;
        color: #162F3B;
        text-decoration: none;
    }

    .status {
        border-bottom: 1px solid #D4D6D6;
        display: block;
        align-items: center;
        padding: 0px;

        .title {
            display: flex;
            padding: 15px 10px 5px 15px
        }

        & a {
            display: flex;
            padding: 0px 0px;
            .online {
                color: #00B600;
            }
            .away {
                color: #E6C029;
            }
            .offline {
                color: #FF5A52;
            }
        }

        .bold {
            padding: 0px 3px;
            font-weight: bolder;
        }

        & button {
            width: 100%

            display: flex;
            padding: 3px 0px 3px 9px
            padding: 8px 40px;
            color: black;
            border: none;
            .boldButton {
                padding: 0px 3px;
                font-weight: bolder;
            }
            &:hover {
                background-color: #11609D;
                color: white;
                .boldButton {
                    color: white;
                }
            }
        }

        .online {
            color: #00B600;
            & > svg {
                font-size: 17px;
                margin-right: 6px;
            }
        }
        .away {
            color: #E6C029;
            & > svg {
                font-size: 17px;
                margin-right: 6px;
            }
        }
        .offline {
            color: #FF5A52;
            & > svg {
                font-size: 17px;
                margin-right: 6px;
            }
        }
    }

    & > li {
        border-bottom: 1px solid #D4D6D6;

        &:hover {
            background-color: #F4D345;
        }

        & > ul {
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;

            & > li {
                padding-left: 25px;

                &:hover {
                    background-color: #F4D345;
                }
            }
        }
    }

    & > li.nohover:hover {
        background-color: #FFF;
    }
`

export const Images = styled.li`
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 41px;

    & > img {
        height: 30px;
        margin: 3px;
        cursor: pointer;
    }
 `
export const StatusFooter = styled.div`
 padding: 1em 2em;
 position: absolute;
 width: 100%;
 bottom: 50px;
 font-size: 10px;
 text-align: center;
`
