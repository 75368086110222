import accounts from './accounts'
import api from './api'
import app from './app'
import appSync from './appSync'
import apps from './apps'
import billing from './billing'
import bullStats from './bullStats'
import globalLimit from './globalLimit'
import globalSkip from './globalSkip'
import hsmTemplates from './hsmTemplates'
import isReady from './isReady'
import messages from './messages'
import origins from './origins'
import originsAll from './originsAll'
import previousPath from './previousPath'
import profile from './profile'
import queries from './queries'
import query from './query'
import queueMessages from './queueMessages'
import resolutions from './resolutions'
import schedule from './schedule'
import scheduleUnavailable from './scheduleUnavailable'
import scheduleUnavailableDay from './scheduleUnavailableDay'
import skip from './skip'
import socket from './socket'
import stats from './stats'
import statusCT from './statusCT'
import topic from './topic'
import topics from './topics'
import uptimeStats from './uptimeStats'
import user from './user'
import users from './users'
import { routerReducer as routing } from 'react-router-redux'

export default {
    accounts,
    api,
    app,
    appSync,
    apps,
    billing,
    bullStats,
    globalLimit,
    globalSkip,
    hsmTemplates,
    isReady,
    messages,
    origins,
    originsAll,
    previousPath,
    profile,
    queries,
    query,
    queueMessages,
    resolutions,
    routing,
    schedule,
    scheduleUnavailable,
    scheduleUnavailableDay,
    skip,
    socket,
    stats,
    statusCT,
    topic,
    topics,
    uptimeStats,
    user,
    users
}
