window.mtCurrentStorage = 'localStorage';
window.mtMemoryStorage = {
    data: {},
    setItem(itemKey, itemData) {
        this.data[itemKey] = itemData;
        return itemData;
    },
    getItem(itemKey) {
        return this.data[itemKey];
    },
    removeItem(itemKey) {
        delete this.data[itemKey];
    }
};
window.mtCookieStorage = {
    data: {},
    init() {
        var self = this;
        var currentCookies = document.cookies;
        if (currentCookies) {
            var keyValues = currentCookies.split(';');
            keyValues.forEach(function(keyValue){
                var keyAndValue = keyValue.split('=');
                self.data[keyAndValue[0]] = keyAndValue[1];
            });
        }
    },
    saveCookies() {
        var toReturn = '';
        var self = this;
        Object.keys(self.data).forEach(function(key){
            toReturn += key + '=' + self.data[key] + ';';
        });
        document.cookies = toReturn;
    },
    setItem(itemKey, itemData) {
        this.data[itemKey] = itemData;
        this.saveCookies();
        return itemData;
    },
    getItem(itemKey) {
        return this.data[itemKey];
    },
    removeItem(itemKey) {
        delete this.data[itemKey];
        this.saveCookies();
    }
};

window.mtCookieStorage.init();

const callWithTry = function(storageType, methodName, key, value) {
    if (!storageType || !methodName) {
        return null;
    }
    try {
        var storage = window[storageType];
        if (storage) {
            var method = storage[methodName];
            if (method) {
                var toReturn = method.call(storage, key, value);
                if (methodName == 'setItem') {
                    if (storage.getItem(key) == value) {
                        window.mtCurrentStorage = storageType;
                        return value;
                    }
                    return null;
                } else if (methodName == 'getItem') {
                    return toReturn;
                } else {
                    return true;
                }
            } else {
                throw 'Method not found';
            }
        } else {
            throw 'Storage not found';
        }
    } catch(error) {
        window.mtCurrentStorage = null;
        return null;
    }
}

const callWithTryForStorages = function(methodName, key, value) {
    var toReturn;
    toReturn = callWithTry(window.mtCurrentStorage, methodName, key, value);
    if (!toReturn) {
        toReturn = callWithTry('localStorage', methodName, key, value);
    }
    if (!toReturn) {
        toReturn = callWithTry('sessionStorage', methodName, key, value);
    }
    if (!toReturn) {
        toReturn = callWithTry('mtCookieStorage', methodName, key, value);
    }
    if (!toReturn) {
        toReturn = callWithTry('mtMemoryStorage', methodName, key, value);
    }
    return toReturn;
}

export const set = function(key, value) {
    return callWithTryForStorages('setItem', key, value);
}

export const get = function(key) {
    return callWithTryForStorages('getItem', key);
}

export const remove = function(key) {
    return callWithTryForStorages('removeItem', key);
}

export const accessTokenKey = 'manager_token';

window.setToken = set.bind(this, accessTokenKey);
window.setLanguage = set.bind(this, 'language');
