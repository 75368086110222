import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    background-color: #fff;

    & > textarea {
        resize: none;
        border: none;
        border-radius: 4px;
        background-color: #fff;
        color: #555555;
        font-size: 16px;
        cursor: text;
        box-sizing: border-box;
        padding: 5px 10px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow: auto;
        width: 75%;
        box-shadow: 0px 0px 35px 2px rgba(0,0,0,0.2);
        -webkit-appearance: none;
        min-height: 80px;
        max-height: 120px;

        & > img {
            width: 16px;
        }
    }

    & > textarea:focus {
        outline: none;
        background-color: #fff;
        box-shadow: 0px 0px 35px 2px rgba(0,0,0,0.2);
        -webkit-appearance: none;
    }
`

export const Buttons = styled.div`
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    min-height: 80px;
    max-height: 120px;
    padding: 5px 10px;
    background-color: #fff;
`

export const Primary = styled.div`
    opacity: 1;
    cursor: pointer;
    padding: 5px;

    & > a {
        display: block;
        font-size: 42px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & > a.icon-primary {
        color: #162F3B;
        border-radius: 100%;
        background-color: #FFF;
    }

    & > a.icon-primary:hover {
        opacity: 0.6;
    }
`

export const Secondary = styled.div`
    font-size: 1.8rem;
    width: 50px;
    opacity: 1;
    cursor: pointer;
    padding: 5px;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    & svg:hover {
        opacity: 0.6;
    }
`

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: #35485b;
    background-color: #FFFFFF;
    width: 100%;
    border-top: 2px solid #ecf3f9;
    min-height: 60px;
    padding: 15px;

    & > button {
        margin-left: 10px;
    }
`

export const Tooltip = styled.div`
    max-width: 40px;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 5px;
    background-color: #F95E4E;
    color: #FFF;
    border-radius: 3px;
`

export const HSMTemplates = styled.div`
    position: absolute;
    bottom: 85px;
    margin-left: 35px;
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column-reverse;
    background-color: #FFF;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #D4D6D6;
    max-height: 100px;
    overflow: auto;

    & > button {
        margin: 1px 0px;
        background-color: #FFF;
        border: 1px solid #D4D6D6;

        &:hover {
            background-color: #11384C;
            color: #FFF;
        }

        &:focus {
            outline: none;
        }
    }
`

export const Languages = styled.div`
    position: absolute;
    bottom: 85px;
    left: calc(30% + 230px);
    display: ${props => props.show ? 'flex' : 'none'};
    flex-direction: column-reverse;
    background-color: #FFF;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #D4D6D6;
    max-height: 100px;
    overflow: auto;

    & > button {
        margin: 1px 0px;
        background-color: #FFF;
        border: 1px solid #D4D6D6;

        &:hover {
            background-color: #11384C;
            color: #FFF;
        }

        &:focus {
            outline: none;
        }
    }
`

export const HSMTemplate = styled.div`
    display: flex;
    background-color: #fff;
`

export const Text = styled.div`
    border: none;
    background-color: #fff;
    color: #999999;
    font-size: 15px;
    box-sizing: border-box;
    padding: 5px 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto;
    width: 70%;
    min-height: 80px;
    max-height: 120px;

    & > input {
        border: none;
        border-bottom: 1px dashed #D4D6D6;

        &:focus {
            outline: none;
        }
    }
`

export const Record = styled.div`
    padding: 5px 10px;
    width: 70%;
`

export const TemplateButton = styled.button`
    position: absolute;
    margin-top: -28px;
    display: ${props => props.show ? 'block' : 'none'};
    margin-left: 2px;
    background-color: #FFF;
    border-radius: 3px;
    border: 1px solid #D4D6D6;
    outline: none;
`
