import * as actions from '../actions'
const defaultState = {}
export default function statusCT(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_STATUS_CT: {
            return state
        }
        case actions.SET_STATUS_CT: {
            if (action.payload) {
                state = action.payload
            }
            return state
        }
        default:
            return state
    }
}
