import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Value
} from './styles'

import {
    Button,
    Collapse,
    Modal
} from 'react-bootstrap'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import style from './styles.less'
import Form from 'react-jsonschema-form'
import { Translate } from 'react-localize-redux'
class ContextOrExtraData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: true,
            value: props.value,
            changed: false,
            showAlertDelete: false
        }

        this.formSubmit = this.formSubmit.bind(this)
        this.formChanged = this.formChanged.bind(this)
        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.confirmDeleteData = this.confirmDeleteData.bind(this)
    }

    formSubmit(data) {
        this.setState({
            changed: false
        })
        return this.props.formSubmit(this.props.type, this.props.name, data.formData)
    }

    formChanged(data) {
        const self = this
        // Only trigger if the original value is not defined, or the new value is not defined
        // Else if both are not defined, do nothing
        if (self.state.value || data.formData) {
            if (self.timedFormInput) {
                clearTimeout(self.timedFormInput)
            }
            self.timedFormInput = setTimeout(() => {
                self.setState({
                    value: data.formData || null,
                    changed: true
                })
                if (self.props.formChanged) {
                    self.props.formChanged(this.props.type, this.props.name, data.formData || null)
                }
                self.timedFormInput = null
            }, 500)
        }
    }

    toggleCollapse() {
        this.setState({
            open: !this.state.open
        })
    }

    toggleAlertDelete() {
        this.setState({
            showAlertDelete: !this.state.showAlertDelete
        })
    }

    confirmDeleteData() {
        this.toggleAlertDelete()
        this.setState({
            value: ''
        })
        return this.props.formSubmit(this.props.type, this.props.name, '')
    }

    render() {
        const { loading, type, name, contextSchema, contextReadOnly, contextTextArea } = this.props
        const { changed, value, showAlertDelete } = this.state

        let schema = {
            type: 'string'
        }
        let formData = value
        let displayValue = this.state.value
        let uiSchema = {}
        let disabled = loading

        if (['context', 'extraData'].indexOf(type) < 0) {
            return ''
        } else if (type === 'context') {
            if (contextSchema && contextSchema.length) {
                try {
                    schema = JSON.parse(contextSchema)
                } catch (err) {
                    schema = {
                        type: 'string'
                    }
                }
            }

            if (schema.type === 'string' && typeof value === 'string') {
                formData = value
            } else if ((schema.type === 'integer' || schema.type === 'number') && (typeof value === 'number' || typeof value === 'string')) {
                formData = (typeof value === 'number') ? value : parseInt(value)
            } else if (schema.type === 'object' && value === Object(value)) {
                formData = value
            } else if (schema.type === 'array' && Array.isArray(value)) {
                formData = value
            }

            if (schema.type === 'object' && this.state.value === Object(this.state.value)) {
                displayValue = JSON.stringify(this.state.value)
            } else if (schema.type === 'array' && Array.isArray(this.state.value)) {
                displayValue = `${this.state.value.length} objetos`
            }

            uiSchema = (contextReadOnly || name === 'id') ? {
                'ui:readonly': true
            } : {}

            if (contextTextArea) {
                uiSchema['ui:widget'] = 'textarea'
            }

            disabled = loading || contextReadOnly || name === 'id'
        }

        return (
            <div>
                { !disabled && (
                    <Collapse in={this.state.open}>
                        <Form
                            className={style.form}
                            schema={schema}
                            uiSchema={uiSchema}
                            formData={formData}
                            onChange={this.formChanged}
                            onSubmit={this.formSubmit}
                        >
                            { !this.props.formChanged && (
                                <div style={{ 'marginBottom': '9px' }}>
                                    
                                    <Button bsStyle="success" bsSize="small" type="submit" disabled={disabled} style={{ display: changed ? '' : 'none', 'marginRight': '9px' }}>
                                        <FontAwesomeIcon icon="save" />
                                    </Button>
                                    <Button bsStyle="danger" bsSize="small" onClick={this.toggleAlertDelete.bind(this)} disabled={disabled} style={{ display: value ? '' : 'none' }}>
                                        <FontAwesomeIcon icon="trash" />
                                    </Button>
                                </div>
                            ) || (
                                <Button bsStyle="success" bsSize="small" type="submit" style={{ 'display': 'none' }}></Button>
                            )}
                        </Form>
                    </Collapse>
                ) || (
                    <Translate id="topics.disabledUntilInProgress" />
                ) }
                <Modal show={showAlertDelete} onHide={this.toggleAlertDelete.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title><Translate id={`topics.value.title`} /></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Translate id={`topics.value.description`} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.confirmDeleteData} disabled={loading}>
                            <Translate id='buttons.delete' />
                        </Button>
                        <Button bsStyle="primary" onClick={this.toggleAlertDelete.bind(this)} disabled={loading}>
                            <Translate id='buttons.cancel' />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

ContextOrExtraData.propTypes = {
    formSubmit: PropTypes.func,
    formChanged: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    contextSchema: PropTypes.string,
    contextReadOnly: PropTypes.bool,
    contextTextArea: PropTypes.bool
}

export default ContextOrExtraData
