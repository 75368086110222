const themes = {
    default: {
        colors: {
            primary: '#162F3B',
            secondary: '#3CACBB'
        },
        menu: {
            border: '#D4D6D6',
            selected: '#F4D345',
            text: '#162F3B'
        },
        logo: ''
    }
}

export default themes
