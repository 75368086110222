import styled from 'styled-components'

export const Alerts = styled.div`
    position: absolute;
    width: 100%;
    z-index: 1;
`

export const Pointer = styled.a`
    cursor: pointer;
`

export const Images = styled.li`
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 41px;

    & > img {
        height: 30px;
        margin: 3px;
        cursor: pointer;
    }
 `
export const StatusFooter = styled.div`
    padding: 1em 2em;
    position: absolute;
    width: 100%;
    bottom: 0;
    font-size: 10px;
    text-align: center;
`
