import * as actions from '../actions'

const defaultState = []

export default function billing(state = defaultState, action) {
    switch (action.type) {
        case actions.GET_BILLING:
            let newState = []
            if (!action.error && action.payload && action.payload.data) {
                newState = action.payload.data
            }
            return newState
        default:
            return state
    }
}
