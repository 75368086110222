import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { Translate, withLocalize } from 'react-localize-redux'

import moment from 'moment'
import 'moment-timezone'
import * as platforms from '../../../../components/common/Platforms'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import {
    Collapse,
    OverlayTrigger,
    Tooltip,
    Label
} from 'react-bootstrap'

import {
    Arrow,
    Container,
    Main,
    UserPhoto,
    Icon,
    Data,
    Top,
    Message,
    Bottom,
    Separator,
    Other,
    Table,
    TitleExtraData
} from './styles'
import isObject from 'lodash/isObject'

class Query extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            now: moment.utc().toISOString()
        }

        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.timer = setInterval(() => this.setState({ now: moment.utc().toISOString() }), 60000)
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer)
            this.timer = null
        }
    }

    goTo(uri) {
        if (this.props.clearNotifications) {
            this.props.clearNotifications()
        }
        browserHistory.push({
            pathname: uri,
            search: `?status=${this.props.status}`
        })
    }

    toggleCollapse() {
        this.setState({
            open: !this.state.open
        })
    }

    render () {
        const { appCodename, app, selected, query, activeLanguage, translations, origins, resolutions, operators } = this.props

        const messageUser = query && query.messageUser || {}
        const platform = messageUser.platform || ''

        let name = this.props.translate('messageUser.defaultName')
        if (messageUser.firstName && messageUser.lastName) {
            name = `${messageUser.firstName} ${messageUser.lastName}`
        } else if (messageUser.firstName) {
            name = messageUser.firstName
        } else if (platform.startsWith('whatsapp') && messageUser.id) {
            name = messageUser.id
        }

        let profilePicture = messageUser.profilePictureURL
        if (profilePicture && !profilePicture.startsWith('http')) {
            profilePicture = `${(app && app.chattonic && app.chattonic.url) ? app.chattonic.url : ''}${profilePicture}`
        } else if (!profilePicture) {
            profilePicture = '/manager/static/images/user.png'
        }

        const platformSettings = platforms[platform] || platforms['unknown']
        const color = platformSettings.color
        const icon = platformSettings.icon

        let date = moment.tz(query.createdAt, app.timezone).locale(activeLanguage.code).format('L LT')
        if (moment.tz(query.createdAt, app.timezone).startOf('day').isSame(moment.tz(app.timezone).startOf('day'))) {
            date = moment.tz(query.createdAt, app.timezone).locale(activeLanguage.code).format('HH:mm')
        } else if (moment.tz(query.createdAt, app.timezone).startOf('week').isSame(moment.tz(app.timezone).startOf('week'))) {
            date = moment.tz(query.createdAt, app.timezone).locale(activeLanguage.code).format('dddd HH:mm')
        }

        const uri = `/manager/customer/${appCodename}/${query._id}`

        const extras = []
        if (query.extra && query.extra && typeof query.extra === 'object' && query.extra.constructor === Object) {
            Object.keys(query.extra).forEach(key => {
                const title = key.charAt(0).toUpperCase() + key.slice(1)
                let value = query.extra[key]
                let displayValue
                if (value && Array.isArray(value)) {
                    displayValue = value.map(v => {
                        if (v && v.startsWith && v.startsWith('http')) {
                            return (
                                <div>
                                    <a href={v} target="_blank" ><Translate id="query.documentLinkOpen" /></a>
                                </div>
                            )
                        }
                        return (
                            <div>
                                {JSON.stringify(v)}
                            </div>
                        )
                    })
                } else {
                    if (value && isObject(value)) {
                        value = JSON.stringify(value)
                    }
                    const separatedValues = value && value.toString().split('\n')
                    displayValue = value && separatedValues && (
                        <div>
                            {
                                separatedValues.map((separatedValue, index) => {
                                    let toReturn
                                    if (separatedValue && separatedValue.toString().match(/^https?:\/\//)) {
                                        toReturn = (
                                            <div>
                                                <a href={value} target="_blank" key={ `a-${index}`}>
                                                    <Translate id="query.documentLinkOpen" />{ ((separatedValues.length > 1) && ` #${index + 1}`) || '' }
                                                </a>
                                            </div>
                                        )
                                    } else {
                                        toReturn = separatedValue
                                    }
                                    return (
                                        <div key={ `p-${index}`}>
                                            { toReturn }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) || null
                }
                if (displayValue) {
                    extras.push(
                        <tr key={`extra_${key.toLocaleLowerCase()}`}>
                            <TitleExtraData>{title}:&nbsp;</TitleExtraData>
                            <td>{displayValue}</td>
                        </tr>
                    )
                }
            })
        }

        const unreadMessages = (query.status === 'progress' && query.lastReadAt && query.lastNotifiedAt && query.lastReadAt < query.lastNotifiedAt) ? (
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip id="tooltip-unread">
                    {this.props.translate('query.newMessage')}
                </Tooltip>
            }>
                <FontAwesomeIcon icon="dot-circle" color="#D9534F" />
            </OverlayTrigger>
        ) : ''

        let timeToResponse = ''
        if (query.status !== 'resolved' && ((platform && platform.startsWith('whatsapp') && (platform !== 'whatsapp-chatapi-instance')) || (platform === 'messenger'))) {
            let diffTime = -1
            if (query.lastNotifiedAt || (query.message && (query.message.createdAt || query.message.receivedAt))) {
                const lastMessagePlus24Hours = moment.utc(query.lastNotifiedAt || query.message.createdAt || query.message.receivedAt).add(24, 'hours')
                diffTime = lastMessagePlus24Hours.diff(moment.utc(), 'minutes')
            }

            if (query.HSMSendedAt) {
                timeToResponse = (
                    <Label bsStyle="success">
                        <Translate id="query.recontactSend" />
                    </Label>
                )
            } else if (diffTime > 0) {
                timeToResponse = (
                    <Label bsStyle="warning">
                        <Translate id="query.timeToReply" data={{ hours: Math.floor(diffTime / 60), minutes: diffTime % 60 }} />
                    </Label>
                )
            } else {
                timeToResponse = (
                    <Label bsStyle="danger">
                        <Translate id="query.noTimeToReply" />
                    </Label>
                )
            }
        }

        let message = ''
        if (query && query.message && query.message.type) {
            if (query.message.type === 'text' || query.message.text) {
                message = query.message.text
            } else if (translations[`query.message.type.${query.message.type}`]) {
                message = this.props.translate(`query.message.type.${query.message.type}`)
            } else {
                message = this.props.translate(`query.message.type.default`)
            }
        }

        let statusColor = '#0000000F'
        if (query.status === 'pending') {
            statusColor = '#F95E4E75'
        } else if (query.status === 'progress') {
            statusColor = '#F4D3457D'
        } else if (query.status === 'resolved') {
            statusColor = '#27CCC373'
        }

        let originAssigned
        if (origins && origins.length && query && query.originCodename) {
            originAssigned = origins.find(origin => {
                return origin.codename === query.originCodename
            })
        }

        let resolutionAssigned
        if (resolutions && resolutions.length && query && query.resolution) {
            resolutionAssigned = resolutions.find(resolution => {
                return resolution._id === query.resolution
            })
        }

        let operatorAssigned
        if (operators && operators.length && query && query.operator) {
            operatorAssigned = operators.find(operator => {
                return operator._id === query.operator
            })
        }
        return (
            <Container selected={selected} color={statusColor}>
                <Main onClick={this.goTo.bind(this, uri)}>
                    <UserPhoto url={profilePicture} color={color}>
                        <Icon color={color}>
                            <i className={icon}></i>
                        </Icon>
                    </UserPhoto>
                    <Data>
                        <Top>
                            <span>{name}</span>
                            <span>{date}</span>
                        </Top>
                        <Message>
                            <span>{message}</span>
                            {query.isPublic && <span><FontAwesomeIcon icon="bullhorn" /></span>}
                        </Message>
                        <Bottom>
                            <div key={this.state.now}>
                                {timeToResponse}
                            </div>
                        </Bottom>
                    </Data>
                </Main>
                <Arrow onClick={this.toggleCollapse}>
                    <div>
                        {unreadMessages}
                        <FontAwesomeIcon icon={this.state.open ? 'chevron-up' : 'chevron-down' } />
                    </div>
                </Arrow>
                <Collapse in={this.state.open}>
                    {this.state.open && (
                        <Other>
                            <Separator />
                            <Table>
                                <tbody>
                                    {(query.dates && query.dates.pending) ? (
                                        <tr>
                                            <td><Translate id="query.status.pending" />:</td>
                                            <td>{moment.tz(query.dates.pending, app.timezone).locale(activeLanguage.code).format('L LT')}</td>
                                        </tr>
                                    ) : <tr></tr>}
                                    {(query.dates && query.dates.progress) ? (
                                        <tr>
                                            <td><Translate id="query.status.progress" />:</td>
                                            <td>{moment.tz(query.dates.progress, app.timezone).locale(activeLanguage.code).format('L LT')}</td>
                                        </tr>
                                    ) : <tr></tr>}
                                    {(query.dates && query.dates.resolved) ? (
                                        <tr>
                                            <td><Translate id="query.status.resolved" />:</td>
                                            <td>{moment.tz(query.dates.resolved, app.timezone).locale(activeLanguage.code).format('L LT')}</td>
                                        </tr>
                                    ) : <tr></tr>}
                                    {(originAssigned || query.originCodename) ? (
                                        <tr>
                                            <td><Translate id="query.origin" />:</td>
                                            <td>{`${(originAssigned && originAssigned.name) || query.originCodename || ''}`}</td>
                                        </tr>
                                    ) : <tr></tr>}
                                    {query.resolution && resolutionAssigned ? (
                                        <tr>
                                            <td><Translate id="query.resolution" />:</td>
                                            <td>{`${resolutionAssigned.name || '-'}`}</td>
                                        </tr>
                                    ) : <tr></tr>}
                                    {(operatorAssigned && operatorAssigned.displayName) ? (
                                        <tr>
                                            <td><Translate id="query.operator" />:</td>
                                            <td>{`${operatorAssigned.displayName || ''}`}</td>
                                        </tr>
                                    ) : <tr></tr>}
                                </tbody>
                            </Table>
                            {(extras && extras.length) ? (
                                <Separator />
                            ) : ''}
                            {(extras && extras.length) ? (
                                <Table>
                                    <tbody>
                                        {extras.map(extra => {
                                            return extra
                                        })}
                                    </tbody>
                                </Table>
                            ) : ''}
                        </Other>
                    ) || <div/>}
                </Collapse>
            </Container>
        )
    }
}

Query.propTypes = {
    appCodename: PropTypes.string.isRequired,
    app: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    status: PropTypes.string,
    clearNotifications: PropTypes.func,
    translations: PropTypes.object.isRequired
}

Query.defaultProps = {
    query: {},
    selected: false,
    status: 'all'
}

export default withLocalize(Query)
