import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../state/actions'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'
import {
    Container,
    Action,
    Explanation,
    Answer,
    Label,
    Section,
    DropCont,
    DropText,
    FilesText,
    Text
} from './styles'

import {
    Alert,
    Modal,
    Button,
    FormGroup,
    Tooltip,
    OverlayTrigger,
    Checkbox
} from 'react-bootstrap'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import Dropzone from 'react-dropzone'
import ContextOrExtraData from '../ContextOrExtraData'
import FormData from 'form-data'
import Spinner from '../../../../components/common/Spinner'

const validMediaTypes = [
    'image/jpeg',
    'image/png',
    'application/pdf'
]
class Step extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            resolve: props.step.resolve,
            valueFile: null,
            valueFileURL: props.step.value || null,
            changed: false,
            showAlertDelete: false,
            notification: {
                data: null,
                status: null
            }
        }

        this.copyMessage = this.copyMessage.bind(this)
        this.toggleResolve = this.toggleResolve.bind(this)
        this.formSubmit = this.formSubmit.bind(this)
        this.formChanged = this.formChanged.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.confirmDeleteData = this.confirmDeleteData.bind(this)
    }

    copyMessage() {
        this.props.copyMessage(this.props.step.answerUser)
    }

    toggleResolve() {
        this.setState({
            resolve: !this.state.resolve
        })
    }

    formSubmit(type, key, data) {
        const promise = this.props.editContextOrExtraData(type, key, data).then(() => {
            this.setState({ loading: false })
        })
        this.setState({ loading: true }, () => promise)
    }

    formChanged(type, key, data) {
        if (this.props.step.autoSave) {
            if (this.formChangedTimer) {
                clearTimeout(this.formChangedTimer)
                this.formChangedTimer = null
            }
            this.formChangedTimer = setTimeout(_ => {
                this.formSubmit(type, key, data)
            }, 1000)
        }
    }

    onSendMessageTopic(message) {
        this.props.onSendMessageTopic(message)
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text ? text.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="__blank">' + url + '</a>'
        }) : ''
    }

    async formSubmitFile() {
        const self = this
        try {
            const files = [ this.state.valueFile ]
            for (const file of files) {
                if (validMediaTypes.indexOf(file.type) >= 0) {
                    const fileNameVsTags = file.name.split('.')
                    const tags = ['file']
                    const title = fileNameVsTags[0]
                    tags.shift()

                    const formData = new FormData()
                    formData.append('upload', file)
                    formData.append('title', title)

                    if (tags.length) {
                        formData.append('tags', tags.join(','))
                    }

                    const params = {
                        fileName: formData.fileName
                    }

                    const uploadResponse = await self.props.actions.uploadTopic(self.props.app.codename, formData, params)
                    if (uploadResponse && uploadResponse.payload && uploadResponse.payload.data && uploadResponse.payload.data.originalURL) {
                        const uploadURL = `${uploadResponse.payload.data.baseURL}${uploadResponse.payload.data.originalURL}`
                        this.setState({
                            valueFileURL: uploadURL
                        }, _ => {
                            this.formSubmit('context', this.props.step.context, this.state.valueFileURL)
                        })
                    } else {
                        throw this.props.translate('topic.errorFileType')    
                    }
                } else {
                    throw this.props.translate('topic.errorFileType')
                }
            }
        } catch (error) {
            this.setState({
                valueFileURL: null,
                notification: {
                    data: error.toString(),
                    status: 'danger'
                }
            })
        }
    }

    async onDrop(files) {
        const self = this
        const file = files && files.length && files[0] || null
        if (file) {
            self.setState({
                valueFile: file,
                valueFileURL: null,
                notification: null,
                changed: true
            }, _ => {
                if (self.props.step.autoSave) {
                    self.formSubmitFile()
                }
            })
        }
    }

    toggleAlertDelete() {
        this.setState({
            showAlertDelete: !this.state.showAlertDelete
        })
    }

    confirmDeleteData() {
        this.toggleAlertDelete()
        this.setState({
            valueFile: null,
            valueFileURL: null,
            notification: null,
            changed: false
        })
        return this.formSubmit('context', this.props.step.context, '')
    }

    render() {
        const { index, step, query, expired } = this.props
        const { valueFile, valueFileURL, changed, showAlertDelete } = this.state
        const theresSomething = step.answerUser || step.flow
        const disabled = step.condition && step.condition.enabled && !step.condition.isTrue
        const canResolve = step.canResolve && step.flow && query && query.status && query.status === 'progress'
        const autoResolve = canResolve && step.autoResolve
        const hasExplanation = step.condition && step.condition.explanation

        let button = ''
        if (step.answerUser && step.flow) {
            button = (
                <Button disabled={disabled || expired} className="btn btn-primary" onClick={this.onSendMessageTopic.bind(this, {
                    message: {
                        text: step.answerUser
                    },
                    flow: step.flow,
                    resolve: (step.canResolve && step.autoResolve) || this.state.resolve,
                    unpause: step.unpause
                })}>
                    {step.textButton || <FontAwesomeIcon icon="paper-plane" />}
                </Button>
            )
        } else if (step.answerUser) {
            button = (
                <Button disabled={disabled || expired} className="btn btn-primary" onClick={this.onSendMessageTopic.bind(this, {
                    message: {
                        text: step.answerUser
                    }
                })}>
                    {step.textButton || <FontAwesomeIcon icon="paper-plane" />}
                </Button>
            )
        } else if (step.flow) {
            button = (
                <Button disabled={disabled || expired} className="btn btn-primary" onClick={this.onSendMessageTopic.bind(this, {
                    flow: step.flow,
                    resolve: (step.canResolve && step.autoResolve) || this.state.resolve,
                    unpause: step.unpause
                })}>
                    {step.textButton || <FontAwesomeIcon icon="paper-plane" />}
                </Button>
            )
        }

        return (
            <Container>
                <div style={{ 'height': button ? '40px' : '20px' }}>
                    <Label>{((step.textButton && step.textButton !== '') || step.flow) ? '' : `${step.title}`}</Label>
                    { (query && query.status === 'progress') && (
                        (this.state.loading && (
                            <Spinner />
                        )) || (
                            <Action enabled={theresSomething}>
                                {button}
                                {' '}
                                <Button style={{ display: step.answerUser ? 'block' : 'none' }} bsStyle="default" bsSize="xsmall" onClick={this.copyMessage}>
                                    <FontAwesomeIcon icon="clipboard" />
                                </Button>
                            </Action>
                        )) || null}
                </div>
                { canResolve && !autoResolve && (
                    <FormGroup style={{ display: '', marginBottom: '5px' }} bsSize="small">
                        <Checkbox checked={this.state.resolve} onChange={this.toggleResolve} disabled={disabled}>
                            <Translate id="topic.resolve" />
                        </Checkbox>
                    </FormGroup>
                )}
                <Explanation enabled={hasExplanation}>{step.condition && step.condition.explanation}</Explanation>
                <Answer enabled={step.answerUser} dangerouslySetInnerHTML={{ __html: this.urlify(step.answerUser) }}></Answer>
                {(step.context && step.context.length && step.contextMedia) && (
                    <div>
                        {(step.contextReadOnly || (query && query.status !== 'progress')) && (
                            <div>
                                <Translate id="topics.disabledUntilInProgress" />
                            </div>
                        ) || (valueFileURL && (
                            <div>
                                <Text>
                                    <a href={ valueFileURL } target="_blank">{ valueFileURL }</a> 
                                </Text>
                            </div>
                        )) || (valueFile && (
                            <div>
                                <Text>
                                    { valueFile.name } 
                                </Text>
                            </div>
                        )) || (
                            <div>
                                <Dropzone
                                    key={ `dropzone-${this.state.formKey}` }
                                    onDrop={this.onDrop}
                                    multiple={false}
                                    disabled={disabled}
                                    style={{ 'display': 'inline-block', 'width': '100%' }}
                                >
                                    <div>
                                        <Section>
                                            <DropCont>
                                                <Translate id="topic.upload" />
                                            </DropCont>
                                        </Section>
                                    </div>
                                </Dropzone>
                                {(this.state.notification && this.state.notification.data && this.state.notification.status) && (
                                    <div className="col-xs-12">
                                        <br />
                                        <Alert bsStyle={this.state.notification.status} onDismiss={this.clearNotification}>
                                            <strong>{ this.state.notification.data }</strong>
                                        </Alert>
                                    </div>
                                ) || null}
                            </div>
                        )}
                        {(query && query.status === 'progress') && (
                            <div style={{ 'marginBottom': '9px' }}>
                                {!valueFileURL && !this.props.step.autoSave && (
                                    <Button bsStyle="success" bsSize="small" onClick={this.formSubmitFile.bind(this)} type="submit" disabled={disabled} style={{ display: changed ? '' : 'none', 'marginRight': '9px' }}>
                                        <FontAwesomeIcon icon="save" />
                                    </Button>
                                )}
                                <Button bsStyle="danger" bsSize="small" onClick={this.toggleAlertDelete.bind(this)} disabled={disabled} style={{ display: (valueFile || valueFileURL) ? '' : 'none' }}>
                                    <FontAwesomeIcon icon="trash" />
                                </Button>
                            </div>
                        ) || null}
                        <Modal show={showAlertDelete} onHide={this.toggleAlertDelete.bind(this)}>
                            <Modal.Header closeButton>
                                <Modal.Title><Translate id={`topics.value.title`} /></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Translate id={`topics.value.description`} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button bsStyle="danger" onClick={this.confirmDeleteData} disabled={this.state.loading}>
                                    <Translate id='buttons.delete' />
                                </Button>
                                <Button bsStyle="primary" onClick={this.toggleAlertDelete.bind(this)} disabled={this.state.loading}>
                                    <Translate id='buttons.cancel' />
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                ) || ((step.context && step.context.length) && (
                    <ContextOrExtraData
                        formSubmit={this.formSubmit}
                        formChanged={this.props.step.autoSave && this.formChanged || null}
                        loading={!this.props.step.autoSave && this.state.loading}
                        type="context"
                        name={step.context}
                        value={step.value}
                        contextSchema={step.contextSchema}
                        contextReadOnly={step.contextReadOnly || (query && query.status !== 'progress')}
                        contextTextArea={step.contextTextArea}
                    />
                )) || null}
                {(step.extraData && step.extraData.length) && (
                    <ContextOrExtraData
                        formSubmit={this.formSubmit}
                        formChanged={this.props.step.autoSave && this.formChanged || null}
                        loading={!this.props.step.autoSave && this.state.loading}
                        type="extraData"
                        name={step.extraData}
                        value={query && query.extra && query.extra[step.extraData]}
                    />
                ) || null}
            </Container>
        )
    }
}

Step.propTypes = {
    index: PropTypes.number,
    query: PropTypes.object,
    topic: PropTypes.object,
    step: PropTypes.object,
    copyMessage: PropTypes.func,
    editContextOrExtraData: PropTypes.func,
    onSendMessageTopic: PropTypes.func,
    expired: PropTypes.bool
}

function mapStateToProps(state) {
    return {
        app: state.app,
        appSync: state.appSync
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Step))
