import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`

export const Chat = styled.div`
    height: 100%;
    width: 60%;

    & > :first-child {
        height: calc(100% - 90px);
        width: 100%;
    }

    & > :last-child {
        height: 90px;
        width: 100%;
        border-top: 1px solid #D4D6D6;
    }
`

export const ChatApp = styled.div`
    height: 100%;
    width: 100%;

    & > :first-child {
        height: calc(100% - 82px);
        width: 100%;
    }

    & > :last-child {
        height: 82px;
        width: 100%;
        border-top: 1px solid #D4D6D6;
    }
`

export const Management = styled.div`
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #D4D6D6;
`

export const ManagementApp = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #D4D6D6;
`
