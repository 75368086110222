import * as actions from '../actions'

const defaultState = []

export default function accounts(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.CLEAN_ACCOUNTS:
            return defaultState

        case actions.GET_ACCOUNTS:
            if (!action.error) {
                state = action.payload.data.objects
            }
            return state

        default:
            return state
    }
}
