import styled from 'styled-components'

export const Container = styled.div`
    background: ${props => props.theme.colors.primary};
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & #nav-dropdown {
        color: white;
        font-size: 2rem;
        width: 64px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .open {
        & #nav-dropdown {
            background-color: #FFF;
            color: ${props => props.theme.colors.primary};
        }
    }

    & .dropdown-menu {
        & > li {
            padding: 5px 15px;
            & > a {
                color: ${props => props.theme.colors.primary};
            }
        }
    }
`

export const LeftMenu = styled.div`
    height: 50px;
    min-width: 40%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    @media(max-width: 767px) {
        padding-left: 5px;
    }

    & > button {
        width: 45px;
        margin-right: 8px;

        &:focus {
            outline: 0;
        }
    }

    .btn-profile {
        min-width: 250px;
        background-color: ${props => props.theme.colors.primary};
        color: white;
        border-radius: 4px;
        border: none;
        display: flex;
        align-items: center;
        padding: 6px;
        justify-content: flex-start;
        & > svg {
            margin-right: 12px;
            font-size: 2rem;
            width: 20px;
        }

        &:hover {
            border: '1px solid black'
            transition: all 0.4s ease 0s;
            opacity: 0.8;
        }
    }
    
    .soonOffline {
        min-width: 350px;
        max-height: 30px;
    }
`

export const StatusMenu = styled.div`
    text-align: left;
`

export const StatusMenuTitle = styled.div`
    color: white;
    font-weight: bolder;
    font-size: 1.3rem;
    margin-bottom: -2px;
`

export const StatusMenuDes = styled.div`
    .online {
        color: #00B600;
    }
    .away {
        color: #E6C029;
    }
    .offline {
        color: #FF5A52;
    }
    font-size: 1.2rem;
    font-weight: bolder;
`

export const Logo = styled.div`
    background-image: url(${props => '/manager/static/images/chat-tonic-negativo-2.png'});
    width: 180px;
    height: 40px;
    background-size: ${props => 'cover'};
    background-position: center;
    background-repeat: no-repeat;
    @media(max-width: 767px) {
        padding: 0px;
    }
`

export const RightMenu = styled.div`
    list-style-type: none;
    height: 50px;
    min-width: 33%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const Alert = styled.div `
    color: #FFFFFF;
    background-color: #F0AD4E;
    border-radius: 3px;
    padding: 5px 8px;
    text-align: center;
    margin: 0px 7px;
`
export const ButtonAlert = styled.button `
    background-color: #FF0000;
    border: none;
    margin: 3px;
    padding-left: 12px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`

export const AlertBilling = styled.div `
    color: white;
`
