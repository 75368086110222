import * as actions from '../actions'

const defaultState = {}

export default function globalSkip(state = defaultState, action) {
    switch (action.type) {
        case actions.CHANGED_SKIP:
            state = action.payload
            return state
        case actions.CLEAN_CHANGED_SKIP:
            state = {}
            return state
        default:
            return state
    }
}
