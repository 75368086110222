import * as actions from '../actions'

const defaultState = null

export default function app(state = defaultState, action) {
    switch (action.type) {
        case actions.APP_SYNC:
            if (!action.error){
                state = action.payload.data
            }
            return state

        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
        case actions.APP_CLEAN_ONE:
            return defaultState

        default:
            return state
    }
}
