import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-color: #FFF;
`

export const Separator = styled.div`
    text-align: center;
    background-color: #ddd;
    padding: 3px;
    margin: 5px 0px;
`

export const NotFound = styled.div`
    position: relative;
    top: 50%;
    height: fit-content;
    font-weight: bold;
    text-align: center;
`
export const ToBottom = styled.button`
    position: fixed;
    left: 69%;
    bottom: 17%;
    height: 35px;
    width: 35px;
    background-color: #11384C;
    color: #FFF;
    font-size: 2rem;
    border: none;
    border-radius: 100%;
    outline: 0;
`
