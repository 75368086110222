import * as actions from '../actions'
import * as storage from '../../utils/storage'

const defaultState = null

export default function api(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.API_CLEAN:
            return defaultState

        default:
            if (action.error) {
                state = {}
                if (action.payload && action.payload.error && action.payload.error.response && action.payload.error.response.status && action.payload.error.response.status === 401) {
                    storage.remove(storage.accessTokenKey)
                }
                if (action.payload && action.payload.error && action.payload.error.data && action.payload.error.data.error) {
                    state.error = action.payload.error.data.error
                } else if (action.payload.error) {
                    state.error = action.payload.error
                } else {
                    state.error = action.payload
                }
                if (!state.error) {
                    state.error = 'Unexpected error'
                }
            }
            return state
    }
}
