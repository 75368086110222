import React from 'react'
import { withLocalize } from 'react-localize-redux'
import style from './styles.less'
import * as storage from '../../utils/storage'

import {
    DropdownButton,
    MenuItem
} from 'react-bootstrap'

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
    <DropdownButton
        id="language-picker"
        pullRight
        bsStyle="default"
        className={style.language}
        title={(activeLanguage && activeLanguage.name && activeLanguage.code) ? (
            <div>
                <img src={`/manager/static/images/flags/${activeLanguage.code}.png`} />
            </div>
        ) : ''}
    >
        {languages.map(lang => (
            <MenuItem key={lang.code} eventKey={lang.code} onClick={() => {
                storage.set('language', lang.code)
                setActiveLanguage(lang.code)
            }}>
                {lang.name} <img src={`/manager/static/images/flags/${lang.code}.png`} />
            </MenuItem>
        ))}
    </DropdownButton>
)

export default withLocalize(LanguageToggle)
