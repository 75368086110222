import React, { Component} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Translate } from 'react-localize-redux'

class AlertNonPayment extends Component {
    render() {
        const self = this
        const { billing } = self.props
        const textDetails = billing && billing.billing && billing.billing.enabled && billing.billing.registers && billing.billing.registers.length ? (
            billing.billing.registers.map((item, i) => {
                const array = Object.values(item)
                return (
                    <p key={`billing nonpayment ${i}`}><Translate id="alertNonPayment.description2" data={{date: array[0], registerNumber: array[2]}}/></p>
                )
            })
        ) : ''
        return (
            <div>
                <Modal show={this.props.show} onHide={this.props.closeModal}>
                    <Modal.Header closeButton >
                        <Modal.Title>
                            <p><Translate id="alertNonPayment.title"/></p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p><Translate id="alertNonPayment.description"/></p>
                            {textDetails}
                            <p><Translate id="alertNonPayment.alertCancel"/></p>
                            <p><Translate id="alertNonPayment.thankYou"/></p>
                            <br/>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        billing: state.billing
    }
}

AlertNonPayment.propTypes = {
    show: PropTypes.bool,
    closeModal: PropTypes.func
}

export default connect(mapStateToProps, null, null, { withRef: true })(AlertNonPayment)
