import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Wrapper,
    Container,
    Message
} from '../MessageContainer/styles'
import {
    Text
} from '../Message/styles'

function PendingMessage({ message, messageUserId, status }) {
    const { text, images, videos, documents, voices } = message
    let messageText = text
    if (!text) {
        let translation = 'query.message.pending.default'
        if (images) {
            translation = 'query.message.pending.image'
        } else if (videos) {
            translation = 'query.message.pending.video'
        } else if (documents) {
            translation = 'query.message.pending.document'
        } else if (voices) {
            translation = 'query.message.pending.voice'
        }
        messageText = <Translate id={translation} />
    }
    return (
        <Wrapper from="self">
            <Container from="self" operatorData={true}>
                <Message isText={true} from="self" operatorData={true}>
                    <Text from="self">
                        {messageText}
                        &nbsp;
                        { status && status === 'sending' && (
                            <FontAwesomeIcon icon="spinner" spin={true} />
                        ) || ((!status || status === 'failed') && (
                            <FontAwesomeIcon icon="exclamation-circle" style={ { color: 'red' } } />
                        ))}
                    </Text>
                </Message>
            </Container>
        </Wrapper>
    )
}

PendingMessage.propTypes = {
    message: PropTypes.object,
    messageUserId: PropTypes.string,
    status: PropTypes.string
}

export default PendingMessage
