import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import Spinner from './../common/Spinner'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import { Translate, withLocalize } from 'react-localize-redux'
import ScheduleUnavailableRecurrentAddEddit from '../ScheduleUnavailableRecurrentAddEddit'

import {
    FormGroup,
    ControlLabel,
    Button,
    ButtonToolbar,
    HelpBlock,
    Tabs,
    Tab
} from 'react-bootstrap'

class ScheduleUnavailableDayAddEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            form: {},
            tab: 'exception',
            tabEnabledExceptionIsRecurrent: true,
            tabEnabledExceptionNotRecurrent: true
        }

        Form.addInputTypes(types)
        momentLocalizer(moment)

        this.goBack = this.goBack.bind(this)
        this.addEditScheduleUnavailableDay = this.addEditScheduleUnavailableDay.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
    }

    async componentWillMount() {
        const self = this
        const origins = await self.props.actions.originsGet(self.props.params.appCodename)
        try {
            self.setState({ loading: true })
            if (self.props.params.scheduleUnavailableDayId) {
                const getScheduleUnavailableDay = await self.props.actions.getScheduleUnavailableDay(self.props.params.appCodename, self.props.params.scheduleUnavailableDayId)
                const tabEnabledExceptionIsRecurrent = getScheduleUnavailableDay && getScheduleUnavailableDay.payload && getScheduleUnavailableDay.payload.data && getScheduleUnavailableDay.payload.data.isRecurrent
                self.setState({
                    tabEnabledExceptionIsRecurrent,
                    tabEnabledExceptionNotRecurrent: !tabEnabledExceptionIsRecurrent,
                    origins: origins
                })
            }
            self.setState({ origins: origins })
        } finally {
            self.setState({ loading: false })
        }
    }

    componentWillUnmount() {
        this.props.actions.cleanScheduleUnavailableDay()
    }

    getModelSchema() {
        const self = this
        const { scheduleUnavailableDay } = self.props

        let convertDateObject = new Date()
        let convertDateToObject = new Date()
        if (scheduleUnavailableDay && (scheduleUnavailableDay.date && scheduleUnavailableDay.dateTo)) {
            const convertDate = moment(scheduleUnavailableDay.date).utc().format('YYYY-MM-DD HH:mm:ss')
            convertDateObject = new Date(convertDate)

            const convertDateTo = moment(scheduleUnavailableDay.dateTo).utc().format('YYYY-MM-DD HH:mm:ss')
            convertDateToObject = new Date(convertDateTo)
        }

        const defaultOrigin = scheduleUnavailableDay && scheduleUnavailableDay.origins || []
        return yup.object({
            dateFrom: yup.date().nullable().min(moment.utc().startOf('day'))
                .required(self.props.translate('unschedules.validations.date'))
                .default(convertDateObject),
            dateTo: yup.date().nullable().min(yup.ref('dateFrom'), self.props.translate('unschedules.validations.dateTo'))
                .required(self.props.translate('unschedules.validations.date'))
                .default(convertDateToObject),
            from: yup.date().nullable().required(self.props.translate('unschedules.validations.from')).default((scheduleUnavailableDay && scheduleUnavailableDay.from) ? moment(scheduleUnavailableDay.from, 'HH:mm').toDate() : moment().hours(9).minutes(0).toDate()),
            to: yup.date().nullable().required(self.props.translate('unschedules.validations.to')).default((scheduleUnavailableDay && scheduleUnavailableDay.to) ? moment(scheduleUnavailableDay.to, 'HH:mm').toDate() : moment().hours(18).minutes(0).toDate()),
            message: yup.string().required(self.props.translate('unschedules.validations.message')).default(scheduleUnavailableDay && scheduleUnavailableDay.message || ''),
            origins: yup.array().default(defaultOrigin)
        })
    }

    goBack() {
        browserHistory.push({
            pathname: `/manager/${this.props.params.appCodename}/schedule/unavailable`
        })
    }

    addEditScheduleUnavailableDay(formValues) {
        const self = this
        let promise = Promise.resolve()

        let values = {}

        const newsOrigins = []
        if (formValues && formValues.origins && formValues.origins.length > 0) {
            formValues.origins.forEach(origin => {
                newsOrigins.push(origin && origin.codename || origin)
            })
        }
        if (formValues && formValues.isRecurrent && formValues.isoWeekday && formValues.isoWeekday.length > 0) {
            formValues.origins = newsOrigins
            values = formValues
        } else {
            values.date = moment(formValues.dateFrom).utc().startOf('day').toDate()
            values.dateTo = moment(formValues.dateTo).utc().startOf('day').toDate()
            values.from = moment(formValues.from).format('HH:mm')
            values.to = moment(formValues.to).format('HH:mm')
            values.message = formValues.message
            values.isRecurrent = false
            values.origins = newsOrigins
        }

        if (self.props.params.scheduleUnavailableDayId) {
            promise = self.props.actions.putScheduleUnavailableDay(self.props.params.appCodename, self.props.params.scheduleUnavailableDayId, values)
        } else {
            promise = self.props.actions.postScheduleUnavailableDay(self.props.params.appCodename, values)
        }

        promise = promise.catch(error => {
            console.log(error)
        }).then(action => {
            this.setState({
                loading: false
            }, () => {
                this.goBack()
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    handleSelect(value) {
        this.setState({ tab: value })
    }

    render() {
        const self = this
        const { activeLanguage, scheduleUnavailableDay, origins } = self.props
        const { loading, tab, tabEnabledExceptionIsRecurrent, tabEnabledExceptionNotRecurrent } = self.state
        if (!scheduleUnavailableDay && self.props.params.scheduleUnavailableDayId) {
            return <Spinner/>
        }

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <h1 className="page-header">
                            <Translate id={self.props.params.scheduleUnavailableDayId ? tabEnabledExceptionIsRecurrent ? 'unschedules.titles.editRecurrent' : 'unschedules.titles.edit' : 'unschedules.titles.add'} />
                        </h1>
                    </div>
                </div>
                {!self.props.params.scheduleUnavailableDayId &&
                <Tabs activeKey={tab} onSelect={self.handleSelect.bind(self)} id="controlled-tab-example" style={{'margin-bottom': '7px'}}>
                    <Tab eventKey={'exception'} title={self.props.translate('unschedules.titles.exception')}></Tab>
                    <Tab eventKey={'recurrent'} title={self.props.translate('unschedules.titles.exceptionRecurrent')}></Tab>
                </Tabs>}
                <div style={{padding: '5px 0px'}} className="row">
                    <div className="col-xs-12">
                        {tab === 'exception' && tabEnabledExceptionNotRecurrent ? <Form schema={self.getModelSchema()} defaultValue={self.getModelSchema().default()} onSubmit={self.addEditScheduleUnavailableDay}>
                            <div className="row">
                                <div className="col-xs-6">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="unschedules.dataColumnsNames.dayFrom" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="dateFrom"
                                            min={moment.utc().toDate()}
                                            format="DD/MM/YYYY"
                                            disabled={loading}
                                            culture={activeLanguage.code}
                                        />
                                        <Form.Message for="dateFrom"/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="unschedules.dataColumnsNames.dayTo" />
                                        </ControlLabel>
                                        <Form.Field
                                            name='dateTo'
                                            min={moment.utc().toDate()}
                                            format="DD/MM/YYYY"
                                            disabled={loading}
                                            culture={activeLanguage.code}
                                        />
                                        <Form.Message for="dateTo"/>
                                    </FormGroup>
                                </div>
                                <div className="col-xs-6">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="unschedules.dataColumnsNames.time" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="from"
                                            placeholder="09:00"
                                            type="datetimepicker"
                                            culture={activeLanguage.code}
                                            date={false}
                                            parse={['HH:mm', 'H:mm']}
                                            format="HH:mm"
                                            timeFormat="HH:mm"
                                            disabled={loading}
                                        />
                                        <Form.Message for="from"/>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="unschedules.dataColumnsNames.time" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="to"
                                            placeholder="18:00"
                                            type="datetimepicker"
                                            culture={activeLanguage.code}
                                            date={false}
                                            parse={['HH:mm', 'H:mm']}
                                            format="HH:mm"
                                            timeFormat="HH:mm"
                                            disabled={loading}
                                        />
                                        <Form.Message for="to"/>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="unschedules.dataColumnsNames.reason" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="message"
                                            placeholder={self.props.translate('unschedules.placeholders.message')}
                                            type="textarea"
                                            className="form-control"
                                            disabled={loading}
                                        />
                                        <Form.Message for="message"/>
                                        <HelpBlock>
                                            <Translate id="unschedules.helps.message" />
                                        </HelpBlock>
                                    </FormGroup>
                                </div>
                            </div>
                            {origins && origins.objects && origins.objects.length ?
                                <div className="row">
                                    <div className="col-xs-12">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="users.propNames.origins" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="origins"
                                            type="multiselect"
                                            textField="name"
                                            data={origins.objects}
                                            disabled={loading}
                                        />
                                        <Form.Message for="origins" />
                                    </FormGroup>
                                </div>
                            </div> : ''}
                            <div className="row">
                                <div className="col-xs-12">
                                    <FormGroup>
                                        <ButtonToolbar className="pull-right">
                                            <Form.Button type="submit" className="btn btn-primary" disabled={loading}>
                                                <Translate id="buttons.save" />
                                            </Form.Button>
                                            <Button type="button" className="btn btn-danger" onClick={self.goBack} disabled={loading}>
                                                <Translate id="buttons.cancel" />
                                            </Button>
                                        </ButtonToolbar>
                                    </FormGroup>
                                </div>
                            </div>
                        </Form> :  <ScheduleUnavailableRecurrentAddEddit
                            addEditScheduleUnavailableDay={self.addEditScheduleUnavailableDay}
                            goBack= {self.goBack}
                        />
                    }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        origins: state.origins,
        profile: state.profile,
        scheduleUnavailableDay: state.scheduleUnavailableDay
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(ScheduleUnavailableDayAddEdit))
