import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'

import {
    FormGroup,
    ControlLabel,
    ButtonToolbar,
    Button,
    Alert
} from 'react-bootstrap'

class AppAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formKey: new Date().toISOString(),
            syncedApp: false,
            value: [],
            formValues: {},
            error: false
        }

        Form.addInputTypes(types)

        this.registerAppForm = this.registerAppForm.bind(this)
        this.appSync = this.appSync.bind(this)
        this.goBack = this.goBack.bind(this)
    }

    goBack() {
        browserHistory.push({
            pathname: '/manager/app'
        })
    }

    getModelSchema() {
        const { formValues } = this.state
        const currentModel = {
            chattonic: {
                url: yup.string().required('Ingrese la url de chattonic').default(formValues && formValues.chattonic && formValues.chattonic.url || 'https://chat-tonic.com'),
                codename: yup.string().required('Ingrese el codename de chattonic').default(formValues && formValues.chattonic && formValues.chattonic.codename),
                token: yup.string().required('Ingrese el app token de chattonic').default(formValues && formValues.chattonic && formValues.chattonic.token),
            }
        }
        if (this.state.syncedApp) {
            currentModel.name = yup.string().required('Ingrese un nombre').default(formValues && formValues.name)
            currentModel.codename = yup.string().required('Ingrese un codename').default(formValues && formValues.codename)
            currentModel.timezone = yup.string().required('Ingrese un timezone').default(formValues && formValues.timezone)
            currentModel.location = yup.string().required('Ingrese un location').default(formValues && formValues.location)
            currentModel.chattonic.endFlow = yup.string().required('Ingrese el flow correspondiente al cierre de la consulta').default('middlewareCustomerServiceEnd')
        }
        currentModel.chattonic = yup.object(currentModel.chattonic)
        return yup.object(currentModel)
    }

    async appSync(formValues) {
        const self = this
        const chattonic = formValues.chattonic
        const appCodename = chattonic.codename
        const appToken = chattonic.token
        const ctURL = chattonic.url

        self.setState({
            loading: true,
            syncedApp: false,
            notifications: []
        })

        try {
            const syncData = await self.props.actions.appSync({
                url: ctURL,
                codename: appCodename,
                token: appToken
            })

            if (!(syncData && syncData.payload && syncData.payload.data && syncData.payload.data.customerservice)) {
                throw new Error('La opción "ATENCION AL CLIENTE" está desactivada. Para continuar, ingrese al Manager de Chat-Tonic y edite su aplicación activando esta opción')
            }

            const formValuesNew = Object.assign({}, formValues)
            formValuesNew.name = syncData.payload.data.name
            formValuesNew.codename = syncData.payload.data.codename
            formValuesNew.timezone = syncData.payload.data.timezone
            formValuesNew.location = syncData.payload.data.bigquery && syncData.payload.data.bigquery.location || 'US'

            self.setState({
                loading: false,
                syncedApp: syncData,
                formKey: new Date().toISOString(),
                formValues: formValuesNew,
                notifications: []
            })
        } catch (error) {
            self.setState({
                loading: false,
                syncedApp: false,
                notifications: [{
                    style: 'danger',
                    text: `Could not sync with Chat-Tonic: ${(error && error.error && error.error.message) || (error.message) || error.toString()}`
                }] || []
            })
        }
    }

    async registerAppForm(formValues) {
        const self = this
        const chattonic = formValues.chattonic
        const appCodename = chattonic.codename
        const appToken = chattonic.token
        delete formValues.chattonic.token

        self.setState({
            loading: true,
            notifications: []
        })

        try {
            await self.props.actions.appAdd(formValues)
            await self.props.actions.appEditAppData(appCodename, 'chattonic', { token: appToken })
            self.setState({
                loading: false,
                notifications: []
            }, self.goBack)
        } catch (error) {
            self.setState({
                loading: false,
                notifications: error && error.message && [{
                    style: 'danger',
                    text: error.message
                }] || []
            })
        }
    }

    render() {
        const self = this
        const submitMethod = self.state && self.state.syncedApp ? self.registerAppForm : self.appSync
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className="page-header">Agregar App</h1>
                    </div>
                </div>
                <div className="row">
                    {this.state && this.state.notifications && this.state.notifications.map((notification, index) => {
                        return (
                            <Alert key={`notification-${index}`} bsStyle={notification.style}>
                                <strong>{notification.text}</strong>
                            </Alert>
                        )
                    })}
                    <Form key={this.state.formKey} schema={this.getModelSchema()} defaultValue={this.getModelSchema().default()} onChange={(formValues, fieldUpdated) => {
                        this.setState({ formValues })
                    }} onSubmit={submitMethod}>
                        <FormGroup>
                            <ControlLabel>Chat-Tonic API URL</ControlLabel>
                            <Form.Field name="chattonic.url" placeholder="http://chat-tonic.com" className="form-control" disabled={ self.state.syncedApp }/>
                            <Form.Message for="chattonic.url" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Chat-Tonic App Codename</ControlLabel>
                            <Form.Field name="chattonic.codename" placeholder="codename" className="form-control" disabled={ self.state.syncedApp }/>
                            <Form.Message for="chattonic.codename" />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Chat-Tonic App Token</ControlLabel>
                            <Form.Field name="chattonic.token" placeholder="token" className="form-control" disabled={ self.state.syncedApp }/>
                            <Form.Message for="chattonic.token" />
                        </FormGroup>
                        { !self.state.syncedApp && (
                            <FormGroup>
                                <ButtonToolbar>
                                    <Form.Button type="submit" className="btn btn-primary">
                                        Sincronizar con Chat-Tonic
                                    </Form.Button>
                                </ButtonToolbar>
                            </FormGroup>
                        ) }
                        { self.state.syncedApp && (
                            <div>
                                <FormGroup>
                                    <ControlLabel>Nombre</ControlLabel>
                                    <Form.Field name="name" className="form-control" disabled={ true } />
                                    <Form.Message for="name" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Codename</ControlLabel>
                                    <Form.Field name="codename" className="form-control" disabled={ true } />
                                    <Form.Message for="codename" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Timezone</ControlLabel>
                                    <Form.Field name="timezone" className="form-control" disabled={ true } />
                                    <Form.Message for="timezone" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Location BigQuery</ControlLabel>
                                    <Form.Field name="location" placeholder="Location BigQuery" className="form-control" type="select" disabled={ true } >
                                        <option value="US">United States</option>
                                        <option value="EU">Europe</option>
                                    </Form.Field>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Flow a mandar cuando termina la consulta</ControlLabel>
                                    <Form.Field name="chattonic.endFlow" placeholder="middlewareCustomerServiceEnd" className="form-control" />
                                    <Form.Message for="chattonic.endFlow" />
                                </FormGroup>
                                <FormGroup>
                                    <ButtonToolbar className="pull-right">
                                        <Form.Button type="submit" className="btn btn-primary">
                                            Guardar
                                        </Form.Button>
                                        <Button bsStyle="danger" onClick={this.goBack}>
                                            Cancelar
                                        </Button>
                                    </ButtonToolbar>
                                    {this.state.error ? (
                                        <div style={{color: '#d9534f'}}>
                                            {this.state.error}
                                        </div>
                                    ) : ''}
                                </FormGroup>

                            </div>
                        ) }
                    </Form>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        app: state.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(AppAdd)
