import * as actions from '../actions'

const defaultState = null

export default function topics(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_SELECT:
            return defaultState

        case actions.TOPIC_GET:
            if (!action.error) {
                state = action.payload.data.objects
            }
            return state

        case actions.TOPIC_GET_SEARCH:
            if (!action.error) {
                state = action.payload.data.objects
            }
            return state

        case actions.CLEAN_TOPICS:
            return defaultState

        default:
            return state
    }
}
