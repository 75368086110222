import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import List from './../common/List'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate, withLocalize } from 'react-localize-redux'

import {
    Button,
    ButtonToolbar
} from 'react-bootstrap'

class Resolution extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataColumnsNames: [
                'resolutions.dataColumnNames.name', 'resolutions.dataColumnNames.actions'
            ],
            showModal: false
        }

        this.props.actions.resolutionsGet = this.props.actions.resolutionsGet.bind(this, this.props.params.appCodename)
        this.props.actions.resolutionsClean = this.props.actions.resolutionsClean.bind(this)
        this.props.actions.resolutionsAdd = this.props.actions.resolutionsAdd.bind(this)
        this.confirmDeleteData = this.confirmDeleteData.bind(this)
        this.addResolution = this.addResolution.bind(this)
    }

    addResolution() {
        const self = this
        const list = self.list.getWrappedInstance()
        list.openModal({
            title: this.props.translate('resolutions.add.title'),
            description: this.props.translate('resolutions.add.description'),
            input: {
                fields: [
                    'name'
                ],
                modelSchema: yup.object({
                    name: yup.string().required(this.props.translate('validations.required', { propName: 'name' }))
                })
            },
            button: {
                submit: {
                    title: this.props.translate('buttons.save'),
                    className: 'btn btn-success',
                    onClick: self.addResolutionSuccess,
                    onClickBind: self
                }
            }
        })
    }

    addResolutionSuccess(data, metadata) {
        const self = this
        const list = self.list.getWrappedInstance()
        let promise = this.props.actions.resolutionsAdd(this.props.params.appCodename, { name: metadata.name })

        promise = promise.catch(() => {
            // do nothing
        }).then(() => {
            return list.refreshData(true)
        }).then(() => {
            return list.closeModal()
        }).then(() => {
            self.setState({
                loading: false
            })
        })

        self.setState({
            loading: true
        }, () => promise)
    }

    deleteData(dataId) {
        const self = this
        const list = self.list.getWrappedInstance()
        return list.openModal({
            title: this.props.translate('resolutions.delete.title'),
            description: this.props.translate('resolutions.delete.description'),
            button: {
                cancel: {
                    title: this.props.translate('buttons.cancel'),
                    className: 'btn-default'
                },
                success: {
                    title: this.props.translate('buttons.delete'),
                    className: 'btn-danger',
                    onClick: self.confirmDeleteData,
                    onClickBind: self
                }
            }
        }, {
            dataId: dataId
        })
    }

    confirmDeleteData(metadata) {
        const list = this.list.getWrappedInstance()
        let promise = this.props.actions.resolutionDelete(this.props.params.appCodename, metadata.dataId)

        promise = promise.catch(() => {
            // do nothing
        }).then(() => {
            return list.refreshData(true)
        }).then(() => {
            return list.closeModal()
        }).then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    dataObjectProcess(resolution) {
        const { loading } = this.state
        const row = []

        row.push(resolution.name)

        row.push(
            <ButtonToolbar>
                <Button title="Eliminar" bsStyle="danger" onClick={this.deleteData.bind(this, resolution._id)} type="button" disabled={loading}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button>
            </ButtonToolbar>
        )

        return row
    }

    render() {
        const { loading } = this.state
        const { resolutions } = this.props

        const itemsProcessed = []
        if (resolutions && resolutions.objects.length) {
            resolutions.objects.forEach(resolution => {
                itemsProcessed.push(this.dataObjectProcess(resolution))
            })
        }

        const description = (
            <div className="row">
                <div className="col-xs-12">
                    <ButtonToolbar className="pull-left">
                        <Button title="Agregar" bsStyle="primary" onClick={this.addResolution} type="button" className="pull-right" disabled={loading}>
                            <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;<Translate id="resolutions.misc.add" />
                        </Button>
                    </ButtonToolbar>
                </div>
            </div>
        )

        return (
            <div>
                <List ref={(list) => { this.list = list }}
                    items={itemsProcessed}
                    name={this.props.translate('resolutions.titles.list')}
                    dataColumnsNames={this.state.dataColumnsNames.map(dataColumnsNames => this.props.translate(dataColumnsNames))}
                    description={description}
                    getObjects={this.props.actions.resolutionsGet}
                    clearObjects={this.props.actions.resolutionsClean}
                    routing={this.props.routing}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        resolutions: state.resolutions,
        routing: state.routing
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Resolution))
