import styled from 'styled-components'

export const Loading = styled.div`
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 5px solid #D4D6D6;
`

export const Container = styled.div`
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 5px solid #D4D6D6;
`

export const FormContainer = styled.div`
    padding: 10px;
    border-bottom: 1px solid #D4D6D6;

    & .form-group {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    & .form-control {
        height: 30px;
    }
`

export const TopicsContainer = styled.div`
    overflow-y: scroll;
`
