import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-timezone'
import { Translate, withLocalize } from 'react-localize-redux'
import { Recorder } from '../'
import { browserHistory } from 'react-router'
import {
    Container,
    Buttons,
    Primary,
    Secondary,
    Wrapper,
    Tooltip,
    HSMTemplates,
    Languages,
    HSMTemplate,
    Text,
    Record,
    TemplateButton
} from './styles'

import {
    ButtonToolbar,
    Button,
    Modal
} from 'react-bootstrap'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

class Composer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            error: null,
            recontact: false,
            uploading: false,
            uploadError: null,
            hsmTemplate: null,
            language: null,
            record: null,
            playing: false,
            paused: false,
            open: false
        }

        this.onSend = this.onSend.bind(this)
        this.verifyKeyCode = this.verifyKeyCode.bind(this)
        this.type = this.type.bind(this)
        this.toggleTemplates = this.toggleTemplates.bind(this)

        this.recontact = this.recontact.bind(this)
        this.openModalRecontact = this.openModalRecontact.bind(this)
        this.closeModalRecontact = this.closeModalRecontact.bind(this)

        this.take = this.take.bind(this)
        this.assign = this.assign.bind(this)
        this.reOpen = this.reOpen.bind(this)

        this.handleFileChange = this.handleFileChange.bind(this)
        this.sendHSM = this.sendHSM.bind(this)

        this.saveRecord = this.saveRecord.bind(this)
        this.sendRecord = this.sendRecord.bind(this)
        this.deleteRecord = this.deleteRecord.bind(this)
        this.playRecord = this.playRecord.bind(this)
        this.pauseRecord = this.pauseRecord.bind(this)
    }

    componentDidMount() {
        if (this.textarea) {
            this.textarea.autofocus = true
        }
    }

    componentWillMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    _setState(state) {
        return new Promise((resolve) => {
            if (this._isMounted) {
                this.setState(state, resolve)
            } else {
                resolve()
            }
        })
    }

    async onSend() {
        const message = this.state.message
        if (message) {
            this.props.onSend({ text: message })
        }
        this.setState({
            message: ''
        })
    }

    verifyKeyCode(e) {
        if (e && e.keyCode === 13 && !e.shiftKey && !this.props.loading) {
            this.onSend()
        }
    }

    type(e) {
        if (e.target.value !== '\n') {
            this.setState({
                message: e.target.value
            })
        }
    }

    toggleTemplates() {
        const state = {
            open: !this.state.open
        }

        if (!state.open) {
            state.hsmTemplate = null
            state.language = null
        }

        this.setState(state)
    }

    adaptValue(root, param) {
        let toReturn = root
        if (param && typeof param === 'string') {
            const props = param.split('.')
            if (props.length) {
                props.shift()
                props.forEach(prop => {
                    if (toReturn) {
                        toReturn = toReturn[prop]
                    } else {
                        return null
                    }
                })
            }
            return toReturn
        } else {
            return null
        }
    }

    recontact() {
        const { hsm, messageUser, operator, platform, accountId } = this.props
        const paramsNotFound = []
        const params = hsm.params && hsm.params.length ? hsm.params.map(param => {
            if (param.startsWith('operator.')) {
                return this.adaptValue(operator, param)
            } else if (param.startsWith('messageUser.')) {
                return this.adaptValue(messageUser, param)
            } else {
                return param
            }
        }) : []

        if (params && params.length) {
            params.forEach((param, index) => {
                if (!param) {
                    paramsNotFound.push(hsm.params[index])
                }
            })
        }

        if (paramsNotFound.length) {
            this.setState({
                error: `Faltan los parametros: ${paramsNotFound.join(', ')}`
            })
        } else {
            let promise = Promise.resolve()

            if (platform && platform.match(/whatsapp/)) {
                promise = promise.then(() => {
                    return this.props.sendHSM({
                        id: messageUser.id,
                        params,
                        platform,
                        accountId
                    })
                })
            }

            promise = promise.then(() => {
                this.setState({
                    loading: false,
                    recontact: false
                })
            })

            this.setState({
                loading: true,
                recontact: false
            }, () => promise)
        }
    }

    openModalRecontact() {
        this.setState({
            recontact: true
        })
    }

    closeModalRecontact() {
        this.setState({
            recontact: false
        })
    }

    async take(queryId) {
        try {
            await this._setState({
                loading: true
            })
            await this.props.take(queryId)
        } catch(e){
            //
        } finally {
            await this._setState({
                loading: false
            })
        }
    }

    assign() {
        if (this && this.props && this.props.assign) {
            const callback = this.props.assign(this.props.profile._id).then(() => {
                this.setState({
                    loading: false
                })
            })

            this.setState({
                loading: true
            }, () => callback)
        }
    }

    async reOpen() {
        const self = this
        const { message, messageUser, account } = self.props.query
        try {
            await self._setState({
                loading: true
            })
            const checkQueryActiveByUser = await self.props.verifyQuery(self.props.appCodename, messageUser._id)
            if (checkQueryActiveByUser && checkQueryActiveByUser.payload && checkQueryActiveByUser.payload.data && checkQueryActiveByUser.payload.data.queryId) {
                browserHistory.push({
                    pathname: `/manager/customer/${self.props.appCodename}/${checkQueryActiveByUser.payload.data.queryId}`,
                    search: '?status=me'
                })
            } else {
                const query = await self.props.newQuery(self.props.appCodename, {
                    message,
                    messageUser,
                    account
                })
                await self.props.pause()
                if (query && query.payload && query.payload.data && query.payload.data._id) {
                    await self.take(query.payload.data._id)
                }
                await self.props.editContext(self.props.appCodename, messageUser._id, 'customerService.active', true, query.payload.data._id)
            }
        } catch (e) {
            //
        } finally {
            await this._setState({
                loading: false
            })
        }
    }

    handleFileChange(event) {
        const self = this
        const { platform } = self.props
        event.stopPropagation()
        event.preventDefault()
        const file = event.target.files[0]

        const allowedFileTypes = [
            'image/jpeg',
            'image/png',
            'audio/mpeg',
            'audio/mpeg3',
            'audio/mp3',
            'audio/x-mpeg-3',
            'audio/webm',
            'audio/ogg',
            'audio/wav',
            'video/mp4',
            'application/pdf'
        ]

        if (['telegram', 'webchattonic'].indexOf(platform) >= 0) {
            allowedFileTypes.push('application/zip')
        }

        if (file && file.type && allowedFileTypes.indexOf(file.type) >= 0) {
            const promise = this.props.onSendResource(this.prepareObjLocalUpload(file)).then(() => {
                this.setState({
                    uploading: false
                })
            })

            this.setState({
                uploading: true
            }, () => promise)
        } else {
            this.setState({
                uploadError: 'Archivo inválido'
            })

            setTimeout(() => this.setState({ uploadError: null }), 5000)
        }
    }

    updateParam(index, e) {
        const hsmTemplate = Object.assign({}, this.state.hsmTemplate)
        hsmTemplate.parameters[index] = e.target.value
        this.setState({
            hsmTemplate
        })
    }

    selectTemplate(hsmTemplate) {
        const language = (hsmTemplate.languages && hsmTemplate.languages.length === 1) ? hsmTemplate.languages[0] : null

        this.setState({
            hsmTemplate,
            language
        })
    }

    sendHSM() {
        const { platform, accountId, messageUser } = this.props
        const { hsmTemplate, language } = this.state
        const promise = this.props.sendHSM({
            id: messageUser.id,
            elementName: hsmTemplate.elementName,
            languageCode: language.code,
            text: language.text,
            params: hsmTemplate.parameters,
            platform,
            accountId
        }).then(() => {
            this.setState({
                message: '',
                loading: false,
                open: false,
                hsmTemplate: null,
                language: null
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    saveRecord(record) {
        this.setState({
            record
        })
    }

    sendRecord() {
        const { timezone } = this.props
        const { record } = this.state

        const file = new File([record], `record_${moment.tz(timezone).format('YYYYMMDD_HHmmss')}.${record.type.replace('audio/', '')}`, {
            lastModified: new Date(0),
            type: record.type
        })

        const promise = this.props.onSendResource(this.prepareObjLocalUpload(file, true)).then(() => {
            this.setState({
                uploading: false
            })
        })

        this.setState({
            record: null,
            playing: false,
            paused: false,
            uploading: true
        }, () => promise)
    }

    deleteRecord() {
        this.setState({
            record: null,
            playing: false,
            paused: false
        })
    }

    playRecord() {
        const { record } = this.state
        const url = URL.createObjectURL(record)

        if (this.state.playing) {
            this.setState({
                paused: false
            }, () => {
                this.state.playing.play()
            })
        } else {
            const audio = new Audio(url)
            audio.onended = () => {
                this.setState({
                    playing: false
                })
            }

            this.setState({
                playing: audio
            }, () => this.state.playing.play())
        }
    }

    pauseRecord() {
        if (this.state.playing) {
            this.setState({
                paused: true
            }, () => this.state.playing.pause())
        }
    }

    prepareObjLocalUpload(file, isAudio) {
        const formData = new FormData()
        const tags = ['file']
        const title = tags[0]
        formData.append('upload', file)
        formData.append('title', title)
        if (tags.length) {
            formData.append('tags', tags.join(','))
        }

        return {
            url: '/media',
            fileName: formData.get('upload').name,
            files: formData,
            isAudio
        }
    }

    render() {
        const self = this
        const { platform, accountId, expired, hsm, HSMSendedAt, query, operator, profile, hsmTemplates } = this.props
        const { message, loading, error, recontact, uploading, uploadError, hsmTemplate, language, record, playing, paused, open } = this.state
        const isWhatsapp = platform && platform.startsWith('whatsapp')

        const allowedFileTypes = [
            'image/jpeg',
            'image/png',
            'audio/mpeg',
            'audio/mpeg3',
            'audio/mp3',
            'audio/x-mpeg-3',
            'audio/webm',
            'audio/ogg',
            'audio/wav',
            'video/mp4',
            'application/pdf'
        ]

        if (['telegram', 'webchattonic'].indexOf(platform) >= 0) {
            allowedFileTypes.push('application/zip')
        }

        if (query.status === 'pending') {
            return (
                <Wrapper>
                    <Translate id="query.needAttend" />
                    <Button onClick={() => this.take()} disabled={loading} style={{
                        backgroundColor: '#F4D345',
                        color: '#000'
                    }}>
                        <div>
                            <FontAwesomeIcon icon="headset" />
                        </div>
                        <div>
                            <Translate id="buttons.attend" />
                        </div>
                    </Button>
                </Wrapper>
            )
        } else if (query.status === 'resolved') {
            return (
                <Wrapper>
                    {query && query.resolution && query.resolution.name ? (
                        <Translate id="query.resolvedAlt" data={{ resolution: query.resolution.name }} />
                    ) : (
                        <Translate id="query.resolved" />
                    )}
                    {platform && ['whatsapp-chatapi-instance', 'telegram'].indexOf(platform) >= 0 ?
                    <Button onClick={this.reOpen} disabled={loading} style={{
                        backgroundColor: '#F4D345',
                        color: '#000'
                    }}>
                        <Translate id="buttons.reOpen" />
                    </Button> : <></>}
                </Wrapper>
            )
        } else if (query.status === 'failed') {
            return (
                <Wrapper>
                    <Translate id="query.failed" />
                </Wrapper>
            )
        } else if (!(operator && profile && operator._id === profile._id)) {
            const replyButton = (
                <Wrapper>
                    <Translate id="query.assignByOther" />
                    <Button onClick={this.assign} disabled={loading} style={{
                        backgroundColor: '#F4D345',
                        color: '#000'
                    }}>
                        <div>
                            <FontAwesomeIcon icon="headset" />
                        </div>
                        <div>
                            <Translate id="buttons.attend" />
                        </div>
                    </Button>
                </Wrapper>
            )

            // Check if we need to allow or deny this query, because it has origins
            if (query && query.origins && query.origins.length && profile && profile.origins && profile.origins.length) {
                const missingOrigins = query.origins.filter(origin => {
                    if (profile.origins.indexOf(origin) < 0) {
                        return origin
                    }
                }) || []
                if (missingOrigins.length) {
                    return null
                } else {
                    return replyButton
                }
            } else {
                return replyButton
            }
        } else if (error) {
            return (
                <Wrapper>
                    {error}
                </Wrapper>
            )
        } else if (expired && HSMSendedAt && HSMSendedAt.length) {
            return (
                <Wrapper>
                    <Translate id="query.recontacted" data={{ time: HSMSendedAt }} />
                </Wrapper>
            )
        } else if (expired) {
            let recontactButton = ''
            if (platform && platform.match(/whatsapp/) && hsm && hsm.done && accountId) {
                recontactButton = (
                    <Button onClick={this.openModalRecontact} disabled={loading}>
                        <Translate id="buttons.recontact" />
                    </Button>
                )
            }

            return (
                <Wrapper>
                    <Translate id="query.noTimeToReply" />
                    {recontactButton}
                    <div className="static-modal">
                        <Modal show={recontact} onHide={this.closeModalRecontact}>
                            <Modal.Header closeButton></Modal.Header>
                            <Modal.Body>
                                <Translate id="query.recontactAlert" />
                            </Modal.Body>
                            <Modal.Footer>
                                <ButtonToolbar className="pull-right">
                                    <Button onClick={this.closeModalRecontact} disabled={loading}>
                                        <Translate id="buttons.cancel" />
                                    </Button>
                                    <Button bsStyle="success" onClick={this.recontact} disabled={loading}>
                                        <Translate id="buttons.accept" />
                                    </Button>
                                </ButtonToolbar>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </Wrapper>
            )
        } else if (uploading) {
            return (
                <Wrapper>
                    <Translate id="query.uploadingFile" />
                </Wrapper>
            )
        } else if (hsmTemplate && language) {
            let parts = language.text.split(/({{\d}})/g)
            let index = -1
            const text = parts.map(part => {
                if (part.match(/({{\d}})/)) {
                    index++
                    return (
                        <input
                            key={`param_${index}`}
                            name={`param_${index}`}
                            value={hsmTemplate.parameters[index]}
                            placeholder={`{{${index + 1}}}`}
                            onChange={self.updateParam.bind(self, index)}
                            disabled={loading}
                        />
                    )
                } else {
                    return part
                }
            })

            return (
                <HSMTemplate>
                    <Text>
                        {text}
                    </Text>
                    <Buttons>
                        <Secondary onClick={() => this.setState({ hsmTemplate: null, language: null, open: false })}>
                            <FontAwesomeIcon icon="trash-alt" />
                        </Secondary>
                        {(language.text && language.text.length) ? (
                            <Primary onClick={this.sendHSM}>
                                <a className="icon-primary icon-send"></a>
                            </Primary>
                        ) : ''}
                    </Buttons>
                </HSMTemplate>
            )
        } else if (record) {
            return (
                <Wrapper>
                    <Record>
                        <Secondary>
                            <FontAwesomeIcon icon={(playing && !paused) ? 'pause' : 'play'} onClick={(playing && !paused) ? self.pauseRecord : self.playRecord} />
                            <FontAwesomeIcon icon="trash-alt" onClick={this.deleteRecord} />
                        </Secondary>
                    </Record>
                    <Buttons>
                        <Primary onClick={this.sendRecord}>
                            <a className="icon-primary icon-send"></a>
                        </Primary>
                    </Buttons>
                </Wrapper>
            )
        } else if (query.isPublic) {
            return (
                <Wrapper>
                    <Translate id="query.isPublic" />
                </Wrapper>
            )
        } else {
            return (
                <Container>
                    <TemplateButton show={isWhatsapp && hsmTemplates && hsmTemplates.length} onClick={this.toggleTemplates}>
                        <FontAwesomeIcon icon={open ? 'caret-down' : 'caret-up'} />
                    </TemplateButton>
                    <HSMTemplates show={isWhatsapp && hsmTemplates && hsmTemplates.length && open}>
                        {hsmTemplates.map(hsmTemplate => (
                            <button key={hsmTemplate._id} onClick={this.selectTemplate.bind(this, hsmTemplate)}>
                                {hsmTemplate.elementName}
                            </button>
                        ))}
                    </HSMTemplates>
                    <Languages show={isWhatsapp && hsmTemplate && hsmTemplate.languages && !language}>
                        {(hsmTemplate && hsmTemplate.languages) ? hsmTemplate.languages.map(language => (
                            <button key={language.code} onClick={() => this.setState({ language })}>
                                {language.code}
                            </button>
                        )) : ''}
                    </Languages>
                    <textarea
                        ref={(c) => { this.textarea = c }}
                        value={message}
                        onKeyDown={this.verifyKeyCode}
                        onChange={this.type}
                        autoFocus={true}
                        autoComplete="off"
                        disabled={this.props.disabled}
                        placeholder={this.props.translate('query.placeholders.message')}
                        maxLength="2000"
                    >
                    </textarea>
                    <Buttons>
                        {(message && message.length) ? (
                            <Primary onClick={this.onSend} disabled={this.props.disabled}>
                                <a className="icon-primary icon-send"></a>
                            </Primary>
                        ) : (
                        <Secondary>
                            {!MediaRecorder.notSupported && (
                                <Recorder
                                    saveRecord={this.saveRecord}
                                />
                            ) || null}
                            <Tooltip
                                style={{ display: uploadError ? 'block' : 'none' }}
                            >
                                {uploadError}
                            </Tooltip>
                            <FontAwesomeIcon icon="paperclip" onClick={() => this.upload.click()} />
                        </Secondary>
                        )}
                        <input
                            ref={(c) => { this.upload = c }}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={this.handleFileChange}
                            accept={allowedFileTypes.join(', ')}
                        />
                    </Buttons>
                </Container>
            )
        }
    }
}

Composer.propTypes = {
    onSend: PropTypes.func.isRequired,
    onSendResource: PropTypes.func.isRequired,
    query: PropTypes.object,
    messageUser: PropTypes.object,
    operator: PropTypes.object,
    platform: PropTypes.string,
    accountId: PropTypes.string,
    expired: PropTypes.bool,
    hsm: PropTypes.object,
    assignHSMSendedAt: PropTypes.func,
    sendHSM: PropTypes.func,
    HSMSendedAt: PropTypes.string,
    take: PropTypes.func,
    assign: PropTypes.func,
    hsmTemplates: PropTypes.array,
    timezone: PropTypes.string,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    pause: PropTypes.func,
    newQuery: PropTypes.func,
    editContext: PropTypes.func,
    verifyQuery: PropTypes.func
}

export default withLocalize(Composer)
