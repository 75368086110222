import * as actions from '../actions'

const defaultState = false

export default function isReady(state = defaultState, action) {
    switch (action.type) {
        case actions.IS_READY:
            return true

        default:
            return state
    }
}
