import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate, withLocalize } from 'react-localize-redux'

import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'

import Spinner from 'react-spinkit'

import {
    FormGroup,
    Tabs,
    Tab
} from 'react-bootstrap'

import {
    Loading,
    Container,
    FormContainer,
    TopicsContainer
} from './styles'

import {
    AnswerTopic,
    GuideTopic
} from '../index'

class KnowledgeBase extends Component {
    constructor(props) {
        super(props)

        Form.addInputTypes(types)

        this.state = {
            loading: false,
            tab: 'knowledgeBase'
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    getFilterSchema() {
        return yup.object({
            search: yup.string().nullable()
        })
    }

    handleSubmit(formValues) {
        const promise = this.props.handleSubmitFilter(formValues.search).then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    handleScroll() {
        if (!this.scrolling) {
            this.props.handleScroll()
        }
        this.scrolling = true
        this.scheduleClearScroll()
    }

    scheduleClearScroll() {
        if (this.stopScrollTimer) {
            clearTimeout(this.stopScrollTimer)
        }
        this.stopScrollTimer = setTimeout(() => {
            this.scrolling = null
        }, 1000)
    }

    render() {
        const { topics, loading, query, onSendMessageTopic, showForm, app } = this.props
        if (loading) {
            return (
                <Loading>
                    <Spinner name="circle" />
                </Loading>
            )
        } else {
            return (
                <Container>
                    <Tabs id={'controlled-tab'} className="pull-left" style={{ width: '100%' }} activeKey={this.state.tab} onSelect={value => this.setState({ tab: value })}>
                        <Tab eventKey={'knowledgeBase'} title={this.props.translate(`menu.knowledgeBase`)}>
                            {!showForm ? (
                                <FormContainer>
                                    <Form schema={this.getFilterSchema()} defaultValue={this.getFilterSchema().default()} onSubmit={this.handleSubmit}>
                                        <FormGroup>
                                            <Form.Field
                                                style={{ fontSize: '1.25rem' }}
                                                name="search"
                                                placeholder={this.props.translate('query.searchInKnowledgeBase')}
                                                className="form-control"
                                                disabled={this.state.loading}
                                            />
                                        </FormGroup>
                                    </Form>
                                </FormContainer>
                            ) : ''}
                            <TopicsContainer onScroll={this.handleScroll.bind(this)}>
                                {topics ? topics.map(topic => {
                                    if (!showForm && topic.type === 'Respuesta' && (topic.answerUser || topic.video || (topic.media && topic.media[0]))) {
                                        return (
                                            <AnswerTopic
                                                key={topic._id}
                                                topic={topic}
                                                onSendMessageTopic={onSendMessageTopic}
                                                expired={this.props.expired}
                                                sendMessageDisabled={this.props.sendMessageDisabled}
                                                s3Url={this.props.s3Url}
                                            />
                                        )
                                    } else if (topic.type === 'Guia') {
                                        return (
                                            <GuideTopic
                                                key={topic._id}
                                                topic={topic}
                                                query={query}
                                                onSendMessageTopic={onSendMessageTopic}
                                                editContext={this.props.editContext}
                                                editExtraData={this.props.editExtraData}
                                                expired={this.props.expired}
                                                showForm={showForm}
                                                swapShowForm={this.props.swapShowForm}
                                            />
                                        )
                                    }
                                }) : ''}
                            </TopicsContainer>
                        </Tab>
                        {app && app.integrations && app.integrations.enabled && app.integrations.ba360 && app.integrations.ba360.enabled && <Tab eventKey={'integration'} title='BA360'>
                            <div style={{ height: '70vmin' }}><iframe src={`https://${app.integrations.ba360.instanceURL}/integracionview/${app.integrations.ba360.platform}/${app.integrations.ba360.channel}/${app.integrations.ba360.chatId}/{}/${app.integrations.ba360.token}`} style={{ width: '100%', height: '100%', border: 'none' }} /></div>
                        </Tab>}
                    </Tabs>
                </Container>
            )
        }
    }
}

KnowledgeBase.propTypes = {
    handleScroll: PropTypes.func,
    handleSubmitFilter: PropTypes.func,
    topics: PropTypes.array,
    loading: PropTypes.bool,
    query: PropTypes.object,
    onSendMessageTopic: PropTypes.func,
    editContext: PropTypes.func,
    editExtraData: PropTypes.func,
    expired: PropTypes.bool,
    sendMessageDisabled: PropTypes.bool,
    showActions: PropTypes.bool,
    s3Url: PropTypes.string,
    showForm: PropTypes.bool,
    swapShowForm: PropTypes.func,
    app: PropTypes.object
}

KnowledgeBase.defaultProps = {
    topics: []
}

export default withLocalize(KnowledgeBase)
