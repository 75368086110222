import styled from 'styled-components'

export const Main = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`
export const Container = styled.div`
    background-color: ${props => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
`

export const Wrapper = styled.div`
    background-color: #FFF;
    margin: auto;
    padding: 30px;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
`

export const UserPhoto = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-right: 10px;
    background-image: url(/manager/static/images/user.png);
    background-color: lightgray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid ${props => props.color};
`

export const Icon = styled.div`
    position: relative;
    left: 35px;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    color: ${props => props.color};
    border: 2px solid ${props => props.color};
    font-size: 1.6rem;
    background-color: white;
    border-radius: 100%;
`
export const Data = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
`
export const List = styled.div`
    display: grid;
`

export const SecondaryItem = styled.li`
    display: flex;
    align-items: center;
`
export const Separator = styled.div`
    border-top: 1px solid #D4D6D6;
    width: 90%;
    margin: 10px auto;
`
