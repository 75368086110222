import { createStore, combineReducers, applyMiddleware } from 'redux'
import promiseMiddleware from 'redux-promise'
import reducers from '../reducers'
import { localizeReducer } from 'react-localize-redux'
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
    localize: localizeReducer,
    // other reducers,
    ...reducers
})

const store = createStore(
    rootReducer, // create redux store using root reducer
    {}, // initial state of the application
    composeWithDevTools(
        applyMiddleware(
            // router and other middlewares
            promiseMiddleware
        )
    )
)

export default store
