import styled from 'styled-components'

export const Text = styled.div`
    word-wrap: break-word;
    display: inline;
    white-space: pre-wrap;
    & > a {
        color: ${props => (props.from === 'self') ? '#FFFFFF' : '#666666'} !important;
    }
    & > img {
        width: 16px;
    }
`

export const Image = styled.img`
    border-radius: 3px;
    width: ${props => (props.expanded) ? '100%' : '150px'};
    cursor: pointer;
`

export const Video = styled.video`
    width: 100%;
    height: 150px;
    display: flex;
`

export const Voice = styled.audio`

`

export const Location = styled.img`
    margin-top: 3px;
    border-radius: 3px;
    height: 180px;
`

export const Options = styled.div`
    display: flex !important;
    justify-content: flex-end;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 10px;
    padding: 0;

    & > a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #666666;
        background-color: #F4F7F9;
        padding: 10px;
        text-decoration: none;
        border: solid 1px #000000;
        border-radius: 3px;
        margin: 5px;

        & > i {
            font-size: 10px;
            position: relative;
            float: right;
            top: 4px;
        }

        &:hover {
            background-color: #FA5D48;
            border: solid 1px #FA5D48;
            color: #FFFFFF;
            cursor: pointer;
        }

        &:disabled {
            background-color: gray;
        }
    }
`

export const Option = styled.a`
    display: ${props => props.hidden ? 'none' : 'block'};
    min-height: 25px;
    white-space: nowrap;
    cursor: default !important;

    ${props => props.highlight ? `
        background-color: #FA5D48 !important;
        border: solid 1px #FA5D48 !important;
        color: #FFF !important;
    ` : props.disabled ? `
        background-color: gray !important;
        border: solid 1px gray !important;
        color: #666666 !important;
        cursor: no-drop !important;
    ` : `
        &:hover {
            background-color: #F4F7F9 !important;
            border: solid 1px #000 !important;
            color: #666666 !important;
        }
    `}
`

export const Status = styled.span`
    display: ${props => !props.delivered ? 'none' : 'inline-block'};
    font-size: ${props => props.delivered === 'waiting' ? '1.6em' : '1em'};
    vertical-align: middle;
    display: none;
`

export const Transcription = styled.div`
    font-style: italic;
    font-size: 1.3rem;
    margin-top: 5px;
`

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    & > svg {
        margin-left: 10px;
    }
`
