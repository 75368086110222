import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withLocalize, Translate } from 'react-localize-redux'
import { Button } from 'react-bootstrap'
import {
    TextPlay,
    Transcription,
    Wrapper
} from './styles'
import './styles.less'

class Voice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            playing: false,
            paused: false,
            loading: false,
            transcription: ''
        }

        this.playAudio = this.playAudio.bind(this)
        this.pauseAudio = this.pauseAudio.bind(this)
        this.speech = this.speech.bind(this)
    }

    playAudio() {
        const { voice, chattonicURL } = this.props

        if (this.state.playing) {
            this.setState({
                paused: false
            }, () => {
                this.state.playing.play()
            })
        } else {
            const url = (!voice.url.startsWith('http')) ? `${chattonicURL}${voice.url}` : voice.url
            const audio = new Audio(url)
            audio.onended = () => {
                this.setState({
                    playing: false
                })
            }

            this.setState({
                playing: audio
            }, () => {
                this.state.playing.play()
            })
        }
    }

    pauseAudio() {
        if (this.state.playing) {
            this.setState({
                paused: true
            }, () => {
                this.state.playing.pause()
            })
        }
    }

    speech() {
        const promise = this.props.speech(this.props.messageId).then((action) => {
            const data = action && action.payload && action.payload.data
            let message = ''
            if (data && data.message && data.message.text) {
                message = data.message.text
            }
            this.setState({
                loading: false,
                transcription: message
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    render() {
        const { showTranscript } = this.props
        const { playing, paused, loading, transcription } = this.state
        return (
            <div>
                <Wrapper showTranscript={showTranscript}>
                    <Button className='btn-default btn-xs' bsStyle={playing && !paused ? 'danger' : 'primary'} showTranscript={showTranscript} onClick={(playing && !paused) ? this.pauseAudio : this.playAudio} title={this.props.translate(`conversation.message.titles.${(playing && !paused) ? 'pause' : 'play'}`)}>
                        <FontAwesomeIcon size={'1x'} icon={(playing && !paused) ? 'pause' : 'play'} />
                        <br/>
                        <TextPlay>{<Translate id={`conversation.message.titles.${(playing && !paused) ? 'pause' : 'play'}`}/>}</TextPlay>
                    </Button>
                    &nbsp;&nbsp;
                    {(!showTranscript && !transcription) && (
                        <Button className='btn-default btn-xs' bsStyle="success" onClick={this.speech} disabled={loading} title={this.props.translate('conversation.message.titles.transcribe')}>
                            <FontAwesomeIcon size={'lg'} icon={loading ? 'sync' : 'language'} />
                            <br/>
                            <TextPlay>{<Translate id='conversation.message.titles.transcribe'/>}</TextPlay>
                        </Button>
                    )}
                </Wrapper>
                {transcription !== '' && (
                    <Transcription>
                        {transcription}
                    </Transcription>
                )}
            </div>
        )
    }
}

Voice.propTypes = {
    voice: PropTypes.object.isRequired,
    messageId: PropTypes.string.isRequired,
    chattonicURL: PropTypes.string.isRequired,
    showTranscript: PropTypes.bool.isRequired,
    speech: PropTypes.func.isRequired
}

export default withLocalize(Voice)
