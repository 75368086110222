import * as actions from '../actions'

const defaultState = null

export default function users(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_SELECT:
            return defaultState

        case actions.USER_GET:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.USER_GET_UNLIMITED:
            if (action && !action.error && action.payload && action.payload.data) {
                if (action.payload.data.skip === 0) {
                    // console.log('REDUCER USER GET', action.payload.data.skip)
                    state = action.payload.data
                } else if (state && state.objects && Array.isArray(state.objects) && action && action.payload && action.payload.data && action.payload.data.objects && action.payload.data.objects.length) {
                    // console.log('REDUCER USER GOT MORE', action.payload.data.skip)
                    state.objects = [].concat(state.objects).concat(action.payload.data.objects)
                }
            }
            // console.log('REDUCER USER GET:', state)
            return state

        case actions.USER_CLEAN:
            return defaultState

        case actions.APP_GET_ONE_CODENAME:
            return defaultState

        default:
            return state
    }
}
