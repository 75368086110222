import * as actions from '../actions'

const defaultState = ''

export default function previousPath(state = defaultState, action) {
    switch (action.type) {
        case actions.PREVIOUSPATH_SET:
            if (!state) {
                state = action.payload
            }
            return state
        case actions.USER_CLEAN_PROFILE:
            return defaultState
        default:
            return state
    }
}
