import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import List from './../common/List'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate, withLocalize } from 'react-localize-redux'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'

import {
    Button,
    ButtonToolbar
} from 'react-bootstrap'

class ScheduleUnavailable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataColumnsNames: [
                'unschedules.dataColumnsNames.recurrent', 'unschedules.dataColumnsNames.dayFrom', 'unschedules.dataColumnsNames.dayTo', 'unschedules.dataColumnsNames.origins', 'unschedules.dataColumnsNames.reason', 'unschedules.dataColumnsNames.actions'
            ]
        }

        this.props.actions.getScheduleUnavailable = this.props.actions.getScheduleUnavailable.bind(this, this.props.params.appCodename, { sort: { date: 1 } })
        this.props.actions.cleanScheduleUnavailable = this.props.actions.cleanScheduleUnavailable.bind(this)
        this.confirmDeleteData = this.confirmDeleteData.bind(this)
    }

    async componentWillMount() {
        try{
            await this.props.actions.originsGet(this.props.params.appCodename)
        } catch {
        }
    }

    deleteData(dataId) {
        const self = this
        const list = self.list.getWrappedInstance()
        return list.openModal({
            title: self.props.translate('unschedules.delete.title'),
            description: self.props.translate('unschedules.delete.description'),
            button: {
                cancel: {
                    title: self.props.translate('buttons.cancel'),
                    className: 'btn-default'
                },
                success: {
                    title: self.props.translate('buttons.delete'),
                    className: 'btn-danger',
                    onClick: self.confirmDeleteData,
                    onClickBind: self
                }
            }
        }, {
            dataId: dataId
        })
    }

    confirmDeleteData(metadata) {
        const list = this.list.getWrappedInstance()
        let promise = this.props.actions.deleteScheduleUnavailableDay(this.props.params.appCodename, metadata.dataId)

        promise = promise.catch(() => {
            // do nothing
        }).then(() => {
            return list.refreshData(true)
        }).then(() => {
            return list.closeModal()
        }).then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    dataObjectProcess(schedule) {
        const self = this
        const { activeLanguage, origins } = self.props
        const row = []

        let dateFrom = moment.utc(schedule.date).format('DD/MM/YYYY')
        let dateTo = moment.utc(schedule.dateTo).format('DD/MM/YYYY')
        if (schedule && schedule.isoWeekday && schedule.isoWeekday.length > 0) {
            dateFrom = schedule.isoWeekday.map(day => {
                return ` ${capitalizeFirstLetter(moment().isoWeekday(day).locale(activeLanguage.code).format('dddd'))}`
            })
            dateTo = dateFrom
        }
        row.push(
            <Translate
                id={schedule && schedule.isRecurrent ? 'unschedules.misc.isRecurrent' : 'unschedules.misc.notRecurrent'}
            />
        )
        row.push(
            <Translate
                id="unschedules.misc.initialDate"
                data={{
                    dateFrom: dateFrom,
                    from: schedule.from
                }}
            />
        )
        row.push(
            <Translate
                id="unschedules.misc.finishDate"
                data={{
                    dateTo: dateTo,
                    to: schedule.to
                }}
            />
        )
        let userOrigins
        if (origins && origins.objects && origins.objects.length) {
            if (schedule && schedule.origins && schedule.origins.length && schedule.origins.length > 0) {
                userOrigins = schedule.origins.join(' || ')
            } else {
                userOrigins = self.props.translate('origins.all')
            }
        } else {
            userOrigins = self.props.translate('origins.none')
        }
        row.push(userOrigins)
        row.push(schedule.message)
        row.push(
            <ButtonToolbar>
                <Link title={self.props.translate('buttons.edit')} to={`/manager/${self.props.params.appCodename}/schedule/unavailable/edit/${schedule._id}`} className="btn btn-primary">
                    <FontAwesomeIcon icon="edit" />
                </Link>
                <Button title={self.props.translate('buttons.delete')} className="btn-danger" onClick={this.deleteData.bind(this, schedule._id)}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button>
            </ButtonToolbar>
        )
        return row
    }

    render() {
        const { loading } = this.state
        const itemsProcessed = []

        if (this.props.scheduleUnavailable && this.props.scheduleUnavailable.objects) {
            this.props.scheduleUnavailable.objects.forEach(schedule => {
                itemsProcessed.push(this.dataObjectProcess(schedule))
            })
        }

        const description = (
            <div className="row">
                <div className="col-xs-12">
                    <Link to={`/manager/${this.props.params.appCodename}/schedule/unavailable/add`} type="button" className="btn btn-primary" disabled={loading}>
                        <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;<Translate id="unschedules.misc.add" />
                    </Link>
                    <Link to={`/manager/${this.props.params.appCodename}/schedule`} className="btn btn-default pull-right">
                        <FontAwesomeIcon icon="calendar-alt" />&nbsp;&nbsp;<Translate id="schedules.buttons.schedules" />
                    </Link>
                </div>
            </div>
        )

        return (
            <div>
                <List ref={(list) => { this.list = list }}
                    items={itemsProcessed}
                    name={this.props.translate('unschedules.titles.list')}
                    dataColumnsNames={this.state.dataColumnsNames.map(dataColumnsNames => this.props.translate(dataColumnsNames))}
                    description={description}
                    getObjects={this.props.actions.getScheduleUnavailable}
                    clearObjects={this.props.actions.cleanScheduleUnavailable}
                    routing={this.props.routing}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        origins: state.origins,
        scheduleUnavailable: state.scheduleUnavailable,
        profile: state.profile,
        routing: state.routing,
        app: state.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(ScheduleUnavailable))
