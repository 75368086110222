import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    height: calc(100vh - 53px);
    overflow: hidden;

    & > :first-child {
        width: 30%;
        border-right: 1px solid #D4D6D6;
    }

    & > :last-child {
        width: 70%;
    }
`

export const Loading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NotFound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 300px;
        opacity: 0.5;
    }
`
