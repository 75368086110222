import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import { Translate, withLocalize } from 'react-localize-redux'

import * as actions from '../../state/actions'
import * as project from '../../utils/project'
import * as storage from '../../utils/storage'

import Spinner from 'react-spinkit'

import {
    Container,
    Loading,
    Chat,
    ChatApp,
    Management,
    ManagementApp
} from './styles'

import {
    Messages,
    Composer,
    KnowledgeBase,
    Actions,
    Info
} from './components'

import {
    Alert,
    Modal,
    FormGroup,
    ControlLabel,
    ButtonToolbar,
    Button
} from 'react-bootstrap'

import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'

import moment from 'moment'
import 'moment-timezone'

import io from 'socket.io-client'
import Select from 'react-select'
class Customer extends Component {
    constructor(props) {
        super(props)
        Form.addInputTypes(types)

        this.state = {
            id: this.props.params.queryId,
            loading: true,
            disabled: false,
            refreshing: false,
            firstMessageDate: null,
            lastMessageDate: null,
            showSpinner: false,
            expired: false,
            resolutions: false,
            assign: false,
            topics: null,
            showForm: false,
            messageUserId: '',
            hasOlderMessagesToLoad: true
        }

        this.checkExpired = this.checkExpired.bind(this)
        this.checkExpiredQuery = this.checkExpiredQuery.bind(this)
        this.sendHSM = this.sendHSM.bind(this)
        this.assignHSMSendedAt = this.assignHSMSendedAt.bind(this)

        this.loadOlderMessages = this.loadOlderMessages.bind(this)
        this.onSend = this.onSend.bind(this)
        this.onSendResource = this.onSendResource.bind(this)
        this.onSendMessageTopic = this.onSendMessageTopic.bind(this)

        this.refreshMessageReceive = this.refreshMessageReceive.bind(this)
        this.refreshMessageUpdate = this.refreshMessageUpdate.bind(this)

        this.pause = this.pause.bind(this)
        this.unpause = this.unpause.bind(this)

        this.take = this.take.bind(this)
        this.resolve = this.resolve.bind(this)
        this._resolve = this._resolve.bind(this)

        this.assign = this.assign.bind(this)
        this._assign = this._assign.bind(this)

        this.handleScrollKnowledgeBase = this.handleScrollKnowledgeBase.bind(this)
        this.handleSubmitFilter = this.handleSubmitFilter.bind(this)
        this.editContext = this.editContext.bind(this)
        this.editExtraData = this.editExtraData.bind(this)

        this.speech = this.speech.bind(this)
        this.swapShowForm = this.swapShowForm.bind(this)

        this.prepare = this.prepare.bind(this)
    }

    componentWillMount() {
        this._isMounted = true
        this.props.actions.cleanQuery()
        this.props.actions.cleanMessages()
        this.props.actions.cleanTopics()
        this.prepare()
    }

    async prepare() {
        let topics = null
        await this._setState({
            loading: true,
            disabled: false,
            refreshing: false,
            firstMessageDate: null,
            lastMessageDate: null,
            showSpinner: false,
            expired: false,
            assign: false,
            topics,
            showForm: false
        })

        if (!this.props.resolutions) {
            this.props.actions.resolutionsGet(this.props.params.appCodename)
        }

        try {
            await this.initialize()

            const { query } = this.props
            if (query && ['resolved', 'failed'].indexOf(query.status) < 0) {
                await this.startSocketIO()
            }

            const messageUserId = query && query.messageUser && query.messageUser._id
            if (messageUserId) {
                const action = await this.props.actions.getTopicContextVerificated(this.props.params.appCodename, messageUserId)
                if (action && action.payload && action.payload.data && action.payload.data.messageUserId === messageUserId) {
                    topics = action.payload.data.objects
                }
            }
        } catch (_) {
            topics = []
        }

        await this._setState({
            loading: false,
            topics
        })
        this.scrollToBottom()
    }

    _setState(state) {
        return new Promise((resolve) => {
            if (this._isMounted) {
                this.setState(state, resolve)
            } else {
                resolve()
            }
        })
    }

    async initialize() {
        const self = this
        const { query, messages } = self.props
        let promise = Promise.resolve()

        if (!query) {
            await self.props.actions.getQuery(self.props.params.appCodename, self.state.id)
        }

        if (!messages || !messages.length) {
            await self.props.actions.getQueryMessages(self.props.params.appCodename, self.state.id)
        }

        try {
            let expired = self.checkExpired()
            self.setState({
                expired,
                requiredResolution: self.props.app && self.props.app.requiredResolution,
                sendPoll: self.props.app && self.props.app.sendPoll,
                hasOlderMessagesToLoad: true
            }, () => {
                self.clearAllInterval()
                let loadedQuery = self.props.query
                if (loadedQuery && ['resolved', 'failed'].indexOf(loadedQuery.status) < 0) {
                    self.checkExpiredQueryTimer = setInterval(self.checkExpiredQuery.bind(self), 45000)
                    self.intervalOnSend = setInterval(self._onSend.bind(self), 500)
                    self.intervalReconnectSocketIO = setInterval(self.startSocketIO.bind(self, true), 5000)
                }
                this.scrollToBottom()
            })
        } catch (_) {
        }
    }

    checkExpiredQuery() {
        const self = this
        const expired = self.checkExpired()
        self.setState({
            expired
        })
    }

    checkExpired() {
        const self = this
        const { query } = self.props
        let expired = false
        if (query) {
            const platform = query && query.messageUser && query.messageUser.platform
            if ((platform && platform.startsWith('whatsapp') && (platform !== 'whatsapp-chatapi-instance')) || (platform === 'messenger')) {
                let diffTime = -1
                if (query.lastNotifiedAt || (query.message && (query.message.createdAt || query.message.receivedAt))) {
                    const lastMessagePlus24Hours = moment.utc(query.lastNotifiedAt || query.message.createdAt || query.message.receivedAt).add(24, 'hours')
                    diffTime = lastMessagePlus24Hours.diff(moment.utc(), 'minutes')
                }
                expired = diffTime <= 0
            }
        }
        return expired
    }

    checkSendMessageDisabled() {
        const self = this
        const { query, profile } = self.props

        let operatorId = ''
        if (query && query.operator) {
            operatorId = typeof query.operator === 'object' ? query.operator._id : query.operator
        }
        const sendMessageDisabled = !(
            query && query.status &&
            query.status === 'progress' &&
            profile && profile._id &&
            operatorId === profile._id
        )
        return sendMessageDisabled
    }

    assignHSMSendedAt() {
        const { query } = this.props
        if (query && query.messageUser && query.messageUser._id) {
            return this.props.actions.assignHSMSendedAt(this.props.params.appCodename, query.messageUser._id)
        } else {
            return Promise.resolve()
        }
    }

    sendHSM(data) {
        const { query } = this.props
        if (query && query.messageUser && query.messageUser._id) {
            return this.props.actions.sendHSM(this.props.params.appCodename, query.messageUser._id, data)
        } else {
            return Promise.resolve()
        }
    }

    refreshMessageReceive(response) {
        const self = this
        const { actions, query } = self.props
        if (
            response &&
            response.data &&
            response.data.messageUser &&
            query &&
            query.messageUser &&
            query.messageUser._id &&
            response.data.messageUser === query.messageUser._id
        ) {
            actions.messageReceive(response.data).then(() => {
                self.scrollToBottom()
            })
        }
    }

    refreshMessageUpdate(response) {
        const self = this
        const { actions, query } = self.props
        if (
            response &&
            response.data &&
            response.data.messageUser &&
            query &&
            query.messageUser &&
            query.messageUser._id &&
            response.data.messageUser === query.messageUser._id
        ) {
            actions.messageUpdate(response.data)
        }
    }

    async startSocketIO(isReconnect) {
        const self = this
        if (!self.isSocketSuccess()) {
            try {
                const { query } = self.props
                if (!self.state.socketLoading) {
                    self.setState({ socketLoading: true, messageUserId: null })
                    if (!self.socket) {
                        const ioURL = (process.env.MT_ENV === 'development') ? 'http://localhost:8200' : self.props.app.chattonic.url
                        const cookieOptions = ((process.env.MT_ENV === 'development') && { path: '/', sameSite: 'strict' }) || { path: '/', sameSite: 'strict', secure: true }
                        self.socket = io(ioURL, { autoConnect: false, reconnection: false, cookie: cookieOptions })
                        await self.connectSocketIO()
                    } else if (!self.socket.connected) {
                        await self.connectSocketIO()
                    } else if (query && query.messageUser && query.messageUser.app && query.messageUser._id && query.messageUser._id !== self.state.messageUserId) {
                        await new Promise((resolve, reject) => {
                            self.subscribeMessageUserToSocket(resolve, reject)
                        })
                    }
                }
            } catch (_) {
            } finally {
                self.setState({ socketLoading: false })
            }
        }
    }

    isSocketSuccess() {
        const self = this
        const { query } = self.props
        return !!(!self.state.socketLoading && self.socket && self.socket.connected && query && query.messageUser && query.messageUser.app && query.messageUser._id && query.messageUser._id === self.state.messageUserId)
    }

    async connectSocketIO() {
        const self = this
        let promise = new Promise((resolve, reject) => {
            self.socket.off()
            self.socket.on('connect', () => {
                self.socket.emit('authenticate', {
                    url: process.env.project.API_URL.toString(),
                    token: storage.get(storage.accessTokenKey)
                })
            })

            self.socket.on('connect_error', (error) => {
                reject()
            })

            self.socket.on('disconnect', () => {
                reject()
            })

            self.socket.on('authenticated', () => {
                self.subscribeMessageUserToSocket(resolve, reject)
            })

            self.socket.on('/message/receive', self.refreshMessageReceive)
            self.socket.on('/message/update', self.refreshMessageUpdate)
            self.socket.connect()
        })
        return promise
    }

    subscribeMessageUserToSocket(resolve, reject) {
        const self = this
        const { query } = self.props
        if (query && query.messageUser && query.messageUser.app && query.messageUser._id) {
            const finishSubscribeTimer = setTimeout(reject, 30000)
            self.socket.once('subscribed', () => {
                clearTimeout(finishSubscribeTimer)
                self._setState({ messageUserId: query.messageUser._id })
                resolve()
            })
            self.socket.emit('subscribe', `${query.messageUser.app}/message/${query.messageUser._id}`)
            self.updateLastReadAt()
        } else {
            reject()
        }
    }

    componentWillUnmount() {
        const self = this
        self._isMounted = false
        self.clearAllInterval()
        self.props.actions.cleanQuery()
        self.props.actions.cleanMessages()
        self.props.actions.cleanTopics()

        self.disconnectSocket()
    }

    clearAllInterval() {
        const self = this
        if (self.checkExpiredQueryTimer) {
            clearInterval(self.checkExpiredQueryTimer)
            self.checkExpiredQueryTimer = null
        }
        if (self.intervalOnSend) {
            clearInterval(self.intervalOnSend)
            self.intervalOnSend = null
        }
        if (self.intervalReconnectSocketIO) {
            clearInterval(self.intervalReconnectSocketIO)
            self.intervalReconnectSocketIO = null
        }
    }

    disconnectSocket() {
        if (this.socket) {
            this.socket.disconnect()
            this.socket = null
        }
    }

    updateLastReadAt() {
        const self = this
        let promise = Promise.resolve()
        if (this.props.query && this.props.query.messageUser && this.props.query.status === 'progress' && this.props.query.messageUser._id && this.props.profile && this.props.profile._id) {
            promise = this.props.actions.updateLastReadAt(this.props.params.appCodename, this.props.query.messageUser._id, this.props.profile._id).catch(() => {
                // do nothing
            })
        }
        return promise
    }

    updateLastReadAtInternal() {
        const self = this
        let promise = Promise.resolve()
        if (self.props.query && self.props.query._id) {
            promise = self.props.actions.updateLastReadAtInternal(self.props.params.appCodename, self.props.query._id).catch(() => {
                // do nothing
            })
        }
        return promise
    }

    scrollToBottom() {
        if (this.messages) {
            this.updateLastReadAtInternal()
            this.messages.scrollToBottom()
        }
    }

    async loadOlderMessages() {
        const self = this
        const { messages } = self.props
        const firstMessageDate = messages && messages[0] && messages[0].createdAt
        let hasOlderMessagesToLoad = true

        if (!(self.state.loading || self.state.refreshing) && firstMessageDate && firstMessageDate !== self.state.firstMessageDate && self.props.query && self.props.query.messageUser && self.props.query.messageUser._id) {
            const currentScrollPosition = this.messages.getValues()
            try {
                const action = await self.props.actions.getOldMessages(self.props.params.appCodename, self.props.query.messageUser._id, firstMessageDate)
                if (action && action.payload && action.payload.data && action.payload.data.objects && !action.payload.data.objects.length) {
                    hasOlderMessagesToLoad = false
                }
            } catch (_) {
                // Do nothing
            }
            const updatedScrollPosition = this.messages.getValues()
            const scrollToPosition = Object.assign({}, updatedScrollPosition)
            scrollToPosition.scrollTop = updatedScrollPosition.scrollHeight - currentScrollPosition.scrollHeight
            this.messages.scrollTop(scrollToPosition)
            self.setState({
                refreshing: false,
                firstMessageDate,
                hasOlderMessagesToLoad
            })
        }
    }

    async onSend(message) {
        const self = this
        const { query } = self.props
        const queryId = query && query._id
        const messageUserId = query && query.messageUser && query.messageUser._id
        if (queryId && messageUserId) {
            await self.props.actions.addMessage({ message: { ...message, queryId }, messageUserId, status: 'sending' })
            self.scrollToBottom()
        }
    }

    async _onSend() {
        const self = this
        const messagesToSend = self.props.queueMessages && self.props.queueMessages.messages || []
        const messageToSend = messagesToSend.find((message) => {
            return (!message.status || message.status === 'sending')
        })
        if (messageToSend && self.props.queueMessages && !self.props.queueMessages.sending && self.isSocketSuccess()) {
            const { message, messageUserId } = messageToSend
            try {
                self.props.actions.sendingMessages()
                const appCodename = self.props.params.appCodename
                await self.props.actions.webhookAction(appCodename, 'send', messageUserId, message)
                self.props.actions.removeMessage(message)
            } catch (_) {
                self.props.actions.failMessage(message)
            }
            self.props.actions.stopMessages()
        }
    }

    async onSendResource(toUpload) {
        const self = this
        const { query, appSync } = self.props
        const appCodename = self.props.params.appCodename
        const params = {
            fileName: toUpload.fileName
        }
        const supportsOGGAudio = (query && query.messageUser && query.messageUser.platform).match(/(whatsapp)|(telegram)/) && true
        if (toUpload.isAudio) {
            if (supportsOGGAudio) {
                // convert to ogg
                params.conversionFileExtension = '.ogg'
                params.conversionSubtype = 'audio/ogg'
            } else {
                // convert to mp3
                params.conversionFileExtension = '.mp3'
                params.conversionSubtype = 'audio/mp3'
            }
        }
        let media = null
        try {
            const action = await self.props.actions.upload(appCodename, toUpload.files, params)
            media = action && action.payload && action.payload.data
        } catch (_) {
        }
        if (media && media.originalURL) {
            const message = {}
            const baseUrl = appSync && appSync.s3Url
            if (media.type === 'image') {
                message.images = []
                message.images.push({
                    full: {
                        url: `${baseUrl}${media.originalURL}`,
                        mimeType: media.subtype
                    },
                    preview: {
                        url: `${baseUrl}${media.thumbURL}`
                    }
                })
            } else if (media.type === 'video') {
                message.videos = []
                message.videos.push({
                    full: {
                        url: `${baseUrl}${media.originalURL}`,
                        mimeType: media.subtype
                    }
                })
            } else if (media.type === 'audio') {
                message.voices = []
                message.voices.push({
                    url: `${baseUrl}${media.fullURL}`,
                    mimeType: (supportsOGGAudio && 'audio/ogg') || 'audio/mp3'
                })
            } else {
                message.documents = []
                message.documents.push({
                    url: `${baseUrl}${media.originalURL}`,
                    mimeType: media.subtype,
                    fileName: toUpload.fileName
                })
            }
            self.onSend(message)
        }
    }

    async onSendMessageTopic(config) {
        const self = this
        if (config.message) {
            self.onSend(config.message)
        }
        if (config.flow) {
            const { query } = self.props
            const appCodename = self.props.params.appCodename
            const messageUserId = query && query.messageUser && query.messageUser._id
            if (messageUserId) {
                await this.props.actions.webhookAction(appCodename, 'receive', messageUserId, {
                    command: {
                        flow: config.flow
                    }
                })
            }
            if (config.resolve) {
                this._resolve()
            }
            if (config.unpause) {
                this.unpause()
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.queryId !== nextProps.params.queryId) {
            this.setState({
                id: nextProps.params.queryId
            }, () => this.componentWillMount())
        }
    }

    pause() {
        const self = this
        const { query } = self.props
        const messageUserId = query && query.messageUser && query.messageUser._id
        if (messageUserId) {
            return self.props.actions.webhookAction(this.props.params.appCodename, 'pause', messageUserId, {})
        }
    }

    unpause() {
        const self = this
        const { query } = self.props
        const messageUserId = query && query.messageUser && query.messageUser._id
        if (messageUserId) {
            return self.props.actions.webhookAction(this.props.params.appCodename, 'unpause', messageUserId, {})
        }
    }

    take(queryId) {
        return this.props.actions.takeQuery(this.props.params.appCodename, queryId || this.state.id).then(() => {
            browserHistory.push({
                pathname: `/manager/customer/${this.props.params.appCodename}/${queryId || this.state.id}`,
                search: '?status=me'
            })
            return Promise.resolve()
        }).catch(() => {
            return Promise.resolve()
        })
    }

    resolve() {
        const { resolutions } = this.props
        if (resolutions && resolutions.objects && resolutions.objects.length) {
            this.setState({
                resolutions: true
            })

            return Promise.resolve()
        } else {
            return this.props.actions.resolveQuery(this.props.params.appCodename, this.state.id, {}).catch(() => {
                // do nothing
            }).then(() => {
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage('query_resolved')
                }
            })
        }
    }

    async _resolve(data) {
        try {
            const self = this
            const { resolutions } = self.props

            await self._setState({
                disabled: true
            })

            if (data && !data.resolution && self.state.requiredResolution && resolutions && resolutions.objects && resolutions.objects[0] && resolutions.objects[0]._id) {
                data.resolution = resolutions.objects[0]._id
            }

            if (data && data.resolution) {
                await self.props.actions.assignResolution(self.props.params.appCodename, self.state.id, data.resolution)
            }

            await self.props.actions.resolveQuery(self.props.params.appCodename, self.state.id, {})

            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage('query_resolved')
            }

            await self._setState({
                resolutions: false,
                disabled: false
            })
        } catch (err) {
            // do nothing
        }
    }

    async assign() {
        await this.props.actions.userGetUnlimited(this.props.actions.userGet, this.props.params.appCodename, { profile: { $lte: this.props.profile.profile } })
        this.setState({
            assign: true,
            assignFormKey: new Date().toISOString()
        })
    }

    _assignNewOperator(operator) {
        const self = this
        self.setState({
            assignValues: {
                operator: {
                    value: operator
                }
            }
        }, _ => {
            self._assign()
        })
        return Promise.resolve()
    }

    _assign() {
        const self = this
        const data = this.state.assignValues
        if (data && data.operator && data.operator.value) {
            let promise = self.props.actions.assignQuery(self.props.params.appCodename, self.state.id, data.operator.value, data.origin)

            promise = promise.catch(() => {
                // do nothing
            }).then(() => {
                self.setState({
                    assign: false,
                    assignValues: null,
                    changingOrigin: false,
                    assignFormKey: new Date().toISOString(),
                    disabled: false
                })

                browserHistory.push({
                    pathname: `/manager/customer/${self.props.params.appCodename}`,
                    search: '?status=me'
                })
            })

            self.setState({
                disabled: true
            }, () => promise)
        }

        return Promise.resolve()
    }

    _assignValuesOrigin(data) {
        const self = this
        let assignValues = {
            origin: data
        }
        self.setState({
            assignValues,
            assignFormKey: new Date().toISOString()
        })
    }

    _assignValuesOperator(data) {
        const self = this
        let assignValues = this.state.assignValues
        if (assignValues) {
            assignValues.operator = data
        } else {
            assignValues = {
                operator: data
            }
        }
        self.setState({
            assignValues
        })
    }

    handleScrollKnowledgeBase() {
        if (this.infoComponent) {
            this.infoComponent.getWrappedInstance().forceCollapse()
        }
    }

    handleSubmitFilter(search) {
        let promise = Promise.resolve()
        const { query } = this.props
        const messageUserId = query && query.messageUser && query.messageUser._id
        if (messageUserId) {
            if (search && search.length) {
                promise = this.props.actions.getTopicSearch(this.props.params.appCodename, messageUserId, search)
            } else {
                promise = this.props.actions.getTopicContextVerificated(this.props.params.appCodename, messageUserId)
            }
        }

        return promise.catch(() => {
            // do nothing
        }).then(action => {
            if (action && action.payload && action.payload.data && action.payload.data.messageUserId === messageUserId) {
                this.setState({
                    topics: action.payload.data.objects
                })
            }
            return Promise.resolve()
        })
    }

    async editContext(key, value) {
        const { query } = this.props
        const messageUserId = query && query.messageUser && query.messageUser._id
        try {
            if (messageUserId) {
                await this.props.actions.editContext(this.props.params.appCodename, messageUserId, key, value, this.props.query._id)
            }
            await this.initialize()
        } catch (err) {
            // do nothing
        }
    }

    editExtraData(key, value) {
        return this.props.actions.editExtraData(this.props.params.appCodename, this.props.query._id, key, value)
    }

    speech(messageId) {
        return this.props.actions.speech(this.props.params.appCodename, messageId)
    }

    getColorByStatus(status) {
        return `${status === 'online' ? '🟢' : (status === 'away') ? '🟡' : '🔴'} `
    }

    swapShowForm() {
        this.setState({
            showForm: !this.state.showForm
        })
    }

    enableDisableChangingOrigin(e) {
        e.stopPropagation()
        e.preventDefault()

        const origins = this.props.originsAll && this.props.originsAll.objects
        const newChangingOrigin = !this.state.changingOrigin
        const assignValues = {}
        if (newChangingOrigin) {
            assignValues.origin = origins && origins.length && origins[0]._id || null
        }
        this.setState({
            assignValues,
            assignFormKey: new Date().toISOString(),
            changingOrigin: newChangingOrigin
        })
    }

    render() {
        const self = this
        const { loading, showSpinner, disabled, topics, assignValues, hasOlderMessagesToLoad } = this.state
        const { query, app, profile, users, activeLanguage, queueMessages, user } = this.props
        const origins = this.props.originsAll && this.props.originsAll.objects
        const resolutions = this.props.resolutions && this.props.resolutions.objects
        const s3Url = process.env.project.S3_URL.toString()
        const showPanel = this.props.location.pathname.includes('panel')
        const showConversation = this.props.location.pathname.includes('conversation')
        const messageUserId = self.props.query && self.props.query.messageUser && self.props.query.messageUser._id
        const messagesToSend = messageUserId && queueMessages && queueMessages.messages && queueMessages.messages.filter(messageToSend => messageToSend.messageUserId === messageUserId) || []
        const messages = messageUserId && self.props.messages && self.props.messages.filter(message => message.messageUser === messageUserId) || []
        const expired = self.checkExpired()
        const sendMessageDisabled = self.checkSendMessageDisabled()

        if (loading) {
            return (
                <Loading>
                    <Spinner name="circle" />
                </Loading>
            )
        } else {
            const accountId = query && query.account && query.account._id
            const hsmTemplates = []
            if (this.props.hsmTemplates && this.props.hsmTemplates.length) {
                this.props.hsmTemplates.forEach(hsmTemplate => {
                    if (accountId === hsmTemplate.account) {
                        hsmTemplate.parameters = []
                        hsmTemplates.push(hsmTemplate)
                    }
                })
            }
            const operators = []
            if (users && users.objects && users.objects.length) {
                users.objects.forEach(operator => {
                    if (profile && operator._id !== profile._id) {
                        operators.push({
                            _id: operator._id,
                            status: operator.status,
                            name: operator.displayName,
                            position: operator.position || '',
                            area: operator.area || '',
                            displaySelect: `${app.userStatus ? this.getColorByStatus(operator.status) : ''}${operator.displayName} ${operator.position ? '| ' + operator.position : ''}  ${operator.area ? '| ' + operator.area : ''}`.trim(),
                            origins: operator.origins
                        })
                    }
                })
                if (app.userStatus) {
                    operators.sort(function (operatorA, operatorB) {
                        if (operatorA.status === 'online' || !operatorB.status || operatorB.status === 'offline') {
                            return -1
                        } else if (!operatorA.status || operatorA.status === 'offline' || operatorB.status === 'online') {
                            return 1
                        }
                        return 0
                    })
                }
            }
            let modal = ''
            if (this.state.resolutions) {
                const cancel = () => this.setState({ resolutions: false })

                let resolution = yup.string().nullable()
                if (this.state.requiredResolution) {
                    resolution = resolution.required(this.props.translate('conversation.resolve.required'))
                }

                const schema = yup.object({
                    resolution
                })

                modal = (
                    <Modal show={this.state.resolutions} onHide={cancel}>
                        <Modal.Header closeButton>
                            <Modal.Title><Translate id="conversation.resolve.title" /></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-xs-12">
                                    <Form schema={schema} defaultValue={schema.default()} onSubmit={this._resolve}>
                                        <FormGroup>
                                            <ControlLabel><Translate id="conversation.resolve.description" /></ControlLabel>
                                            <Form.Field name="resolution" type="select" className="form-control" disabled={disabled}>
                                                <option value={null}>{this.props.translate('misc.none')}</option>
                                                {(this.props.resolutions && this.props.resolutions.objects && this.props.resolutions.objects.length) ? this.props.resolutions.objects.map(r => {
                                                    return <option key={r._id} value={r._id}>{r.name}</option>
                                                }) : ''}
                                            </Form.Field>
                                            <Form.Message for="resolution" />
                                        </FormGroup>
                                        <FormGroup className="pull-right">
                                            <ButtonToolbar>
                                                <Form.Button type="submit" className="btn btn-success" disabled={disabled}>
                                                    <Translate id="buttons.resolve" />
                                                </Form.Button>
                                                <Button type="button" bsStyle="default" onClick={cancel} disabled={disabled}>
                                                    <Translate id="buttons.cancel" />
                                                </Button>
                                            </ButtonToolbar>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )
            } else if (this.state.assign) {
                const cancel = () => this.setState({
                    assign: false,
                    changingOrigin: false,
                    assignValues: null
                })

                let originAssigned
                if (origins && origins.length && query && query.originCodename) {
                    originAssigned = origins.find(origin => {
                        return origin.codename === query.originCodename
                    })
                }

                const defaultOriginValue = (assignValues && assignValues.origin) || (originAssigned && originAssigned._id) || null
                const defaultOperatorValue = (assignValues && assignValues.operator) || null

                let defaultOriginObject = origins.filter(origin => defaultOriginValue && (origin._id === defaultOriginValue)) || null
                let defaultOperatorObject = operators.filter(operator => defaultOperatorValue && (operator._id === defaultOperatorValue.value)) || null
                if (defaultOriginObject && defaultOriginObject.length) {
                    defaultOriginObject = defaultOriginObject[0]
                } else {
                    defaultOriginObject = null
                }
                if (defaultOperatorObject && defaultOperatorObject.length) {
                    defaultOperatorObject = defaultOperatorObject[0]
                } else {
                    defaultOperatorObject = null
                }

                const schema = yup.object({
                    operator: yup.string().default(defaultOperatorValue).nullable(),
                    origin: yup.string().default(defaultOriginValue).nullable()
                })

                modal = (
                    <Modal show={this.state.assign} onHide={cancel}>
                        <Modal.Header closeButton>
                            <Modal.Title><Translate id="conversation.assign.title" /></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-xs-12">
                                    <Form key={ this.state.assignFormKey } schema={schema} defaultValue={schema.default()} onSubmit={this._assign}>
                                        <FormGroup>
                                            <ControlLabel>
                                                <Translate id="conversation.assign.currentOrigin" />
                                                {(originAssigned && originAssigned.name) || query.originCodename || <Translate id="conversation.assign.unknown" />}
                                                { !this.state.changingOrigin && (
                                                    <span>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <a href="#" onClick={ this.enableDisableChangingOrigin.bind(this) }><Translate id='conversation.assign.changeOrigin' /></a>
                                                    </span>
                                                )}
                                            </ControlLabel>
                                            <br/>
                                            { this.state.changingOrigin && (
                                                <Form.Field name="origin" type="select" className="form-control" disabled={this.state.loading} value={ defaultOriginValue } onChange={this._assignValuesOrigin.bind(this)}>
                                                    {
                                                        origins.map(o => (
                                                            <option key={o._id} value={o._id}>{o.name}</option>
                                                        ))
                                                    }
                                                </Form.Field>
                                            ) || null}
                                            { this.state.changingOrigin && (
                                                <a href="#" onClick={ this.enableDisableChangingOrigin.bind(this) }><Translate id='conversation.assign.cancelChangeOrigin' /></a>
                                            ) || null}
                                            { this.state.changingOrigin && (
                                                <br />
                                            ) || null}
                                            { this.state.changingOrigin && (
                                                <br />
                                            ) || null}
                                            <ControlLabel><Translate id="conversation.assign.description" /></ControlLabel>
                                            <Select
                                                name="operator"
                                                placeholder={<Translate id='conversation.assign.description' />}
                                                options={
                                                    operators.filter(operator => {
                                                        const currentSelectedOrigin = defaultOriginValue
                                                        if (operator && operator.origins && operator.origins.length) {
                                                            if (operator.origins.indexOf(currentSelectedOrigin) >= 0 || operator.origins.some(origin => defaultOriginObject && defaultOriginObject.parentsIds && defaultOriginObject.parentsIds.indexOf(origin) >= 0)) {
                                                                return operator
                                                            }
                                                        } else {
                                                            return operator
                                                        }
                                                    }).map(o => ({
                                                        value: o._id,
                                                        label: o.displaySelect
                                                    })) || []
                                                }
                                                isDisabled={disabled}
                                                isLoading={loading}
                                                onChange={this._assignValuesOperator.bind(this)}
                                                value={ defaultOperatorValue }
                                            />
                                            <Form.Message for="operator" />
                                            { app.userStatus && defaultOperatorObject && defaultOperatorObject.status !== 'online' && (
                                                <div>
                                                    <br/>
                                                    <Alert bsStyle='warning'>
                                                        <Translate id="conversation.assign.warning" />
                                                    </Alert>
                                                </div>
                                            )}
                                        </FormGroup>
                                        <FormGroup className="pull-right">
                                            <ButtonToolbar>
                                                { defaultOperatorObject && (
                                                    <Form.Button type="submit" className="btn btn-success" disabled={disabled}>
                                                        { this.props.app.userStatus && this.state && defaultOperatorObject && defaultOperatorObject.status !== 'online' && (
                                                            <Translate id="buttons.assignAnyway" />
                                                        ) || (
                                                            <Translate id="buttons.assign" />
                                                        )}
                                                    </Form.Button>
                                                )}
                                                <Button type="button" bsStyle="default" onClick={cancel} disabled={disabled}>
                                                    <Translate id="buttons.cancel" />
                                                </Button>
                                            </ButtonToolbar>
                                        </FormGroup>
                                    </Form>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                )
            }

            if (origins && origins.length && query && query.originCodename) {
                const originFound = origins.find(origin => {
                    return origin.codename === query.originCodename
                })
                query.origin = [ originFound ]
            }

            if (resolutions && resolutions.length && query && query.resolution && typeof query.resolution !== 'object') {
                query.resolution = resolutions.find(resolution => {
                    return resolution._id === query.resolution
                })
            }

            if (users && users.objects && users.objects.length && query && query.operator && typeof query.operator !== 'object') {
                if (user && user._id && user._id === query.operator) {
                    query.operator = user
                } else {
                    query.operator = users.objects.find(operator => {
                        return operator._id === query.operator
                    })
                }
            }

            const showActions = !!(query && ['resolved', 'failed'].indexOf(query.status) < 0 && (operators.length || query.status === 'progress'))
            const ChatStyle = (showConversation) ? ChatApp : Chat
            const ManagementStyle = (showPanel) ? ManagementApp : Management
            return (
                <Container>
                    {showConversation || (!showPanel && !showConversation) ? (
                        <ChatStyle>
                            <Messages
                                ref={(c) => { this.messages = c }}
                                user={query && query.messageUser}
                                query={query}
                                app={app}
                                messages={messages}
                                messagesToSend={messagesToSend}
                                showSpinner={showSpinner}
                                onInfiniteLoadTop={null}
                                onInfiniteLoadBottom={null}
                                stopInfiniteLoadTop={true}
                                stopInfiniteLoadBottom={true}
                                speech={this.speech}
                                profile={profile}
                                reloadMessages={this.prepare}
                                loadOlderMessages={this.loadOlderMessages}
                                hasOlderMessagesToLoad={hasOlderMessagesToLoad}
                            />
                            <Composer
                                onSend={this.onSend}
                                onSendResource={this.onSendResource}
                                query={query || {}}
                                messageUser={messageUserId && query.messageUser || {}}
                                operator={(query && query.operator && query.operator._id) ? query.operator : {}}
                                profile={profile}
                                platform={query && query.messageUser && query.messageUser.platform}
                                accountId={accountId}
                                expired={expired}
                                hsm={app && app.hsm}
                                assignHSMSendedAt={this.assignHSMSendedAt}
                                sendHSM={this.sendHSM}
                                HSMSendedAt={query && query.HSMSendedAt ? moment.tz(query.HSMSendedAt, app.timezone).locale(activeLanguage.code).format('lll') : ''}
                                take={this.take}
                                assign={this._assignNewOperator.bind(this)}
                                hsmTemplates={hsmTemplates}
                                timezone={(app && app.timezone) ? app.timezone : ''}
                                loading={loading}
                                disabled={!messages.length || !query}
                                pause={this.pause}
                                appCodename={this.props.params.appCodename}
                                newQuery={this.props.actions.newQuery}
                                editContext={this.props.actions.editContext}
                                verifyQuery={this.props.actions.verifyQuery}
                            />
                        </ChatStyle>
                    ) : ''}
                    {showPanel || (!showPanel && !showConversation) ? (
                        <ManagementStyle>
                            {!this.state.showForm ? (
                            <Info
                                query={query}
                                messageUser={messageUserId && query.messageUser || {}}
                                app={app}
                                ref={(instance) => { this.infoComponent = instance }}
                                editContext={this.editContext}
                            />
                            ) : ''}
                            {showActions && !this.state.showForm ? (
                                <Actions
                                    query={query}
                                    messageUser={messageUserId && query.messageUser || {}}
                                    profile={profile}
                                    app={app}
                                    pause={this.pause}
                                    unpause={this.unpause}
                                    take={this.take}
                                    resolve={this.resolve}
                                    assign={this.assign}
                                    operators={operators}
                                />
                            ) : ''}
                            <KnowledgeBase
                                handleScroll={this.handleScrollKnowledgeBase}
                                handleSubmitFilter={this.handleSubmitFilter}
                                topics={topics || []}
                                loading={!topics}
                                query={query}
                                onSendMessageTopic={this.onSendMessageTopic}
                                editContext={this.editContext}
                                editExtraData={this.editExtraData}
                                expired={expired}
                                sendMessageDisabled={sendMessageDisabled}
                                showActions={showActions}
                                s3Url={s3Url}
                                showForm={this.state.showForm}
                                swapShowForm={this.swapShowForm}
                                app={app}
                            />
                        </ManagementStyle>
                    ) : ''}
                    {modal}
                </Container>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        hsmTemplates: state.hsmTemplates,
        user: state.user,
        users: state.users,
        resolutions: state.resolutions,
        topics: state.topics,
        messages: state.messages,
        query: state.query,
        queueMessages: state.queueMessages,
        app: state.app,
        appSync: state.appSync,
        profile: state.profile,
        isReady: state.isReady,
        routing: state.routing,
        originsAll: state.originsAll
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Customer))
