import * as actions from '../actions'

const defaultState = null

export default function schedule(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_SELECT:
            return defaultState

        case actions.GET_SCHEDULE:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.CLEAN_SCHEDULE:
            return defaultState

        default:
            return state
    }
}
