import styled from 'styled-components'

export const FormContainer = styled.div`
    padding: 10px;
    border-bottom: 1px solid #D4D6D6;

    & .form-group {
        margin-bottom: 4px;

        &:first-child {
            margin: 5px 0px;
            display: inline-flex;

            & > .btn-toolbar {
                display: inline-flex;
                margin-left: 0px;
            }
        }
    }

    & .form-control {
        height: 30px;
    }
`

export const QueriesContainer = styled.div`
    height: calc(100% - ${props => props.adjust}px);
`

export const Status = styled.div`
    width: 100%;
    background-color: ${props => props.color};
    color: ${props => props.fontColor};
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 2px 0px;
`

export const NotFound = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: #000;
    opacity: 0.5;
    cursor: default;
    padding: 0px 35px;
`
