require("imports-loader?jQuery=jquery!bootstrap/js/transition");
require("imports-loader?jQuery=jquery!bootstrap/js/alert");
require("imports-loader?jQuery=jquery!bootstrap/js/button");
require("imports-loader?jQuery=jquery!bootstrap/js/carousel");
require("imports-loader?jQuery=jquery!bootstrap/js/collapse");
require("imports-loader?jQuery=jquery!bootstrap/js/dropdown");
require("imports-loader?jQuery=jquery!bootstrap/js/modal");
require("imports-loader?jQuery=jquery!bootstrap/js/tooltip");
require("imports-loader?jQuery=jquery!bootstrap/js/popover");
require("imports-loader?jQuery=jquery!bootstrap/js/scrollspy");
require("imports-loader?jQuery=jquery!bootstrap/js/tab");
require("imports-loader?jQuery=jquery!bootstrap/js/affix");