import * as actions from '../actions'
import * as storage from '../../utils/storage'
import io from 'socket.io-client'

const defaultState = null

export default function socket(state = defaultState, action) {
    switch (action.type) {
        case actions.CONNECT_SOCKET:
            let reconnected = false
            const ioURL = (process.env.MT_ENV === 'development') ? 'http://localhost:9600' : process.env.project.API_URL.toString()
            const cookieOptions = ((process.env.MT_ENV === 'development') && { path: '/', sameSite: 'strict' }) || { path: '/', sameSite: 'strict', secure: true }
            state = io(ioURL, { cookie: cookieOptions })

            state.on('connect', () => {
                if (reconnected) {
                    reconnected = false
                    action.payload.reconnect()
                }
                state.emit('authenticate', { token: storage.get(storage.accessTokenKey) })
            })

            // state.on('connect_error', (error) => {
            //     console.log('queries: connect error', error)
            // })

            state.on('disconnect', () => {
            })

            state.on('reconnecting', () => {
                reconnected = true
            })

            state.on('authenticated', () => {
                if (action.payload.app && action.payload.app._id) {
                    state.emit('subscribe', action.payload.app._id + '/queries')
                }
            })

            return state

        case actions.DISCONNECT_SOCKET:
            if (state) {
                state.disconnect()
                state = null
            }
            return null

        case actions.ADD_SOCKET_METHOD:
            if (state) {
                state.on(action.payload.method, action.payload.callback)
            }
            return state

        default:
            return state
    }
}
