import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../state/actions'
import { Translate, withLocalize } from 'react-localize-redux'
import moment from 'moment'
import 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    Text,
    Image,
    Video,
    Location,
    Options,
    Option,
    Transcription,
    Content
} from './styles'

import {
    Voice
} from './components'

import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap'

class Message extends Component {
    constructor(props) {
        super(props)

        this.state = {
            displayInlineOptions: true,
            expandendImages: {}
        }

        this.rendered = this.rendered.bind(this)
        this.startRender = this.startRender.bind(this)
        this.imageLoad = this.imageLoad.bind(this)
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text ? text.replace(urlRegex, function(url) {
            return '<a href="' + url + '" target="__blank">' + url + '</a>'
        }) : ''
    }

    expandImage(index) {
        const self = this
        const expandendImages = Object.assign({}, self.state.expandendImages)
        if (!expandendImages[index]) {
            expandendImages[index] = true
        } else {
            delete expandendImages[index]
        }
        self.setState({
            expandendImages
        })
    }

    rendered() {
        if (this.props.checkScrollToBottom) {
            this.props.checkScrollToBottom()
        }
    }

    startRender() {
        requestAnimationFrame(this.rendered)
    }

    imageLoad() {
        requestAnimationFrame(this.startRender)
    }

    imageError(image, e) {
        e.target.onerror = null
        e.target.src = '/manager/static/images/not_found.png'
        if (image && image.expiresAt) {
            e.target.style.display = 'none'
        }
    }

    componentDidMount() {
        const { data } = this.props
        if (data.type === 'image' && data.images && data.images.length && data.images) {
            const imagesWithExpire = data.images.filter(image => image.expiresAt)
            imagesWithExpire.forEach(image => {
                const oneMinuteAgo = moment.utc().subtract(1, 'minute')
                const maxWaitBeforeReRender = Math.min(Math.abs(oneMinuteAgo.diff(data.createdAt)), 60000) + 1000
                if (oneMinuteAgo.isBefore(data.createdAt)) {
                    setTimeout(_ => {
                        this.setState({ updateAfter1minute: moment.utc() })
                    }, maxWaitBeforeReRender)
                }
            })
        }
    }

    render() {
        const self = this
        const { chattonicURL, data, speech, query } = self.props
        const { expandendImages } = self.state
        const invalidOptionTexts = [
            'maxwait'
        ]
        const hasOptionSelected = data.selectedOption && data.selectedOption.length > 1 && data.text && data.text !== data.selectedOption && invalidOptionTexts.indexOf(data.selectedOption.toLowerCase()) < 0

        let content = ''
        const showTranscript = !!(data.type === 'voice' && (data.text || data.entities))

        const oneMinuteAgo = moment.utc().subtract(1, 'minute')

        if (data.type === 'text') {
            if (hasOptionSelected) {
                const original = (
                    <Tooltip id={`${data._id}-tooltip`}>
                        {data.text}
                    </Tooltip>
                )
                content = (
                    <OverlayTrigger key={data._id} placement="right" overlay={original}>
                        <Text
                            from={data.from}>
                            {data.selectedOption}
                        </Text>
                    </OverlayTrigger>
                )
            } else if (data && data.form) {
                const dataContent = []
                dataContent.push(data.text)
                data.form.forEach(input => {
                    if (input && input.label) {
                        dataContent.push(input.label)
                    }
                })
                content = (
                    <div key={data._id}>
                        <Text
                            from={data.from}>
                            {dataContent.join('\n')}
                        </Text>
                    </div>
                )
            } else {
                content = (
                    <div key={data._id}>
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: self.urlify(data.text)
                            }}
                            from={data.from}
                        >
                        </Text>
                    </div>
                )
            }
        } else if (data.type === 'image' && data.images && data.images.length) {
            content = (
                <div key={data._id + '-image'}>
                    {data.images.map((image, index) => {
                        const shouldShowBeforeOneMinuteElapses = oneMinuteAgo.isBefore(data.createdAt)
                        if (shouldShowBeforeOneMinuteElapses || !image.expiresAt) {
                            return (
                                <div key={data._id + '-image-' + index}>
                                    <Image
                                        src={(!image.full.url.startsWith('http')) ? `${chattonicURL}${image.full.url}` : image.full.url}
                                        expanded={expandendImages[index]}
                                        onClick={self.expandImage.bind(self, index)}
                                        onLoad={image.expiresAt && moment.utc().isAfter(image.expiresAt) && self.imageError.bind(self, image) || self.imageLoad}
                                        onError={self.imageError.bind(self, image)}
                                    />
                                    {image.caption && (
                                        <br/>
                                    ) || null }
                                    {image.caption && (
                                        <Text>
                                            {image.caption}
                                        </Text>
                                    ) || null}
                                </div>
                            )
                        } else {
                            return (
                                <div key={data._id + '-image-' + index}>
                                    <a href={(!image.full.url.startsWith('http')) ? `${chattonicURL}${image.full.url}` : image.full.url} target="_blank">
                                        <Translate id="conversation.message.expiredImage" />
                                    </a>
                                </div>
                            )
                        }
                    })}
                </div>
            )
        } else if (data.type === 'video' && data.videos && data.videos.length) {
            content = (
                <div key={data._id + '-video'}>
                    {data.videos.map((video, index) => {
                        return (
                            <div key={data._id + '-video-' + index}>
                                <Video controls>
                                    <source src={(!video.full.url.startsWith('http')) ? `${chattonicURL}${video.full.url}` : video.full.url} type='video/mp4'/>
                                    <Translate id="conversation.message.notSupportVideo" />
                                </Video>
                                <br/>
                                {video.caption && <Text>
                                    {video.caption}
                                </Text>}
                            </div>
                        )
                    })}
                </div>
            )
        } else if (data.type === 'voice' && data.voices && data.voices.length) {
            content = (
                <div key={data._id + '-voice'}>
                    {data.voices.map((voice, index) => {
                        return (
                            <Voice
                                key={data._id + '-voice-' + index}
                                voice={voice}
                                messageId={data._id}
                                chattonicURL={chattonicURL}
                                showTranscript={showTranscript}
                                speech={speech}
                            />
                        )
                    })}
                </div>
            )
        } else if (data.type === 'document' && data.documents && data.documents.length) {
            content = (
                <Options key={data._id + '-options'}>
                    {data.documents.map((document, index) => {
                        const number = index + 1
                        return (
                            <Option key={data._id + '-document-' + index} href={document.url} download target="__blank">
                                {document.fileName || <Translate id="conversation.message.download" data={{ number }} />}
                            </Option>
                        )
                    })}
                </Options>
            )
        } else if (data.type === 'location' && data.location) {
            content = (
                <div key={data._id + '-location'}>
                    <Location key={data._id + '-location'} src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.location.latitude},${data.location.longitude}&zoom=15&size=500x250&maptype=roadmap&key=AIzaSyBOOBXZpN6N5p4VrZvPG0cEaPVLrEzFE-U`} />
                </div>
            )
        } else if (data.type === 'hsm') {
            content = (
                <div key={data._id + '-hsm'}>
                    <Text from={data.from}>
                        {data.text}
                    </Text>
                </div>
            )
        } else if (data.type === 'formData') {
            if (data && data.formData && data.formData.inputs && data.formData.inputs.length) {
                content = (
                    <div key={data._id + '-formData'}>
                        <Text from={data.from}>
                            {data.formData.inputs.map(input => {
                                let toReturn = ''
                                if (input && input.label && input.value)  {
                                    if (input.type === 'password') {
                                        toReturn += `${input.label}: ********`
                                    } else {
                                        toReturn += `${input.label}: ${input.value}`
                                     }
                                }
                                return toReturn
                            }).filter(input => input).join('\n')}
                        </Text>
                    </div>
                )
            } else {
                content = (
                    <div key={data._id + '-cancel'}>
                        <Text from={data.from}>
                            {data.formData && data.formData.messageCancel || <Translate id="conversation.message.form.cancel" />}
                        </Text>
                    </div>
                )
            }
        } else if (data.type === 'contact') {
            const returnMsg = []
            Object.keys(data.contact).forEach(keys => {
                if (typeof data.contact[keys] === 'object') {
                    if (data.contact[keys].length) {
                        data.contact[keys].forEach(key => {
                            Object.keys(key).forEach(value => {
                                if (key[value] !== '') {
                                    if (keys === 'phones') {
                                        returnMsg.push(value === 'phone' ? `${this.props.translate('query.message.contact.phone')} ${key[value]}` : `${value}: ${key[value]}`)
                                    } else if (keys === 'addresses') {
                                        returnMsg.push(value === 'city' ? `${this.props.translate('query.message.contact.addresses.city')} ${key[value]}` :
                                        value === 'country' ? `${this.props.translate('query.message.contact.addresses.country')} ${key[value]}` :
                                        value === 'countryCode' ? `${this.props.translate('query.message.contact.addresses.countryCode')} ${key[value]}` :
                                        value === 'state' ? `${this.props.translate('query.message.contact.addresses.state')} ${key[value]}` :
                                        value === 'street' ? `${this.props.translate('query.message.contact.addresses.street')} ${key[value]}` :
                                        value === 'type' ? `${this.props.translate('query.message.contact.addresses.type')} ${key[value]}` :
                                        value === 'zip' ? `${this.props.translate('query.message.contact.addresses.zip')} ${key[value]}` :
                                        `${value}: ${key[value]}`)
                                    } else {
                                        returnMsg.push(`${value}: ${key[value]}`)
                                    }
                                }
                            })
                        })
                    } else if (keys === 'org') {
                        Object.keys(data.contact[keys]).forEach(value => {
                            if (data.contact[keys][value] !== '') {
                                returnMsg.push(value === 'company' ? `${this.props.translate('conversation.message.contact.org.company')} ${data.contact[keys][value]}` : value === 'department' ? `${this.props.translate('conversation.message.contact.org.department')} ${data.contact[keys][value]}` : `${this.props.translate('conversation.message.contact.org.title')} ${data.contact[keys][value]}`)
                            }
                        })
                    }
                } else if (data.contact[keys] !== '' && keys !== 'formattedName') {
                    returnMsg.push(keys === 'name' ? `${this.props.translate('conversation.message.contact.name')} ${data.contact[keys]}` : keys === 'lastName' ? `${this.props.translate('conversation.message.contact.lastName')} ${data.contact[keys]}` : keys === 'birthday' ? `${this.props.translate('conversation.message.contact.birthday')} ${data.contact[keys]}` : data.contact[keys])
                }
            })
            content = (
                <div key={data._id + '-contact'}>
                    <Text from={data.from}>
                        {returnMsg.join('\n')}
                    </Text>
                </div>
            )
        }
        const optionsToDisplay = data && (data.options || data.inlineOptions)
        return (
            <div>
                <Content>
                    {content}
                    { data && data.sentError && (
                        <FontAwesomeIcon icon="exclamation-circle" style={ { color: 'red' } } />
                    )}
                </Content>
                {(showTranscript && data.text) ? (
                    <Transcription>
                        {data.text}
                    </Transcription>
                ) : ''}
                {optionsToDisplay && optionsToDisplay.length ? (
                    <Options>
                        {optionsToDisplay.map((option, index) => (
                            <Option key={`${data._id}_option_${index}`} hidden={!option.visible} highlight={option.highlight}>
                                {option.title}
                            </Option>
                        ))}
                    </Options>
                ) : ''}
            </div>
        )
    }
}

Message.propTypes = {
    data: PropTypes.object.isRequired,
    chattonicURL: PropTypes.string.isRequired,
    checkScrollToBottom: PropTypes.func,
    speech: PropTypes.func,
    activeLanguageCode: PropTypes.string
}

function mapStateToProps(state) {
    return {
        app: state.app,
        profile: state.profile,
        query: state.query
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Message))
