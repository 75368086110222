import React, { Component } from 'react'
import PropTypes from 'prop-types'

import copy from 'copy-to-clipboard'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import {
    Container,
    Top,
    Bottom,
    Separator
} from './styles'

import {
    ControlLabel,
    Collapse
} from 'react-bootstrap'

import { Step } from '../index'

class GuideTopic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }

        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.copyMessage = this.copyMessage.bind(this)
        this.editContextOrExtraData = this.editContextOrExtraData.bind(this)
    }

    toggleCollapse() {
        this.props.swapShowForm()
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    copyMessage(message) {
        copy(message)
    }

    editContextOrExtraData(type, key, value) {
        if (type === 'context') {
            return this.props.editContext(key, value)
        } else if (type === 'extraData') {
            return this.props.editExtraData(key, value)
        } else {
            return Promise.resolve()
        }
    }

    render() {
        const { topic, query } = this.props

        const icons = []
        if (topic.type === 'Respuesta' && topic.media && topic.media[0]) {
            icons.push(<FontAwesomeIcon icon="file-image" />)
        }

        if (topic.type === 'Respuesta' && topic.video) {
            if (icons.length) {
                icons.push(' ')
            }

            icons.push(<FontAwesomeIcon icon="file-video" />)
        }

        return (
            !this.props.showForm || this.state.collapsed ? (
                <Container>
                    <Top onClick={this.toggleCollapse}>
                        <div>
                            <FontAwesomeIcon icon="clipboard" />
                            {' '}
                            <ControlLabel>{topic.title}</ControlLabel>
                            <span className="pull-right">
                                {icons}
                            </span>
                            {this.state.collapsed ? (
                                <FontAwesomeIcon icon="times" style={{ float: 'right', marginRight: '5px' }}/>
                            ) : ''}
                        </div>
                        <div>
                            <span>
                                {topic.description}
                            </span>
                        </div>
                    </Top>
                    <Bottom>
                        <Collapse in={this.state.collapsed} timeout={1000}>
                            <div>
                                <Separator />
                                <div>
                                    {topic.steps && topic.steps.map((step, i) => {
                                        return (
                                            <Step
                                                key={`${topic._id}_step_${i}`}
                                                index={i}
                                                query={query}
                                                topic={topic}
                                                step={step}
                                                copyMessage={this.copyMessage}
                                                editContextOrExtraData={this.editContextOrExtraData}
                                                onSendMessageTopic={this.props.onSendMessageTopic}
                                                expired={this.props.expired}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </Collapse>
                    </Bottom>
                </Container>
            ) : ''
        )
    }
}

GuideTopic.propTypes = {
    topic: PropTypes.object,
    query: PropTypes.object,
    onSendMessageTopic: PropTypes.func,
    editContext: PropTypes.func,
    editExtraData: PropTypes.func,
    expired: PropTypes.bool,
    showForm: PropTypes.bool,
    swapShowForm: PropTypes.func
}

export default GuideTopic
