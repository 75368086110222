import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'

import {
    FormGroup,
    ControlLabel,
    HelpBlock,
    Button,
    ButtonToolbar,
    Panel,
    Alert,
    Checkbox
} from 'react-bootstrap'

class AppEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            formRef: React.createRef(),
            formKey: new Date().toISOString(),
            formValues: {},
            params: ['messageUser.firstName', 'operator.name.givenName']
        }

        Form.addInputTypes(types)

        this.updateChatTonicToken = this.updateChatTonicToken.bind(this)
        this.appEdit = this.appEdit.bind(this)
        this.createParam = this.createParam.bind(this)
        this.goBack = this.goBack.bind(this)
        this.updatedUserOrganizer = this.updatedUserOrganizer.bind(this)
        this.updatedUserAdmin = this.updatedUserAdmin.bind(this)
    }

    componentWillMount() {
        this.props.actions.appGetOne(this.props.params.appId).then(result => {
            const app = result.payload.data
            const formValues = {
                name: app.name,
                timezone: app.timezone,
                sendPoll: app.sendPoll,
                requiredResolution: app.requiredResolution,
                soundNotifications: app.soundNotifications,
                derivationOfQueriesEnabled: (app.derivationOfQueries && app.derivationOfQueries.enabled) || false,
                derivationOfQueriesMaxQueries: (app.derivationOfQueries && app.derivationOfQueries.maxQueries) || 5,
                daysToExpireQueries: (app.derivationOfQueries && app.derivationOfQueries.expireCSQueriesAfterDays) || 30,
                location: app.bigquery && app.bigquery.location,
                chattonic: app.chattonic,
                hsm: app.hsm,
                userStatus: app.userStatus,
                organizerEnabled: (app.derivationOfQueries && app.derivationOfQueries.organizerEnabled) || false,
                adminEnabled: (app.derivationOfQueries && app.derivationOfQueries.adminEnabled) || false,
                integrations: {
                    enabled: app.integrations && app.integrations.enabled,
                    ba360: {
                        enabled: app.integrations && app.integrations.ba360 && app.integrations.ba360.enabled,
                        instanceURL: app.integrations && app.integrations.ba360 && app.integrations.ba360.instanceURL,
                        platform: app.integrations && app.integrations.ba360 && app.integrations.ba360.platform,
                        channel: app.integrations && app.integrations.ba360 && app.integrations.ba360.channel,
                        chatId: app.integrations && app.integrations.ba360 && app.integrations.ba360.chatId,
                        token: app.integrations && app.integrations.ba360 && app.integrations.ba360.token
                    }
                }
            }
            this.setState({
                loading: false,
                formKey: new Date().toISOString(),
                formValues
            })
        })
    }

    goBack() {
        browserHistory.push({
            pathname: '/manager/app'
        })
    }

    getModelSchema() {
        const self = this
        const formValues = Object.assign({}, self.state.formValues)
        return yup.object({
            name: yup.string().required('Ingrese un nombre').default(formValues.name),
            timezone: yup.string().required('Ingrese un timezone').default(formValues.timezone),
            sendPoll: yup.bool().default(formValues.sendPoll),
            requiredResolution: yup.bool().default(formValues.requiredResolution),
            soundNotifications: yup.bool().default(formValues.soundNotifications),
            userStatus: yup.bool().default(formValues.userStatus),
            derivationOfQueriesEnabled: yup.bool().default(formValues.derivationOfQueriesEnabled),
            derivationOfQueriesMaxQueries: yup.number().default(formValues.derivationOfQueriesMaxQueries),
            daysToExpireQueries: yup.number().required('Ingrese la cantidad de días para que expiren las consultas').max(120, 'Ingrese un número menor o igual a 120').min(1, 'Ingrese un número mayor o igual a 1').default(formValues.daysToExpireQueries).typeError('Ingrese el número de días para que expiren las consultas'),
            location: yup.string().default(formValues.location),
            chattonic: yup.object({
                url: yup.string().required('Ingrese la url de chattonic').default((formValues.chattonic && formValues.chattonic.url) || (self.props.app && self.props.app.chattonic && self.props.app.chattonic.url) || 'https://chat-tonic.com'),
                codename: yup.string().required('Ingrese el codename de chattonic').default((formValues.chattonic && formValues.chattonic.codename) || (self.props.app && self.props.app.chattonic && self.props.app.chattonic.codename)),
                token: yup.string().default((formValues.chattonic && formValues.chattonic.updateChatTonicToken)),
                endFlow: yup.string().required('Ingrese el flow correspondiente al cierre de la consulta').default((formValues.chattonic && formValues.chattonic.endFlow) || (self.props.app && self.props.app.chattonic && self.props.app.chattonic.endFlow) || 'middlewareCustomerServiceEnd')
            }).default(formValues.chattonic),
            hsm: yup.object({
                name: yup.string().nullable(),
                namespace: yup.string().nullable(),
                language: yup.string().nullable(),
                languagePolicy: yup.string().nullable().default('DETERMINISTIC'),
                text: yup.string().nullable(),
                params: yup.array().of(yup.string())
            }).default(formValues.hsm),
            integrations: yup.object({
                enabled: yup.boolean().default(formValues.integrations && formValues.integrations.enabled),
                ba360: yup.object({
                    enabled: yup.boolean().default(formValues.integrations && formValues.integrations.ba360 && formValues.integrations.ba360.enabled),
                    instanceURL: yup.string().default(formValues.integrations && formValues.integrations.ba360 && formValues.integrations.ba360.instanceURL),
                    platform: yup.string().default(formValues.integrations && formValues.integrations.ba360 && formValues.integrations.ba360.platform),
                    channel: yup.string().default(formValues.integrations && formValues.integrations.ba360 && formValues.integrations.ba360.channel),
                    chatId: yup.string().default(formValues.integrations && formValues.integrations.ba360 && formValues.integrations.ba360.chatId),
                    token: yup.string().default(formValues.integrations && formValues.integrations.ba360 && formValues.integrations.ba360.token)
                }).default(formValues.integrations && formValues.integrations.ba360)
            }).default(formValues.integrations)
        })
    }

    async appEdit(formValues) {
        const self = this
        self.setState({ loading: true })
        try {
            await self.props.actions.appEdit(this.props.params.appId, formValues)
            self.goBack()
        } catch (error) {
            self.setState({
                loading: false,
                notifications: [
                    {
                        style: 'danger',
                        text: error.message
                    }
                ]
            })
        }
    }

    async appSync(formValues) {
        const self = this
        const chattonic = formValues.chattonic
        const appCodename = chattonic.codename
        const appToken = chattonic.token
        const ctURL = chattonic.url

        self.setState({
            loading: true,
            syncedApp: false,
            notifications: []
        })

        try {
            const syncData = await self.props.actions.appSync({
                url: ctURL,
                codename: appCodename,
                token: appToken
            })

            if (!(syncData && syncData.payload && syncData.payload.data && syncData.payload.data.customerservice)) {
                throw new Error('La opción "ATENCION AL CLIENTE" está desactivada. Para continuar, ingrese al Manager de Chat-Tonic y edite su aplicación activando esta opción')
            }

            const formValuesNew = Object.assign({}, formValues)
            formValuesNew.name = syncData.payload.data.name
            formValuesNew.timezone = syncData.payload.data.timezone
            formValuesNew.location = syncData.payload.data.bigquery && syncData.payload.data.bigquery.location || 'US'

            self.setState({
                loading: false,
                syncedApp: syncData,
                formKey: new Date().toISOString(),
                formValues: formValuesNew,
                notifications: []
            })
            return true
        } catch (error) {
            self.setState({
                loading: false,
                syncedApp: false,
                notifications: [{
                    style: 'danger',
                    text: `Could not sync with Chat-Tonic: ${(error && error.error && error.error.message) || (error.message) || error.toString()}${(error && error.error && error.error.response && error.error.response.data && error.error.response.data.error && error.error.response.data.error.detail && error.error.response.data.error.detail.response && error.error.response.data.error.detail.response.body && error.error.response.data.error.detail.response.body.error && `: ${JSON.stringify(error.error.response.data.error.detail.response.body.error)}`) || ''}`
                }] || []
            })
            return false
        }
    }

    async updateChatTonicToken(formValues) {
        const self = this
        self.setState({ loading: true })
        try {
            if (formValues && formValues.chattonic && formValues.chattonic.token) {
                const synced = await self.appSync(formValues)
                if (synced) {
                    await self.props.actions.appEditAppData(self.props.app.codename, 'chattonic', { token: formValues.chattonic.token })
                    await self.disableUpdateToken()
                } else {
                    throw new Error('Invalid new token')
                }
            } else {
                throw new Error('Missing new token')
            }
            self.setState({
                loading: false,
                updatingChatTonicToken: false
            })
        } catch (error) {
            self.setState({
                loading: false,
                notifications: [
                    {
                        style: 'danger',
                        text: error.message
                    }
                ]
            })
        }
    }

    createParam(value) {
        const formValues = Object.assign({}, this.state.formValues)
        const params = this.state.params.slice(0)
        if (!formValues.hsm) {
            formValues.hsm = {}
        }
        if (!formValues.hsm.params) {
            formValues.hsm.params = []
        }
        formValues.hsm.params.push(value)
        params.push(value)

        this.setState({
            formValues,
            params,
            formKey: new Date().toISOString()
        })
    }

    submitForm(type, formValues) {
        const self = this
        if (type === 'appEdit') {
            if (!formValues.userStatus && (formValues.derivationOfQueriesEnabled || formValues.organizerEnabled || formValues.adminEnabled)) {
                formValues.derivationOfQueriesEnabled = false
                formValues.organizerEnabled = false
                formValues.adminEnabled = false
            }
            formValues.derivationOfQueries = {
                enabled: formValues.derivationOfQueriesEnabled,
                maxQueries: formValues.derivationOfQueriesMaxQueries,
                expireCSQueriesAfterDays: formValues.daysToExpireQueries,
                organizerEnabled: formValues.organizerEnabled,
                adminEnabled: formValues.adminEnabled
            }
            delete formValues.derivationOfQueriesEnabled
            delete formValues.derivationOfQueriesMaxQueries
            delete formValues.daysToExpireQueries
            delete formValues.organizerEnabled
            delete formValues.adminEnabled
            self.appEdit(formValues)
        } else if (type === 'updateChatTonicToken') {
            self.updateChatTonicToken(formValues)
        } else if (type === 'appSync') {
            self.appSync(formValues)
        }
    }

    enableUpdateToken() {
        const self = this
        const formValues = self.state.formValues
        delete formValues.chattonic.token
        self.setState({
            formValues,
            formKey: new Date().toISOString(),
            updatingChatTonicToken: true
        })
    }

    disableUpdateToken() {
        const self = this
        const formValues = self.state.formValues
        delete formValues.chattonic.token
        self.setState({
            formValues,
            formKey: new Date().toISOString(),
            updatingChatTonicToken: false
        })
    }

    updatedUserOrganizer() {
        const formValues = this.state.formValues
        formValues.organizerEnabled = !this.state.formValues.organizerEnabled
        this.setState({
            formValues: formValues
        })
    }

    updatedUserAdmin() {
        const formValues = this.state.formValues
        formValues.adminEnabled = !this.state.formValues.adminEnabled
        this.setState({
            formValues: formValues
        })
    }

    render() {
        const { loading, params, formValues } = this.state
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className="page-header">Editar App</h1>
                    </div>
                </div>
                <div className="row col-md-12">
                    <div>
                        {this.state && this.state.notifications && this.state.notifications.map((notification, index) => {
                            return (
                                <Alert key={`notification-${index}`} bsStyle={notification.style}>
                                    <strong>{notification.text}</strong>
                                </Alert>
                            )
                        })}
                        <Form ref={ this.state.formRef } key={this.state.formKey} schema={this.getModelSchema()} defaultValue={this.getModelSchema().default()} onChange={(formValues, fieldUpdated) => {
                            this.setState({ formValues })
                        }}>
                            <FormGroup>
                                <ControlLabel>Chat-Tonic URL</ControlLabel>
                                <Form.Field name="chattonic.url" placeholder="https://chat-tonic.com" className="form-control" disabled={loading} />
                                <Form.Message for="chattonic.url" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Chat-Tonic App Codename</ControlLabel>
                                <Form.Field name="chattonic.codename" placeholder="demo" className="form-control" disabled={loading} />
                                <Form.Message for="chattonic.codename" />
                            </FormGroup>
                            { this.state.updatingChatTonicToken && (
                                <FormGroup>
                                    <ControlLabel>Chat-Tonic App Token</ControlLabel>
                                    <Form.Field name="chattonic.token" placeholder="token" className="form-control"/>
                                    <Form.Message for="chattonic.token" />
                                </FormGroup>
                            )}
                            <FormGroup>
                                <ControlLabel>Nombre</ControlLabel>
                                <Form.Field name="name" placeholder="Nombre" className="form-control" disabled={ true } />
                                <Form.Message for="name" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Timezone</ControlLabel>
                                <Form.Field name="timezone" placeholder="America/Argentina/Buenos_Aires" className="form-control" disabled={ true } />
                                <Form.Message for="timezone" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Location BigQuery</ControlLabel>
                                <Form.Field name="location" placeholder="Location BigQuery" className="form-control" type="select" disabled={ true } >
                                    <option value="US">United States</option>
                                    <option value="EU">Europe</option>
                                </Form.Field>
                            </FormGroup>
                            <FormGroup>
                                <ButtonToolbar>
                                    { this.state.updatingChatTonicToken && (
                                        <Form.Button className="btn btn-primary" onClick={ this.submitForm.bind(this, 'updateChatTonicToken', this.state.formValues) }>
                                            Actualizar Token y Sincronizar con Chat-Tonic
                                        </Form.Button>
                                    )}
                                    { !this.state.updatingChatTonicToken && (
                                        <Form.Button className="btn btn-primary" onClick={ this.submitForm.bind(this, 'appSync', this.state.formValues) }>
                                            Sincronizar con Chat-Tonic
                                        </Form.Button>
                                    )}
                                    { !this.state.updatingChatTonicToken && (
                                        <Form.Button className="btn btn-default" onClick={ this.enableUpdateToken.bind(this) }>
                                            Actualizar Token
                                        </Form.Button>
                                    )}
                                    { this.state.updatingChatTonicToken && (
                                        <Form.Button className="btn btn-danger" onClick={ this.disableUpdateToken.bind(this) }>
                                            Cancelar Actualizar Token
                                        </Form.Button>
                                    )}
                                </ButtonToolbar>
                            </FormGroup>
                            <br />
                            <FormGroup>
                                <ControlLabel>Flow a mandar cuando termina la consulta</ControlLabel>
                                <Form.Field name="chattonic.endFlow" placeholder="middlewareCustomerServiceEnd" className="form-control" disabled={loading} />
                                <Form.Message for="chattonic.endFlow" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Días para que expiren las consultas</ControlLabel>
                                <Form.Field name="daysToExpireQueries" placeholder="30" type="text" className="form-control"/>
                                <Form.Message for="daysToExpireQueries" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Envío de encuesta obligatorio</ControlLabel>
                                <Form.Field name="sendPoll" type="select" className="form-control" disabled={loading}>
                                    <option value={false}>Desactivado</option>
                                    <option value={true}>Activado</option>
                                </Form.Field>
                                <Form.Message for="sendPoll" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Motivo de resolución de consulta obligatorio</ControlLabel>
                                <Form.Field name="requiredResolution" type="select" className="form-control" disabled={loading}>
                                    <option value={false}>Desactivado</option>
                                    <option value={true}>Activado</option>
                                </Form.Field>
                                <Form.Message for="requiredResolution" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Notificaciones con sonido</ControlLabel>
                                <Form.Field name="soundNotifications" type="select" className="form-control" disabled={loading}>
                                    <option value={false}>Desactivado</option>
                                    <option value={true}>Activado</option>
                                </Form.Field>
                                <Form.Message for="soundNotifications" />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Usuarios online/offline</ControlLabel>
                                <Form.Field name="userStatus" type="select" className="form-control" disabled={loading}>
                                    <option value={false}>Desactivado</option>
                                    <option value={true}>Activado</option>
                                </Form.Field>
                                <Form.Message for="userStatus" />
                            </FormGroup>
                            {this.state.formValues.userStatus && (
                                <FormGroup>
                                    <ControlLabel>Derivacion automatica de consultas</ControlLabel>
                                    <Form.Field name="derivationOfQueriesEnabled" type="select" className="form-control" disabled={loading}>
                                        <option value={false}>Desactivado</option>
                                        <option value={true}>Activado</option>
                                    </Form.Field>
                                    <Form.Message for="derivationOfQueriesEnabled" />
                                </FormGroup>
                            )}

                            {this.state.formValues.userStatus && this.state.formValues.derivationOfQueriesEnabled && (
                                <FormGroup>
                                    <Checkbox checked={true} disabled>
                                        Operadores
                                    </Checkbox>
                                    <Checkbox name="organizerEnabled" checked={this.state.formValues.organizerEnabled} onChange={this.updatedUserOrganizer.bind(this)} disabled={loading}>
                                        Coordinadores
                                    </Checkbox>
                                    <Checkbox name="adminEnabled" checked={this.state.formValues.adminEnabled} onChange={this.updatedUserAdmin.bind(this)} disabled={loading}>
                                        Administradores
                                    </Checkbox>
                                </FormGroup>
                            )}

                            {this.state.formValues.userStatus && this.state.formValues.derivationOfQueriesEnabled && (
                                <FormGroup>
                                    <ControlLabel>Máximo de consultas por operador</ControlLabel>
                                    <Form.Field name="derivationOfQueriesMaxQueries" placeholder="5" type="select" className="form-control">
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={15}>15</option>
                                        <option value={20}>20</option>
                                        <option value={30}>30</option>
                                        <option value={40}>40</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </Form.Field>
                                    <Form.Message for="derivationOfQueriesMaxQueries" />
                                </FormGroup>
                            )}

                            <FormGroup>
                                <ControlLabel>Integraciones</ControlLabel>
                                <Form.Field name="integrations.enabled" type="select" className="form-control" disabled={loading}>
                                    <option value={false}>Desactivado</option>
                                    <option value={true}>Activado</option>
                                </Form.Field>
                                <Form.Message for="integrations.enabled" />
                            </FormGroup>
                            {formValues.integrations && formValues.integrations.enabled && (
                                <Panel>
                                    <Panel.Heading>
                                    <ControlLabel>BE AWARE 360</ControlLabel>
                                    <Form.Field name="integrations.ba360.enabled" type="select" className="form-control" disabled={loading}>
                                        <option value={false}>Desactivado</option>
                                        <option value={true}>Activado</option>
                                    </Form.Field>
                                    <Form.Message for="integrations.ba360.enabled" />
                                    </Panel.Heading>
                                    {formValues.integrations && formValues.integrations.enabled && formValues.integrations.ba360 && formValues.integrations.ba360.enabled && (
                                        <Panel style={{marginBottom: 0}}>
                                            <Panel.Body>
                                                <FormGroup>
                                                    <ControlLabel>URL Instancia</ControlLabel>
                                                    <Form.Field name="integrations.ba360.instanceURL" className="form-control" disabled={loading} />
                                                    <Form.Message for="integrations.ba360.instanceURL" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ControlLabel>Plataforma</ControlLabel>
                                                    <Form.Field name="integrations.ba360.platform" className="form-control" disabled={loading} />
                                                    <Form.Message for="integrations.ba360.platform" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ControlLabel>Canal</ControlLabel>
                                                    <Form.Field name="integrations.ba360.channel" className="form-control" disabled={loading} />
                                                    <Form.Message for="integrations.ba360.channel" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ControlLabel>Chat ID</ControlLabel>
                                                    <Form.Field name="integrations.ba360.chatId" className="form-control" disabled={loading} />
                                                    <Form.Message for="integrations.ba360.chatId" />
                                                </FormGroup>
                                                <FormGroup>
                                                    <ControlLabel>Token</ControlLabel>
                                                    <Form.Field name="integrations.ba360.token" className="form-control" disabled={loading} />
                                                    <Form.Message for="integrations.ba360.token" />
                                                </FormGroup>
                                            </Panel.Body>
                                        </Panel>
                                    )}</Panel>)}
                            <Panel>
                                <Panel.Heading>
                                    Configuración de HSM
                                </Panel.Heading>
                                <Panel.Body>
                                    <FormGroup>
                                        <ControlLabel>Nombre</ControlLabel>
                                        <Form.Field name="hsm.name" className="form-control" disabled={loading} />
                                        <Form.Message for="hsm.name" />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Namespace</ControlLabel>
                                        <Form.Field name="hsm.namespace" className="form-control" disabled={loading} />
                                        <Form.Message for="hsm.namespace" />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Lenguaje</ControlLabel>
                                        <Form.Field name="hsm.language" className="form-control" disabled={loading} />
                                        <Form.Message for="hsm.language" />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Política lingüística</ControlLabel>
                                        <Form.Field name="hsm.languagePolicy" className="form-control" disabled={loading} />
                                        <Form.Message for="hsm.languagePolicy" />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>Parámetros para completar en el texto (en orden)</ControlLabel>
                                        <Form.Field name="hsm.params" type="multiselect" data={params} onCreate={this.createParam} disabled={loading} />
                                        <Form.Message for="hsm.params" />
                                        <HelpBlock>Los parámetros se completan en el orden que estan marcados en el texto. Los parametros comenzados en "messageUser" u "operator" son variables, el resto se ingresa tal cual.</HelpBlock>
                                    </FormGroup>
                                </Panel.Body>
                            </Panel>
                            <ButtonToolbar className="pull-right">
                                <Form.Button className="btn btn-default" disabled={loading} onClick={ this.submitForm.bind(this, 'appEdit', this.state.formValues) }>
                                    Guardar
                                </Form.Button>
                                <Button bsStyle="danger" onClick={this.goBack} disabled={loading}>
                                    Cancelar
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        app: state.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(AppEdit)
