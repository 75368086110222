import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import { Alert } from 'react-bootstrap'
import { ThemeProvider } from 'styled-components'
import themes from '../../styles/themes'
import * as actions from '../../state/actions'
import * as storage from '../../utils/storage'
import style from './styles.less'

import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize } from 'react-localize-redux'
import globalTranslations from '../../translations/global.json'

class Base extends Component {
    constructor(props) {
        super(props)

        const languages = [
            { name: 'Español', code: 'es' },
            { name: 'English', code: 'en' }
        ]

        let navLanguage = navigator.language || navigator.userLanguage
        navLanguage = navLanguage.split('-')[0]

        if (!languages.find(language => language.code === navLanguage)) {
            navLanguage = 'es'
        }

        this.props.initialize({
            languages,
            translation: globalTranslations,
            options: {
                defaultLanguage: storage.get('language') || navLanguage,
                renderToStaticMarkup
            }
        })
        this.props.actions.previousPath(`${this.props.location.pathname}${this.props.location.search}`)
    }

    componentWillMount() {
        const { profile, app } = this.props
        const accessToken = storage.get(storage.accessTokenKey)

        let promise = Promise.resolve()

        if (!profile && accessToken) {
            promise = this.props.actions.userGetProfile()
        }

        return promise.catch(() => {
            // do nothing
        }).then(() => {
            return this.props.actions.isReady()
        })
    }

    componentDidUpdate() {
        const { isReady, routing, api, profile } = this.props

        if (isReady) {
            const location = routing && routing.locationBeforeTransitions
            const unauthorized = api && api.error && api.error.response && api.error.response.status && api.error.response.status === 401
            if (!profile && location && location.pathname !== '/manager/login') {
                browserHistory.push('/manager/login')
            } else if (profile && location && (location.pathname === '/manager/login' || location.pathname === '/manager' || location.pathname === '/manager/')) {
                if (!unauthorized && profile && profile.profile) {
                    if (profile.profile === 2000) {
                        browserHistory.push(this.props.previousPath && !this.props.previousPath.match(/\/manager($|\/$|\/login)/) ? this.props.previousPath : '/manager/app')
                    } else {
                        browserHistory.push(this.props.previousPath && !this.props.previousPath.match(/\/manager($|\/$|\/login|\/app|\/stats)/) ? this.props.previousPath : `/manager/customer/${profile.app.codename}`)
                    }
                } else {
                    this.props.actions.userCleanProfile().then(() => {
                        browserHistory.push('/manager/login')
                    })
                }
            }
        }
    }

    async componentDidMount() {
        const self = this
        self.checkStatusPage()
        if(!self.refreshCheckScript && !self.refreshCheckStatus){
            self.refreshCheckScript = setInterval(() => {
                self.checkStatusPage()
            }, 6000) // 1'
        }
    }

    componentWillUnmount() {
        const self = this
        if (self.refreshCheckStatus) {
            clearInterval(self.refreshCheckStatus)
        }
        if(self.refreshCheckScript){
            clearInterval(self.refreshCheckScript)
        }
    }

    checkStatusPage(){
        const self = this
        try{
            if(StatusPage){
                if(self.refreshCheckScript){
                    clearInterval(self.refreshCheckScript)
                }
                self.sp = new StatusPage.page({ page : '3s9bx3y3vn6t' })
                self.getStatusCT()
                self.refreshCheckStatus = setInterval(() => {
                    self.getStatusCT()
                }, 300000) // 5'
            }
        } catch{
        }
    }

    getStatusCT() {
        const self = this
        try {
            self.sp.status({
                success : data => {
                    let icon
                    let color
                    let description = data && data.status && data.status.description
                    switch (data && data.status && data.status.indicator) {
                        case 'critical':
                            color = 'red'
                            icon = 'times-circle'
                            break
                        case 'major':
                            color = 'orange'
                            icon = 'exclamation-circle'
                            break
                        case 'minor':
                            color = 'yellow'
                            icon = 'exclamation-circle'
                            break
                        case 'none':
                            color = 'green'
                            icon = 'check-circle'
                            break
                        default:
                            color = ''
                            icon = ''
                    }
                    self.props.actions.setStatusCT({
                        icon,
                        color,
                        description
                    })
                }
            })
        } catch{
            // err
        }
    }

    render () {
        const { app, api } = this.props

        const theme = (app && app.theme) ? app.theme : themes.default
        theme.brand = {
            primary: '#428bca',
            success: '#5cb85c',
            info: '#5bc0de',
            warning: '#f0ad4e',
            danger: '#d9534f'
        }

        const alert = (process.env.MT_ENV === 'development' && api && api.error) ? (
            <div className={style.error}>
                <Alert id="alertError" bsStyle="danger" onDismiss={this.props.actions.apiClean.bind(this)}>
                    <strong>{ api.error.message }</strong>
                    {api.error.detail ? (
                        <p>{ api.error.detail }</p>
                    ) : ''}
                    {api.error.stack ? (
                        <p>{ api.error.stack }</p>
                    ) : ''}
                </Alert>
            </div>
        ) : null

        if (alert) {
            setTimeout(_ => {
                this.props.actions.apiClean()
            }, 3000)
        }

        return (
            <ThemeProvider theme={theme}>
                <div>
                    { alert }
                    { this.props.children }
                </div>
            </ThemeProvider>
        )
    }
}

function mapStateToProps(state) {
    return {
        api: state.api,
        app: state.app,
        isReady: state.isReady,
        profile: state.profile,
        routing: state.routing,
        previousPath: state.previousPath
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Base))
