import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import * as project from '../../utils/project'
import { bindActionCreators } from 'redux'
import List from './../common/List'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate, withLocalize } from 'react-localize-redux'
import Spinner from '../common/Spinner'
import {
    Button,
    ButtonToolbar,
    Modal,
    FormGroup,
    ControlLabel,
    Alert
} from 'react-bootstrap'

class Origin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataColumnsNames: [
                'origins.dataColumnNames.name', 'origins.dataColumnNames.codename', 'origins.dataColumnNames.father', 'origins.dataColumnNames.actions'
            ],
            modal: {
                show: false
            },
            parentId: null,
            showAlertDelete: false
        }

        Form.addInputTypes(types)

        this.props.actions.originsGet = this.props.actions.originsGet.bind(this, this.props.params.appCodename)
        this.props.actions.originsClean = this.props.actions.originsClean.bind(this)
        this.props.actions.originsAdd = this.props.actions.originsAdd.bind(this)
        this.confirmDeleteData = this.confirmDeleteData.bind(this)
        this.toggleAlertDelete = this.toggleAlertDelete.bind(this)
    }

    componentWillMount() {
        this.props.actions.originsGet(this.props.params.appCodename)
    }

    getModelSchema() {
        return yup.object({
            name: yup.string().required(this.props.translate('origins.validations.name')),
            codename: yup.string().required(this.props.translate('origins.validations.codename')),
            origin: yup.object().nullable()
        })
    }
    addOrigin() {
        this.setState({
            modal: {
                show: true
            }
        })
    }

    addOriginSuccess(data) {
        let promise = this.props.actions.originsAdd(this.props.params.appCodename, {
            name: data.name,
            parentId: this.state.parentId && this.state.parentId._id ? this.state.parentId._id : undefined,
            parentsIds: this.state.parentId && this.state.parentId.parentsIds ? this.state.parentId.parentsIds : undefined,
            parentsNames: this.state.parentId && this.state.parentId.parentsNames ? this.state.parentId.parentsNames : undefined,
            codename: data.codename
        })

        promise = promise.catch(() => {
            // do nothing
        }).then(() => {
            return this.closeModal()
        }).then(() => {
            return this.list.getWrappedInstance().refreshData(true)
        }).then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true,
            parentId: null
        }, () => promise)
    }

    closeModal(data) {
        const modal = Object.assign({}, this.state.modal)
        modal.show = false
        this.setState({
            modal
        })
    }

    toggleAlertDelete() {
        this.setState({
            showAlertDelete: !this.state.showAlertDelete
        })
    }

    async deleteData(originId) {
        const self = this
        try {
            this.toggleAlertDelete()
            this.setState({
                loading: true,
                originSelected: originId
            })
            const validateOrigin = await self.props.actions.originCheckDelete(self.props.params.appCodename, originId)
            const deleteError = validateOrigin && validateOrigin.payload && validateOrigin.payload.data
            this.setState({
                deleteError
            })
        } catch(err){
            //
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    async confirmDeleteData() {
        try {
            this.setState({
                loading: true
            })
            const list = this.list.getWrappedInstance()
            await this.props.actions.originDelete(this.props.params.appCodename, this.state.originSelected)
            await list.refreshData(true)
            this.setState({
                loading: false
            })
            this.toggleAlertDelete()
        } catch {
            // do nothing
        }
    }

    dataObjectProcess(origin) {
        const row = []
        const { loading } = this.state
        const { profile } = this.props

        let name = ''
        let parents = ''

        if (origin.parentsNames && origin.parentsNames.length) {
            origin.parentsNames.forEach(parentName => {
                name += parentName + ' > '
                parents += parentName + ' > '
            })
            name += origin.name
            parents = parents.replace(/>\s$/, '')
        } else {
            name = origin.name
        }

        origin.parentNamesBeauty = name

        row.push(
            name
        )

        row.push(
            origin.codename
        )

        row.push(
            parents && parents !== '' ? parents : '✔'
        )

        const isParent = this.props.origins.objects && this.props.origins.objects.length && this.props.origins.objects.filter(otherOrigin => otherOrigin.parentsIds.length && otherOrigin.parentsIds.some(id => origin._id === id)).length
        if (!(profile && profile.profile && profile.profile <= 500) && !isParent) {
            row.push(
                <ButtonToolbar>
                    <Button bsStyle="danger" onClick={this.deleteData.bind(this, origin._id)} type="button" disabled={loading}>
                        <FontAwesomeIcon icon="trash-alt"/>
                    </Button>
                </ButtonToolbar>
            )
        } else {
            row.push('')
        }

        return row
    }

    render() {
        const { loading, modal = {}, deleteError, showAlertDelete } = this.state
        const { profile, routing } = this.props
        const origins = (this.props.origins && this.props.origins.objects && this.props.origins.objects.length) ? this.props.origins.objects : []

        const itemsProcessed = []
        origins.forEach(origin => {
            itemsProcessed.push(this.dataObjectProcess(origin))
        })

        const addButton = !(profile && profile.profile && profile.profile <= 500) ? (
            <Button bsStyle="primary" onClick={this.addOrigin.bind(this)} type="button" disabled={loading}>
                <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;<Translate id="origins.misc.add" />
            </Button>
        ) : ''

        const description = (
            <div className="row">
                <div className="col-xs-12">
                    {addButton}
                </div>
            </div>
        )

        return (
            <div>
                <List ref={(list) => { this.list = list }}
                    items={itemsProcessed}
                    name={this.props.translate('origins.titles.list')}
                    dataColumnsNames={this.state.dataColumnsNames.map(dataColumnsNames => this.props.translate(dataColumnsNames))}
                    description={description}
                    getObjects={this.props.actions.originsGet}
                    clearObjects={this.props.actions.originsClean}
                    routing={routing}
                    limit={100}
                />
                <div className="static-modal">
                    <Modal show={modal.show} onHide={this.closeModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <Translate id="origins.add.title" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <Form className="col-xs-12" schema={this.getModelSchema()} onSubmit={this.addOriginSuccess.bind(this)} >
                                    <div className="row">
                                        <div className="col-md-6 col-xs-12">
                                            <FormGroup>
                                                <ControlLabel>
                                                    <Translate id="origins.dataColumnNames.name" />
                                                </ControlLabel>
                                                <Form.Field name="name" className="form-control" disabled={loading}/>
                                                <Form.Message for="name" />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6  col-xs-12">
                                            <FormGroup>
                                                <ControlLabel>
                                                    <Translate id="origins.dataColumnNames.codename" />
                                                </ControlLabel>
                                                <Form.Field name="codename" className="form-control" disabled={loading}/>
                                                <Form.Message for="codename" />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <FormGroup>
                                                <ControlLabel>
                                                    <Translate id="origins.dataColumnNames.father" />
                                                </ControlLabel>
                                                <Form.Field
                                                    name="origin"
                                                    type="multiselect"
                                                    textField="parentNamesBeauty"
                                                    filter="contains"
                                                    data={origins}
                                                    onChange={value => this.setState({
                                                        parentId: value[0]
                                                    })}
                                                    disabled={loading}
                                                />
                                                <Form.Message for="origin" />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="pull-right">
                                            <Form.Button type="submit" style={{ 'marginTop': 30, 'marginRight': 15 }} className="btn btn-success" disabled={loading}>
                                                <Translate id="buttons.save" />
                                            </Form.Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={showAlertDelete} onHide={this.toggleAlertDelete}>
                    {
                        loading ? <Spinner style={{'height': '40px', 'textAlign': 'center', 'padding': '10px 0px'}}/> :
                        <>  <Modal.Header closeButton>
                                <Modal.Title><Translate id={`origins.delete.title.${(deleteError && deleteError.title) || 'original'}`} /></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Translate id={`origins.delete.body.${(deleteError && deleteError.title) || 'original'}`} />
                                {deleteError && deleteError.description && deleteError.description.length && deleteError.description.map(data => <><br/>{`${data}`}</>)}
                            </Modal.Body>
                            {!(deleteError && deleteError.error) && <Modal.Footer>
                                <Button bsStyle="danger" onClick={this.confirmDeleteData} disabled={loading}>
                                    <Translate id='buttons.delete' />
                                </Button>
                                <Button bsStyle="primary" onClick={this.toggleAlertDelete.bind(this)} disabled={loading}>
                                    <Translate id='buttons.cancel' />
                                </Button>
                            </Modal.Footer>}
                        </>
                    }
                </Modal>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        origins: state.origins,
        routing: state.routing,
        profile: state.profile
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Origin))
