import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinkit from 'react-spinkit'

class Spinner extends Component {
    render() {
        const self = this
        return (<Spinkit name={self.props.name} style={self.props.style}/>)
    }
}

Spinner.propTypes = {
    name: PropTypes.string,
    style: PropTypes.object
}

Spinner.defaultProps = {
    name: 'three-bounce',
    style: {
        'textAlign': 'center',
        'padding': '10px 0px'
    }
}

export default Spinner
