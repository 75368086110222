import * as actions from '../actions'

const defaultState = null

export default function origin(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_SELECT:
            return defaultState

        case actions.GET_ORIGINS_ALL:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        default:
            return state
    }
}
