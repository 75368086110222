import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import copy from 'copy-to-clipboard'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import {
    Container,
    Top,
    Bottom,
    Separator,
    Text
} from './styles'

import {
    ControlLabel,
    Collapse,
    ButtonToolbar,
    Button
} from 'react-bootstrap'

class AnswerTopic extends Component {
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }

        this.toggleCollapse = this.toggleCollapse.bind(this)
        this.sendAll = this.sendAll.bind(this)
    }

    toggleCollapse() {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    copyMessage(message) {
        copy(message)
    }

    sendAll() {
        const { topic } = this.props
        const messages = []

        let promise = Promise.resolve()

        if (topic.answerUser) {
            messages.push({
                text: topic.answerUser
            })
        }

        if (topic.media && topic.media[0]) {
            messages.push({
                images: [
                    {
                        full: {
                            url: `${this.props.s3Url}${topic.media[0].originalURL}`
                        },
                        preview: {
                            url: `${this.props.s3Url}${topic.media[0].thumbURL}`
                        }
                    }
                ]
            })
        }

        if (topic.video) {
            messages.push({
                text: topic.video
            })
        }

        messages.forEach(message => {
            promise = promise.then(() => {
                return this.props.onSendMessageTopic({ message })
            })
        })

        return promise
    }

    urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="__blank">' + url + '</a>'
        })
    }

    render() {
        const { topic, expired } = this.props

        const icons = []
        if (topic.type === 'Respuesta' && topic.media && topic.media[0]) {
            icons.push(<FontAwesomeIcon key="media" icon="file-image" />)
        }

        if (topic.type === 'Respuesta' && topic.video) {
            if (icons.length) {
                icons.push(' ')
            }

            icons.push(<FontAwesomeIcon key="video" icon="file-video" />)
        }

        return (
            <Container>
                <Top onClick={this.toggleCollapse}>
                    <div>
                        <FontAwesomeIcon icon="comment-dots" />
                        {' '}
                        <ControlLabel>{topic.title}</ControlLabel>
                        <span className="pull-right">
                            {icons}
                        </span>
                    </div>
                    <div>
                        <span>
                            {topic.description}
                        </span>
                    </div>
                </Top>
                <Bottom>
                    <Collapse in={this.state.collapsed}>
                        <div>
                            <Separator />
                            <div>
                                {topic.answerUser ? (
                                    <div>
                                        <label><Translate id="topic.answer" />:</label>
                                        <ButtonToolbar className="pull-right">
                                            <Button bsStyle="default" bsSize="xsmall" onClick={() => this.props.onSendMessageTopic({
                                                message: {
                                                    text: topic.answerUser
                                                }
                                            })} disabled={expired}>
                                                <FontAwesomeIcon icon="paper-plane" />
                                            </Button>
                                            <Button bsStyle="default" bsSize="xsmall" onClick={this.copyMessage.bind(this, topic.answerUser)}>
                                                <FontAwesomeIcon icon="clipboard" />
                                            </Button>
                                        </ButtonToolbar>
                                        <Text dangerouslySetInnerHTML={ {__html: this.urlify(topic.answerUser)} }></Text>
                                        <hr/>
                                    </div>
                                ) : ''}
                                {topic.media && topic.media[0] && topic.media[0].type === 'image' && (
                                    <div>
                                        <label><Translate id="topic.image" />:</label>
                                        <ButtonToolbar className="pull-right">
                                            <Button bsStyle="default" bsSize="xsmall" onClick={() => this.props.onSendMessageTopic({
                                                message: {
                                                    images: [
                                                        {
                                                            full: {
                                                                url: `${this.props.s3Url}${topic.media[0].originalURL}`
                                                            },
                                                            preview: {
                                                                url: `${this.props.s3Url}${topic.media[0].thumbURL}`
                                                            }
                                                        }
                                                    ]
                                                }
                                            })} disabled={expired}>
                                                <FontAwesomeIcon icon="paper-plane" />
                                            </Button>
                                            <Button bsStyle="default" bsSize="xsmall" onClick={this.copyMessage.bind(this, `${this.props.s3Url}${topic.media[0].thumbURL}`)}>
                                                <FontAwesomeIcon icon="clipboard" />
                                            </Button>
                                        </ButtonToolbar>
                                        <img src={`${this.props.s3Url}${topic.media[0].thumbURL}`} width="100%" />
                                        <hr/>
                                    </div>
                                ) || null}
                                {topic.media && topic.media[0] && topic.media[0].type === 'file' && (
                                    <div>
                                        <label><Translate id="topic.document" />:</label>
                                        <ButtonToolbar className="pull-right">
                                            <Button bsStyle="default" bsSize="xsmall" onClick={() => this.props.onSendMessageTopic({
                                                message: {
                                                    documents: [
                                                        {
                                                            url: `${this.props.s3Url}${topic.media[0].originalURL}`
                                                        }
                                                    ]
                                                }
                                            })} disabled={expired}>
                                                <FontAwesomeIcon icon="paper-plane" />
                                            </Button>
                                            <Button bsStyle="default" bsSize="xsmall" onClick={this.copyMessage.bind(this, `${this.props.s3Url}${topic.media[0].thumbURL}`)}>
                                                <FontAwesomeIcon icon="clipboard" />
                                            </Button>
                                        </ButtonToolbar>
                                        PDF
                                        <hr/>
                                    </div>
                                ) || null}
                                {topic.video ? (
                                    <div>
                                        <label><Translate id="topic.video" />:</label>
                                        <ButtonToolbar className="pull-right">
                                            <Button bsStyle="default" bsSize="xsmall" onClick={() => this.props.onSendMessageTopic({
                                                message: topic.video
                                            })} disabled={expired}>
                                                <FontAwesomeIcon icon="paper-plane" />
                                            </Button>
                                            <Button bsStyle="default" bsSize="xsmall" onClick={this.copyMessage.bind(this, topic.video)}>
                                                <FontAwesomeIcon icon="clipboard" />
                                            </Button>
                                        </ButtonToolbar>
                                        <p>{topic.video}</p>
                                        <hr/>
                                    </div>
                                ) : ''}
                                <div className="text-center">
                                    <Button bsStyle="primary" onClick={this.sendAll} disabled={expired}>
                                        <Translate id="buttons.sendAll" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </Bottom>
            </Container>
        )
    }
}

AnswerTopic.propTypes = {
    topic: PropTypes.object,
    onSendMessageTopic: PropTypes.func,
    expired: PropTypes.bool,
    s3Url: PropTypes.string
}

export default AnswerTopic
