import * as actions from '../actions'

const defaultState = {
    messages: [],
    sending: false
}

export default function queueMessages(state = defaultState, action) {
    if (action.type === actions.CLEAN_ALL || action.type === actions.APP_SELECT) {
        return defaultState
    } else if (action.type === actions.ADD_MESSAGE) {
        const messages = [...state.messages]
        messages.push(action.payload)
        return Object.assign({}, state, { messages })
    } else if (action.type === actions.REMOVE_MESSAGE) {
        const stringifiedMessage = JSON.stringify(action.payload)
        const messages = [...state.messages]
        let index = 0
        while (index < messages.length) {
            const compareMessage = JSON.stringify(messages[index].message)
            if (compareMessage === stringifiedMessage) {
                messages.splice(index, 1)
            } else {
                index++
            }
        }
        return Object.assign({}, state, { messages })
    } else if (action.type === actions.SENDING_MESSAGES) {
        return Object.assign({}, state, { sending: true })
    } else if (action.type === actions.STOP_MESSAGES) {
        return Object.assign({}, state, { sending: false })
    } else if (action.type === actions.FAIL_MESSAGE) {
        const stringifiedMessage = JSON.stringify(action.payload)
        const foundMessages = state.messages.filter(message => JSON.stringify(message.message) === stringifiedMessage)
        if (foundMessages && foundMessages.length) {
            for (let index = 0; index < foundMessages.length; index++) {
                const foundMessage = foundMessages[index]
                foundMessage.status = 'failed'
            }
        }
        const messages = [...state.messages]
        return Object.assign({}, state, { messages })
    } else {
        return state
    }
}
