import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'
import LanguageToggle from '../../../../components/LanguageToggle'
import { connect } from 'react-redux'
import * as actions from '../../../../state/actions'
import { bindActionCreators } from 'redux'
import AlertNonPayment from '../../../../components/AlertNonPayment'
import moment from 'moment'
import {
    NavDropdown,
    MenuItem,
    Button,
    OverlayTrigger,
    Popover
} from 'react-bootstrap'

import {
    AlertBilling,
    ButtonAlert,
    Container,
    LeftMenu,
    Logo,
    RightMenu,
    StatusMenu,
    StatusMenuTitle,
    StatusMenuDes,
    Alert
} from './styles'

class Navigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            lastUpdated: new Date().toISOString()
        }
        this.dropdownAction = this.dropdownAction.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.cookieBillingAlert = this.cookieBillingAlert.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
        this.validatePendingMessages = this.validatePendingMessages.bind(this)
    }

    componentWillMount() {
        this.props.actions.appSync({ codename: this.props.appCodename })
        this.props.actions.getBilling(this.props.appCodename)
    }

    componentDidUpdate() {
        this.startRecurringCheck()
    }

    startRecurringCheck() {
        const self = this
        if (self.props.app && !self.recurringCheckTimer) {
            let intervalCheck = 300000
            if (self.props.app.userStatus) {
                intervalCheck = 60000
            }
            self.recurringCheckTimer = setInterval(_ => self.refreshMenu(), intervalCheck)
        }
    }

    stopRecurringCheck() {
        const self = this
        if (self.recurringCheckTimer) {
            clearInterval(self.recurringCheckTimer)
            self.recurringCheckTimer = null
        }
    }

    async refreshMenu() {
        const self = this
        const userProfile = await self.props.actions.userGetProfile()
        self.setState({ lastUpdated: new Date().toISOString() })
    }

    dropdownAction(value) {
        if (value === 'logout') {
            this.props.userCleanProfile()
        } else if (value === 'profile') {
            browserHistory.push(`/manager/${this.props.appCodename}/user/edit/me`)
        } else if (value === 'apps') {
            browserHistory.push('/manager/app')
        }
    }

    openModal() {
        this.setState({
            show: true
        })
    }

    closeModal() {
        this.setState({
            show: false
        })
    }

    cookieBillingAlert() {
        const self = this
        const { billing, profile } = self.props
        const { show } = self.state
        if ((billing && billing.billing && billing.billing.enabled) && !(document.cookie.split(';').some((item) => item.trim().startsWith('alert_billing_cs'))) && profile && profile.profile !== 2000) {
            const startDate = moment().endOf('day').clone().format('ddd, DD MMM YYYY HH:mm:ss')
            document.cookie = `alert_billing_cs=true; expires=${startDate}`
            if (!show) {
                self.setState({
                    show: true
                })
            }
        }
    }

    toggleMenu() {
        const self = this
        if (!this.props.open) {
            self.validatePendingMessages()
        }
        self.props.toggleMenu()
    }

    validatePendingMessages() {
        const self = this
        const { queueMessages } = self.props
        if (queueMessages && queueMessages.messages && queueMessages.messages.length) {
            alert('Hay mensajes pendientes de envío, si salis de esta pantalla no se van enviar.')
        }
    }

    async updateStatus() {
        const self = this
        const { profile } = self.props
        await self.props.actions.changeStatus(this.props.appCodename, profile.status)
        self.setState({ lastUpdated: new Date().toISOString() })
    }

    isSoonOffline(isoString) {
        const dateToCheck = new Date(isoString)
        const now = new Date()
        const differenceInMilliseconds = now - dateToCheck
        const hoursInMilliseconds = 11.5 * 60 * 60 * 1000 // 11.5 hours we start to show this message
        const toReturn = differenceInMilliseconds > hoursInMilliseconds
        return toReturn
    }

    render() {
        const { profile, appCodename, app, status, billing } = this.props
        const { show } = this.state
        const goToProfile = (appCodename && profile && profile.profile < 2000) ? (
            <MenuItem eventKey="profile">
                <FontAwesomeIcon icon="home" />
                &nbsp;
                <Translate id="navigation.profile" />
            </MenuItem>
        ) : ''

        const goToApps = (profile && profile.profile === 2000) ? (
            <MenuItem eventKey="apps">
                <FontAwesomeIcon icon="laptop" />
                &nbsp;
                <Translate id="navigation.apps" />
            </MenuItem>
        ) : ''

        let goToAlertBilling = ''
        if (billing && billing.billing && billing.billing.enabled) {
            this.cookieBillingAlert()
            goToAlertBilling = (
                <ButtonAlert onClick={this.openModal}>
                    <AlertBilling>
                        <Translate id="alertNonPayment.billingNonpayment"/> <img src={`/manager/static/images/alert.png`} height={30}/>
                    </AlertBilling>
                </ButtonAlert>
            )
        }

        const overlayIntegration = app && app.integrations && app.integrations.enabled && app.integrations.ba360 && app.integrations.ba360.enabled && (
            <Popover id="popover-trigger-click-root-close" title="Be Aware 360">
                <div style={{ height: '70vmin' }}><iframe src={`https://${app.integrations.ba360.instanceURL}/integracionview/${app.integrations.ba360.platform}/${app.integrations.ba360.channel}/${app.integrations.ba360.chatId}/{}/${app.integrations.ba360.token}`} style={{ width: '100%', height: '100%', border: 'none' }} /></div>
            </Popover>
        )

        return (
            <div>
                <Container>
                    <LeftMenu>
                        {(app && app.userStatus && profile && profile.profile < 2000) && (
                            <Button bsClass="btn-profile" onClick={this.toggleMenu}>
                                {this.props.open ? (
                                    <FontAwesomeIcon icon="times" />
                                ) : (
                                    <FontAwesomeIcon icon="bars" />
                                )}
                                {(profile && profile.displayName) && (
                                    <StatusMenu>
                                        <StatusMenuTitle>{profile.displayName}</StatusMenuTitle>
                                        <StatusMenuDes>
                                            <div className={(status === 'online') ? 'online' : (status === 'away') ? 'away' : 'offline'}>
                                                <Translate id={(status === 'online') ? 'navigation.online' : (status === 'away') ? 'navigation.away' : 'navigation.offline'} />
                                            </div>
                                        </StatusMenuDes>
                                    </StatusMenu>
                                )}
                            </Button>
                        ) || (
                            (profile && profile.profile >= 600) ? (
                                <Button onClick={this.toggleMenu}>
                                    {this.props.open ? (
                                        <FontAwesomeIcon icon="times" />
                                    ) : (
                                        <FontAwesomeIcon icon="bars" />
                                    )}
                                </Button>
                            ) : ''
                        )}
                        { app && app.userStatus && profile && (profile.profile < 2000) && ([ 'online', 'away' ].indexOf(profile.status) >= 0) && profile.statusUpdatedAt && this.isSoonOffline(profile.statusUpdatedAt) && (
                            <a className="btn" onClick={ this.updateStatus.bind(this) }>
                                <Alert className="soonOffline">
                                    <Translate id={'navigation.notification.soonOffline'}/>
                                </Alert>
                            </a>
                        ) || null }
                    </LeftMenu>
                    <RightMenu>
                        {goToAlertBilling}
                        {app && app.integrations && app.integrations.enabled && app.integrations.ba360 && app.integrations.ba360.enabled && <div style={{ color: '#fff', cursor: 'pointer', fontSize: '20px', padding: '11px'}}>
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="bottom"
                                overlay={overlayIntegration}
                            >
                                <div><FontAwesomeIcon icon='puzzle-piece' /></div>
                            </OverlayTrigger>
                        </div>}
                        <LanguageToggle />
                        <NavDropdown id="nav-dropdown" title={<FontAwesomeIcon icon="user-circle" />} onSelect={this.dropdownAction} onClick={this.validatePendingMessages} pullRight>
                            {goToApps}
                            {goToProfile}
                            <MenuItem eventKey="logout">
                                <FontAwesomeIcon icon="sign-out-alt" />
                                &nbsp;
                                <Translate id="navigation.logout" />
                            </MenuItem>
                        </NavDropdown>
                    </RightMenu>
                </Container>
                {show ? <AlertNonPayment
                    show={show}
                    closeModal={this.closeModal}
                /> : ''}
            </div>
        )
    }
}

Navigation.propTypes = {
    userCleanProfile: PropTypes.func.isRequired,
    appCodename: PropTypes.string.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    open: PropTypes.bool,
    status: PropTypes.string
}

function mapStateToProps(state) {
    return {
        billing: state.billing,
        profile: state.profile,
        app: state.app,
        appSync: state.appSync,
        queueMessages: state.queueMessages
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
