import * as actions from '../actions'
import { isUserProfileKey } from '../../utils/validate'

const defaultState = []

let operator, me, status, index

export default function queries(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_SELECT:
            return defaultState

        case actions.GET_QUERIES:
            if (!action.error) {
                if (state && state.length && action.payload.data.skip && action.payload.data.skip !== undefined && action.payload.data.skip !== 0) {
                    action.payload.data.objects.forEach(query => {
                        if (!state.find(q => q._id === query._id)) {
                            state.push(query)
                        }
                    })
                } else {
                    state = action.payload.data.objects
                }
            }
            return state.slice(0)

        case actions.EDIT_CONTEXT:
            if (state && state.length && action && action.payload && action.payload.data && action.payload.data.key && isUserProfileKey(action.payload.data.key)) {
                state = state.map(query => {
                    if (query.messageUser && query.messageUser._id === action.payload.data.messageUserId) {
                        query.messageUser[action.payload.data.key] = action.payload.data.value
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.EDIT_EXTRA_DATA:
            if (state && state.length && action && action.payload && action.payload.data && action.payload.data.key) {
                state = state.map(query => {
                    if (query._id === action.payload.data.queryId) {
                        if (!query.extra) {
                            query.extra = {}
                        }
                        query.extra[action.payload.data.key] = action.payload.data.value
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.MESSAGE_RECEIVE:
            if (state && state.length) {
                state = state.map(query => {
                    if (query.HSMSendedAt && query.messageUser && query.messageUser._id === action.payload.messageUser && action.payload.to === 'self') {
                        query.lastNotifiedAt = new Date()
                        delete state.HSMSendedAt
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.WEBHOOK_ACTION:
            if (state && state.length && action && action.payload && action.payload.data && action.payload.data.matched >= 1 && action.payload.data.messageUserId && ['pause', 'unpase'].indexOf(action.payload.data.webhookAction) >= 0) {
                state = state.map(query => {
                    if (query && query.messageUser && query.messageUser._id === action.payload.data.messageUserId) {
                        if (action.payload.data.webhookAction === 'pause') {
                            query.messageUser.pausedBot = {
                                from: new Date()
                            }
                        } else if (action.payload.data.webhookAction === 'unpause') {
                            query.messageUser.pausedBot
                        }
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.TAKE_QUERY:
        case actions.ASSIGN_QUERY:
            if (state && state.length && !action.error) {
                state = state.map(query => {
                    if (query._id === action.payload.data.query) {
                        query.dates.progress = new Date().toISOString()
                        query.status = 'progress'
                        query.operator = action.payload.data.operator
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.RESOLVE_QUERY:
            if (state && state.length && !action.error) {
                state = state.map(query => {
                    if (query._id === action.payload.data.query) {
                        query.dates.resolved = new Date().toISOString()
                        query.status = 'resolved'
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.ASSIGN_RESOLUTION:
            if (state && state.length && !action.error) {
                state = state.map(query => {
                    if (query._id === action.payload.data.query) {
                        query.resolutionSettedAt = new Date().toISOString()
                        query.resolution = action.payload.data.resolution
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.UPDATE_QUERIES:
            if (state && state.length) {
                state = state.map(query => {
                    if (action.payload.validation(query)) {
                        if (action.payload.data.message) {
                            action.payload.data.message = Object.assign(query.message, action.payload.data.message)
                        }
                        query = Object.assign(query, action.payload.data)
                    }
                    return query
                })
            }
            return state.slice(0)

        case actions.SOCKET_PENDING_QUERY:
            state.push(action.payload)
            return state.slice(0)

        case actions.SOCKET_PROGRESS_QUERY:
            operator = action.payload.operator._id
            me = action.payload.profile._id
            status = action.payload.status
            if (state && state.length) {
                index = state.findIndex(query => query._id === action.payload.data._id)
                if (index >= 0) {
                    if (status !== 'other' && operator === me) {
                        state[index].operator = action.payload.profile
                        state[index].status = 'progress'
                        state[index].dates.progress = new Date().toISOString()
                    } else if (['other', 'all'].indexOf(status) >= 0 && operator !== me) {
                        state[index].operator = action.payload.operator
                        state[index].status = 'progress'
                        state[index].dates.progress = new Date().toISOString()
                    } else {
                        state.splice(index, 1)
                    }
                } else if (status !== 'other' && operator === me) {
                    action.payload.data.operator = action.payload.profile
                    state.push(action.payload.data)
                } else if (['other', 'all'].indexOf(status) >= 0 && operator !== me) {
                    state.push(action.payload.data)
                }
            } else if (status !== 'other' && operator === me) {
                action.payload.data.operator = action.payload.profile
                state.push(action.payload.data)
            } else if (['other', 'all'].indexOf(status) >= 0 && operator !== me) {
                state.push(action.payload.data)
            }
            return state.slice(0)

        case actions.SOCKET_RESOLVED_QUERY:
            if (state && state.length) {
                index = state.findIndex(query => query._id === action.payload.data._id)
                if (index >= 0) {
                    state[index].status = 'resolved'
                    state[index].dates.resolved = new Date().toISOString()
                } else {
                    state.push(action.payload.data)
                }
            } else {
                state.push(action.payload.data)
            }
            return state.slice(0)

        case actions.SOCKET_FAILED_QUERY:
            if (state && state.length) {
                index = state.findIndex(query => query._id === action.payload.data._id)
                if (index >= 0) {
                    state[index].status = 'failed'
                    state[index].dates.failed = new Date().toISOString()
                } else {
                    state.push(action.payload.data)
                }
            } else {
                state.push(action.payload.data)
            }
            return state.slice(0)

        case actions.SOCKET_REASSIGNED_QUERY:
            operator = action.payload.operator._id
            me = action.payload.profile._id
            status = action.payload.status
            if (state && state.length) {
                index = state.findIndex(query => query._id === action.payload.data._id)
                if (index >= 0) {
                    if (status !== 'other' && operator === me) {
                        state[index].operator = action.payload.profile
                    } else if (['other', 'all'].indexOf(status) >= 0 && operator !== me) {
                        state[index].operator = action.payload.operator
                    } else {
                        state.splice(index, 1)
                    }
                } else if (status !== 'other' && operator === me) {
                    action.payload.data.operator = action.payload.profile
                    state.push(action.payload.data)
                } else if (['other', 'all'].indexOf(status) >= 0 && operator !== me) {
                    state.push(action.payload.data)
                }
            } else if (status !== 'other' && operator === me) {
                action.payload.data.operator = action.payload.profile
                state.push(action.payload.data)
            } else if (['other', 'all'].indexOf(status) >= 0 && operator !== me) {
                state.push(action.payload.data)
            }
            return state.slice(0)

        case actions.ASSIGN_HSM_SENDED_AT:
            if (state && state.length && action.payload && action.payload.data && action.payload.data.HSMSendedAt) {
                index = state.findIndex(query => query.messageUser && query.messageUser._id === action.payload.data.messageUserId)
                if (index >= 0) {
                    state[index].HSMSendedAt = action.payload.data.HSMSendedAt
                }
            }
            return state.slice(0)

        case actions.UPDATE_LAST_READ_AT_INTERNAL:
            if (state && state.length && action.payload && action.payload.queryId && !action.error) {
                index = state.findIndex(query => query._id === action.payload.queryId)
                if (index >= 0) {
                    state[index].lastReadAt = new Date().toISOString()
                }
            }
            return state.slice(0)

        case actions.CLEAN_QUERIES:
            return defaultState

        default:
            return state
    }
}
