import * as actions from '../actions'

const defaultState = {}

export default function skip(state = defaultState, action) {
    switch (action.type) {
        case actions.CHANGED_SKIP:
            const skipData = {
                [action.payload.list]: action.payload.skip
            }
            state = Object.assign({}, state, skipData)
            return state
        case actions.CLEAN_CHANGED_SKIP:
            state = {}
            return state
        default:
            return state
    }
}
