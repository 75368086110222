import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { Link, browserHistory } from 'react-router'
import { Alert, ButtonToolbar, Button } from 'react-bootstrap'
import List from './../common/List'
import Navigation from '../Navigation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.less'

class Apps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataColumnsNames: [
                'Nombre', 'Acciones'
            ],
            notification: {
                data: null,
                status: null
            },
            loading: false
        }
    }

    appSelect(app, e) {
        if (e) {
            e.preventDefault()
        }

        const self = this
        self.props.actions.appSelect(app).then(() => {
            browserHistory.push(`/manager/customer/${app.codename}`)
        })
    }

    editApp(appId) {
        const self = this
        self.props.actions.appCleanOne().then(function() {
            browserHistory.push(`/manager/app/edit/${appId}`)
        })
    }

    appReset(appCodename) {
        const self = this
        const appsList = self.refs.Apps.getWrappedInstance()
        return appsList.openModal({
            title: 'Resetear app',
            description: '¿Está seguro que desea resetear ' + appCodename + '?\nSe borrará TODO lo relacionado con Consultas, Origenes, Resoluciones, Horarios, Horarios no disponibles y Topics',
            extraBody: <div>
                <br/>
                Por favor, escriba debajo: 'Si, estoy seguro de querer resetear {appCodename}'<br/>
                <input id="appResetConfirm" type="text" size="40"/>
            </div>,
            button: {
                cancel: {
                    title: 'Cancelar',
                    className: 'btn-default'
                },
                success: {
                    title: 'Resetear',
                    className: 'btn-danger',
                    onClick: self.confirmResetApp,
                    onClickBind: self
                }
            }
        }, {
            appCodename: appCodename
        })
    }

    appNewToken(appCodename) {
        const self = this
        const appsList = self.refs.Apps.getWrappedInstance()
        return appsList.openModal({
            title: 'Nuevo token',
            description: '¿Está seguro que desea crear un nuevo token para esta app?\nEl token anterior dejará de funcionar en forma inmediata',
            button: {
                cancel: {
                    title: 'Cancelar',
                    className: 'btn-default'
                },
                success: {
                    title: 'Crear nuevo token',
                    className: 'btn-danger',
                    onClick: self.confirmNewTokenApp,
                    onClickBind: self
                }
            }
        }, {
            appCodename: appCodename
        })
    }

    confirmResetApp(metadata) {
        const self = this
        const appsList = self.refs.Apps.getWrappedInstance()
        const appResetConfirmInput = document.getElementById('appResetConfirm')

        if (appResetConfirmInput.value === 'Si, estoy seguro de querer resetear ' + metadata.appCodename) {
            self.props.actions.appReset(metadata.appCodename).catch(() => {
                // do nothing
            }).then(() => {
                return appsList.refreshData(true)
            }).then(() => {
                return appsList.closeModal()
            })
        } else {
            alert('El texto de confirmación no es el correcto')
        }
    }

    confirmNewTokenApp(metadata) {
        const self = this
        const appsList = self.refs.Apps.getWrappedInstance()
        let newToken = null
        self.props.actions.appNewToken(metadata.appCodename).catch(() => {
            // do nothing
        }).then(data => {
            if (data.payload && data.payload.data && data.payload.data.token) {
                newToken = data.payload.data.token
            }
            return appsList.closeModal()
        }).then(() => {
            if (newToken) {
                return appsList.openModal({
                    title: 'Nuevo token',
                    description: 'El nuevo token es: ' + newToken,
                    button: {
                        cancel: {
                            title: 'Cerrar',
                            className: 'btn-default'
                        }
                    }
                })
            }
        })
    }

    appDelete(appId) {
        const self = this
        const appsList = self.refs.Apps.getWrappedInstance()
        return appsList.openModal({
            title: 'Eliminar app',
            description: '¿Está seguro que desea eliminar esta app?',
            button: {
                cancel: {
                    title: 'Cancelar',
                    className: 'btn-default'
                },
                success: {
                    title: 'Eliminar',
                    className: 'btn-danger',
                    onClick: self.confirmDeleteApp,
                    onClickBind: self
                }
            }
        }, {
            appId: appId
        })
    }

    confirmDeleteApp(metadata) {
        const self = this
        const appsList = self.refs.Apps.getWrappedInstance()
        self.props.actions.appDelete(metadata.appId).catch(() => {
            // do nothing
        }).then(() => {
            return appsList.refreshData(true)
        }).then(() => {
            return appsList.closeModal()
        })
    }

    clearNotification() {
        const self = this
        self.setState({
            notification: {
                data: null,
                status: null
            }
        })
    }

    dataObjectProcess(app) {
        const self = this
        const row = []
        row.push(
            <a href={ '/manager/' + app.codename + '/dashboard' } onClick={ self.appSelect.bind(self, app) }>{ app.name }</a>
        )
        row.push(
            <ButtonToolbar>
                <Button title="Editar" bsStyle="primary" onClick={self.editApp.bind(self, app._id)}>
                    <FontAwesomeIcon icon="edit" />
                </Button>
                <Button title="Reiniciar token" bsStyle="warning" onClick={self.appNewToken.bind(self, app.codename)}>
                    <FontAwesomeIcon icon="redo" /> &nbsp; Token
                </Button>
                <Button title="Reiniciar app" bsStyle="danger" onClick={self.appReset.bind(self, app.codename)}>
                    <FontAwesomeIcon icon="redo" /> &nbsp; App
                </Button>
                <Button title="Eliminar" bsStyle="danger" onClick={self.appDelete.bind(self, app._id)}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button>
            </ButtonToolbar>
        )
        return row
    }

    render() {
        const self = this

        const itemsProcessed = []
        if (self.props.apps && self.props.apps.objects) {
            self.props.apps.objects.forEach(function(app) {
                itemsProcessed.push(self.dataObjectProcess(app))
            })
        }

        const description = (
            <div className="row">
                <div className="col-xs-12">
                    <ButtonToolbar className="pull-left">
                        <Link className="btn btn-primary" to={'/manager/app/add'}>
                            <FontAwesomeIcon icon="plus" />&nbsp;App
                        </Link>
                        <Link className="btn btn-primary" to="/manager/stats">
                            <FontAwesomeIcon icon="chart-bar" />&nbsp;Stats
                        </Link>
                    </ButtonToolbar>
                </div>
            </div>
        )

        return (
            <div>
                <Navigation
                    title="Superadmin"
                />
                {(this.state.notification.data && this.state.notification.status) && <Alert bsStyle={this.state.notification.status} onDismiss={self.clearNotification.bind(self)}>
                    <strong>{ this.state.notification.data }</strong>
                </Alert>}
                <div className="container">
                    <List
                        ref="Apps"
                        items={itemsProcessed}
                        name="CS Apps"
                        dataColumnsNames={self.state.dataColumnsNames}
                        description={description}
                        getObjects={self.props.actions.appGet.bind(this, {sort: { name: 1 }})}
                        clearObjects={self.props.actions.appClean.bind(this)}
                        limit={100}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        profile: state.profile,
        apps: state.apps,
        routing: state.routing
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Apps)
