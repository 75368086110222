import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {

} from './styles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Recorder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            recording: false
        }

        this.recorder

        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
    }

    start() {
        const promise = new Promise((resolve, reject) => {
            // Request permissions to record audio
            navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
                this.recorder = new MediaRecorder(stream)

                // Set record to <audio> when recording will be finished
                this.recorder.addEventListener('dataavailable', e => {
                    this.props.saveRecord(e.data)
                })

                // Start recording
                this.recorder.start()

                resolve()
            })
        })

        this.setState({
            recording: true
        }, () => promise)
    }

    stop() {
        try {
            const promise = new Promise((resolve, reject) => {
                // Stop recording
                this.recorder.stop()

                // Remove “recording” icon from browser tab
                this.recorder.stream.getTracks().forEach(i => i.stop())

                resolve()
            })

            return promise.then(() => {
                this.setState({
                    recording: false
                })
            })
        } catch (error) {
            this.setState({
                recording: false
            })
        }
    }

    render() {
        const { recording } = this.state

        return (
            <div>
                <FontAwesomeIcon icon={recording ? 'stop' : 'microphone-alt'} onClick={recording ? this.stop : this.start} />
            </div>
        )
    }
}

Recorder.propTypes = {
    saveRecord: PropTypes.func.isRequired
}

export default Recorder
