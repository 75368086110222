import styled from 'styled-components'

export const Title = styled.h4`
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-style: normal;
    border-bottom: 2px solid #F4D345;;
    padding-bottom: 10px;
    margin-bottom: 20px;
`
