import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-timezone'
import { withLocalize, Translate } from 'react-localize-redux'

import {
    Wrapper,
    Container,
    Message,
    Avatar,
    ExtraData,
    Date,
    Line
} from './styles'
import Data from '../Message'

class MessageContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showWebView: false
        }
        this.toggleWebView = this.toggleWebView.bind(this)
    }

    componentWillMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    _setState(state) {
        return new Promise((resolve) => {
            if (this._isMounted) {
                this.setState(state, resolve)
            } else {
                resolve()
            }
        })
    }


    toggleWebView() {
        this.setState({
            showWebView: !this.state.showWebView
        })
    }

    render() {
        const { data, logo, color, timezone, activeLanguage, checkScrollToBottom, chattonicURL, speech, queryMessageId, queryStatus } = this.props
        let date = moment.tz(data.createdAt, timezone).locale(activeLanguage.code).format('L LT')
        if (moment.utc(data.createdAt).startOf('day').isSame(moment.utc().startOf('day'))) {
            date = moment.tz(data.createdAt, timezone).locale(activeLanguage.code).format('HH:mm')
        } else if (moment.utc(data.createdAt).startOf('week').isSame(moment.utc().startOf('week'))) {
            date = moment.tz(data.createdAt, timezone).locale(activeLanguage.code).format('dddd HH:mm')
        }
        const isOperator = data && data.operatorData && data.operatorData.name && data.operatorData.name.familyName && data.operatorData.name.givenName
        return (
            <Wrapper lastGroupMessage={data.lastGroupMessage} from={data.from} key={ `message-${data.updatedAt}`}>
                {(data.to === 'self' || data.firstGroupMessage) ? (
                    <Avatar lastGroupMessage={data.lastGroupMessage} from={data.from} logo={logo} color={color} userColor={data.userColor}>
                        {data.from === 'self' && isOperator && (
                            <div>
                                <div className='displayName'>{`${data.operatorData.name.givenName.charAt(0)} ${data.operatorData.name.familyName}`}</div>
                                <span className='tipOver'>{`${data.operatorData.name.givenName} ${data.operatorData.name.familyName} ${data.operatorData.position ? '| ' + data.operatorData.position : ''}  ${data.operatorData.area ? '| ' + data.operatorData.area : ''}`}</span>
                            </div>
                        )}
                    </Avatar>
                ) : data.operatorData ? (
                    <Line color={data.userColor}></Line>
                ) : ''}
                <Container firstGroupMessage={data.firstGroupMessage} from={data.from} operatorData={data.operatorData}>
                    <Message isText={['text', 'formData'].indexOf(data.type) >= 0} from={data.from} operatorData={data.operatorData} isQueryMessage={data.isPublic && queryMessageId && queryMessageId === data.id} queryStatus={queryStatus}>
                        <Data
                            data={data}
                            showWebView={this.state.showWebView}
                            toggleWebView={this.toggleWebView}
                            checkScrollToBottom={checkScrollToBottom}
                            chattonicURL={chattonicURL}
                            speech={speech}
                            activeLanguageCode={activeLanguage.code}
                        />
                    </Message>
                    <ExtraData from={data.from}>
                        <Date>{date}</Date>
                    </ExtraData>
                </Container>
            </Wrapper>
        )
    }
}

MessageContainer.propTypes = {
    data: PropTypes.object,
    logo: PropTypes.string,
    color: PropTypes.string,
    timezone: PropTypes.string,
    checkScrollToBottom: PropTypes.func,
    chattonicURL: PropTypes.string.isRequired,
    speech: PropTypes.func,
    queryMessageId: PropTypes.string,
    queryStatus: PropTypes.string
}

export default withLocalize(MessageContainer)
