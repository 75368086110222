import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import Spinner from '../Spinner'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../state/actions'
import isEqual from 'lodash/isEqual'
import Form from 'react-formal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'

import {
    Modal,
    Button,
    ButtonToolbar,
    FormGroup
} from 'react-bootstrap'

class List extends Component {
    constructor(props) {
        super(props)
        const location = props.routing && (props.routing.location || props.routing.locationBeforeTransitions)

        this.state = {
            loading: false,
            skip: (location && location.query && location.query.skip) ? parseInt(location.query.skip) : (props.globalSkip[location.pathname] || 0),
            limit: (location && location.query && location.query.limit) ? parseInt(location.query.limit) : (props.limit || props.globalLimit || 10),
            modal: {
                data: {
                    title: null,
                    description: null,
                    extraBody: null,
                    input: {
                        fields: [],
                        modelSchema: {}
                    },
                    button: {
                        cancel: {
                            title: null,
                            className: null
                        },
                        success: {
                            title: null,
                            className: null,
                            onClick: null,
                            onClickBind: null
                        },
                        submit: {
                            title: null,
                            className: null,
                            onClick: null,
                            onClickBind: null
                        },
                        moreButtons: []
                    }
                },
                metadata: {},
                show: false
            }
        }

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.goFirstPage = this.goFirstPage.bind(this)
        this.goPrevPage = this.goPrevPage.bind(this)
        this.goNextPage = this.goNextPage.bind(this)
        this.limitChanged = this.limitChanged.bind(this)
        this.refreshData = this.refreshData.bind(this)
    }

    async componentDidMount() {
        const self = this
        self.setState({ loading: true })
        try {
            const params = Object.assign({}, self.props.params)
            params.skip = self.state.skip * self.state.limit
            params.limit = self.state.limit
            params.skipDirection = 'refresh'
            await self.props.getObjects(params)
        } finally {
            self.setState({ loading: false })
        }
    }

    componentDidUpdate() {
        const self = this
        const location = self.props.location
        if (location) {
            const params = Object.assign({}, location.query)
            params['limit'] = self.state.limit.toString()
            params['skip'] = self.state.skip.toString()
            if (self.props.params && self.props.params.query) {
                const mapQueryParams = Object.keys(self.props.params.query)
                mapQueryParams.forEach(param => {
                    if (typeof self.props.params.query[param] !== 'object') {
                        if (typeof self.props.params.query[param] === 'boolean') {
                            self.props.params.query[param] = self.props.params.query[param].toString()
                        }
                        params[param] = self.props.params.query[param]
                    }
                })
            }

            if (!isEqual(location.query, params)) {
                location.query = params
                browserHistory.replace(location)
            }
        }
    }

    openModal(data, metadata) {
        const self = this
        const currentState = self.state
        currentState.modal.data = data
        currentState.modal.metadata = metadata
        currentState.modal.show = true
        return new Promise(function(resolve, reject) {
            self.setState(currentState, resolve)
        })
    }

    closeModal() {
        const self = this
        const currentState = self.state
        currentState.modal.show = false
        return new Promise(function(resolve, reject) {
            self.setState(currentState, resolve)
        })
    }

    async goPage(skipDirection, limit) {
        const self = this
        let newSkip = 0
        if (skipDirection === 'previous') {
            newSkip = self.state.skip - 1
            if (newSkip < 0) {
                newSkip = 0
            }
        } else if (skipDirection === 'next') {
            newSkip = self.state.skip + 1
        } else if (skipDirection === 'refresh') {
            newSkip = self.state.skip
        }
        const limitNow = self.state.limit
        self.setState({ skip: newSkip, limit: limit || self.state.limit, loading: true })

        try {
            const skipData = {}
            skipData[self.props.routing.locationBeforeTransitions.pathname] = newSkip
            await self.props.actions.changedSkip(Object.assign(self.props.globalSkip, skipData))
            if (limit && (limitNow !== limit)) {
                await self.props.actions.changedGlobalLimit(limit)
            }

            if (skipDirection === 'first') {
                await self.props.clearObjects()
            }

            const params = Object.assign({}, self.props.params)
            params.skip = self.state.skip * self.state.limit
            params.limit = self.state.limit
            params.skipDirection = skipDirection
            await self.props.getObjects(params)
        } finally {
            self.setState({ loading: false })
        }
    }

    goFirstPage() {
        const self = this
        self.goPage('first')
    }

    goPrevPage() {
        const self = this
        self.goPage('previous')
    }

    goNextPage() {
        const self = this
        self.goPage('next')
    }

    limitChanged(e) {
        const self = this
        self.goPage('first', parseInt(e.target.value))
    }

    refreshData(useSkip) {
        const self = this
        if (useSkip) {
            self.goPage('refresh')
        } else {
            self.goPage('first')
        }
    }

    render() {
        const self = this
        let listTable = null
        const pageSizes = [10, 25, 50, 100]
        if (process.env.MT_ENV === 'development') {
            pageSizes.unshift(1)
        }

        if (self.props.items) {
            let listTableBody = null
            if (self.state.loading) {
                listTableBody = (
                    <tbody>
                        <tr>
                            <td colSpan={ self.props.dataColumnsNames.length }>
                                <Spinner />
                            </td>
                        </tr>
                    </tbody>
                )
            } else if (self.props.items.length) {
                listTableBody = (
                    <tbody>
                        {
                            self.props.items.map(function (object, objectIndex) {
                                const rowClassName = self.props.itemRowClassName && self.props.itemRowClassName(object, objectIndex)
                                const rowStyle = self.props.itemRowStyle
                                return (
                                    <tr key={'row_' + objectIndex} ref={'row_' + objectIndex} className={ rowClassName } style={ rowStyle }>
                                        {
                                            object.map(function(columnValue, columnIndex) {
                                                return (
                                                    <td key={'column_' + columnIndex} ref={'column_' + columnIndex}>
                                                        { columnValue }
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                )
            } else {
                listTableBody = (
                    <tbody>
                        <tr>
                            <td colSpan={ self.props.dataColumnsNames.length }>
                                <Translate id="list.noData" />
                            </td>
                        </tr>
                    </tbody>
                )
            }

            const listTableHeader = (
                <thead>
                    <tr>
                        {
                            self.props.dataColumnsNames.map(function(columnName, columnIndex) {
                                let columnStyle = {...self.props.tableHeaderStyle}
                                if (self.props.fixedHeader) {
                                    Object.assign(columnStyle, {
                                        position: 'sticky',
                                        top: '0',
                                        background: 'white'
                                    })
                                }
                                return (
                                    <th key={'header_' + columnIndex} style={columnStyle}>{ columnName }</th>
                                )
                            })
                        }
                    </tr>
                </thead>
            )

            let listTableFooterPrevButton = null
            let listTableFooterNextButton = null
            let listTableFooterFirstPageButton = null

            if (self.props.allowPagination) {
                if (self.state.skip > 0) {
                    listTableFooterPrevButton = (
                        <Button bsStyle="default" onClick={self.goPrevPage}>
                            <Translate id="list.prev" data={{
                                limit: self.state.limit,
                                icon: (<FontAwesomeIcon icon="angle-left" />)
                            }} />
                        </Button>
                    )
                    listTableFooterFirstPageButton = (
                        <Button bsStyle="default" onClick={self.goFirstPage}>
                            <Translate id="list.start" data={{
                                icon: (<FontAwesomeIcon icon="angle-double-left" />)
                            }} />
                        </Button>
                    )
                }
                if (self.state.limit === self.props.items.length) {
                    listTableFooterNextButton = (
                        <Button bsStyle="default" onClick={self.goNextPage}>
                            <Translate id="list.next" data={{
                                limit: self.state.limit,
                                icon: (<FontAwesomeIcon icon="angle-right" />)
                            }} />
                        </Button>
                    )
                }
            }

            const listTableFooter = (
                <tfoot>
                    <tr>
                        <td colSpan={ self.props.dataColumnsNames.length }>
                            <ButtonToolbar className="pull-left">
                                { listTableFooterFirstPageButton }
                                { listTableFooterPrevButton }
                                { listTableFooterNextButton }
                            </ButtonToolbar>
                            <div className="pull-right">
                                {self.props.displayLimit && <label>
                                    <Translate id="list.show" />
                                    &nbsp;&nbsp;
                                    <select className="form-control input-sm" defaultValue={ self.state.limit } onChange={ self.limitChanged }>
                                        {
                                            pageSizes.map(function(value) {
                                                return (
                                                    <option value={ value } key={ value }>{ value }</option>
                                                )
                                            })
                                        }
                                    </select>
                                </label>}
                                &nbsp;
                                <Button bsStyle="default" onClick={self.refreshData.bind(self, false)} disabled={self.state.loading}>
                                    <FontAwesomeIcon icon="sync" spin={self.state.loading} />
                                </Button>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            )
            listTable = (
                <table className="table table-striped table-bordered table-hover table-responsive" style={{ tableLayout: self.props.fixedHeader ? 'fixed' : 'auto'}}>
                    { listTableHeader }
                    { listTableBody }
                    { (self.props && !self.props.hideFooter) ? listTableFooter : null }
                </table>
            )
        } else {
            listTable = (
                <Spinner />
            )
        }

        let modalFooterCancelButton
        let modalFooterSuccessButton
        let modalFooterMoreButtons = false

        if (self.state.modal.data && self.state.modal.data.button && self.state.modal.data.button.cancel && self.state.modal.data.button.cancel.title) {
            modalFooterCancelButton = <Button className={ self.state.modal.data.button.cancel.className } onClick={ self.closeModal.bind(self) }>{ self.state.modal.data.button.cancel.title }</Button>
        }
        if (self.state.modal.data && self.state.modal.data.button && self.state.modal.data.button.success && self.state.modal.data.button.success.title) {
            let onClickCall
            if (self.state.modal.data.button.success && self.state.modal.data.button.success.onClick && self.state.modal.data.button.success.onClickBind) {
                onClickCall = self.state.modal.data.button.success.onClick.bind(self.state.modal.data.button.success.onClickBind, self.state.modal.metadata)
            }
            modalFooterSuccessButton = <Button className={ self.state.modal.data.button.success.className } onClick={ onClickCall }>{ self.state.modal.data.button.success.title }</Button>
        }

        if (self.state.modal.data && self.state.modal.data.button && self.state.modal.data.button.moreButtons && self.state.modal.data.button.moreButtons.length) {
            modalFooterMoreButtons = true
        }

        const modal = <div className="static-modal">
            <Modal show={self.state.modal.show} onHide={ self.closeModal.bind(self) }>
                <Modal.Header closeButton>
                    <Modal.Title>{ self.state.modal.data && self.state.modal.data.title }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <strong>{ self.state.modal.data && self.state.modal.data.description }</strong>
                    { self.state.modal.data && self.state.modal.data.extraBody }
                    {(self.state.modal.data && self.state.modal.data.input && self.state.modal.data.input.fields && self.state.modal.data.input.fields.length && self.state.modal.data.button.submit && (
                        <div className="row">
                            <div className="col-xs-12">
                                <Form schema={self.state.modal.data.input.modelSchema} onSubmit={self.state.modal.data.button.submit.onClick.bind(self.state.modal.data.button.submit.onClickBind, self.state.modal.metadata)}>
                                    { self.state.modal.data.input.fields.map(field => {
                                        return (
                                            <div className="row">
                                                <div className="col-xs-12 col-md-6">
                                                    <FormGroup>
                                                        <Form.Field name={field} className="form-control" />
                                                        <Form.Message for={field}/>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <Form.Button type="submit" className={self.state.modal.data.button.submit.className}>
                                        {self.state.modal.data.button.submit.title}
                                    </Form.Button>
                                </Form>
                            </div>
                        </div>
                    )) || null}
                </Modal.Body>
                <Modal.Footer>
                    { modalFooterCancelButton }
                    { modalFooterSuccessButton }
                    { modalFooterMoreButtons && self.state.modal.data.button.moreButtons.map(function(button, index) {
                        if (button.title && button.className) {
                            let onClickCall
                            if (button.onClick && button.onClickBind) {
                                onClickCall = button.onClick.bind(button.onClickBind, self.state.modal.metadata)
                            }
                            return <Button key={'moreButtons-' + index} className={button.className} onClick={ onClickCall }>{button.title}</Button>
                        }
                    }) }
                </Modal.Footer>
            </Modal>
        </div>

        return (
            <div>
                <div className="row" style={{ display: (self.props.name && self.props.name.length) ? 'block' : 'none' }}>
                    <div className="col-lg-12">
                        <h1 className="page-header" style={{ marginTop: 'auto' }}>{ self.props.name }</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        { self.props.listHeader ?
                            <div className="row">
                                <div className="col-sm-12">
                                    { self.props.listHeader }
                                </div>
                            </div> : <div/>
                        }
                        {(self.props.description || self.props.listHeader) &&
                            <div className="panel panel-default">
                                <div className="panel-heading">
                                    { self.props.description }
                                </div>
                                <div className="panel-body">
                                    <div className="dataTable_wrapper">
                                        <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                            <div className="row">
                                                <div className="col-sm-12" style={{ overflowY: 'auto' }}>
                                                    { listTable }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> ||
                            <div className="dataTable_wrapper">
                                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                                    <div className="row">
                                        <div className="col-sm-12" style={{ overflowY: 'auto', height: self.props.fixedHeader ? '65vh' : 'auto'  }}>
                                            { listTable }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                { modal }
            </div>
        )
    }
}

List.propTypes = {
    items: PropTypes.array,
    itemRowClassName: PropTypes.func,
    itemRowStyle: PropTypes.object,
    name: PropTypes.string,
    description: PropTypes.element,
    dataColumnsNames: PropTypes.array.isRequired,
    lastColumnWidth: PropTypes.string,
    listHeader: PropTypes.element,
    getObjects: PropTypes.func.isRequired,
    clearObjects: PropTypes.func.isRequired,
    allowPagination: PropTypes.bool,
    params: PropTypes.object,
    limit: PropTypes.number,
    displayLimit: PropTypes.bool,
    globalLimit: PropTypes.number,
    globalSkip: PropTypes.object,
    hideFooter: PropTypes.bool,
    tableHeaderStyle: PropTypes.object,
    fixedHeader: PropTypes.bool
}

List.defaultProps = {
    lastColumnWidth: 'col-sm-1',
    allowPagination: true,
    displayLimit: true
}

function mapStateToProps(state) {
    return {
        routing: state.routing,
        globalLimit: state.globalLimit,
        globalSkip: state.globalSkip
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(List)
