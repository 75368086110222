import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import * as project from '../../utils/project'
import List from './../common/List'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate, withLocalize } from 'react-localize-redux'
import { ButtonOffline } from './styles'

class Users extends Component {
    constructor(props) {
        super(props)
        const dataColumnsNames = [
            'users.dataColumnsNames.user', 'users.dataColumnsNames.email', 'users.dataColumnsNames.role', 'users.dataColumnsNames.origin', 'users.dataColumnsNames.actions'
        ]
        if (this.props.app && this.props.app.userStatus) {
            dataColumnsNames.unshift('users.dataColumnsNames.status')
        }
        this.state = {
            dataColumnsNames: dataColumnsNames,
            showModal: false
        }

        this.props.actions.userGet = this.props.actions.userGet.bind(this, this.props.params.appCodename)
        this.props.actions.userClean = this.props.actions.userClean.bind(this)
        this.confirmDeleteData = this.confirmDeleteData.bind(this)
    }

    async componentWillMount() {
        try{
            await this.props.actions.originsGet(this.props.params.appCodename)
        } catch {
        }

    }

    deleteData(objectId) {
        const self = this
        const list = self.list.getWrappedInstance()
        return list.openModal({
            title: this.props.translate('users.delete.title'),
            description: this.props.translate('users.delete.description'),
            button: {
                cancel: {
                    title: this.props.translate('buttons.cancel'),
                    className: 'btn-default'
                },
                success: {
                    title: this.props.translate('buttons.delete'),
                    className: 'btn-danger',
                    onClick: self.confirmDeleteData,
                    onClickBind: self
                }
            }
        }, {
            objectId: objectId
        })
    }

    confirmDeleteData(metadata) {
        const list = this.list.getWrappedInstance()
        let promise = this.props.actions.userDelete(this.props.params.appCodename, metadata.objectId)

        promise = promise.catch(() => {
            // do nothing
        }).then(() => {
            return list.refreshData(true)
        }).then(() => {
            return list.closeModal()
        }).then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    async changeStatusUser(status, userId) {
        const list = this.list.getWrappedInstance()
        this.setState({ loading: true })
        await this.props.actions.changeStatus(this.props.app.codename, status, userId)
        await list.refreshData(true)
        this.setState({ loading: false })
    }

    dataObjectProcess(user) {
        const { loading } = this.state
        const { origins, app } = this.props
        const row = []
        let profile = 'user'

        switch (user.profile) {
            case 0:
                profile = 'user'
                break
            case 500:
                profile = 'operator'
                break
            case 600:
                profile = 'coordinator'
                break
            case 1000:
                profile = 'admin'
                break
            case 2000:
                profile = 'superAdmin'
                break
        }

        if (this.props.app && this.props.app.userStatus) {
            row.push(
                <div>
                    {user.status === 'online' ? <Translate id={`users.online`} /> : (user.status === 'away') ? <Translate id={`users.away`} /> : <Translate id={`users.offline`} />}
                    {app && app.userStatus && user && user.status && user.status !== 'offline' && user.profile <= 500 && this.props.profile && this.props.profile.profile >= 600  ?
                        <ButtonOffline type="button" title={this.props.translate('buttons.offline')} onClick={this.changeStatusUser.bind(this, 'offline', user._id)} className="btn btn-danger" disabled={loading}>
                            <FontAwesomeIcon icon={'ban'}/>
                    </ButtonOffline> : ''}
                </div>
            )
        }
        row.push(user.displayName)

        row.push(user.emails[0].value)

        row.push(
            <Translate id={`users.profiles.${profile}`} />
        )

        let userOrigins
        if (origins && origins.objects && origins.objects.length) {
            if (user.origins && user.origins.length) {
                user.origins.forEach(userOrigin => {
                    origins.objects.forEach(origin => {
                        if (userOrigin === origin._id) {
                            userOrigins = userOrigins ? `${userOrigins} || ${origin.name}` : `${origin.name}`
                        }
                    })
                })
            } else {
                userOrigins = this.props.translate('origins.all')
            }
        } else {
            userOrigins = this.props.translate('origins.none')
        }

        row.push(userOrigins)

        let userId = user._id === this.props.profile._id ? 'me' : user._id
        const buttonActions = (
            <ButtonToolbar>
                {this.props.profile && (this.props.profile.profile >= 1000 || this.props.profile.authAbmUser && this.props.profile.authAbmUser.editUser) ?
                <Link title={this.props.translate('buttons.edit')} to={`/manager/${this.props.params.appCodename}/user/edit/${userId}`} type="button" className="btn btn-primary" disabled={loading}>
                    <FontAwesomeIcon icon="edit" />
                </Link> : '' }
                {this.props.profile && (this.props.profile.profile >= 1000 || this.props.profile.authAbmUser && this.props.profile.authAbmUser.deleteUser) ?
                <Button title={this.props.translate('buttons.delete')} onClick={this.deleteData.bind(this, userId)} type="button" className="btn btn-danger" disabled={loading}>
                    <FontAwesomeIcon icon="trash-alt" />
                </Button> : '' }
            </ButtonToolbar>
        )

        if (buttonActions) {
            row.push(buttonActions)
        }
        return row
    }

    render() {
        const { loading } = this.state
        const itemsProcessed = []

        if (this.props.users && this.props.users.objects.length) {
            this.props.users.objects.forEach(user => {
                itemsProcessed.push(this.dataObjectProcess(user))
            })
        }

        const description = (
            <div className="row">
                <div className="col-xs-12">
                    {(this.props.profile && this.props.profile.profile && this.props.profile.profile >= 1000 || this.props.profile && this.props.profile.authAbmUser && this.props.profile.authAbmUser.generateUser) && (
                        <ButtonToolbar className="pull-left">
                            <Link to={`/manager/${this.props.params.appCodename}/user/add`} type="button" className="btn btn-primary pull-right" disabled={loading}>
                                <FontAwesomeIcon icon="plus" />&nbsp;&nbsp;<Translate id="users.profiles.user" />
                            </Link>
                        </ButtonToolbar>
                    )}
                </div>
            </div>
        )

        return (
            <div>
                <List ref={(list) => { this.list = list }}
                    items={itemsProcessed}
                    name={this.props.translate('users.titles.list')}
                    dataColumnsNames={this.state.dataColumnsNames.map(dataColumnsNames => this.props.translate(dataColumnsNames))}
                    description={description}
                    getObjects={this.props.actions.userGet}
                    clearObjects={this.props.actions.userClean}
                    routing={this.props.routing}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.users,
        profile: state.profile,
        routing: state.routing,
        app: state.app,
        origins: state.origins
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Users))
