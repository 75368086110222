import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import get from 'lodash/get'
import { Translate, withLocalize } from 'react-localize-redux'

import {
    FormGroup,
    ControlLabel,
    ButtonToolbar,
    Button,
    Alert
} from 'react-bootstrap'

class UserAdd extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAllQueries: true,
            loading: false,
            error: false,
            formValues: null,
            appOrigins: [],
            userOrigins: []
        }

        yup.addMethod(yup.string, 'samePassword', this.samePassword)
        yup.addMethod(yup.string, 'hasANumber', this.hasANumber)
        yup.addMethod(yup.string, 'hasAnUpperCaseLetter', this.hasAnUpperCaseLetter)

        Form.addInputTypes(types)

        this.addUser = this.addUser.bind(this)
        this.goBack = this.goBack.bind(this)
        this.toggleShowAllQueries = this.toggleShowAllQueries.bind(this)
    }

    componentWillMount() {
        return this.props.actions.originsGet(this.props.params.appCodename).then(res => {
            if (res.payload.data && res.payload.data.objects) {
                this.setState({
                    appOrigins: res.payload.data.objects
                })
            }
        }).catch(() => {
            // do nothing
        })
    }

    getUserSchema() {
        const { formValues } = this.state

        const modelSchema = {
            username: yup.string().lowercase().email(this.props.translate('validations.email', { propName: 'username' })).required(this.props.translate('validations.required', { propName: 'username' })),
            passwords: yup.object({
                password: yup.string()
                    .required(this.props.translate('validations.required', { propName: 'password' }))
                    .min(8, this.props.translate('validations.leastCharacters', { count: 8 }))
                    .hasANumber(this.props.translate('validations.leastCharacters', { count: 1 }))
                    .hasAnUpperCaseLetter(this.props.translate('validations.leasUppercase', { count: 1 })),
                validation: yup.string()
                    .required(this.props.translate('validations.required', { propName: 'password' }))
                    .samePassword(this.props.translate('validations.doNotMatch', { propName: 'passwords' }), formValues && formValues.passwords && formValues.passwords.password || '')
            }),
            name: yup.object({
                first: yup.string().required(this.props.translate('validations.required', { propName: 'firstName' })),
                last: yup.string().required(this.props.translate('validations.required', { propName: 'lastName' }))
            }),
            profile: yup.number().required(this.props.translate('validations.select', { propName: 'profile' })),
            operator: yup.object({
                showAllQueries: yup.bool().default(true)
            }),
            position: yup.string(),
            area: yup.string(),
            externalId: yup.string()
        }

        if (!get(formValues, 'operator.showAllQueries')) {
            modelSchema.origins = yup.array().of(yup.string()).required(this.props.translate('validations.selectMultiple', { propName: 'origins' }))
        }
        return yup.object(modelSchema)
    }

    samePassword(msg, password) {
        return this.test('samePassword', msg || '${path} must be the same password', value => {
            return value === password
        })
    }

    hasANumber(msg) {
        return this.test('hasANumber', msg || '${path} must contain a number', value => {
            return value && value.match(/\d+/g) && value.match(/\d+/g).length
        })
    }

    hasAnUpperCaseLetter(msg) {
        return this.test('hasAnUpperCaseLetter', msg || '${path} must contain a capital letter', value => {
            return value && value !== value.toLowerCase()
        })
    }

    goBack() {
        browserHistory.push({
            pathname: `/manager/${this.props.params.appCodename}/user`
        })
    }

    toggleShowAllQueries() {
        this.setState({
            showAllQueries: !this.state.showAllQueries
        })
    }

    getProfileName(profile) {
        switch (profile) {
            case 0:
                return 'Usuario'
            case 500:
                return 'Operador'
            case 600:
                return 'Coordinador'
            case 1000:
                return 'Admin'
            default:
                return profile
        }
    }

    addUser(formValues) {
        const { profile } = this.props

        const data = {
            username: formValues.username,
            password: formValues.passwords.password,
            displayName: `${formValues.name.first} ${formValues.name.last}`,
            name: {
                givenName: formValues.name.first,
                familyName: formValues.name.last
            },
            emails: [
                {
                    value: formValues.username,
                    type: 'work'
                }
            ],
            photos: [],
            profile: 500,
            origins: (!formValues.operator.showAllQueries && this.state.userOrigins && this.state.userOrigins.length) ? this.state.userOrigins.map(origen => origen._id) : [],
            operator: {
                showAllQueries: formValues.operator.showAllQueries
            },
            area: formValues.area,
            position: formValues.position,
            externalId: formValues.externalId
        }

        if (profile && profile.profile && profile.profile >= 1000 || profile.authAbmUser && profile.authAbmUser.generateUser) {
            data.profile = formValues.profile
        }

        const promise = this.props.actions.userAdd(this.props.params.appCodename, data).then(action => {
            if (action && action.payload && action.payload.status === 201) {
                this.goBack()
            } else {
                this.setState({
                    loading: false,
                    error: 'users.errors.add'
                })
            }
        }).catch(error => {
            const code = error && error.error && error.error.response && error.error.response.data && error.error.response.data.error && error.error.response.data.error.code
            this.setState({
                loading: false,
                error: code === 202 ? 'users.errors.exists' : 'users.errors.add'
            }, () => {
                if (code === 202) {
                    const { app } = this.props
                    return this.props.actions.sendEmail({
                        to: 'info@chat-tonic.com',
                        subject: 'Recuperar usuario',
                        text: `${profile.displayName} (${profile.username}) esta queriendo crear un usuario en la app ${app.name} (${app.codename}) de customer-service, pero no se pudo ya que el mismo puede estar borrado o ya pertenece a otra app.\n\nDatos del usuario:\n\nUsername: ${data.username}\nNombre: ${data.name.givenName}\nApellido: ${data.name.familyName}\nProfile: ${this.getProfileName(data.profile)}`
                    })
                }
            })
        })

        this.setState({
            loading: true,
            error: false
        }, () => promise)
    }

    render() {
        const { appOrigins, loading, error, showAllQueries } = this.state

        const origins = (appOrigins && appOrigins.length && !showAllQueries) ? (
            <div className="row">
                <div className="col-xs-12">
                    <FormGroup>
                        <ControlLabel>
                            <Translate id="users.propNames.origins" />
                        </ControlLabel>
                        <Form.Field
                            name="origins"
                            type="multiselect"
                            textField="name"
                            data={appOrigins}
                            onChange={value => this.setState({ userOrigins: value })}
                            disabled={loading}
                        />
                        <Form.Message for="origins" />
                    </FormGroup>
                </div>
            </div>
        ) : ''

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <h1 className="page-header">
                            <Translate id="users.titles.add" />
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <Form className="col-xs-12" schema={this.getUserSchema()} defaultValue={this.getUserSchema().default()} onChange={(formValues, fieldUpdated) => {
                        this.setState({ formValues })
                    }} onSubmit={this.addUser}>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.username" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="username"
                                        placeholder={this.props.translate('users.placeholders.username')}
                                        className="form-control"
                                        disabled={loading}
                                    />
                                    <Form.Message for="username" />
                                </FormGroup>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.profile" />
                                    </ControlLabel>
                                    <Form.Field name="profile" className="form-control" type="select" disabled={loading}>
                                        <option value={undefined}>{this.props.translate('users.misc.selectRole')}</option>
                                        <option value={500}>{this.props.translate('users.profiles.operator')}</option>
                                        <option value={600}>{this.props.translate('users.profiles.coordinator')}</option>
                                        <option value={1000}>{this.props.translate('users.profiles.admin')}</option>
                                    </Form.Field>
                                    <Form.Message for="profile" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.password" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="passwords.password"
                                        placeholder={this.props.translate('users.placeholders.password')}
                                        className="form-control"
                                        type="password"
                                        disabled={loading}
                                    />
                                    <Form.Message for="passwords.password" />
                                </FormGroup>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.validation" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="passwords.validation"
                                        placeholder={this.props.translate('users.placeholders.password')}
                                        className="form-control"
                                        type="password"
                                        disabled={loading}
                                    />
                                    <Form.Message for="passwords.validation" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.firstName" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="name.first"
                                        placeholder={this.props.translate('users.placeholders.firstName')}
                                        className="form-control"
                                        disabled={loading}
                                    />
                                    <Form.Message for="name.first" />
                                </FormGroup>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.lastName" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="name.last"
                                        placeholder={this.props.translate('users.placeholders.lastName')}
                                        className="form-control"
                                        disabled={loading}
                                    />
                                    <Form.Message for="name.last" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.position" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="position"
                                        placeholder={this.props.translate('users.placeholders.optional')}
                                        className="form-control"
                                        disabled={loading}
                                    />
                                    <Form.Message for="position" />
                                </FormGroup>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.area" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="area"
                                        placeholder={this.props.translate('users.placeholders.optional')}
                                        className="form-control"
                                        disabled={loading}
                                    />
                                    <Form.Message for="area" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.propNames.externalId" />
                                    </ControlLabel>
                                    <Form.Field
                                        name="externalId"
                                        placeholder={this.props.translate('users.placeholders.optional')}
                                        className="form-control"
                                        disabled={loading}
                                    />
                                    <Form.Message for="externalId" />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <FormGroup>
                                    <ControlLabel>
                                        <Translate id="users.misc.showAllQueries" />&nbsp;<Form.Field name="operator.showAllQueries" onClick={this.toggleShowAllQueries} />
                                    </ControlLabel>
                                    <Form.Message for="operator.showAllQueries" />
                                </FormGroup>
                            </div>
                        </div>
                        {origins}
                        <div className="row">
                            <div className="col-xs-12">
                                <FormGroup>
                                    <ButtonToolbar className="pull-right">
                                        <Form.Button className="btn btn-primary" type="submit" disabled={loading}>
                                            <Translate id="buttons.save" />
                                        </Form.Button>
                                        <Button bsStyle="danger" type="button" onClick={this.goBack} disabled={loading}>
                                            <Translate id="buttons.cancel" />
                                        </Button>
                                    </ButtonToolbar>
                                </FormGroup>
                            </div>
                        </div>
                    </Form>
                </div>
                {error ? (
                    <div className="row">
                        <div className="col-xs-12">
                            <br />
                            <Alert bsStyle={error === 'users.errors.exists' ? 'info' : 'danger'}>
                                <Translate id={error} />
                            </Alert>
                        </div>
                    </div>
                ) : ''}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        profile: state.profile,
        app: state.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(UserAdd))
