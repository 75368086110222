import * as actions from '../actions'

const defaultState = 10

export default function globalLimit(state = defaultState, action) {
    switch (action.type) {
        case actions.CHANGED_GLOBAL_LIMIT:
            state = action.payload
            return state

        default:
            return state
    }
}
