import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../state/actions'
import { browserHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'
import LanguageToggle from '../LanguageToggle'
import AlertNonPayment from '../AlertNonPayment'
import moment from 'moment'

import {
    Navbar,
    Nav,
    NavDropdown,
    MenuItem
} from 'react-bootstrap'

import {
    Alert,
    ButtonAlert,
    Container,
    Logo
} from './styles'

class Navigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
        this.dropdownAction = this.dropdownAction.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.cookieBillingAlert = this.cookieBillingAlert.bind(this)
    }

    componentWillMount() {
        if (this.props.appCodename) {
            this.props.actions.appSync({ codename: this.props.appCodename })
            this.props.actions.getBilling(this.props.appCodename)
        }
    }

    dropdownAction(value) {
        if (value === 'logout') {
            this.props.actions.userCleanProfile()
        } else if (value === 'profile') {
            browserHistory.push(`/manager/${this.props.appCodename}/user/edit/me`)
        } else if (value === 'apps') {
            browserHistory.push('/manager/app')
        }
    }

    openModal() {
        this.setState({
            show: true
        })
    }

    closeModal() {
        this.setState({
            show: false
        })
    }

    cookieBillingAlert() {
        const self = this
        const { billing, profile } = self.props
        const { show } = self.state
        if ((billing && billing.billing && billing.billing.enabled) && !(document.cookie.split(';').some((item) => item.trim().startsWith('alert_billing_cs'))) && profile && profile.profile !== 2000) {
            const startDate = moment().endOf('day').clone().format('ddd, DD MMM YYYY HH:mm:ss')
            document.cookie = `alert_billing_cs=true; expires=${startDate}`
            if (!show) {
                self.setState({
                    show: true
                })
            }
        }
    }

    render() {
        const self = this
        const { profile, appCodename, billing } = self.props
        const { show } = self.state
        const goToProfile = (appCodename && profile && profile.profile < 2000) ? (
            <MenuItem eventKey="profile">
                <FontAwesomeIcon icon="home" />
                &nbsp;
                <Translate id="navigation.profile" />
            </MenuItem>
        ) : ''

        const goToApps = (profile && profile.profile === 2000) ? (
            <MenuItem eventKey="apps">
                <FontAwesomeIcon icon="laptop" />
                &nbsp;
                <Translate id="navigation.apps" />
            </MenuItem>
        ) : ''

        let goToAlertBilling = ''
        if (billing && billing.billing && billing.billing.enabled) {
            self.cookieBillingAlert()
            goToAlertBilling = (
                <ButtonAlert onClick={self.openModal}>
                    <Alert>
                        <Translate id="alertNonPayment.billingNonpayment"/> <img src={`/manager/static/images/alert.png`} height={30}/>
                    </Alert>
                </ButtonAlert>
            )
        }

        return (
            <div>
                <Container>
                    <Navbar fluid>
                        <Navbar.Header>
                            <Navbar.Brand>
                                <Logo></Logo>
                            </Navbar.Brand>
                            <Navbar.Toggle />
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <Nav>
                                {self.props.children}
                            </Nav>
                            <Nav pullRight>
                                {goToAlertBilling}
                                {self.props.alert}
                                <LanguageToggle />
                                <NavDropdown id="nav-dropdown" title={<FontAwesomeIcon icon="user-circle" />} onSelect={self.dropdownAction} pullRight>
                                    {goToApps}
                                    {goToProfile}
                                    <MenuItem eventKey={'logout'}>
                                        <FontAwesomeIcon icon="sign-out-alt" />
                                        &nbsp;
                                        <Translate id="navigation.logout" />
                                    </MenuItem>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
                {show ? <AlertNonPayment
                    show={show}
                    closeModal={self.closeModal}
                /> : ''}
            </div>
        )
    }
}

Navigation.propTypes = {
    appCodename: PropTypes.string,
    alert: PropTypes.any,
    onTitleClick: PropTypes.func
}

function mapStateToProps(state) {
    return {
        profile: state.profile,
        routing: state.routing,
        app: state.app,
        billing: state.billing
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Navigation)
