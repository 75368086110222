import styled from 'styled-components'

export const Wrapper = styled.div`
    background-color: ${props => props.theme.colors.primary};
    min-height: 100vh;
    max-width: 100vw;
    padding-top: 10%;
`

export const Logo = styled.img`
    width: 100%;
    max-width: 400px;
    height: auto;
`

export const Input = styled.input`
    border-radius: 0px;
`

export const Button = styled.button`
    background-color: ${props => props.theme.colors.secondary};
    color: white;
    border: none;
    border-radius: 0px;

    &:hover {
        background-color: white;
        color: ${props => props.theme.colors.secondary};
    }

    &:disabled, &:hover:disabled {
        background-color: #7E8181;
        color: white;
    }

    &:active, &:focus {
        background-color: ${props => props.theme.colors.primary};
        color: white;
    }
`

export const Error = styled.div`
    color: ${props => (props.theme.colors && props.theme.colors.brand && props.theme.colors.brand.danger) ? props.theme.colors.brand.danger : 'red'};
    text-align: center;
    margin-top: 5px;
`
