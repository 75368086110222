import * as actions from '../actions'
import moment from 'moment'
const defaultState = null

export default function uptimeStats(state = defaultState, action) {
    let newState = Object.assign({}, state)
    if (!newState) {
        newState = {}
    }
    switch (action.type) {
        case actions.UPTIME_STATS_GET:
            if (action && !action.error && action.payload && action.payload.data) {
                newState.updated = moment.utc()
                if (!newState.uptime) {
                    newState.uptime = {}
                }
                if (!newState.branch) {
                    newState.branch = {}
                }
                if (!newState.lastSeen) {
                    newState.lastSeen = {}
                }
                if (!newState.responseTime) {
                    newState.responseTime = {}
                }
                if (!newState.memoryUsage) {
                    newState.memoryUsage = {}
                }
                if (!newState.cpuUsage) {
                    newState.cpuUsage = {}
                }
                if (action.payload.data.uptime) {
                    Object.keys(action.payload.data.uptime).map(process => {
                        if (action.payload.data.uptime[process] && action.payload.data.uptime[process].uptime) {
                            newState.uptime[process] = action.payload.data.uptime[process].uptime
                            newState.branch[process] = action.payload.data.uptime[process].branch
                            newState.lastSeen[process] = newState.updated
                            newState.responseTime[process] = action.payload.data.uptime[process].responseTime
                            newState.memoryUsage[process] = action.payload.data.uptime[process].memoryUsage
                            newState.cpuUsage[process] = action.payload.data.uptime[process].cpuUsage
                        } else {
                            if (!newState.uptime[process]) {
                                newState.uptime[process] = 0
                            }
                        }
                    })
                }
            }
            return newState
        default:
            return state
    }
}
