import { createAction } from 'redux-actions'
import axios from 'axios'
import * as storage from '../../utils/storage'

const axiosInstance = axios.create({
    baseURL: process.env.project.API_URL.toString(),
    timeout: 60000
})

// API - NOT FOR EXPORT

const getHeaders = function(params, contentType) {
    const queryParams = params || {}
    const headers = {
        'Accept': 'application/json',
        'Content-Type': contentType || 'application/json'
    }

    const accessToken = storage.get(storage.accessTokenKey)
    if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken
    }

    return {
        headers: headers,
        params: queryParams
    }
}

const apiCall = function(method, url, data, config, extra) {
    return axiosInstance[method](url, data, config).then(function(response) {
        if (response.status >= 200 && response.status < 300) {
            if (extra) {
                response.extra = extra
            }
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    }).catch(function(error) {
        return Promise.reject({
            error: error,
            apiCall: true,
            apiCallStatus: error && error.response && error.response.status || 'unknown'
        })
    })
}

//
// ACTIONS - FOR EXPORT
//

// CLEAN ALL
export const CLEAN_ALL = 'CLEAN_ALL'

// API
export const API_CLEAN = 'API_CLEAN'
export const IS_READY = 'IS_READY'

// SOCKET
export const CONNECT_SOCKET = 'CONNECT_SOCKET'
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET'
export const ADD_SOCKET_METHOD = 'ADD_SOCKET_METHOD'

// APPS
export const APP_ADD = 'APP_ADD'
export const APP_EDIT = 'APP_EDIT'
export const APP_EDIT_APPDATA = 'APP_EDIT_APPDATA'
export const APP_DELETE = 'APP_DELETE'
export const APP_GET = 'APP_GET'
export const APP_GET_ONE = 'APP_GET_ONE'
export const APP_GET_ONE_CODENAME = 'APP_GET_ONE_CODENAME'
export const APP_CLEAN = 'APP_CLEAN'
export const APP_CLEAN_ONE = 'APP_CLEAN_ONE'
export const APP_SELECT = 'APP_SELECT'
export const APP_RESET = 'APP_RESET'
export const APP_NEW_TOKEN = 'APP_NEW_TOKEN'
export const APP_SYNC = 'APP_SYNC'

export const BULL_STATS_GET = 'BULL_STATS_GET'
export const UPTIME_STATS_GET = 'UPTIME_STATS_GET'

// USERS
export const LOGIN_USER = 'LOGIN_USER'
export const USER_ADD = 'USER_ADD'
export const USER_EDIT = 'USER_EDIT'
export const USER_DELETE = 'USER_DELETE'
export const USER_GET = 'USER_GET'
export const USER_GET_UNLIMITED = 'USER_GET_UNLIMITED'
export const USER_GET_ONE = 'USER_GET_ONE'
export const USER_CLEAN = 'USER_CLEAN'
export const USER_CLEAN_ONE = 'USER_CLEAN_ONE'
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD'
export const USER_GET_PROFILE = 'USER_GET_PROFILE'
export const USER_CLEAN_PROFILE = 'USER_CLEAN_PROFILE'
export const ADD_FCM_TOKEN = 'ADD_FCM_TOKEN'
export const SHOW_NEW_CUSTOMER = 'SHOW_NEW_CUSTOMER'
export const CHANGE_STATUS = 'CHANGE_STATUS'

// TOPICS
export const TOPIC_GET = 'TOPIC_GET'
export const TOPIC_GET_ONE = 'TOPIC_GET_ONE'
export const TOPIC_GET_SEARCH = 'TOPIC_GET_SEARCH'
export const TOPIC_ADD = 'TOPIC_ADD'
export const TOPIC_EDIT = 'TOPIC_EDIT'
export const TOPIC_DELETE = 'TOPIC_DELETE'
export const CLEAN_TOPICS = 'CLEAN_TOPICS'
export const TOPIC_ONE_CLEAN = 'TOPIC_ONE_CLEAN'
export const TOPIC_MEDIA_ADD = 'TOPIC_MEDIA_ADD'
export const TOPIC_MEDIA_DELETE = 'TOPIC_MEDIA_DELETE'
export const TOPIC_TEMPORAL_MEDIA = 'TOPIC_TEMPORAL_MEDIA'

// QUERIES
export const NEW_QUERY = 'NEW_QUERY'
export const GET_QUERY = 'GET_QUERY'
export const CLEAN_QUERY = 'CLEAN_QUERY'
export const GET_QUERIES = 'GET_QUERIES'
export const CLEAN_QUERIES = 'CLEAN_QUERIES'
export const UPDATE_QUERY = 'UPDATE_QUERY'
export const UPDATE_QUERIES = 'UPDATE_QUERIES'
export const UPDATE_LAST_READ_AT = 'UPDATE_LAST_READ_AT'
export const UPDATE_LAST_READ_AT_INTERNAL = 'UPDATE_LAST_READ_AT_INTERNAL'
export const TAKE_QUERY = 'TAKE_QUERY'
export const ASSIGN_QUERY = 'ASSIGN_QUERY'
export const ASSIGN_RESOLUTION = 'ASSIGN_RESOLUTION'
export const RESOLVE_QUERY = 'RESOLVE_QUERY'
export const SET_QUERY_PRIVATE = 'SET_QUERY_PRIVATE'
export const WEBHOOK_ACTION = 'WEBHOOK_ACTION'
export const EDIT_CONTEXT = 'EDIT_CONTEXT'
export const EDIT_EXTRA_DATA = 'EDIT_EXTRA_DATA'
export const SEND_HSM = 'SEND_HSM'
export const ASSIGN_HSM_SENDED_AT = 'ASSIGN_HSM_SENDED_AT'
export const REMOVE_HSM_SENDED_AT = 'REMOVE_HSM_SENDED_AT'
export const SOCKET_PENDING_QUERY = 'SOCKET_PENDING_QUERY'
export const SOCKET_PROGRESS_QUERY = 'SOCKET_PROGRESS_QUERY'
export const SOCKET_RESOLVED_QUERY = 'SOCKET_RESOLVED_QUERY'
export const SOCKET_FAILED_QUERY = 'SOCKET_FAILED_QUERY'
export const SOCKET_REASSIGNED_QUERY = 'SOCKET_REASSIGNED_QUERY'
export const CREATE_MESSAGEUSER = 'CREATE_MESSAGEUSER'
export const VERIFY_QUERY = 'VERIFY_QUERY'
export const ASSIGN_MULTIPLE_QUERIES = 'ASSIGN_MULTIPLE_QUERIES'
export const GET_USERS_WITH_ACTIVE_QUERIES = 'GET_USERS_WITH_ACTIVE_QUERIES'
// MESSAGES
export const GET_QUERY_MESSAGES = 'GET_QUERY_MESSAGES'
export const GET_OLD_MESSAGES = 'GET_OLD_MESSAGES'
export const CLEAN_MESSAGES = 'CLEAN_MESSAGES'
export const MESSAGE_RECEIVE = 'MESSAGE_RECEIVE'
export const MESSAGE_UPDATE = 'MESSAGE_UPDATE'
export const SPEECH_TO_TEXT = 'SPEECH_TO_TEXT'

// SCHEDULE
export const GET_SCHEDULE = 'GET_SCHEDULE'
export const CLEAN_SCHEDULE = 'CLEAN_SCHEDULE'
export const UPDATE_SCHEDULE_DAY = 'UPDATE_SCHEDULE_DAY'

// TAGS
export const GET_RESOLUTIONS = 'GET_RESOLUTIONS'
export const CLEAN_RESOLUTIONS = 'CLEAN_RESOLUTIONS'
export const ADD_RESOLUTION = 'ADD_RESOLUTION'
export const DELETE_RESOLUTION = 'DELETE_RESOLUTION'

// ORIGINS
export const GET_ORIGINS = 'GET_ORIGINS'
export const GET_ORIGINS_ALL = 'GET_ORIGINS_ALL'
export const CLEAN_ORIGINS = 'CLEAN_ORIGINS'
export const ADD_ORIGINS = 'ADD_ORIGIN'
export const DELETE_ORIGINS = 'DELETE_ORIGINS'
export const CHECK_DELETE_ORIGINS = 'CHECK_DELETE_ORIGINS'

// SCHEDULE NOT AVAILABLE
export const GET_SCHEDULE_UNAVAILABLE = 'GET_SCHEDULE_UNAVAILABLE'
export const CLEAN_SCHEDULE_UNAVAILABLE = 'CLEAN_SCHEDULE_UNAVAILABLE'
export const DELETE_SCHEDULE_UNAVAILABLE_DAY = 'DELETE_SCHEDULE_UNAVAILABLE_DAY'
export const GET_SCHEDULE_UNAVAILABLE_DAY = 'GET_SCHEDULE_UNAVAILABLE_DAY'
export const CLEAN_SCHEDULE_UNAVAILABLE_DAY = 'CLEAN_SCHEDULE_UNAVAILABLE_DAY'
export const PUT_SCHEDULE_UNAVAILABLE_DAY = 'PUT_SCHEDULE_UNAVAILABLE_DAY'
export const POST_SCHEDULE_UNAVAILABLE_DAY = 'POST_SCHEDULE_UNAVAILABLE_DAY'

// STATS
export const GET_STATS = 'GET_STATS'
export const CLEAN_STATS = 'CLEAN_STATS'

// RESOURCES
export const UPLOAD = 'UPLOAD'

// HSM
export const GET_HSM_TEMPLATES = 'GET_HSM_TEMPLATES'
export const CLEAN_HSM_TEMPLATES = 'CLEAN_HSM_TEMPLATES'

// ACCOUNTS
export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const CLEAN_ACCOUNTS = 'CLEAN_ACCOUNTS'

// GLOBAL LIMIT
export const CHANGED_GLOBAL_LIMIT = 'CHANGED_GLOBAL_LIMIT'

// SKIP
export const CHANGED_SKIP = 'CHANGED_SKIP'
export const CLEAN_CHANGED_SKIP = 'CLEAN_CHANGED_SKIP'

// SEND EMAIL
export const SEND_EMAIL = 'SEND_EMAIL'

// BIG QUERY
export const REQUEST_BIGQUERY_DATA = 'REQUEST_BIGQUERY_DATA'

// STATUS
export const CLEAN_STATUS_CT = 'CLEAN_STATUS_CT'
export const SET_STATUS_CT = 'SET_STATUS_CT'

// BILLING
export const GET_BILLING = 'GET_BILLING'

// PATH
export const PREVIOUSPATH_SET = 'PREVIOUSPATH_SET'

// QUEUE MESSAGES
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const SENDING_MESSAGES = 'SENDING_MESSAGES'
export const STOP_MESSAGES = 'STOP_MESSAGES'
export const FAIL_MESSAGE = 'FAIL_MESSAGE'

// DEBUG_RECONNECT_SOCKETIO
export const DEBUG_RECONNECT_SOCKETIO = 'DEBUG_RECONNECT_SOCKETIO'

// CLEAN ALL
export const cleanAll = createAction(CLEAN_ALL, () => {
    storage.remove(storage.accessTokenKey)
    return true
})

// API
export const apiClean = createAction(API_CLEAN, function() {
    return Promise.resolve()
})

export const isReady = createAction(IS_READY, function() {
    return Promise.resolve()
})

// SOCKET
export const connectSocket = createAction(CONNECT_SOCKET, (app, reconnect) => Promise.resolve({ app, reconnect }))

export const disconnectSocket = createAction(DISCONNECT_SOCKET, () => Promise.resolve())

export const addSocketMethod = createAction(ADD_SOCKET_METHOD, (method, callback) => Promise.resolve({ method, callback }))

// APPS
export const appAdd = createAction(APP_ADD, function(app) {
    return apiCall('post', '/api/v1/app', app, getHeaders())
})

export const appEdit = createAction(APP_EDIT, function(appId, data) {
    return apiCall('put', '/api/v1/app/' + appId, data, getHeaders())
})

export const appEditAppData = createAction(APP_EDIT_APPDATA, function(appCodename, key, data) {
    return apiCall('put', '/api/v1/app/' + appCodename + '/update/appdata/' + key, data, getHeaders())
})

export const appDelete = createAction(APP_DELETE, function(appId) {
    return apiCall('delete', '/api/v1/app/' + appId, getHeaders())
})

export const appReset = createAction(APP_RESET, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/app/' + appCodename + '/reset', getHeaders(queryParams))
})

export const appNewToken = createAction(APP_NEW_TOKEN, function(appCodename) {
    return apiCall('get', '/api/v1/app/' + appCodename + '/newtoken', getHeaders())
})

export const appGet = createAction(APP_GET, function(query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/app', getHeaders(queryParams))
})

export const appGetOne = createAction(APP_GET_ONE, function(appId) {
    return apiCall('get', '/api/v1/app/' + appId, getHeaders())
})

export const appGetOneCodename = createAction(APP_GET_ONE_CODENAME, function(appCodename) {
    return apiCall('get', '/api/v1/app/codename/' + appCodename, getHeaders())
})

export const appSync = createAction(APP_SYNC, function (data) {
    return apiCall('post', '/api/v1/app/sync', data, getHeaders())
})

export const appClean = createAction(APP_CLEAN, function() {
    return Promise.resolve()
})

export const appCleanOne = createAction(APP_CLEAN_ONE, function() {
    return Promise.resolve()
})

export const appSelect = createAction(APP_SELECT, function(app) {
    return Promise.resolve(app)
})

export const bullStats = createAction(BULL_STATS_GET, () => {
    return apiCall('get', '/api/v1/stats/bull', getHeaders(), { timeout: 1000 * 60 * 15 })
})

export const uptimeStats = createAction(UPTIME_STATS_GET, () => {
    return apiCall('get', '/api/v1/stats/uptime', getHeaders(), { timeout: 1000 * 60 * 15 })
})

// USERS
export const userLogin = createAction(LOGIN_USER, function(user) {
    return apiCall('post', '/api/v1/user/login', user, getHeaders())
})

export const userAdd = createAction(USER_ADD, function(appCodename, user) {
    return apiCall('post', '/api/v1/' + appCodename + '/user', user, getHeaders())
})

export const userEdit = createAction(USER_EDIT, function(appCodename, userId, user) {
    return apiCall('put', '/api/v1/' + appCodename + '/user/' + userId, user, getHeaders())
})

export const userDelete = createAction(USER_DELETE, function(appCodename, userId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/user/' + userId, getHeaders())
})

export const userGet = createAction(USER_GET, function(appCodename, params) {
    return apiCall('get', `/api/v1/${appCodename}/user`, getHeaders(params))
})

export const userGetUnlimited = createAction(USER_GET_UNLIMITED, async function(userGet, appCodename, params) {
    // console.log('ACTION USER GET UNLIMITED START')
    if (!params) {
        params = {}
    }
    let getAllUsers = true
    let getAllUsersSkip = 0
    while (getAllUsers) {
        // console.log('ACTION USER GET UNLIMITED GETTING')
        getAllUsers = false
        params.limit = 300
        params.skip = getAllUsersSkip
        const action = await userGet(appCodename, params)
        if (action && !action.error && action.payload && action.payload.data && action.payload.data.objects && action.payload.data.objects.length && (action.payload.data.objects.length === action.payload.data.limit)) {
            getAllUsers = true
            getAllUsersSkip += action.payload.data.limit
        }
    }
    // console.log('ACTION USER GET UNLIMITED END')
})

export const userGetOne = createAction(USER_GET_ONE, function(appCodename, userId, params) {
    return apiCall('get', `/api/v1/${appCodename}/user/${userId}`, getHeaders(params))
})

export const userClean = createAction(USER_CLEAN, function() {
    return Promise.resolve()
})

export const userCleanOne = createAction(USER_CLEAN_ONE, () => {
    return Promise.resolve()
})

export const userChangePassword = createAction(USER_CHANGE_PASSWORD, function(userId, user) {
    return apiCall('put', '/api/v1/user/' + userId + '/changePassword', user, getHeaders())
})

export const userGetProfile = createAction(USER_GET_PROFILE, function() {
    return apiCall('get', '/api/v1/user/me/profile', getHeaders())
})

export const userCleanProfile = createAction(USER_CLEAN_PROFILE, function() {
    return Promise.resolve()
})

export const showNewCustomer = createAction(SHOW_NEW_CUSTOMER, (showNewCustomer) => {
    return apiCall('put', '/api/v1/user/me/newcustomer', { showNewCustomer }, getHeaders())
})

export const changeStatus = createAction(CHANGE_STATUS, (appCodename, status, userId) => {
    return apiCall('put', '/api/v1/' + appCodename + '/user/me/changeStatus', { status: status, userId: userId }, getHeaders())
})

// TOPICS
export const getTopic = createAction(TOPIC_GET, function(appCodename, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/topic', getHeaders(params))
})

export const getTopicContextVerificated = createAction(TOPIC_GET, function(appCodename, messageUserId) {
    return apiCall('get', '/api/v1/' + appCodename + '/topic/messageuser/' + messageUserId, getHeaders())
})

export const getOneTopic = createAction(TOPIC_GET_ONE, function(appCodename, topicId) {
    return apiCall('get', '/api/v1/' + appCodename + '/topic/' + topicId, getHeaders())
})

export const getTopicSearch = createAction(TOPIC_GET_SEARCH, function(appCodename, messageUserId, searchText) {
    return apiCall('get', '/api/v1/' + appCodename + '/topic/manager/search/messageuser/' + messageUserId + '/?search=' + searchText, getHeaders())
})

export const addTopic = createAction(TOPIC_ADD, function(appCodename, params) {
    return apiCall('post', '/api/v1/' + appCodename + '/topic', params, getHeaders())
})

export const editTopic = createAction(TOPIC_EDIT, function(appCodename, topicId, params) {
    return apiCall('put', '/api/v1/' + appCodename + '/topic/' + topicId, params, getHeaders())
})

export const deleteTopic = createAction(TOPIC_DELETE, function(appCodename, topicId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/topic/' + topicId, getHeaders())
})

export const cleanTopics = createAction(CLEAN_TOPICS, function() {
    return true
})

export const cleanTopicOneSuggest = createAction(TOPIC_ONE_CLEAN, function() {
    return Promise.resolve()
})

export const mediaAddTopic = createAction(TOPIC_MEDIA_ADD, function(appCodename, topicId, media) {
    return apiCall('post', '/api/v1/' + appCodename + '/topic/' + topicId + '/media/file/upload', media, getHeaders(), { timeout: 1000 * 60 * 5 })
})

export const mediaDeleteTopic = createAction(TOPIC_MEDIA_DELETE, function(appCodename, topicId, mediaId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/topic/' + topicId + '/media/' + mediaId, getHeaders())
})

export const uploadTopic = createAction(TOPIC_TEMPORAL_MEDIA, function (appCodename, formData, params) {
    return apiCall('post', '/api/v1/' + appCodename + '/topic/upload/resource', formData, getHeaders(params))
})

// QUERIES
export const newQuery = createAction(NEW_QUERY, function (appCodename, data) {
    return apiCall('post', '/api/v1/' + appCodename + '/query', data, getHeaders())
})

export const getQuery = createAction(GET_QUERY, function(appCodename, queryId) {
    return apiCall('get', '/api/v1/' + appCodename + '/query/' + queryId, getHeaders())
})

export const cleanQuery = createAction(CLEAN_QUERY, function() {
    return Promise.resolve()
})

export const getQueries = createAction(GET_QUERIES, function(appCodename, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/query', getHeaders(params))
})

export const cleanQueries = createAction(CLEAN_QUERIES, function() {
    return Promise.resolve()
})

export const updateQuery = createAction(UPDATE_QUERY, (validation, data) => Promise.resolve({ validation, data }))

export const updateQueries = createAction(UPDATE_QUERIES, (validation, data) => Promise.resolve({ validation, data }))

export const takeQuery = createAction(TAKE_QUERY, function (appCodename, queryId, newOrigin) {
    return apiCall('put', '/api/v1/' + appCodename + '/query/take/' + queryId, newOrigin && { newOrigin } || {}, getHeaders())
})

export const assignQuery = createAction(ASSIGN_QUERY, function(appCodename, queryId, userId, newOrigin) {
    return apiCall('put', '/api/v1/' + appCodename + '/query/assign/' + queryId + '/to/' + userId, newOrigin && { newOrigin } || {}, getHeaders())
})

export const updateLastReadAt = createAction(UPDATE_LAST_READ_AT, function(appCodename, messageUser, operator) {
    return apiCall('put', `/api/v1/${appCodename}/query/lastread/${messageUser}`, { messageUser, operator }, getHeaders())
})

export const updateLastReadAtInternal = createAction(UPDATE_LAST_READ_AT_INTERNAL, function(appCodename, queryId) {
    return Promise.resolve({
        appCodename,
        queryId
    })
})

export const assignResolution = createAction(ASSIGN_RESOLUTION, function(appCodename, queryId, tagId) {
    return apiCall('put', '/api/v1/' + appCodename + '/query/assign/' + queryId + '/resolution/' + tagId, {}, getHeaders())
})

export const resolveQuery = createAction(RESOLVE_QUERY, function(appCodename, queryId, data = {}) {
    return apiCall('put', '/api/v1/' + appCodename + '/query/resolve/' + queryId, data, getHeaders())
})

export const setQueryToPrivate = createAction(SET_QUERY_PRIVATE, (queryId) => queryId)

export const assignMultiplesQueries = createAction(ASSIGN_MULTIPLE_QUERIES, function (appCodename, userFrom, userTo) {
    return apiCall('put', '/api/v1/' + appCodename + '/query/assign/all', { userFrom, userTo }, getHeaders())
})

export const getUserWithActiveQueries = createAction(GET_USERS_WITH_ACTIVE_QUERIES, function (appCodename) {
    return apiCall('get', '/api/v1/' + appCodename + '/query/users/all', getHeaders())
})

export const getQueryMessages = createAction(GET_QUERY_MESSAGES, function(appCodename, queryId) {
    return apiCall('get', '/api/v1/' + appCodename + '/query/messages/v2/' + queryId, getHeaders())
})

export const getOldMessages = createAction(GET_OLD_MESSAGES, function(appCodename, messageUserId, messageDate) {
    return apiCall('get', '/api/v1/' + appCodename + '/query/messages/v2/old/' + messageUserId + '/' + messageDate, getHeaders())
})

export const cleanMessages = createAction(CLEAN_MESSAGES, function() {
    return true
})

export const messageReceive = createAction(MESSAGE_RECEIVE, function(message) {
    return Promise.resolve(message)
})

export const messageUpdate = createAction(MESSAGE_UPDATE, function(message) {
    return message
})

export const speech = createAction(SPEECH_TO_TEXT, (appCodename, messageId) => {
    return apiCall('get', `/api/v1/${appCodename}/query/speech/message/${messageId}`, getHeaders())
})

export const webhookAction = createAction(WEBHOOK_ACTION, function(appCodename, webhookAction, messageUserId, data) {
    return apiCall('post', '/api/v1/' + appCodename + '/query/webhook/' + webhookAction + '/' + messageUserId, data, getHeaders())
})

export const editContext = createAction(EDIT_CONTEXT, function(appCodename, messageUserId, key, value, queryId) {
    return apiCall('put', '/api/v1/' + appCodename + '/topic/messageuser/' + messageUserId + '/context/edit', { key, value, queryId }, getHeaders())
})

export const editExtraData = createAction(EDIT_EXTRA_DATA, function(appCodename, queryId, key, value) {
    return apiCall('put', '/api/v1/' + appCodename + '/query/extradata/' + queryId, { key, value }, getHeaders())
})

export const sendHSM = createAction(SEND_HSM, function(appCodename, messageUserId, data) {
    return apiCall('post', `/api/v1/${appCodename}/query/hsm/${messageUserId}/send`, data, getHeaders())
})

export const assignHSMSendedAt = createAction(ASSIGN_HSM_SENDED_AT, function(appCodename, messageUserId) {
    return apiCall('put', `/api/v1/${appCodename}/query/hsm/${messageUserId}`, null, getHeaders())
})

export const removeHSMSendedAt = createAction(REMOVE_HSM_SENDED_AT, function() {
    return true
})

export const socketPendingQuery = createAction(SOCKET_PENDING_QUERY, (data) => Promise.resolve(data))

export const socketProgressQuery = createAction(SOCKET_PROGRESS_QUERY, (data, operator, profile, status) => Promise.resolve({ data, operator, profile, status }))

export const socketResolvedQuery = createAction(SOCKET_RESOLVED_QUERY, (data, profile) => Promise.resolve({ data, profile }))

export const socketFailedQuery = createAction(SOCKET_FAILED_QUERY, (data) => Promise.resolve({ data }))

export const socketReassignedQuery = createAction(SOCKET_REASSIGNED_QUERY, (data, operator, profile, status) => Promise.resolve({ data, operator, profile, status }))

export const createMessageUser = createAction(CREATE_MESSAGEUSER, function (appCodename, params) {
    return apiCall('get', `/api/v1/${appCodename}/query/new/messageuser`, getHeaders(params))
})

export const verifyQuery = createAction(VERIFY_QUERY, function (appCodename, messageUserId) {
    return apiCall('get', `/api/v1/${appCodename}/query/verify/messageuser/${messageUserId}`, getHeaders())
})

// SCHEDULES
export const getSchedule = createAction(GET_SCHEDULE, function(appCodename, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/schedule', getHeaders(params))
})

export const cleanSchedule = createAction(CLEAN_SCHEDULE, function() {
    return Promise.resolve()
})

export const updateScheduleDay = createAction(UPDATE_SCHEDULE_DAY, function(appCodename, data) {
    return apiCall('put', '/api/v1/' + appCodename + '/schedule/update/days', data, getHeaders())
})

// UNAVAILABLE SCHEDULES
export const getScheduleUnavailable = createAction(GET_SCHEDULE_UNAVAILABLE, function(appCodename, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/shedulenotavailable', getHeaders(params))
})

export const cleanScheduleUnavailable = createAction(CLEAN_SCHEDULE_UNAVAILABLE, function() {
    return Promise.resolve()
})

export const deleteScheduleUnavailableDay = createAction(DELETE_SCHEDULE_UNAVAILABLE_DAY, function(appCodename, scheduleId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/shedulenotavailable/' + scheduleId, getHeaders())
})

export const getScheduleUnavailableDay = createAction(GET_SCHEDULE_UNAVAILABLE_DAY, function(appCodename, scheduleDayId) {
    return apiCall('get', '/api/v1/' + appCodename + '/shedulenotavailable/' + scheduleDayId, getHeaders())
})

export const cleanScheduleUnavailableDay = createAction(CLEAN_SCHEDULE_UNAVAILABLE_DAY, function() {
    return Promise.resolve()
})

export const postScheduleUnavailableDay = createAction(POST_SCHEDULE_UNAVAILABLE_DAY, function(appCodename, data) {
    return apiCall('post', '/api/v1/' + appCodename + '/shedulenotavailable', data, getHeaders())
})

export const putScheduleUnavailableDay = createAction(PUT_SCHEDULE_UNAVAILABLE_DAY, function(appCodename, scheduleUnavailableDayId, data) {
    return apiCall('put', '/api/v1/' + appCodename + '/shedulenotavailable/' + scheduleUnavailableDayId, data, getHeaders())
})

// RESOLUTIONS
export const resolutionsGet = createAction(GET_RESOLUTIONS, function(appCodename, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/resolution', getHeaders(params))
})

export const resolutionsClean = createAction(CLEAN_RESOLUTIONS, function() {
    return Promise.resolve()
})

export const resolutionsAdd = createAction(ADD_RESOLUTION, function(appCodename, data) {
    return apiCall('post', '/api/v1/' + appCodename + '/resolution', data, getHeaders())
})

export const resolutionDelete = createAction(DELETE_RESOLUTION, function(appCodename, dataId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/resolution/' + dataId, getHeaders())
})

// ORIGINS
export const originsGet = createAction(GET_ORIGINS, function(appCodename, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/origin?populates=[{"key": "parentId"}]', getHeaders(params))
})

export const originsAllGet = createAction(GET_ORIGINS_ALL, function(appCodename, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/origin?all=true', getHeaders(params))
})

export const originsClean = createAction(CLEAN_ORIGINS, function() {
    return Promise.resolve()
})

export const originsAdd = createAction(ADD_ORIGINS, function(appCodename, data) {
    return apiCall('post', '/api/v1/' + appCodename + '/origin', data, getHeaders())
})

export const originDelete = createAction(DELETE_ORIGINS, function(appCodename, dataId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/origin/' + dataId, getHeaders())
})

export const originCheckDelete = createAction(CHECK_DELETE_ORIGINS, function(appCodename, originId) {
    return apiCall('get', '/api/v1/' + appCodename + '/origin/checkdelete/' + originId, getHeaders())
})

// STATS
export const requestChartData = createAction(GET_STATS, (appCodename, params) => {
    return apiCall('get', `/api/v1/${appCodename}/stats`, getHeaders(params))
})

export const cleanStats = createAction(CLEAN_STATS, function(appCodename) {
    return Promise.resolve()
})

// RESOURCES
export const upload = createAction(UPLOAD, function(appCodename, formData, params) {
    return apiCall('post', '/api/v1/' + appCodename + '/query/upload/resource', formData, getHeaders(params))
})

// HSM
export const hsmTemplatesClean = createAction(CLEAN_HSM_TEMPLATES, function () {
    return Promise.resolve()
})
export const getHSMTemplates = createAction(GET_HSM_TEMPLATES, function(appCodename) {
    return apiCall('get', `/api/v1/app/${appCodename}/hsmtemplates`, getHeaders())
})

// ACCOUNT
export const accountsClean = createAction(CLEAN_ACCOUNTS, function () {
    return Promise.resolve()
})

export const getAccounts = createAction(GET_ACCOUNTS, function (appCodename) {
    return apiCall('get', `/api/v1/app/${appCodename}/accounts`, getHeaders())
})

// GLOBAL LIMIT
export const changedGlobalLimit = createAction(CHANGED_GLOBAL_LIMIT, (limit) => {
    return limit
})

// SKIP
export const changedSkip = createAction(CHANGED_SKIP, (skip) => {
    return skip
})

export const cleanSkip = createAction(CLEAN_CHANGED_SKIP, () => {
    return Promise.resolve()
})

// SEND EMAIL
export const sendEmail = createAction(SEND_EMAIL, email => {
    return apiCall('post', '/api/v1/email/send', email, getHeaders())
})

// STATUS
export const cleanStatusCT = createAction(CLEAN_STATUS_CT, () => true)

export const setStatusCT = createAction(SET_STATUS_CT, data => data)

// BILLING
export const getBilling = createAction(GET_BILLING, function (appCodename) {
    return apiCall('get', `/api/v1/app/${appCodename}/billing`, getHeaders({}))
})

export const previousPath = createAction(PREVIOUSPATH_SET, (path) => path)

// QUEUE MESSAGES
export const addMessage = createAction(ADD_MESSAGE, (message) => message)

export const removeMessage = createAction(REMOVE_MESSAGE)

export const sendingMessages = createAction(SENDING_MESSAGES)

export const stopMessages = createAction(STOP_MESSAGES)

export const failMessage = createAction(FAIL_MESSAGE, (message) => message)
