/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { Translate, withLocalize } from 'react-localize-redux'

import {
    Button,
    Alert
} from 'react-bootstrap'
import {
    Container,
    ControlLabel
} from './styles'
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'
import Select from 'react-select'
class Derive extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: {},
            loading: false,
            alert: false,
            userFrom: null,
            userTo: null
        }
        this.deriveAll = this.deriveAll.bind(this)
    }

    componentWillMount() {
        this._isMounted = true
        this.prepare()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    _setState(state) {
        return new Promise((resolve) => {
            if (this._isMounted) {
                this.setState(state, resolve)
            } else {
                resolve()
            }
        })
    }

    async prepare() {
        try{
            await this._setState({
                loading: true
            })
            let usersWithActiveQueries = []
            await this.props.actions.userGetUnlimited(this.props.actions.userGet, this.props.params.appCodename)

            const { users } = this.props
            const action = await this.props.actions.getUserWithActiveQueries(this.props.params.appCodename)
            if (action && action.payload && action.payload.data) {
                usersWithActiveQueries = action.payload.data
            }
            usersWithActiveQueries = users && users.objects && users.objects.filter(user => usersWithActiveQueries.includes(user._id))
            await this._setState({
                usersWithActiveQueries
            })
        } catch (e) {
            //
        } finally {
            await this._setState({
                loading: false
            })
        }
    }

    async deriveAll() {
        const { userFrom, userTo } = this.state
        try {
            this._setState({
                loading: true
            })
            if (!userFrom || !userTo) {
                this._setState({
                    loading: false,
                    alert: 'notSelectedUser'
                })
            } else if (userFrom && userTo && userFrom === userTo) {
                this._setState({
                    loading: false,
                    alert: 'sameUser'
                })
            } else {
                await this.props.actions.assignMultiplesQueries(this.props.params.appCodename, userFrom, userTo)
                this._setState({
                    alert: 'done'
                })
            }
        } catch (e) {
            this._setState({
                loading: false,
                alert: 'error'
            })
        } finally {
            this.prepare()
            this._setState({
                loading: false,
                userFrom: null,
                userTo: null
            })
        }
    }

    getColorByStatus(status) {
        return `${status === 'online' ? '🟢' : (status === 'away') ? '🟡' : '🔴'} `
    }

    render() {
        const { loading, alert, usersWithActiveQueries, userTo, userFrom } = this.state
        const { users, app } = this.props
        return (
            <Container>
                <div className="row">
                    <div className="col-xs-12">
                        <h1 className="page-header"><Translate id="derive.title" /></h1>
                    </div>
                </div>
                <Alert bsStyle="warning">
                    <Translate id='derive.alert.info' />
                </Alert>
                <div className="row" style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    {alert ? <Alert bsStyle="info">
                        <Translate id={`derive.alert.${alert}`} />
                    </Alert> : ''}
                </div>
                <div className="row" style={{
                    // height: '70vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {(users && users.objects && users.objects.length && usersWithActiveQueries && usersWithActiveQueries.length) ? (
                        <>
                            <div className="col-md-4">
                                <ControlLabel>
                                    <Translate id="derive.operatorFrom" />
                                </ControlLabel>
                                <Select
                                    placeholder={<Translate id='misc.none' />}
                                    options={usersWithActiveQueries.map(option => ({
                                        value: option._id,
                                        label: option.displayName
                                    }))}
                                    isDisabled={loading}
                                    isLoading={loading}
                                    onChange={option => this._setState({ userFrom: option.value, userTo: null })}
                                    value={usersWithActiveQueries.filter(option => option._id === userFrom).map(option => ({ value: option._id, label: option.displayName }))}
                                />
                            </div>
                            <div className="col-md-1" style={{ textAlign: 'center' }}>
                                <Button onClick={this.deriveAll} disabled={this.state.loading}>
                                    <FontAwesomeIcon icon='angle-right' />
                                </Button>
                            </div>
                            <div className="col-md-4">
                                <ControlLabel>
                                    <Translate id="derive.operatorTo" />
                                </ControlLabel>
                                <Select
                                    placeholder={<Translate id='misc.none' />}
                                    options={this.state.userFrom && users && users.objects && users.objects.filter(option => {
                                        return (this.state.userFrom !== option._id) && option || null
                                    }).map(option => ({
                                        value: option._id,
                                        label: `${app.userStatus ? this.getColorByStatus(option.status) : ''}${option.displayName} ${option.position ? '| ' + option.position : ''}  ${option.area ? '| ' + option.area : ''}`.trim(),
                                    }))}
                                    isDisabled={loading}
                                    isLoading={loading}
                                    onChange={option => this._setState({ userTo: option.value })}
                                    value={users.objects.filter(option => option._id === userTo).map(option => ({ value: option._id, label: `${app.userStatus ? this.getColorByStatus(option.status) : ''}${option.displayName} ${option.position ? '| ' + option.position : ''}  ${option.area ? '| ' + option.area : ''}`.trim() }))}
                                />
                            </div>
                        </>
                    ) : (
                        <Alert>
                            <Translate id='derive.noUsers' />
                        </Alert>
                    )}
                </div>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        isReady: state.isReady,
        profile: state.profile,
        users: state.users,
        origins: state.origins
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Derive))
