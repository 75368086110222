import * as actions from '../actions'

const defaultState = null

export default function apps(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_GET:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.APP_CLEAN:
            return defaultState

        default:
            return state
    }
}
