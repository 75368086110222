import styled from 'styled-components'

export const Text = styled.div`
    border: none;
    background-color: #fff;
    color: #999999;
    font-size: 15px;
    box-sizing: border-box;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto;
    margin-top: 10px;

    & > input {
        border: none;
        border-bottom: 1px dashed #D4D6D6;

        &:focus {
            outline: 0;
        }
    }
`
export const FormGroup = styled.div`
    margin-bottom: 10px;
`
