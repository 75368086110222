import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import { Translate, withLocalize } from 'react-localize-redux'

import {
    Modal,
    FormGroup,
    ControlLabel,
    HelpBlock,
    ButtonToolbar,
    Button
} from 'react-bootstrap'

import {
    Title
} from './styles'

class Step extends Component {
    constructor(props) {
        super(props)
        this.state = {
            action: (props.step && props.step.type) ? props.step.type : 'message'
        }

        this.changedForm = this.changedForm.bind(this)
        Form.addInputTypes(types)
    }

    getModelSchema() {
        const step = Object.assign({}, this.props.step)
        step.type = this.state.action

        return yup.object({
            title: yup.string().required(this.props.translate('steps.validations.title')),
            type: yup.string().required(this.props.translate('steps.validations.type')),
            answerUser: yup.string().nullable(),
            textButton: yup.string().nullable(),
            extraData: yup.string().nullable(),
            context: yup.string().nullable().matches(/^(context.|profile.|firstName$|lastName$|gender$|id$)/, {
                message: this.props.translate('steps.validations.context'),
                excludeEmptyString: true
            }),
            contextReadOnly: yup.boolean(),
            contextMedia: yup.boolean(),
            contextTextArea: yup.boolean(),
            contextSchema: yup.string(),
            flow: yup.string().nullable(),
            condition: yup.object({
                enabled: yup.boolean(),
                key: yup.string().nullable(),
                value: yup.string().nullable(),
                explanation: yup.string().nullable()
            }),
            unpause: yup.boolean(),
            canResolve: yup.boolean(),
            autoSave: yup.boolean(),
            autoResolve: yup.boolean()
        }).default(step)
    }

    changedForm(formValues) {
        this.setState({ formValues })
    }

    render() {
        const { open, loading, submit, cancel, step } = this.props
        const { action } = this.state

        let component = ''
        if (action === 'message') {
            component = (
                <div className="row" key="message">
                    <div className="col-xs-12">
                        <Title>
                            <Translate id="steps.two-message.description" />
                        </Title>
                    </div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-message.title" />
                            </ControlLabel>
                            <Form.Field name="answerUser" className="form-control" type="textarea" rows={2} />
                            <Form.Message for="answerUser" />
                            <HelpBlock>
                                <Translate id="steps.two-message.help" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                </div>
            )
        } if (action === 'query') {
            component = (
                <div className="row" key="query">
                    <div className="col-xs-12">
                        <Title>
                            <Translate id="steps.two-query.description" />
                        </Title>
                    </div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-query.title" />
                            </ControlLabel>
                            <Form.Field name="extraData" className="form-control" />
                            <Form.Message for="extraData"/>
                            <HelpBlock>
                                <Translate id="steps.two-query.help" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                </div>
            )
        } else if (action === 'user') {
            component = (
                <div className="row" key="user">
                    <div className="col-xs-12">
                        <Title>
                            <Translate id="steps.two-user.description" />
                        </Title>
                    </div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-user.title" />
                            </ControlLabel>
                            <Form.Field name="context" className="form-control" />
                            <Form.Message for="context"/>
                            <HelpBlock>
                                <Translate id="steps.two-user.help" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                    <div className="col-xs-4">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-user.title2" />
                            </ControlLabel>
                            <br />
                            <Form.Field name="contextReadOnly" />
                            <Form.Message for="contextReadOnly" />
                        </FormGroup>
                    </div>
                    <div className="col-xs-4">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-user.title1" />
                            </ControlLabel>
                            <br />
                            <Form.Field name="contextMedia" />
                            <Form.Message for="contextMedia" />
                        </FormGroup>
                    </div>
                    <div className="col-xs-4">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-user.title4" />
                            </ControlLabel>
                            <br />
                            <Form.Field name="contextTextArea" />
                            <Form.Message for="contextTextArea" />
                        </FormGroup>
                    </div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-user.title3" />
                            </ControlLabel>
                            <Form.Field name="contextSchema" className="form-control" type="textarea" rows={10} />
                            <Form.Message for="contextSchema" />
                            <HelpBlock>
                                <Translate id="steps.two-user.help3" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                </div>
            )
        } else if (action === 'action') {
            component = (
                <div className="row" key="action">
                    <div className="col-xs-12">
                        <Title>
                            <Translate id="steps.two-action.description" />
                        </Title>
                    </div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-action.title" />
                            </ControlLabel>
                            <Form.Field name="textButton" className="form-control" type="textarea" rows={2}/>
                            <Form.Message for="textButton" />
                            <HelpBlock>
                                <Translate id="steps.two-action.help" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-action.title2" />
                            </ControlLabel>
                            <Form.Field name="flow" className="form-control" />
                            <Form.Message for="flow" />
                            <HelpBlock>
                                <Translate id="steps.two-action.help2" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                    <div className="col-xs-6">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-action.title3" />
                            </ControlLabel>
                            &nbsp;
                            <Form.Field name="unpause" type="select">
                                <option value={false}>
                                    {this.props.translate('misc.no')}
                                </option>
                                <option value={true}>
                                    {this.props.translate('misc.yes')}
                                </option>
                            </Form.Field>
                            <Form.Message for="unpause" />
                            <HelpBlock>
                                <Translate id="steps.two-action.help3" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                    <div className="col-xs-6">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.two-action.title4" />
                            </ControlLabel>
                            &nbsp;
                            <Form.Field name="canResolve" type="select">
                                <option value={false}>
                                    {this.props.translate('misc.no')}
                                </option>
                                <option value={true}>
                                    {this.props.translate('misc.yes')}
                                </option>
                            </Form.Field>
                            <Form.Message for="canResolve" />
                            <HelpBlock>
                                <Translate id="steps.two-action.help4" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                    { ((this.state.formValues && this.state.formValues.canResolve) || (!this.state.formValues && step.canResolve)) && (
                        <div className="col-xs-6">
                            <FormGroup>
                                <ControlLabel>
                                    <Translate id="steps.two-action.title5" />
                                </ControlLabel>
                                &nbsp;
                                <Form.Field name="autoResolve" type="select">
                                    <option value={false}>
                                        {this.props.translate('misc.no')}
                                    </option>
                                    <option value={true}>
                                        {this.props.translate('misc.yes')}
                                    </option>
                                </Form.Field>
                                <Form.Message for="autoResolve" />
                                <HelpBlock>
                                    <Translate id="steps.two-action.help5" />
                                </HelpBlock>
                            </FormGroup>
                        </div>
                    ) || null }
                    <div className="col-xs-12">
                        <hr/>
                    </div>
                    <div className="col-xs-12">
                        <Title>
                            <Translate id="steps.three-action.description" />
                        </Title>
                    </div>
                    <div className="col-xs-5">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.three-action.title" />
                            </ControlLabel>
                            <Form.Field name="condition.key" className="form-control" />
                            <Form.Message for="condition.key" />
                            <HelpBlock>
                                <Translate id="steps.three-action.help" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                    <div className="col-xs-5">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.three-action.title2" />
                            </ControlLabel>
                            <Form.Field name="condition.value" className="form-control" />
                            <Form.Message for="condition.value" />
                            <HelpBlock>
                                <Translate id="steps.three-action.help2" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                    <div className="col-xs-2">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.three-action.title3" />
                            </ControlLabel>
                            <br />
                            <Form.Field name="condition.enabled" />
                            <Form.Message for="condition.enabled" />
                        </FormGroup>
                    </div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="steps.three-action.title4" />
                            </ControlLabel>
                            <Form.Field name="condition.explanation" className="form-control" />
                            <Form.Message for="condition.explanation" />
                            <HelpBlock>
                                <Translate id="steps.three-action.help4" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                </div>
            )
        }

        const actions = [
            {
                id: 'message',
                title: 'steps.actions.message'
            }, {
                id: 'query',
                title: 'steps.actions.query'
            }, {
                id: 'user',
                title: 'steps.actions.user'
            }, {
                id: 'action',
                title: 'steps.actions.action'
            }
        ]

        return (
            <div className="static-modal">
                <Modal show={open} onHide={cancel}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <Form schema={this.getModelSchema()} defaultValue={this.getModelSchema().default()} onChange={this.changedForm} onSubmit={submit}>
                            <div className="row">
                                <div className="col-xs-12">
                                    <Title>
                                        <Translate id="steps.one.description" />
                                    </Title>
                                </div>
                                <div className="col-xs-12">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="steps.one.title" />
                                        </ControlLabel>
                                        <Form.Field name="title" className="form-control" />
                                        <Form.Message for="title" />
                                        <HelpBlock>
                                            <Translate id="steps.one.help" />
                                        </HelpBlock>
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="steps.one.title2" />
                                        </ControlLabel>
                                        <Form.Field
                                            name="type"
                                            type="select"
                                            className="form-control"
                                            onChange={value => this.setState({ action: value })}
                                        >
                                            {actions.map(action => (
                                                <option value={action.id}>
                                                    {this.props.translate(action.title)}
                                                </option>
                                            ))}
                                        </Form.Field>
                                        <Form.Message for="type" />
                                        <HelpBlock>
                                            <Translate id="steps.one.help2" />
                                        </HelpBlock>
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12">
                                    <FormGroup>
                                        <ControlLabel>
                                            <Translate id="steps.one.autoSave" />
                                        </ControlLabel>
                                        <br />
                                        <Form.Field name="autoSave" />
                                        <Form.Message for="autoSave" />
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12">
                                    <hr/>
                                </div>
                            </div>
                            {component}
                            { !loading && (
                                <div className="row">
                                    <div className="col-xs-12">
                                        <FormGroup className="pull-right">
                                            <ButtonToolbar>
                                                <Form.Button type="submit" className="btn btn-primary" disabled={loading}>
                                                    <Translate id="buttons.save" />
                                                </Form.Button>
                                                <Button bsStyle="danger" onClick={cancel} disabled={loading}>
                                                    <Translate id="buttons.cancel" />
                                                </Button>
                                            </ButtonToolbar>
                                        </FormGroup>
                                    </div>
                                </div>
                            ) || null }
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

Step.propTypes = {
    step: PropTypes.object,
    open: PropTypes.bool,
    loading: PropTypes.bool,
    submit: PropTypes.func,
    cancel: PropTypes.func
}

Step.defaultProps = {
    type: 'message'
}

export default withLocalize(Step)
