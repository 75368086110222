import * as actions from '../actions'
import moment from 'moment'
import { isUserProfileKey } from '../../utils/validate'

const defaultState = null

export default function query(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
            return defaultState

        case actions.APP_SELECT:
            return defaultState

        case actions.CLEAN_QUERY:
            return defaultState

        case actions.GET_QUERY:
            if (!action.error) {
                state = action.payload.data
            }
            return Object.assign({}, state)

        case actions.EDIT_CONTEXT:
            if (state && action && action.payload && action.payload.data && action.payload.data.key && state.messageUser && state.messageUser._id === action.payload.data.messageUserId && isUserProfileKey(action.payload.data.key)) {
                state.messageUser[action.payload.data.key] = action.payload.data.value
            }
            return Object.assign({}, state)

        case actions.EDIT_EXTRA_DATA:
            if (state && action && action.payload && action.payload.data && action.payload.data.key && (state._id === action.payload.data.queryId)) {
                if (!state.extra) {
                    state.extra = {}
                }
                state.extra[action.payload.data.key] = action.payload.data.value
            }
            return Object.assign({}, state)

        case actions.SEND_HSM:
            if (state && action && action.payload && action.payload.data && action.payload.data.HSMSendedAt) {
                state.HSMSendedAt = action.payload.data.HSMSendedAt
            }
            return Object.assign({}, state)

        case actions.ASSIGN_HSM_SENDED_AT:
            if (state && action && action.payload && action.payload.data && action.payload.data.HSMSendedAt) {
                state.HSMSendedAt = action.payload.data.HSMSendedAt
            }
            return Object.assign({}, state)

        case actions.REMOVE_HSM_SENDED_AT:
            if (state && state.HSMSendedAt) {
                delete state.HSMSendedAt
            }
            return Object.assign({}, state)

        case actions.MESSAGE_RECEIVE:
            if (state && action.payload.to === 'self' && state.HSMSendedAt) {
                state.lastNotifiedAt = new Date()
                delete state.HSMSendedAt
            }
            return Object.assign({}, state)

        case actions.WEBHOOK_ACTION:
            if (state && state.messageUser && state.messageUser._id && action && action.payload && action.payload.data && action.payload.data.matched >= 1 && ['pause', 'unpase'].indexOf(action.payload.data.webhookAction) >= 0) {
                if (action.payload.data.webhookAction === 'pause') {
                    state.messageUser.pausedBot = {
                        from: new Date()
                    }
                } else if (action.payload.data.webhookAction === 'unpause') {
                    delete state.messageUser.pausedBot
                }
            }
            return Object.assign({}, state)

        case actions.UPDATE_QUERY:
            if (state && action.payload.validation(state)) {
                if (action.payload.data.message) {
                    action.payload.data.message = Object.assign(state.message, action.payload.data.message)
                }
                state = Object.assign(state, action.payload.data)
            }
            return Object.assign({}, state)

        case actions.TAKE_QUERY:
        case actions.ASSIGN_QUERY:
            if (state && !action.error && action.payload.data.query === state._id) {
                state.dates.progress = new Date().toISOString()
                state.status = 'progress'
                state.operator = action.payload.data.operator
            }
            return Object.assign({}, state)

        case actions.RESOLVE_QUERY:
            if (state && !action.error && action.payload.data.query === state._id) {
                state.dates.resolved = new Date().toISOString()
                state.status = 'resolved'
            }
            return Object.assign({}, state)

        case actions.ASSIGN_RESOLUTION:
            if (state && !action.error && action.payload.data.query === state._id) {
                state.resolutionSettedAt = new Date().toISOString()
                state.resolution = action.payload.data.resolution
            }
            return Object.assign({}, state)

        case actions.SET_QUERY_PRIVATE:
            if (state && state.isPublic && !action.error && action.payload === state._id) {
                return Object.assign({}, state, { isPublic: false })
            } else {
                return state
            }

        default:
            return state
    }
}
