import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 8px;
`
export const TextPlay = styled.div`
    align-items: center;
    font-size: 12px;
`

export const Transcription = styled.div`
    font-style: italic;
    font-size: 1.3rem;
    margin-top: 5px;
`
