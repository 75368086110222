import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import moment from 'moment'
import 'moment-timezone'
import momentLocalizer from 'react-widgets-moment'
import * as yup from 'yup'
import { Translate, withLocalize } from 'react-localize-redux'
import {
    FormGroup,
    ControlLabel,
    ButtonToolbar,
    Button,
    HelpBlock
} from 'react-bootstrap'

class ScheduleUnavailableRecurrentAddEddit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataColumnsNames: [
                'unschedules.dataColumnsNames.enable', 'unschedules.dataColumnsNames.week'
            ],
            days: null,
            form: {},
            loading: false
        }

        this.prepareData = this.prepareData.bind(this)
        Form.addInputTypes(types)
        momentLocalizer(moment)
    }

    componentWillMount() {
        const self = this
        const { scheduleUnavailableDay } = self.props
        const days = []
        for (let i = 0; i <= 6; i++) {
            days.push({
                enabled: scheduleUnavailableDay && scheduleUnavailableDay.isoWeekday && scheduleUnavailableDay.isoWeekday.length > 0 ? scheduleUnavailableDay.isoWeekday.find(day => day === (i + 1)) : false,
                from: '09:00',
                to: '18:00',
                isoWeekday: i + 1
            })
        }
        self.setState({
            days: days
        })
    }

    getModelSchema() {
        const self = this
        const { scheduleUnavailableDay } = self.props

        const defaultOrigin = scheduleUnavailableDay && scheduleUnavailableDay.origins || []
        return yup.object({
            enabled: yup.boolean().nullable().default((scheduleUnavailableDay && scheduleUnavailableDay.enabled) ? scheduleUnavailableDay.enabled : false),
            from: yup.date().nullable().required(self.props.translate('unschedules.validations.from')).default((scheduleUnavailableDay && scheduleUnavailableDay.from) ? moment(scheduleUnavailableDay.from, 'HH:mm').toDate() : moment().hours(9).minutes(0).toDate()),
            to: yup.date().nullable().required(self.props.translate('unschedules.validations.to')).default((scheduleUnavailableDay && scheduleUnavailableDay.to) ? moment(scheduleUnavailableDay.to, 'HH:mm').toDate() : moment().hours(18).minutes(0).toDate()),
            message: yup.string().required(self.props.translate('unschedules.validations.message')).default(scheduleUnavailableDay && scheduleUnavailableDay.message || ''),
            origins: yup.array().default(defaultOrigin)
        })
    }

    prepareData(formValues) {
        const self = this
        const { days } = self.state
        const isoWeekday = []
        const values = {
            date: moment().utc().startOf('day').toDate(),
            from: moment(formValues.from).format('HH:mm'),
            to: moment(formValues.to).format('HH:mm'),
            message: formValues.message,
            isRecurrent: true,
            origins: formValues.origins
        }

        days.forEach(day => {
            if (day && day.enabled) {
                isoWeekday.push(day.isoWeekday)
            }
        })
        values.isoWeekday = isoWeekday
        self.props.addEditScheduleUnavailableDay(values)
    }

    changeValue(value, prop, index) {
        const days = this.state.days.slice(0)
        days[index][prop] = value
        this.setState({
            days
        })
    }

    dataObjectProcess(day, index) {
        const row = []
        row.push(
            <td key={`column_checkbox${index + 1}`}>
                <FormGroup>
                    <Form.Field
                        type='checkbox'
                        name="enabled"
                        checked= {day.enabled}
                        onChange={(e, value) => {
                            this.changeValue(value.target.checked, 'enabled', index)
                        }}
                    />
                </FormGroup>
            </td>
        )
        row.push(
            <td key={`column_isoWeekday${index + 1}`}>
                <FormGroup>
                    <Translate id={`schedules.days.${day.isoWeekday}`} />
                </FormGroup>
            </td>
        )

        return row
    }

    render() {
        const self = this
        const {activeLanguage, origins} = self.props
        const {days, dataColumnsNames, loading} = self.state
        const itemsProcessed = []
        if (days) {
            days.forEach((day, index) => {
                itemsProcessed.push(self.dataObjectProcess(day, index))
            })
        }
        return (
            <Form schema={self.getModelSchema()} defaultValue={self.getModelSchema().default()} onSubmit={self.prepareData}>
                <div className="row">
                    <div className="col-xs-6">
                        <table className="table table-striped table-bordered table-hover table-responsive">
                            <thead>
                                <tr>
                                    {
                                        dataColumnsNames.map((columnName, index) => {
                                            return (
                                                <th key={'header_' + index}>{<Translate id={columnName}/>}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {itemsProcessed.map((item, index) => {
                                    return (
                                        <tr key={'row_' + index}>
                                            {item}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-xs-6">
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="schedules.dataColumnsNames.from" />
                            </ControlLabel>
                            <Form.Field
                                name="from"
                                placeholder="09:00"
                                type="datetimepicker"
                                culture={activeLanguage.code}
                                date={false}
                                parse={['HH:mm', 'H:mm']}
                                format="HH:mm"
                                timeFormat="HH:mm"
                                disabled={loading}
                            />
                            <Form.Message for="from"/>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="schedules.dataColumnsNames.to" />
                            </ControlLabel>
                            <Form.Field
                                name="to"
                                placeholder="18:00"
                                type="datetimepicker"
                                culture={activeLanguage.code}
                                date={false}
                                parse={['HH:mm', 'H:mm']}
                                format="HH:mm"
                                timeFormat="HH:mm"
                                disabled={loading}
                            />
                            <Form.Message for="to"/>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="unschedules.dataColumnsNames.reason" />
                            </ControlLabel>
                            <Form.Field
                                style={{margin: '0px -8px 0px 0px', height: '202px', width: '553px'}}
                                name="message"
                                placeholder={self.props.translate('unschedules.placeholders.message')}
                                type="textarea"
                                className="form-control"
                                disabled={loading}
                            />
                            <Form.Message for="message"/>
                            <HelpBlock>
                                <Translate id="unschedules.helps.message" />
                            </HelpBlock>
                        </FormGroup>
                    </div>
                </div>
                {origins && origins.objects && origins.objects.length ?
                    <div className="row">
                        <div className="col-xs-12">
                            <FormGroup>
                                <ControlLabel>
                                    <Translate id="users.propNames.origins" />
                                </ControlLabel>
                                <Form.Field
                                    name="origins"
                                    type="multiselect"
                                    textField="name"
                                    data={origins.objects}
                                    disabled={loading}
                                />
                                <Form.Message for="origins" />
                            </FormGroup>
                        </div>
                    </div> : ''}
                <div>
                    <div className="col-xs-12">
                        <FormGroup>
                            <ButtonToolbar className="pull-right">
                                <Form.Button type="submit" className="btn btn-primary">
                                    <Translate id="buttons.save" />
                                </Form.Button>
                                <Button type="button" className="btn btn-danger" onClick={self.props.goBack}>
                                    <Translate id="buttons.cancel" />
                                </Button>
                            </ButtonToolbar>
                        </FormGroup>
                    </div>
                </div>
            </Form>
        )
    }
}

ScheduleUnavailableRecurrentAddEddit.propTypes = {
    goBack: PropTypes.func,
    addEditScheduleUnavailableDay: PropTypes.func
}
function mapStateToProps(state) {
    return {
        schedule: state.schedule,
        profile: state.profile,
        routing: state.routing,
        scheduleUnavailableDay: state.scheduleUnavailableDay,
        origins: state.origins
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(ScheduleUnavailableRecurrentAddEddit))
