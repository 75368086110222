import styled from 'styled-components'

export const Container = styled.div`
    padding-left: 15px
    padding-right: 15px
`

export const Queries = styled.li`
  list-style: none
  padding: 10px
  background: #eee
  display: flex
  justify-content: space-between
`
export const Form = styled.div`
    display: flex
    align-items: center
    justify-content: space-evenly
`

export const FormGroup = styled.div`
    display: flex
    align-items: center
    & > select {
        width: auto
        color: #000
        border: none
        border-radius: 3px
        padding: 2px 5px
        &:focus {
            outline: 0
        }
    }
`

export const ControlLabel = styled.span`
    font-weight: bold
    margin-right: 5px
`
