import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, browserHistory } from 'react-router'
import * as actions from '../../state/actions'
import * as project from '../../utils/project'
import Spinner from '../../components/common/Spinner'
import Navigation from '../../components/Navigation'
import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'

import '../../less/mt.less'
import '../../less/sbadmin2/sb-admin-2.less'

import {
    Alerts,
    Pointer,
    Images,
    StatusFooter
} from './styles'

class Manager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            removing: false,
            userOrigins: [],
            urls: [
                {
                    url: '/dashboard',
                    name: 'menu.dashboard',
                    icon: 'tachometer-alt',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 600
                    }
                }, {
                    custom: true,
                    url: `/customer/${this.props.params.appCodename}`,
                    name: 'menu.queries',
                    icon: 'headset',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 500
                    }
                }, {
                    url: '/derive',
                    name: 'menu.derive',
                    icon: 'share',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 1000
                    }
                }, {
                    url: '/user',
                    name: 'menu.users',
                    icon: 'user',
                    condition: () => {
                        return this.props.profile && (this.props.profile.profile >= 1000 || this.props.profile.authAbmUser && (this.props.profile.authAbmUser.generateUser || this.props.profile.authAbmUser.editUser || this.props.profile.authAbmUser.deleteUser))
                    }
                }, {
                    url: '/topic',
                    name: 'menu.knowledgeBase',
                    icon: 'tasks',
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 600
                    }
                }, {
                    url: '/hsm',
                    name: 'menu.whatsAppHsm',
                    icon: ['fab', 'whatsapp'],
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 500
                    }
                }, {
                    collapse: true,
                    collapsed: true,
                    name: 'menu.config',
                    icon: 'cog',
                    urls: [
                        {
                            url: '/schedule',
                            name: 'menu.schedule',
                            icon: 'calendar-alt'
                        },
                        {
                            url: '/origins',
                            name: 'menu.origins',
                            icon: 'comment'
                        }, {
                            url: '/resolutions',
                            name: 'menu.resolutions',
                            icon: 'check'
                        }
                    ],
                    condition: () => {
                        return this.props.profile && this.props.profile.profile >= 600
                    }
                }
            ]
        }
    }

    async componentWillMount() {
        const self = this
        try {
            const { profile, actions, params } = self.props
            await actions.appGetOneCodename(params.appCodename)
            if (profile) {
                const action = await actions.userGetOne(params.appCodename, profile._id, (profile && profile.profile >= 1000) ? {
                    populates: '[{"key": "origins"}]'
                } : {})
                self.setState({
                    userOrigins: action.payload.data.origins
                })
            }
        } catch (error) {
            // do nothing
        }
    }

    resetSkip() {
        this.props.actions.cleanSkip()
    }

    toggleCollapse(url) {
        const { urls } = this.state
        const matchedUrl = urls.indexOf(url)

        if (matchedUrl >= 0) {
            urls[matchedUrl].collapsed = !urls[matchedUrl].collapsed
            this.setState({
                urls
            })
        }
    }

    render () {
        const { isReady, profile, app, statusCT } = this.props

        if (isReady && profile && profile.profile && app) {
            const { urls } = this.state

            return (
                <div id="wrapper">
                    <div id='navigation'>
                        <Navigation appCodename={this.props.params.appCodename}>
                            <div className="navbar-default sidebar" role="navigation">
                                <div className="sidebar-nav navbar-collapse">
                                    <ul className="nav" id="side-menu">
                                        { urls.map((url, index) => {
                                            if (!url.condition || (url.condition && url.condition())) {
                                                if (url.collapse) {
                                                    return (
                                                        <li key={'collapse-' + index}>
                                                            <a onClick={this.toggleCollapse.bind(this, url)}>
                                                                <div>
                                                                    {url.icon ? (<FontAwesomeIcon icon={url.icon} />) : ''}
                                                                    &nbsp;&nbsp;
                                                                    {url.component ? url.component : (<Translate id={url.name} />)}
                                                                    <FontAwesomeIcon icon={`caret-${!url.collapsed ? 'up' : 'down'}`} style={{
                                                                        float: 'right'
                                                                    }} />
                                                                </div>
                                                            </a>
                                                            {!url.collapsed && <ul className="nav nav-second-level">
                                                                { url.urls.map(slUrl => {
                                                                    if (!slUrl.condition || (slUrl.condition && slUrl.condition())) {
                                                                        return (
                                                                            <li key={slUrl.url}>
                                                                                <Link to={`/manager/${this.props.params.appCodename}${slUrl.url}`} activeClassName="active" onClick={this.resetSkip.bind(this)}>
                                                                                    {slUrl.icon ? (<FontAwesomeIcon icon={slUrl.icon} />) : ''}
                                                                                    &nbsp;&nbsp;
                                                                                    {slUrl.component ? slUrl.component : (<Translate id={slUrl.name} />)}
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                }) }
                                                            </ul>}
                                                        </li>
                                                    )
                                                } else {
                                                    return (
                                                        <li key={url.url}>
                                                            <Link to={url.custom ? `/manager${url.url}` : `/manager/${this.props.params.appCodename}${url.url}`} onClick={this.resetSkip.bind(this)}>
                                                                {url.icon ? (<FontAwesomeIcon icon={url.icon} />) : ''}

                                                                &nbsp;&nbsp;
                                                                {url.component ? url.component : (<Translate id={url.name} />)}
                                                            </Link>
                                                        </li>
                                                    )
                                                }
                                            }
                                        }) }
                                    </ul>
                                </div>
                                <div style={{width: '100%', padding: '1em 2em'}}></div>
                                {
                                    statusCT && statusCT.icon && <StatusFooter>
                                        <a href='https://status.chat-tonic.com' target='_blank'>
                                            {statusCT.description}
                                            &nbsp;&nbsp;
                                            <FontAwesomeIcon icon={statusCT.icon} style={{ color: statusCT.color }}/>
                                        </a>
                                    </StatusFooter>
                                }
                            </div>
                        </Navigation>
                    </div>
                    <div id="page-wrapper">
                        {this.props.children}
                    </div>
                </div>
            )
        } else {
            return (
                <Spinner />
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        profile: state.profile,
        isReady: state.isReady,
        routing: state.routing,
        socket: state.socket,
        statusCT: state.statusCT
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Manager)
