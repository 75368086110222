import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../state/actions'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import Spinner from './../common/Spinner'
import * as yup from 'yup'
import Form from 'react-formal'
import types from 'react-formal-inputs'
import { DropdownList } from 'react-widgets'
import Dropzone from 'react-dropzone'
import style from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Step from './components/Step'
import { Translate, withLocalize } from 'react-localize-redux'

import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove
} from 'react-sortable-hoc'

import {
    Button,
    ButtonToolbar,
    Modal,
    Table,
    FormGroup,
    ControlLabel
} from 'react-bootstrap'

class TopicAddEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            tags: [],
            steps: [],
            value: [],
            deleteStepIdx: false,
            editStepIdx: false,
            openDeleteStep: false,
            openEditStep: false,
            newStep: false,
            typeOptionAnswer: 'Respuesta',
            formValues: {},
            date: new Date().toISOString(),
            uploadingImage: false,
            uploadImageStatus: null
        }

        Form.addInputTypes(types)

        this.onSortEnd = this.onSortEnd.bind(this)
        this.deleteMedia = this.deleteMedia.bind(this)
        this.goBack = this.goBack.bind(this)
        this.cancelModal = this.cancelModal.bind(this)
        this.confirmDeleteObject = this.confirmDeleteObject.bind(this)
        this.editStepConfirm = this.editStepConfirm.bind(this)
        this.addTag = this.addTag.bind(this)
        this.onDrop = this.onDrop.bind(this)
        this.addStep = this.addStep.bind(this)
        this.editTopic = this.editTopic.bind(this)
    }

    componentDidMount() {
        if (this.props.params.topicId) {
            this.props.actions.getOneTopic(this.props.params.appCodename, this.props.params.topicId).then(() => {
                if (this.props.topic) {
                    const value = {
                        title: this.props.topic.title,
                        description: this.props.topic.description,
                        tags: this.props.topic.tags,
                        answerUser: this.props.topic.answerUser,
                        video: this.props.topic.video
                    }

                    this.setState({
                        formValues: value,
                        typeOptionAnswer: this.props.topic.type,
                        value: this.props.topic.tags || [],
                        tags: this.props.topic.tags || [],
                        steps: this.props.topic.steps || [],
                        date: new Date().toDateString()
                    })
                }
            }).catch(() => {
                // do nothing
            })
        }
    }

    componentWillUnmount() {
        this.props.actions.cleanTopicOneSuggest()
    }

    getModelSchema() {
        const { formValues, value } = this.state

        return yup.object({
            title: yup.string().required(this.props.translate('topics.validations.title')).default(formValues.title),
            description: yup.string().nullable().default(formValues.description),
            tags: yup.array().nullable().default(value),
            answerUser: yup.string().nullable().default(formValues.answerUser),
            video: yup.string().nullable().default(formValues.video)
        })
    }

    goBack() {
        browserHistory.push({
            pathname: `/manager/${this.props.params.appCodename}/topic`
        })
    }

    goToTopic(topicID) {
        window.location.href = `/manager/${this.props.params.appCodename}/topic/edit/${topicID}`
    }

    editTopic(formValues) {
        const self = this
        let promise = Promise.resolve()

        if (formValues.steps && formValues.steps.length) {
            formValues.steps = formValues.steps.map(step => {
                if (step.context && step.context === 'id') {
                    step.contextReadOnly = true
                }
                return step
            })
        }

        if (self.props.params.topicId) {
            promise = self.props.actions.editTopic(self.props.params.appCodename, self.props.params.topicId, {
                title: formValues.title,
                description: formValues.description,
                tags: formValues.tags,
                answerUser: formValues.answerUser,
                video: formValues.video,
                type: self.state.typeOptionAnswer,
                steps: self.state.steps
            })
        } else {
            promise = self.props.actions.addTopic(self.props.params.appCodename, {
                title: formValues.title,
                description: formValues.description,
                tags: formValues.tags,
                answerUser: formValues.answerUser,
                video: formValues.video,
                type: self.state.typeOptionAnswer,
                steps: self.state.steps
            })
        }

        promise = promise.catch(error => {
            console.log(error)
        }).then((data) => {
            setTimeout(_ => {
                self.setState({
                    loading: false
                }, () => {
                    if (self.props.params.topicId) {
                        self.goBack()
                    } else {
                        if (data && data.payload && data.payload.data && data.payload.data._id) {
                            self.goToTopic(data.payload.data._id)
                        } else {
                            self.goBack()
                        }
                    }
                })
            }, 500)
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    editTopicStep() {
        const steps = (this.state.steps && this.state.steps.length) ? this.state.steps.map(step => {
            if (step.context && step.context === 'id') {
                step.contextReadOnly = true
            }
            return step
        }) : []

        let promise = Promise.resolve()

        if (this.props.params.topicId && steps && steps.length) {
            promise = this.props.actions.editTopic(this.props.params.appCodename, this.props.params.topicId, { steps: steps })
        }

        promise = promise.catch(error => {
            console.log(error)
        }).then(() => {
            this.setState({
                loading: false,
                editStepIdx: false,
                deleteStepIdx: false,
                newStep: false,
                openDeleteStep: false,
                openEditStep: false
            }, () => {
                return Promise.resolve()
            })
        })

        this.setState({
            loading: true
        }, () => promise)
    }

    addTag(tag) {
        const value = this.state.value || []
        const tags = this.state.tags

        value.push(tag)
        tags.push(tag)

        this.setState({
            value: value,
            tags: tags,
            date: new Date().toISOString()
        })
    }

    onSortEnd({ oldIndex, newIndex }) {
        const steps = arrayMove(this.state.steps, oldIndex, newIndex)
        this.setState({
            steps
        }, () => this.editTopicStep())
    }

    deleteStep(step, idx) {
        this.setState({
            deleteStepIdx: idx,
            openDeleteStep: true
        })
    }

    confirmDeleteObject() {
        const { deleteStepIdx } = this.state
        const steps = this.state.steps.slice(0)

        if (steps && steps.length && deleteStepIdx >= 0) {
            steps.splice(deleteStepIdx, 1)

            this.setState({
                steps
            }, () => this.editTopicStep())
        } else {
            this.setState({
                deleteStepIdx: false,
                openDeleteStep: false
            })
        }
    }

    editStepConfirm(formValues) {
        const { editStepIdx, newStep } = this.state
        const steps = this.state.steps.slice(0)

        if (steps && steps.length && steps[editStepIdx]) {
            steps[editStepIdx].title = formValues.title
            steps[editStepIdx].type = formValues.type
            steps[editStepIdx].answerUser = formValues.answerUser
            steps[editStepIdx].textButton = formValues.textButton
            steps[editStepIdx].extraData = formValues.extraData
            steps[editStepIdx].context = formValues.context
            steps[editStepIdx].contextReadOnly = formValues.contextReadOnly
            steps[editStepIdx].contextTextArea = formValues.contextTextArea
            steps[editStepIdx].contextMedia = formValues.contextMedia
            steps[editStepIdx].contextSchema = formValues.contextSchema
            steps[editStepIdx].flow = formValues.flow
            steps[editStepIdx].condition = formValues.condition
            steps[editStepIdx].unpause = formValues.unpause
            steps[editStepIdx].canResolve = formValues.canResolve
            steps[editStepIdx].autoSave = formValues.autoSave
            steps[editStepIdx].autoResolve = (formValues.canResolve && formValues.autoResolve) || false
        } else if (steps && newStep) {
            steps.push({
                title: formValues.title,
                type: formValues.type,
                answerUser: formValues.answerUser,
                textButton: formValues.textButton,
                extraData: formValues.extraData,
                context: formValues.context,
                contextReadOnly: formValues.contextReadOnly,
                contextTextArea: formValues.contextTextArea,
                contextMedia: formValues.contextMedia,
                contextSchema: formValues.contextSchema,
                flow: formValues.flow,
                condition: formValues.condition,
                unpause: formValues.unpause,
                canResolve: formValues.canResolve,
                autoSave: formValues.autoSave,
                autoResolve: (formValues.canResolve && formValues.autoResolve) || false
            })
        }

        this.setState({
            steps
        }, () => this.editTopicStep())
    }

    addStep(step, idx) {
        this.setState({
            newStep: true,
            openEditStep: true
        })
    }

    editStep(step, idx) {
        this.setState({
            editStepIdx: idx,
            openEditStep: true
        })
    }

    cancelModal() {
        this.setState({
            editStepIdx: false,
            deleteStepIdx: false,
            newStep: false,
            openDeleteStep: false,
            openEditStep: false
        })
    }

    onDrop(files) {
        const { topic } = this.props
        let promise = Promise.resolve()
        let status = 'check'

        if (topic && topic.media && topic.media.length) {
            topic.media.forEach(media => {
                promise = promise.then(() => {
                    return this.props.actions.mediaDeleteTopic(this.props.params.appCodename, this.props.params.topicId, media._id)
                })
            })
        }

        files.forEach(file => {
            promise = promise.then(() => {
                if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
                    const fileNameVsTags = file.name.split('.')
                    const tags = fileNameVsTags[0].split('-')
                    const title = tags[0]
                    tags.shift()

                    const formData = new FormData()
                    formData.set('upload', file, file.name)
                    formData.set('title', title)

                    if (tags.length) {
                        formData.set('tags', tags.join(','))
                    }

                    return this.props.actions.mediaAddTopic(this.props.params.appCodename, this.props.params.topicId, formData)
                } else {
                    status = 'times'
                    return Promise.resolve()
                }
            })
        })

        promise = promise.then(() => {
            return this.props.actions.getOneTopic(this.props.params.appCodename, this.props.params.topicId)
        }).catch(() => {
            // do nothing
        }).then(() => {
            this.setState({
                loading: false,
                uploadingImage: false,
                uploadImageStatus: status
            })
        })

        this.setState({
            loading: true,
            uploadingImage: true,
            uploadImageStatus: null
        }, () => promise)
    }

    deleteMedia() {
        const { topic } = this.props
        let promise = Promise.resolve()

        if (topic && topic.media && topic.media.length) {
            topic.media.forEach((media) => {
                promise = promise.then(() => {
                    return this.props.actions.mediaDeleteTopic(this.props.params.appCodename, this.props.params.topicId, media._id)
                })
            })
        }

        promise = promise.then(() => {
            return this.props.actions.getOneTopic(this.props.params.appCodename, this.props.params.topicId)
        }).catch(() => {
            // do nothing
        }).then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true,
            uploadImageStatus: null
        }, () => promise)
    }

    render() {
        const tipo = [{
            name: this.props.translate('topics.types.answer'),
            id: 'Respuesta'
        }]
        const { openDeleteStep, openEditStep, newStep, steps, editStepIdx, loading, typeOptionAnswer, uploadingImage, uploadImageStatus, value, date, tags } = this.state
        const { topic, profile } = this.props
        let content

        if (profile && profile.profile && profile.profile === 2000) {
            tipo.push({
                name: this.props.translate('topics.types.steps'),
                id: 'Guia'
            })
        }

        const modalInstanceDeleteStep = (
            <div className="static-modal">
                <Modal show={openDeleteStep} onHide={this.cancelModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Translate id="steps.delete.title" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Translate id="steps.delete.description" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.cancelModal}>
                            <Translate id="buttons.cancel" />
                        </Button>
                        <Button onClick={this.confirmDeleteObject} bsStyle="danger">
                            <Translate id="buttons.delete" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )

        const modalInstanceEditStep = openEditStep ? (
            <Step
                step={(steps && steps[editStepIdx]) ? steps[editStepIdx] : {}}
                open={openEditStep}
                isNew={newStep}
                loading={loading}
                submit={this.editStepConfirm}
                cancel={this.cancelModal}
            />
        ) : ''

        const DragHandle = SortableHandle(() => (
            <FontAwesomeIcon icon="bars" />
        ))

        const SortableItem = SortableElement(({value, test}) => (
            <tr>
                <td><DragHandle /> {test + 1}</td>
                <td>{value.title}</td>
                <td>{value.answerUser}</td>
                <td>{value.textButton}</td>
                <td>{value.extraData}</td>
                <td>{value.context}</td>
                <td>
                    {value.autoSave ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>
                    {value.contextReadOnly ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>
                    {value.contextTextArea ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>
                    {value.contextMedia ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>{value.flow}</td>
                <td>
                    {value.condition && value.condition.enabled ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>
                    {value.unpause ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>
                    {value.canResolve ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>
                    {value.autoResolve ? (
                        <FontAwesomeIcon icon="check" />
                    ) : (
                        <FontAwesomeIcon icon="times" />
                    )}
                </td>
                <td>
                    <ButtonToolbar>
                        <Button title={this.props.translate('buttons.delete')} bsStyle="danger" bsSize="small" onClick={this.deleteStep.bind(this, value, test)} disabled={loading}>
                            <FontAwesomeIcon icon="trash-alt" />
                        </Button>
                        <Button title={this.props.translate('buttons.edit')} bsStyle="primary" bsSize="small" onClick={this.editStep.bind(this, value, test)} disabled={loading}>
                            <FontAwesomeIcon icon="edit" />
                        </Button>
                    </ButtonToolbar>
                </td>
            </tr>
        ))

        const SortableList = SortableContainer(({steps}) => {
            return (
                <tbody>
                    {steps.map((value, index) => (
                        <SortableItem key={`item_${index}`} test={index} index={index} value={value} />
                    ))}
                </tbody>
            )
        })

        let contentTypeOption = ''
        if (typeOptionAnswer === 'Respuesta') {
            contentTypeOption = (
                <div>
                    <FormGroup>
                        <ControlLabel>
                            <Translate id="topics.propNames.answer" />
                        </ControlLabel>
                        <Form.Field name="answerUser" className="form-control" type="textarea" rows={3}/>
                        <Form.Message for={'answerUser'}/>
                    </FormGroup>
                    {(this.props.params.topicId && topic) ? (
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="topics.propNames.media" />
                            </ControlLabel>
                            {uploadingImage ? (
                                <FontAwesomeIcon icon="sync" />
                            ) : uploadImageStatus ? (
                                <FontAwesomeIcon icon={uploadImageStatus} />
                            ) : ''}
                            <div>
                                <Dropzone
                                    className={style.dropZone}
                                    onDrop={this.onDrop}
                                    multiple={false}
                                    disabled={loading}
                                    accept="image/jpeg, image/png, application/pdf"
                                >
                                    <div className={style.dropZoneContent} style={{
                                        backgroundImage: `url(${topic.media && topic.media[0] && ((topic.media[0].thumbURL && `${process.env.project.S3_URL}${topic.media[0].thumbURL}`) || (`/manager/static/images/pdf.png`)) || ''})`
                                    }}>
                                        <FontAwesomeIcon icon="cloud-upload-alt" size="2x" />
                                    </div>
                                </Dropzone>
                                <br/><br/>
                                {(topic.media && topic.media.length) && (
                                    <Button title={this.props.translate('buttons.delete')} className="btn btn-danger" onClick={this.deleteMedia} disabled={loading}>
                                        <FontAwesomeIcon icon="trash-alt" />
                                    </Button>
                                ) || null}
                            </div>
                        </FormGroup>
                    ) : ''}
                    <FormGroup>
                        <ControlLabel>
                            <Translate id="topics.propNames.youtube" />
                        </ControlLabel>
                        <Form.Field name="video" className="form-control" />
                        <Form.Message for="video" />
                    </FormGroup>
                </div>
            )
        } else if (typeOptionAnswer === 'Guia') {
            contentTypeOption = (
                <div className="row">
                    <div className="col-lg-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">
                                <div className="row">
                                    <div className="col-xs-10">
                                        <label className={style.description}>
                                            <Translate id="topics.propNames.steps" />
                                        </label>
                                    </div>
                                    <div className="col-xs-2">
                                        <Button title={this.props.translate('buttons.add')} onClick={this.addStep} type="button" className="btn btn-primary pull-right" disabled={loading}>
                                            <FontAwesomeIcon icon="plus" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-body">
                                <Table striped bordered condensed hover responsive >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.title" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.answer" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.buttonText" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.extraData" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.context" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.autoSave" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.justSee" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.textArea" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.media" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.flow" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.condition" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.unpause" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.canResolve" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.autoResolve" />
                                            </th>
                                            <th>
                                                <Translate id="steps.dataColumnsNames.actions" />
                                            </th>
                                        </tr>
                                    </thead>
                                    <SortableList steps={steps} onSortEnd={this.onSortEnd} useDragHandle={true} />
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        if (((!topic || (topic && topic.steps && topic.tags && !value)) && this.props.params.topicId)) {
            return (
                <Spinner/>
            )
        } else {
            content = (
                <Form key={`form_${date}`} className="col-xs-12" schema={this.getModelSchema()} defaultValue={this.getModelSchema().default()} onChange={(formValues, updatedPath) => {
                    this.setState({
                        formValues
                    })
                }} onSubmit={this.editTopic}>
                    <FormGroup>
                        <ControlLabel>
                            <Translate id="topics.propNames.title" />
                        </ControlLabel>
                        <Form.Field name="title" className="form-control" />
                        <Form.Message for="title" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <Translate id="topics.propNames.description" />
                        </ControlLabel>
                        <Form.Field name="description" className="form-control" type="textarea" rows={3} />
                        <Form.Message for="description" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <Translate id="topics.propNames.tags" />
                        </ControlLabel>
                        <Form.Field
                            ref="tags"
                            name="tags"
                            type="multiselect"
                            value={value}
                            data={tags}
                            onCreate={this.addTag}
                            onChange={value => this.setState({ value })}
                        />
                        <Form.Message for="tags" />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>
                            <Translate id="topics.propNames.type" />
                        </ControlLabel>
                        <DropdownList
                            valueField="id"
                            textField="name"
                            data={tipo}
                            value={typeOptionAnswer}
                            onChange={value => this.setState({ typeOptionAnswer: value.id })}
                        />
                    </FormGroup>
                    {contentTypeOption}
                    {(
                        <FormGroup className="pull-right">
                            <ButtonToolbar>
                                <Form.Button className="btn btn-primary" type="submit" disabled={loading}>
                                    <Translate id="buttons.save" />
                                </Form.Button>
                                <Button bsStyle="danger" onClick={this.goBack} disabled={loading}>
                                    <Translate id="buttons.cancel" />
                                </Button>
                            </ButtonToolbar>
                        </FormGroup>
                    ) || null}
                </Form>
            )
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="page-header">
                            <Translate id={this.props.params.topicId ? 'topics.titles.edit' : 'topics.titles.add'} />
                        </h1>
                    </div>
                </div>
                <div className="row">
                    {content}
                </div>
                <div className="row" style={this.state.deleteStepIdx >= 0 ? { display: '' } : { display: 'none' }}>
                    {modalInstanceDeleteStep}
                </div>
                <div className="row" style={this.state.editStepIdx >= 0 ? { display: '' } : { display: 'none' }}>
                    {modalInstanceEditStep}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        topic: state.topic,
        profile: state.profile,
        appSync: state.appSync
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(TopicAddEdit))
