import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

import Spinner from 'react-spinkit'

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome'

import {
    ButtonGroup,
    Button
} from 'react-bootstrap'

import {
    Container
} from './styles'

class Actions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            paused: !!props.messageUser.pausedBot
        }

        this.pause = this.pause.bind(this)
        this.unpause = this.unpause.bind(this)

        this.take = this.take.bind(this)
        this.resolve = this.resolve.bind(this)

        this.assign = this.assign.bind(this)
    }

    pause() {
        const callback = this.props.pause().then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true,
            paused: true
        }, () => callback)
    }

    unpause () {
        const callback = this.props.unpause().then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true,
            paused: false
        }, () => callback)
    }

    take() {
        const callback = this.props.take().then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => callback)
    }

    resolve() {
        const callback = this.props.resolve().then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => callback)
    }

    assign() {
        const callback = this.props.assign().then(() => {
            this.setState({
                loading: false
            })
        })

        this.setState({
            loading: true
        }, () => callback)
    }

    render() {
        const { query, operators, messageUser } = this.props
        const { loading, paused } = this.state

        if (!(messageUser && messageUser._id)) {
            return (
                <Container>
                    <Spinner name="circle" />
                </Container>
            )
        } else {
            const buttons = {
                status: '',
                share: '',
                pause: ''
            }

            if (query) {
                if (['resolved', 'failed'].indexOf(query.status) < 0) {
                    switch (query.status) {
                        // case 'pending':
                        //     buttons.status = (
                        //         <Button onClick={this.take} disabled={loading} style={{
                        //             backgroundColor: '#F4D345',
                        //             color: '#000'
                        //         }}>
                        //             <div>
                        //                 <FontAwesomeIcon icon="headset" />
                        //             </div>
                        //             <div>
                        //                 Atender
                        //             </div>
                        //         </Button>
                        //     )
                        //     break
                        case 'progress':
                            buttons.status = (
                                <Button onClick={this.resolve} disabled={loading} style={{
                                    backgroundColor: '#27CCC3',
                                    color: '#FFF',
                                    minWidth: '90px'
                                }}>
                                    <div>
                                        <FontAwesomeIcon icon="check" />
                                    </div>
                                    <div>
                                        <Translate id="buttons.resolve" />
                                    </div>
                                </Button>
                            )
                            break
                    }

                    if (operators.length) {
                        buttons.share = (
                            <Button onClick={this.assign} disabled={loading} style={{
                                backgroundColor: '#422656',
                                color: '#FFF',
                                minWidth: '90px'
                            }}>
                                <div>
                                    <FontAwesomeIcon icon="share" />
                                </div>
                                <div>
                                    <Translate id="buttons.assign" />
                                </div>
                            </Button>
                        )
                    }
                }
            }

            // buttons.pause = paused ? (
            //     <Button onClick={this.unpause} disabled={loading}>
            //         <div>
            //             <FontAwesomeIcon icon="play" />
            //         </div>
            //         <div>
            //             Despausar
            //         </div>
            //     </Button>
            // ) : (
            //     <Button onClick={this.pause} disabled={loading}>
            //         <div>
            //             <FontAwesomeIcon icon="pause" />
            //         </div>
            //         <div>
            //             Pausar
            //         </div>
            //     </Button>
            // )

            return (
                <Container>
                    {buttons.status}
                    {buttons.share}
                    {/* {buttons.pause} */}
                </Container>
            )
        }
    }
}

Actions.propTypes = {
    query: PropTypes.object,
    messageUser: PropTypes.object,
    profile: PropTypes.object,
    app: PropTypes.object,
    pause: PropTypes.func,
    unpause: PropTypes.func,
    take: PropTypes.func,
    resolve: PropTypes.func,
    assign: PropTypes.func,
    operators: PropTypes.array
}

export default Actions
