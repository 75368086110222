import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { SecondaryItem, UserData, Top } from '../Info/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap'

class InfoUserProfile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.propValue,
            showError: false
        }

        this.editProperty = this.editProperty.bind(this)
    }

    editProperty() {
        let promise = Promise.resolve()

        promise = promise.then(() => {
            return this.props.onConfirm(this.state.value)
        }).catch(() => {
            this.setState({
                showError: true
            })
        })

        this.setState({
            showError: false
        }, () => promise)
    }

    handleEnter(e) {
        if (e && e.target && e.keyCode === 13) {
            e.preventDefault()
            e.target.blur()
        }
    }

    getToggleError() {
        return (
            <OverlayTrigger placement="top" overlay={
                <Tooltip id='tooltip'>
                    {this.props.errorText}
                </Tooltip>
            }>
                <FontAwesomeIcon color='red' icon='times-circle' size={10} style={{marginRight: '4px', marginBottom: '2px'}}/>
            </OverlayTrigger>
        )
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.propValue !== nextProps.propValue) {
            this.setState({value: nextProps.propValue})
        }
    }

    render() {
        const { type, propName, errorText, placeholder, disabled } = this.props
        if (type === 'primary') {
            return (
                <Top
                    type="text"
                    placeholder={placeholder}
                    onChange={e => this.setState({value: e.target.value})}
                    value={this.state.value}
                    onKeyDown={this.handleEnter.bind(this)}
                    onBlur={this.editProperty}
                    disabled={disabled}
                />
            )
        } else {
            return (
                <SecondaryItem>
                    { this.state.showError && errorText && errorText.length ? this.getToggleError() : '' }
                    <strong>{propName}:</strong>
                    &nbsp;
                    <UserData type="text"
                        placeholder={placeholder}
                        onChange={e => this.setState({value: e.target.value})}
                        value={this.state.value}
                        onKeyDown={this.handleEnter.bind(this)}
                        onBlur={this.editProperty}
                        disabled={disabled}
                    />
                </SecondaryItem>
            )
        }
    }
}

InfoUserProfile.propTypes = {
    propName: PropTypes.string,
    placeholder: PropTypes.string,
    errorText: PropTypes.string,
    propValue: PropTypes.string,
    onConfirm: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool
}

export default InfoUserProfile
