import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-direction: ${props => (props.from === 'self') ? 'row-reverse' : 'row'};
    align-items: center;
    padding: 3px 15px;
    font-size: 13px;
    color: ${props => (props.from === 'self') ? '#FFFFFF' : '#666666'};
    line-height: 18px;
    position: relative;
`

export const Container = styled.div`
    display: flex;
    flex-direction: ${props => (props.from === 'self') ? 'row-reverse' : 'row'};
    align-items: center;
    flex-wrap: wrap;
    max-width: 90%;
    ${props => !props.firstGroupMessage && props.operatorData ? 'padding-right: 44px' : ''}
`

export const Avatar = styled.div`
    margin: ${props => props.from === 'self' ? '0px 0px 15px 10px' : '0px 5px 5px 0px'};
    width: 30px;
    height: 30px;
    background-image: url(${props => props.from === 'self' ? '/manager/static/images/user.png' : props.logo});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: block;
    border-radius: 100%;
    border: 2px solid ${props => props.from === 'self' ? (props.userColor ? props.userColor || 'lightgray' : 'rgb(36, 157, 219)') : props.color};
    background-color: ${props => props.from === 'self' ? (props.userColor ? props.userColor || 'lightgray' : 'rgb(36, 157, 219)') : props.color};
    visibility: visible;
    padding: 30px 30px 0px 0px;

    position: relative;
    display: inline-block;
    &:hover .tipOver {
        opacity: 1;
        visibility: ${props => props.from === 'self' ? 'visible' : 'hidden'};
      }

    .tipOver {
        height: 25px;
        font-size: 13px;
        bottom: 100%;
        ${props => (props.from === 'self') ? 'right: 50%;' : 'left: 50%;'};
        white-space: nowrap;
        background-color: rgba(255, 255, 255, 0.8);
        color: black;
        text-align: center;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        padding: 5px;
        font-style: initial !important;
        text-transform: capitalize;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out;
        -ms-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        -webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.8);
        -moz-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.8);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
    }

    .displayName {
        text-align: center;
        margin: 5px 0px 0px -7px;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 10px;
        line-height: 10px;
        color:black;
        width: 45px;
        overflow: hidden;
    }
`
export const Line = styled.div`
    position: absolute;
    border-right: 2px solid;
    height: 100%;
    color: ${props => props.color};
    opacity: 0.5;
    right: 31px;
    top: 0;
`

export const Dialog = styled.div`
    float: ${props => (props.from === 'self') ? 'right' : 'left'};
    padding-top: 12px;
    margin-top: -5px;
    background-image: url(${props => (props.from === 'self') ? '/static/images/chat-arrow-right.svg' : '/static/images/chat-arrow-left.svg'});
    background-repeat: no-repeat;
    background-position: 0 17px;
    width: 9px;
    height: 30px;
    display: none;
`

export const Message = styled.div`
    float: ${props => (props.from === 'self') ? 'right' : 'left'};
    ${props => {
        if (props.isQueryMessage && ['pending', 'progress'].indexOf(props.queryStatus) >= 0) {
            if (props.queryStatus === 'pending') {
                return 'background-color: #F95E4E;\ncolor: #FFF;'
            } else if (props.queryStatus === 'progress') {
                return 'background-color: #F4D345;\ncolor: #000;'
            }
        } else if (props.from !== 'self') {
            return 'background-color: #F4F7F9;'
        } else if (props.operatorData && props.operatorData.name) {
            return 'background-color: #524C4A;'
        } else {
            return 'background-color: #A8A19F;'
        }
    }}
    padding: ${props => props.isText ? '12' : '5'}px;
    min-width: 30px;
    min-height: 24px;
    border-radius: 3px;

    &:hover .tipOver {
        opacity: 1;
        display: initial;
    }

    .tipOver {
        display: none;
        cursor: pointer;
        bottom: 80%;
        background-color: rgba(255, 255, 255, 0.8);
        color: black;
        border-radius: 4px;
        position: absolute;
        z-index: 1;
        padding: 5px;
        opacity: 0;
        -webkit-transition: opacity 0.5s ease-in-out;
        -moz-transition: opacity 0.5s ease-in-out;
        -ms-transition: opacity 0.5s ease-in-out;
        -o-transition: opacity 0.5s ease-in-out;
        -webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.8);
        -moz-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.8);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
`

export const ExtraData = styled.div`
    font-size: 0.7em;
    color: #808080;
    padding: 0px 10px;
`

export const Date = styled.span`
    text-transform: capitalize;
`
