import styled from 'styled-components'

export const Loading = styled.div`
    width: 100%;
    height: 30%;
    padding: 10px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Container = styled.div`
    width: 100%;
    padding: 10px;
    padding-bottom: 0px;
`

export const UserData = styled.input`
    display: ${props => props.disabled && !props.value ? 'none' : 'initial'};
    width: 100%;
    background-color: transparent;
    text-decoration: ${props => props.disabled ? 'none' : 'underline'};
    text-decoration-style: dotted;
    padding: 2px;
    border: initial;
    font: inherit;
    margin: 0px;
    &:focus {
        text-decoration: none;
        background-color: white;
        padding: 2px;
        outline: none;
    }
`

export const Main = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`
export const UserPhoto = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-right: 10px;
    background-image: url(${props => props.url});
    background-color: lightgray;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid ${props => props.color};
`

export const Icon = styled.div`
    position: relative;
    left: 35px;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    color: ${props => props.color};
    border: 2px solid ${props => props.color};
    font-size: 1.6rem;
    background-color: white;
    border-radius: 100%;
`

export const Data = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 60px);
`

export const Top = styled.input`
    background-color: transparent;
    text-decoration: ${props => props.disabled ? 'none' : 'underline'};
    text-decoration-style: dotted;
    border: initial;
    font-family: sans-serif;
    font-size: 1.49rem;
    font-weight: bold;
    padding: 2px;
    margin: 0px;
    &:focus {
        text-decoration: none;
        background-color: white;
        padding: 2px;
        outline: none;
    }
`

export const Secondary = styled.ul`
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    font-size: 1.22rem;
    & strong {
        white-space: nowrap;
    }
`

export const SecondaryItem = styled.li`
    display: flex;
    align-items: center;
`

export const ExpandableList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & button {
        align-self: flex-end;
        background-color: transparent;
        border: none;
        outline: inherit;
        padding-top: 3px;
    }
`

export const TimeToResponse = styled.div`
    text-align: center;
    margin-top: 5px;
`

export const Separator = styled.div`
    border-top: 1px solid #D4D6D6;
    width: 90%;
    margin: 5px auto;
`
