export const isEmail = (str) => {
    return /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
}

export const isPhone = (str) => {
    return /^[\+]{0,1}[0-9\s\-\[\]\(\)]{10,}$/.test(str)
}

export const isUserProfileKey = (str) => {
    return /^(firstName|lastName|profile\.(id|phone|email|custom\..+))$/.test(str)
}
